import React from "react";

export default function Footer() {
  return (
    <footer className="main-footer">
      <div className="float-right d-none d-sm-block">
        <b>Version</b> 2.1.0
      </div>
      <strong>Copyright © 2014-2022 <a href="https://softmediko.com">Navasys | Ingenios Virtuales</a>.</strong> All rights reserved.
    </footer>
  );
}
