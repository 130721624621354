import React from 'react';
import Api from "../../servicios/api";
import Swal from 'sweetalert2';
import ItemInsumos from "./ItemInsumos";
import ItemMedicamentos from "./ItemMedicamentos";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSave, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

class Crear extends React.Component {
    constructor(props) {
        super(props);
        this.agregarItem = this.agregarItem.bind(this);
        this.state = {
            consecutivo: '0',
            date: '',
            orden: '',
            estado: '0',
            grupo: '',
            id_ope: '',
            id_pro: '',
            bod: '',
            items: [],
            operaciones: [],
            proveedores: [],
            bodegas: [],
            errores: []
        }
    }
    cambioValor = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState({ state, errores: [] })
    }
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    /*currencyFormat(num) {
        return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }*/
    cargarOperaciones() {
        fetch(Api + "SearchOperaciones")
            .then(respuesta => respuesta.json())
            .then((datosOperaciones) => {
                console.log(datosOperaciones);
                this.setState({ operaciones: datosOperaciones })
            })
            .catch(console.log);
    }
    cargarBodegas() {
        fetch(Api + "SearchBodegas/0?page=1")
            .then(respuesta => respuesta.json())
            .then((datosBodegas) => {
                console.log(datosBodegas);
                this.setState({ bodegas: datosBodegas.data })
            })
            .catch(console.log);
    }
    cargarProveedores() {
        fetch(Api + "SearchProveedores/0?page=1")
            .then(respuesta => respuesta.json())
            .then((datosProveedores) => {
                console.log(datosProveedores);
                this.setState({ proveedores: datosProveedores.data })
            })
            .catch(console.log);
    }
    agregarItem(item) {
        this.setState({ items: [...this.state.items, item] });
    }
    borrarItem = (id_mi) => {
        let remove = this.state.items.map(function(item) { return item.id_mi }).indexOf(id_mi)
        Swal.fire({
            title: 'Está seguro de Eliminar el Registro?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Borrar',
            denyButtonText: `No Borrar`,
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(Api + "DelMovimientosItem/" + id_mi)
                    .then((respuesta) => respuesta.json())
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        Swal.fire('Borrado!', datosRespuesta.msj, 'success')
                        this.setState({ items: this.state.items.filter((_, i) => i !== remove) })
                    })
                    .catch(console.log);
            } else if (result.isDenied) {
                Swal.fire('Cancelado', '', 'info')
            }
        })
    }
    enviarDatos = (e) => {
        e.preventDefault();
        console.log("Formulario Enviado...");
        const { orden, grupo, id_ope, id_pro, bod } = this.state;
        var errores = [];
        if (!orden) { errores.push("error_orden"); }
        if (!grupo) { errores.push("error_grupo"); }
        if (!id_ope) { errores.push("error_id_ope"); }
        if (!id_pro) { errores.push("error_id_pro"); }
        if (!bod) { errores.push("error_bod"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let form = document.getElementById('form');
        let formData = new FormData(form);
        fetch(Api + "AddMovimientos", {
            method: "POST",
            body: formData

        })
            .then(respuesta => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                Swal.fire(
                    'Movimientos!',
                    datosRespuesta.msj,
                    'success'
                )
                this.setState({ consecutivo: datosRespuesta.Consecutivo })
            })
            .catch(console.log)
    }
    enviarDatosItems = (e) => {
        e.preventDefault();
        console.log("Formulario Enviado...");
        fetch(Api + "SaveMovimientos/" + this.state.consecutivo, {
            method: "GET"
        })
            .then(respuesta => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                Swal.fire(
                    'Movimientos!',
                    datosRespuesta.msj,
                    'success'
                )
                this.setState({ estado: datosRespuesta.Estado })
                window.$("#nuevoMovimiento").modal('hide');
                this.props.history.push("/MasterMovimientos");
            })
            .catch(console.log)
    }
    componentDidMount() {
        var today = new Date(), newDate = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
        this.setState({ date: newDate });
        this.cargarOperaciones();
        this.cargarProveedores();
        this.cargarBodegas();
    }
    render() {
        const { orden, estado, grupo, id_ope, id_pro, bod, consecutivo, items, operaciones, proveedores, bodegas, date } = this.state;
        return (
            <div className="content">
                <div class="modal fade" id="nuevoItemInsumo" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoItemLabel" aria-hidden="true">
                    <ItemInsumos agregarItem={this.agregarItem} />
                </div>
                <div class="modal fade" id="nuevoItemMedicamento" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoItemLabel" aria-hidden="true">
                    <ItemMedicamentos agregarItem={this.agregarItem} />
                </div>
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="nuevoMovimientoLabel">Registro de Movimientos</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="card">
                                <div style={{ backgroundImage: 'linear-gradient(#2FB3FB, #003153)', color: '#FFFFFF', borderTopLeftRadius: 5, borderTopRightRadius: 5 }} className="card-header text-center">
                                    <h5 style={{ fontSize: 18 }}>Formulario de Registro</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <form className='row g-3' id='form'>
                                                <div style={{ marginTop: 0 }} className='row g-3'>
                                                    <div className="col-md-2">
                                                        <label htmlFor="orden">Orden / Factura</label>
                                                        <input className={((this.verificarError("error_orden")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="orden" id="orden" value={orden} onChange={this.cambioValor} readOnly={this.state.consecutivo === '0' ? "" : true} />
                                                        <small id="helpId" className="invalid-feedback">Digite la Orden / Factura</small>
                                                    </div>
                                                    <div className='col-md-2'>
                                                        <label htmlFor="mov">Consecutivo</label>
                                                        <input className="form-control form-control-sm" type="text" name="mov" id="mov" value={consecutivo} readOnly />
                                                    </div>
                                                    <div className='col-md-2'>
                                                        <label htmlFor="fecha_mov">Fecha Registro</label>
                                                        <input className="form-control form-control-sm" type="text" name="fecha_mov" id="fecha_mov" value={date} readOnly />
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label htmlFor="usuario">Registrado Por</label>
                                                        <input type="hidden" name="id_usuario" id='id_usuario' value={window.localStorage.getItem('k_idusuario')} />
                                                        <input className="form-control form-control-sm" type="text" name="usuario" id="usuario" value={window.localStorage.getItem('k_nombre')} readOnly />
                                                    </div>
                                                    <div className='col-md-2'>
                                                        <label htmlFor="estado">Estado</label>
                                                        <input type="hidden" name="estado" id='estado' value={(this.state.estado === '0') ? "0" : "1"} />
                                                        <input style={{ fontWeight: 'bold' }} className={(this.state.estado === '0' ? "bg-danger " : " bg-success") + " form-control form-control-sm text-center"} type="text" name="estado_visible" id="estado_visible" value={(this.state.estado === '0') ? "Sin Guardar" : "Guardado"} readOnly />
                                                    </div>
                                                </div>
                                                <div style={{ marginTop: 0 }} className='row g-3'>
                                                    <div className="col-md-3">
                                                        <label htmlFor="grupo">Grupo</label>
                                                        <select name="grupo"
                                                            id="grupo"
                                                            className={((this.verificarError("error_grupo")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                            onChange={this.cambioValor} disabled={this.state.consecutivo === '0' ? "" : true}>
                                                            <option value="">Seleccione el Grupo</option>
                                                            <option value="Factura">Factura</option>
                                                            <option value="Orden">Orden</option>
                                                        </select>
                                                        <small id="helpId" className="invalid-feedback">Seleccione el Grupo</small>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <label htmlFor="id_ope">Operación</label>
                                                        <select name="id_ope"
                                                            id="id_ope"
                                                            className={((this.verificarError("error_id_ope")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                            onChange={this.cambioValor} disabled={this.state.consecutivo === '0' ? "" : true}>
                                                            <option value="">Seleccione la Operación</option>
                                                            {
                                                                operaciones.map(itemOperaciones => (
                                                                    <option key={itemOperaciones.id_operaciones} value={itemOperaciones.id_operaciones}>{itemOperaciones.descripcion}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        <small id="helpId" className="invalid-feedback">Seleccione la Operación</small>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <label htmlFor="id_pro">Proveedor</label>
                                                        <select name="id_pro"
                                                            id="id_pro"
                                                            className={((this.verificarError("error_id_pro")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                            onChange={this.cambioValor} disabled={this.state.consecutivo === '0' ? "" : true}>
                                                            <option value="">Seleccione el Proveedor</option>
                                                            {
                                                                proveedores.map(itemProveedor => (
                                                                    <option key={itemProveedor.id} value={itemProveedor.id}>{itemProveedor.nombre}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        <small id="helpId" className="invalid-feedback">Seleccione el Proveedor</small>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <label htmlFor="bod">Bodega</label>
                                                        <select name="bod"
                                                            id="bod"
                                                            className={((this.verificarError("error_bod")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                            onChange={this.cambioValor} disabled={this.state.consecutivo === '0' ? "" : true}>
                                                            <option value="">Seleccione la Bodega</option>
                                                            {
                                                                bodegas.map(itemBodegas => (
                                                                    <option key={itemBodegas.id_bodega} value={itemBodegas.id_bodega}>{itemBodegas.bodega}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        <small id="helpId" className="invalid-feedback">Seleccione la Bodega</small>
                                                    </div>
                                                </div>
                                                <div className='row g-3'>
                                                    <hr style={{ marginTop: 0 }} />
                                                </div>
                                                <div style={{ marginTop: 0 }} className='row text-center'>
                                                    <div className='col'></div>
                                                    <div className='col'>
                                                        {
                                                            this.state.consecutivo === '0' ? (
                                                                <button type="submit" className="btn btn-primary btn-sm" id='btnGuardarMovimiento' onClick={this.enviarDatos}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Generar Consecutivo</button>
                                                            ) : (
                                                                <button type="submit" className="btn btn-primary btn-sm" id='btnGuardarMovimientoItems' onClick={this.enviarDatosItems}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Guardar</button>
                                                            )
                                                        }
                                                    </div>
                                                    <div className='col'></div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card'>
                                <div style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5 }} className="card-header">
                                    {
                                        (this.state.bod === '1') ? (
                                            <button className='btn btn-success btn-sm' id='btnNuevoItemInsumo' type='button' data-bs-toggle='modal' data-bs-target='#nuevoItemInsumo' disabled={this.state.consecutivo !== '0' ? "" : true}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nuevo Ítem</button>
                                        ) : (
                                            <button className='btn btn-success btn-sm' id='btnNuevoItemMedicamento' type='button' data-bs-toggle='modal' data-bs-target='#nuevoItemMedicamento' disabled={this.state.consecutivo !== '0' ? "" : true}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nuevo Ítem</button>
                                        )
                                    }
                                </div>
                                <div className='card-body'>
                                    <table className="table table-hover table-condensed table-sm">
                                        <thead className="thead-inverse">
                                            <tr>
                                                <th>CÓDIGO</th>
                                                <th>DESCRIPCIÓN</th>
                                                <th>CANTIDAD</th>
                                                <th>PRECIO</th>
                                                <th>ACCIONES</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                items.length ? (
                                                    items.map(itemMovimiento => (
                                                        <tr key={itemMovimiento.id_mi}>
                                                            <td>{itemMovimiento.codigo}</td>
                                                            <td>{itemMovimiento.nn}</td>
                                                            <td>{itemMovimiento.cant}</td>
                                                            <td><b style={{ fontSize: 13 }}>$</b>{itemMovimiento.precio_ins}</td>
                                                            <td>
                                                                <div className="btn-group" role="group" aria-label="">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-danger btn-sm"
                                                                        onClick={() =>
                                                                            this.borrarItem(itemMovimiento.id_mi)
                                                                        }
                                                                    >
                                                                        <FontAwesomeIcon icon={faTrashAlt} />
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr><td colSpan="5"><b>NO EXISTE ITEMS CREADOS</b></td></tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Crear;