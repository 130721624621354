import React from 'react';
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleLeft, faAngleDoubleRight, faAngleRight, faFilter, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { currencyFormat } from '../../js/Functions';
import * as XLSX from 'xlsx';

class Listar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datosCargados: false,
            load: '',
            atenciones: [],
            pagina: [],
            total: 0,
            totaltf: 0,
            registros: 0,
            sin_f: 0,
            con_f: 0,
            Precio: 0,
            Liq: 0,
            empresas: [],
        };
    }

    cargarEmpresas() {
        fetch(Api + "Empresas")
            .then(respuesta => respuesta.json())
            .then((datosEmpresas) => {
                this.setState({ empresas: datosEmpresas.data });
            })
            .catch(console.log);
    }

    Buscar(page) {
        this.setState({ Precio: 0, total: 0, registros: 0, sin_f: 0, con_f: 0, atenciones: [] });
        let bano = document.getElementById('bano').value;
        let bmes = document.getElementById('bmes').value;
        let bate = document.getElementById('bate').value;
        let empresa = document.getElementById('empresa').value;
        let liq = document.getElementById('bliq').value;
        if (bano === '') {
            bano = 0;
        }
        if (bmes === '') {
            bmes = 0;
        }
        if (bate === '') {
            bate = 0;
        }
        if (empresa === '') {
            empresa = 0;
        }
        Swal.showLoading();
        this.setState({ load: 'Cargando Datos...' });
        fetch(Api + "Auditoria/InformeFinanciero2/" + bano + "/" + bmes + "/" + bate + "/" + empresa + "/" + liq)
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                Swal.close();
                this.setState({ datosCargados: true, load: '', atenciones: datosRespuesta.datos, totaltf: datosRespuesta.total_factura, Precio: datosRespuesta.precio, total: datosRespuesta.total, registros: datosRespuesta.registro, sin_f: datosRespuesta.facturado, con_f: datosRespuesta.liquidado });
            })
            .catch(console.log);
    }

    exportToExcel() {
        const { atenciones } = this.state;
        const headers = [
            "ITEM",
            "FECHA REGISTRO",
            "FACTURA",
            "ORDEN_SERVICIO",
            "ATENCIÓN",
            "CANT. ATENCIONES",
            "PRECIO ATENCION",
            "FACTURADO",
            "LIQUIDADO"
        ];
        const data = atenciones.map((itemAtenciones, i) => ({
            "ITEM": i + 1,
            "FECHA REGISTRO": itemAtenciones.fecha_registro,
            "FACTURA": itemAtenciones.numero_factura,
            "ORDEN_SERVICIO": itemAtenciones.orden_servicio,
            "ATENCIÓN": itemAtenciones.Description,
            "CANT. ATENCIONES": itemAtenciones.cant,
            "PRECIO ATENCION": currencyFormat(itemAtenciones.precio_total),
            "FACTURADO": currencyFormat(itemAtenciones.precio_total * itemAtenciones.cant),
            "LIQUIDADO": itemAtenciones.liquidada != null ? currencyFormat(itemAtenciones.liquidada) : '0'
        }));
        const worksheet = XLSX.utils.json_to_sheet(data, { header: headers });
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Atenciones");
        XLSX.writeFile(workbook, "InformeFinancieroFacturacion.xlsx");
    }

    componentDidMount() {
        this.cargarEmpresas();
    }

    render() {
        const { datosCargados, load, atenciones, pagina, registros, total, sin_f, con_f, Precio, empresas, totaltf } = this.state;
        return (
            <div className="content">
                <div className="content-wrapper">
                    <div className="content">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-header">
                                    &nbsp;
                                </div>
                                <div className="card-body">
                                    <h4>Informe Financiero Facturación</h4>
                                    <div className="row">
                                        <div className="col-xl-1">
                                            <input type="text" id="bano" name="bano" placeholder="Año" className="form-control form-control-sm" maxLength="4" />
                                        </div>
                                        <div className="col-xl-2">
                                            <select className="form-select form-select-sm" type="text" name="bmes" id="bmes">
                                                <option value="">Seleccione el Mes</option>
                                                <option value="01">01 - Enero</option>
                                                <option value="02">02 - Febrero</option>
                                                <option value="03">03 - Marzo</option>
                                                <option value="04">04 - Abril</option>
                                                <option value="05">05 - Mayo</option>
                                                <option value="06">06 - Junio</option>
                                                <option value="07">07 - Julio</option>
                                                <option value="08">08 - Agosto</option>
                                                <option value="09">09 - Septiembre</option>
                                                <option value="10">10 - Octubre</option>
                                                <option value="11">11 - Noviembre</option>
                                                <option value="12">12 - Diciembre</option>
                                            </select>
                                        </div>
                                        <div className="col-xl-2">
                                            <select className="form-select form-select-sm" type="text" name="bliq" id="bliq">
                                                <option value="0">Todas las atenciones</option>
                                                <option value="1">Atenciones Liquidadas</option>
                                                <option value="2">Atenciones Sin liquidar</option>
                                            </select>
                                        </div>
                                        <div className="col-xl-2">
                                            <input type="text" id="bate" name="bate" placeholder="Nombre de la atencion" className="form-control form-control-sm" />
                                        </div>
                                        <div className="col-xl-2">
                                            <select className="form-select form-select-sm" type="text" name="empresa" id="empresa">
                                                <option value="">Seleccione la Empresa</option>
                                                {
                                                    empresas.map(itemEmpresa => (
                                                        <option key={itemEmpresa.id_empresa} value={itemEmpresa.rips}>{itemEmpresa.nombre_emp}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="col-xl-1">
                                            <button id="bt" name="bt" className="btn btn-primary btn-sm" onClick={() => this.Buscar(1)}><FontAwesomeIcon icon={faFilter} />&nbsp;&nbsp;Filtrar</button>
                                        </div>
                                        <div className='col-xl-2'>
                                            {
                                                datosCargados ? (
                                                    <button style={{ color: '#FFFFFF' }} id="btn_exportar" name="btn_exportar" onClick={() => this.exportToExcel()} className="btn btn-info btn-sm"><FontAwesomeIcon icon={faFileDownload} />&nbsp;&nbsp;Exportar</button>
                                                ) : null
                                            }
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row">
                                        <table className="table table-hover table-condensed table-sm">
                                            <thead className="thead-inverse">
                                                <tr>
                                                    <th>ITEM</th>
                                                    <th>FECHA REGISTRO</th>
                                                    <th>FACTURA</th>
                                                    <th>ORDEN_SERVICIO</th>
                                                    <th>ATENCIÓN</th>
                                                    <th>CANT. ATENCIONES</th>
                                                    <th>PRECIO ATENCION</th>
                                                    <th>FACTURADO</th>
                                                    <th>LIQUIDADO</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {load}
                                                {atenciones.map((itemAtenciones, i) => (
                                                    <tr key={i}>
                                                        <td>{i + 1}</td>
                                                        <td>{itemAtenciones.fecha_registro}</td>
                                                        <td>{itemAtenciones.numero_factura}</td>
                                                        <td>{itemAtenciones.orden_servicio}</td>
                                                        <td>{itemAtenciones.Description}</td>
                                                        <td>{itemAtenciones.cant}</td>
                                                        <td>{currencyFormat(itemAtenciones.precio_total)}</td>
                                                        <td>{currencyFormat(itemAtenciones.precio_total * itemAtenciones.cant)}</td>
                                                        <td style={{ backgroundColor: itemAtenciones.liquidada != null ? "green" : "" }}>{itemAtenciones.liquidada != null ? currencyFormat(itemAtenciones.liquidada) : '0'}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            <tr>
                                                <td colSpan={5}>Totales</td>
                                                <td>{currencyFormat(total)}</td>
                                                <td>{currencyFormat(Precio)}</td>
                                                <td>{currencyFormat(sin_f)}</td>
                                                <td>{currencyFormat(con_f)}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div className="card-footer text-muted">
                                    <b>Total de registros: </b> <span className="badge bg-primary">{registros}</span> <b>--</b>Total de Atenciones: <span className="badge bg-success">{currencyFormat(total)}</span>  
                                    | Total Mes Pagado: <span className="badge bg-success">{currencyFormat(con_f)} </span> 
                                    | Total Facturado en Atenciones : <span className="badge bg-warning">{currencyFormat(sin_f)} </span> 
                                    | Total Facturado : <span className="badge bg-warning">{currencyFormat(totaltf)} </span> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Listar;
