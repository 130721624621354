import React from "react";
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import Swal from 'sweetalert2';

class Editar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datosCargados: false,
      nombre:"",
            tipo:"",
      empleado: [],
      errores:[]
    };
  }
  cambioValor = (e) => {
    const state = this.state.empleado;
    state[e.target.name] = e.target.value;
    this.setState({ empleado: state });
  };
verificarError(elemento) {
  return this.state.errores.indexOf(elemento) !== -1;
}
enviarDatos = (e) => {
  e.preventDefault();
  console.log("Formulario Enviado...");
 // const {nombre_atencion, tipo,id_atencion,codigo_atencion,cuenta} = this.state.empleado;

  let form = document.getElementById('form');
  let formData = new FormData(form);
  console.log(formData);
  //var datosEnviar = {nombre:nombre_atencion, tipo:tipo,id:id_atencion,cuenta:cuenta,codigo:codigo_atencion}

 
  fetch(Api + "UpAtenciones", {
      method:"POST",
      body:formData
  })
  .then(respuesta=>respuesta.json())
  .then((datosRespuesta)=> {
      console.log(datosRespuesta)
      Swal.fire(
        'Atenciones!',
        datosRespuesta.msj,
        'success'
      )
      this.props.history.push("/MasterAtenciones");
  })
  .catch(console.log)
}
  componentDidMount() {
   
    console.log(this.props.match.params.id);
    fetch(Api + "Atenciones/" + this.props.match.params.id,
    {
      method:"GET",
      headers: new Headers({
        'Authorization': 'koala2021'
      }), 
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
        this.setState({ datosCargados: true, empleado: datosRespuesta }); //
      })
      .catch(console.log);
  }
  render() {
    const { datosCargados, empleado } = this.state;
    if (!datosCargados) {
      return <div className="content-wrapper">
      <div className="content">
        <div className="container-fluid">Cargando Datos...</div></div></div>;
    } else {
      return (
        <div>
    
        <div className="content-wrapper">
          <div className="content">
            <div className="container-fluid">
              <div className="card">
                <div className="card-header">Editar Atencion</div>
                <div className="card-body">
                  <form onSubmit={this.enviarDatos} id="form">
                    
                    <div className="form-group">
                      <label htmlFor="id">Id :</label>
                      <input
                        type="text"
                        name="id"
                        id="id"
                        value={empleado.id_atencion}
                        className="form-control form-control-sm"
                        placeholder=""
                        aria-describedby="helpId"
                        onChange={this.cambioValor}
                        readOnly
                      />
                      
                    </div>
                    <div className="form-group">
                      <label htmlFor="codigo">Código:</label>
                      <input
                        type="text"
                        name="codigo"
                        id="codigo"
                        defaultValue={empleado.codigo_atencion}
                        className="form-control form-control-sm"
                        onChange={this.cambioValor}
                        required
                      />
                      
                    </div>
                    <div className="form-group">
                      <label htmlFor="nombre">Nombre:</label>
                      <input
                        type="text"
                        name="nombre"
                        id="nombre"
                        defaultValue={empleado.nombre_atencion}
                        className={((this.verificarError("error_nombre"))?"is-invalid":"")+" form-control form-control-sm"}
                        onChange={this.cambioValor}
                      />
                      
                    </div>
                    <div className="form-group">
                      <label htmlFor="tipo">Tipo:</label>
                      
                      <select className={((this.verificarError("error_tipo"))?"is-invalid":"")+" form-select form-select-sm"} name="tipo"
                        id="tipo" defaultValue={empleado.tipo}
                        onChange={this.cambioValor}
                        >
                          <option value="1">Procedimiento</option>
                          <option value="2">Consulta</option>
                          <option value="3">Otra</option>
                        </select>
                      <small id="" className="text-muted">
                          Tipo de Consulta
                      </small>
                    </div>
                    <div className="form-group">
                      <label htmlFor="cuenta">Código Siigo:</label>
                      <input
                        type="text"
                        name="cuenta"
                        id="cuenta"
                        defaultValue={empleado.cuenta}
                        onChange={this.cambioValor}
                        className="form-control form-control-sm"
                      />
                      
                    </div>
                    <br />
                    <div className="btn-group" role="group" aria-label="">
                      <button type="submit" className="btn btn-warning btn-sm">
                        Editar
                      </button>&nbsp;&nbsp;
                      <Link className="btn btn-danger btn-sm" to={"/MasterAtenciones"}>
                        Cancelar
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        </div>
      );
    }
  }
}

export default Editar;
