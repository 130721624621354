import React from "react";
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import FechaActual from "../../servicios/FechaGlobal";
import $ from "jquery";
import findImage from "../../images/find.png"
import FormPaciente from "../atencion/Pacientes"
import axios from 'axios';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleDoubleRight,
  faAngleRight,
  faPrint,
  faPlus,
  faFilter,
  faDownload,
  faEdit,
  faSave,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
const fechag =  new Date();
    let meses = (fechag.getMonth() + 1).toString().padStart(2, '0')
    const Fecha_unos  = fechag.getFullYear()+'-'+meses+'-01';
    
class Facturar extends React.Component {
  constructor(props) {
    super(props);
    this.agregarPaciente = this.agregarPaciente.bind(this);
    

    this.state = {
      total : 0,
      empresas: [],
      facturas: [],
      detalles: [],
      medicamentos: [],
      insumos: [],
      fecha: FechaActual,
      fecha1: Fecha_unos,
      fecha_final: FechaActual,
      pagina : 0,
      t:0,
      gt:0,
      nombre: "",
      Disabled: "",
      pac: "",
      Tot_a:0,
      Tot_i:0,
      Tot_m:0,
      Total:0,
      Orden:0,
    };
  }
  cargarEmpresas() {

    fetch(Api + "Empresas")
      .then((respuesta) => respuesta.json())
      .then((datosEmpresas) => {
        
        this.setState({ empresas: datosEmpresas.data });
      })
      .catch(console.log);
  }
  agregarPaciente(pac, nombre) {
    this.setState({ pac: pac, nombre: nombre });
}
  componentDidMount() {
    this.cargarEmpresas();
    //this.Buscar(1);
    
  }
  Buscar() {

    var regi = $("#empresa").val();
    if(regi==''){
      Swal.fire('Debes de seleccionar la empresa','', 'info');
      return false;
    }

    Swal.showLoading();
    var datos = new FormData(document.getElementById("formulario"));
    fetch(Api + "Facturas/FacturarEmpresa", {
      method: "POST",
      body: datos,
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta.ultima_factura[0].ultima);
        this.setState({
          datosCargados: true,
          facturas: datosRespuesta.factura,
          pagina: datosRespuesta.total,
          t: datosRespuesta.ultima_factura[0].ultima,
        });
        document.getElementById('t').value = parseInt(datosRespuesta.ultima_factura[0].ultima) + 1;
        Swal.close();
      })
      .catch(console.log);
  }
 
revisar(orden){
  Swal.fire({
    title: 'Estas seguro de cambiar el estado a revisado?',
    showDenyButton: true,
    showCancelButton: false,
    confirmButtonText: 'Revisado',
    denyButtonText: `Cancelar`,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      fetch(Api + "Facturas/revisar/"+orden)
        .then((datosRespuesta) => {
          console.log(datosRespuesta);
          Swal.fire('La orden ha sido revisada', '', 'success')
          this.Buscar();
        })
        .catch(console.log);
    } else if (result.isDenied) {
      Swal.fire('Se ha cancelado la operacion', '', 'info')
    }
  })
}

checkear(orden,check){
  Swal.fire({
    title: 'Estas seguro de checkear esta orden?',
    showDenyButton: true,
    showCancelButton: false,
    confirmButtonText: 'Checkear',
    denyButtonText: `Cancelar`,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      fetch(Api + "Facturas/checkear/"+orden+"/"+check)
        .then((datosRespuesta) => {
          console.log(datosRespuesta);
          Swal.fire('La orden ha sido checkeada', '', 'success')
          this.Buscar();
        })
        .catch(console.log);
    } else if (result.isDenied) {
      Swal.fire('Se ha cancelado la operacion', '', 'info')
    }
  })
}
ActualizarAtencion(orden){
    var codext = document.getElementById('codext'+orden).value;
    var codate = document.getElementById('codate'+orden).value;
    var precio = document.getElementById('precio'+orden).value;
    var cuota = document.getElementById('cuota'+orden).value;
    
        fetch(Api + "Facturas/actualizar/"+orden+"/"+codext+"/"+codate+"/"+precio+"/"+cuota)
          .then((datosRespuesta) => {
            console.log(datosRespuesta);
            
          })
          .catch(console.log);
}

facturarItems(){

  var factura = $("#t").val();
  fetch(Api + "Facturas/generar/"+factura)
          .then((datosRespuesta) => {
            console.log(datosRespuesta);
            
          })
          .catch(console.log);

}
facturar(){
  var regi = $("#regimen").val();
  var emp = $("#empresa").val();
  var tx = $("#pagos").val();
  var co = $("#copagos").val();
  var revisado = $("#revisado").val();
  if(revisado==0){
    Swal.fire('Solo debes de facturar lo revisado, cambia el estado a revisado','', 'info');
    return false;
  }
  var paciente = $("#pac").val()!=''?$("#pac").val():0;
  if(regi==''){
    Swal.fire('Debes de seleccionar el regimen','', 'info');
    return false;
  }
  var re = $("#registro").val();
  console.log(re);
  if(re==''){
    Swal.fire('Debes de seleccionar la fecha de registro','', 'info');
    return false;
  }
  this.setState({ Disabled: 'disabled' });
  Swal.fire({
    title: 'Estas seguro de facturar las atenciones seleccionadas?',
    showDenyButton: true,
    showCancelButton: false,
    confirmButtonText: 'Facturar',
    denyButtonText: `Cancelar`,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      Swal.showLoading();
      
  var factura = $("#t").val();
  var datos = [];
  $("input[name=item]:checked").each(function(){
    var id = $(this).attr("id");
           datos.push(id); 
           console.log('id;'+id);
    /* fetch(Api + "Facturas/generar/"+factura+"/"+id)
          .then((datosRespuesta) => {
            console.log(datosRespuesta);  
          })
          .catch(console.log); */
  });
  
  var archivos ={factura,emp,regi,re,paciente,ordenes:datos};
  console.log(archivos);
  //return false;
  axios.post(Api +'Facturas/facturar',archivos)
  .then(response => {
    console.log(response.data);
    let m = response.data.mensaje;
    let est = response.data.estado;
    if(est==1){
      Swal.fire(m, '', 'success');
      this.Buscar();
    }else{
      Swal.fire(m, '', 'error');
    }
    this.setState({ Disabled: '' });
    
  })
  .catch(console.log);


} else if (result.isDenied) {
  this.setState({ Disabled: '' });
  Swal.fire('Se ha cancelado la operacion', '', 'info')
}
})
        
}
Ver(orden){
   console.log(orden);
   fetch(Api + "Ordenes/OrdenInterna/"+orden)
    .then((respuesta) => respuesta.json())
    .then((datosRespuesta) => {
      console.log(datosRespuesta);
      this.setState({
        Orden:orden,
        detalles: datosRespuesta.datos,
        medicamentos: datosRespuesta.medicamentos,
        insumos: datosRespuesta.insumos,
        Tot_a:datosRespuesta.suma_atencion,
        Tot_i:datosRespuesta.total_insumo,
        Tot_m:datosRespuesta.total_med,
        Total:datosRespuesta.grantotal,
      });
     
      Swal.close();
    })
    .catch(console.log);
}
abrirNuevaPestana(orden){
  console.log(orden);
  const nuevaPestana = window.open(`/VerOrdenInterna/${orden}`, '_blank');
    nuevaPestana.focus();
}
ImprimirAte(orden){
      window.open("https://idb.softmediko.com/resumen_atenciones.php?imprimir="+orden);
}
ImprimirAteFirmado(orden){
      window.open("https://idb.softmediko.com/vistas/reporte.php?imprimir="+orden);
}
Limpiar(){
  $("#empresa").val('');
  $("#codigo").val('');
  $("#nombre").val('');
  $("#pac").val('');
  $("#regimen").val('');
}
  render() {
    const {
      fecha,
      empresas,
      fecha1,
      fecha_final,
      facturas,
      pagina,t,gt,medicamentos,detalles,insumos,Tot_a,
      Tot_i,
      Tot_m,
      Total,Orden,Disabled
    } = this.state;
   
    return (
      <div className="content">
        <div class="modal fade" id="asignarPaciente" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="asignarPacienteLabel" aria-hidden="true">
                    <FormPaciente agregarPaciente={this.agregarPaciente} />
                </div>
                <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Detalles de la orden</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <form id="formcrear">
                 
                  <h5>Atenciones Prestadas</h5>
                  <table className="table table-hover table-condensed table-sm">
                     <thead>
                      <tr>
                        <th>ITEM</th>
                        <th>CODIGO</th>
                        <th>ATENCION</th>
                        <th>PROFESIONAL</th>
                        <th>AUTORIZACION</th>
                        <th>FECHA REGISTRO</th>
                        <th>ESTADO</th>
                        <th>PORCENTAJE</th>
                        <th>FIRMADO</th>
                        <th>PRECIO</th>
                        </tr>
                     </thead>
                     
                     {detalles.map((itemMovimiento,i) => (
                      <tr key={itemMovimiento.Id}>
                        <td>{i+1}</td>
                        <td>{itemMovimiento.cod_aten}</td>
                        <td>{itemMovimiento.Description}</td>
                        <td>{itemMovimiento.user}</td>
                        <td>{itemMovimiento.orden_externa}</td>
                        <td>{itemMovimiento.fecha_mod_ta}</td>
                        <td>{itemMovimiento.estado}</td>
                        <td>{itemMovimiento.id_contacto}</td>
                        <td>{itemMovimiento.firmadigital==""?"Pendiente":"Firmado"}</td>
                        <td>{itemMovimiento.precio_total}</td>
                      </tr>
                    ))}
                  </table>
                  <br></br>
                  <h5>Medicamentos Asignados</h5>
                  <table className="table table-hover table-condensed table-sm">
                     <thead>
                      <tr>
                        <th>ITEM</th>
                        <th>CODIGO</th>
                        <th>MEDICAMENTO</th>
                        <th>ORDEN</th>
                        <th>CANTIDAD</th>
                        <th>PRECIO</th>
                        <th>TOTAL</th>
   
                        </tr>
                     </thead>
                     
                     {medicamentos.map((itemMovimiento,i) => (
                      <tr key={itemMovimiento.id}>
                        <td>{i+1}</td>
                        <td>{itemMovimiento.codigo_int}</td>
                        <td>{itemMovimiento.nombre_medicamento}</td>
                        <td>{itemMovimiento.numero_orden}</td>
                        <td>{itemMovimiento.cantidad_usada}</td>
                        <td>{itemMovimiento.sub_precio_m}</td>
                        <td>{itemMovimiento.cantidad_usada*itemMovimiento.sub_precio_m}</td>
                        
                        
                      </tr>
                    ))}
                    
                  </table>
                  <br></br>
                  <h5>Insumos Asignados</h5>
                  <table className="table table-hover table-condensed table-sm">
                     <thead>
                      <tr>
                        <th>ITEM</th>
                        <th>CODIGO</th>
                        <th>INSUMOS</th>
                        <th>ORDEN</th>
                        <th>CANTIDAD</th>
                        <th>PRECIO</th>
                        <th>TOTAL</th>
   
                        </tr>
                     </thead>
                     
                     {insumos.map((itemMovimiento,i) => (
                      <tr key={itemMovimiento.id_ia}>
                        <td>{i+1}</td>
                        <td>{itemMovimiento.cod_insumo}</td>
                        <td>{itemMovimiento.nombre_insumo}</td>
                        <td>{itemMovimiento.rel_atencion}</td>
                        <td>{itemMovimiento.cant_usada}</td>
                        <td>{itemMovimiento.sub_precio}</td>
                        <td>{itemMovimiento.cant_usada*itemMovimiento.sub_precio}</td>
                        
                        
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={7}></td>
                    </tr>
                    <tr>
                      <td colSpan={5}></td>
                      <td>Total Atencion</td>
                      <td>{Tot_a}</td>
                    </tr>
                    <tr>
                      <td colSpan={5}></td>
                      <td>Total Medicamentos</td>
                      <td>{Tot_m}</td>
                    </tr>
                    <tr>
                      <td colSpan={5}></td>
                      <td>Total Insimos</td>
                      <td>{Tot_i}</td>
                    </tr>
                    <tr>
                      <td colSpan={5}></td>
                      <td>Gran Total</td>
                      <td><b>$ {Total}</b></td>
                    </tr>
                  </table>
                </form>
              </div>
              <div class="modal-footer">
              <button className='btn btn-secondary btn-sm' id='' type='button'  onClick={() => this.ImprimirAte(Orden)}><FontAwesomeIcon icon={faPrint} />&nbsp;&nbsp; Imprimir.</button>&nbsp;
                                        <button className='btn btn-secondary btn-sm' id='' type='button'  onClick={() => this.ImprimirAteFirmado(Orden)}><FontAwesomeIcon icon={faPrint} />&nbsp;&nbsp; Imprimir con firma.</button>&nbsp;
              <button type="button" className="btn btn-primary" onClick={() => this.abrirNuevaPestana(Orden)}>Detalles</button>
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
               
              </div>
            </div>
          </div>
        </div>
                    <div className="content-wrapper">
      <div className="content">
      
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
            <h4>Facturar por empresa</h4>
            </div>
            <div className="card-body">
              
              <form id="formulario">
                <div className="row">
                  <div className="col-xl-2">
                  <label>Empresa</label>
                    <select
                      className="form-select form-select-sm"
                      type="text"
                      name="empresa"
                      id="empresa"
                    >
                      <option value="">Seleccione la Empresa</option>
                      {empresas.map((itemEmpresa) => (
                        <option
                          key={itemEmpresa.id_empresa}
                          value={itemEmpresa.rips}
                        >
                          {itemEmpresa.nombre_emp}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-xl-2">
                  <label>Codigo Atencion</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      id="codigo"
                      name="codigo"
                      placeholder="Codigo"
                    />
                  </div>
                  <div className="col-xl-2">
                  <label>Regimen</label>
                    <select
                      className="form-select form-select-sm"
                      type="text"
                      name="regimen"
                      id="regimen"
                    >
                  
                      <option value="">Seleccione</option>
                                                                    <option value="Contributivo">1. Contributivo</option>
                                                                    <option value="Subsidiado">2. Subsidiado</option>
                                                                    <option value="Particular">4. Particular</option>
                                                                    <option value="Vinculado">3. Vinculado</option>
                                                                    <option value="Otro">5. Otro</option>
                                                                    <option value="Plan Complementario">7. Plan Complementario</option>
                                                                    <option value="Poliza">8. Poliza</option>
                                                                    <option value="Arl">9. Arl</option>
                                                                    <option value="No aplica">No aplica</option>
                
                    </select>
                  </div>
                  <div className="col-xl-2">
                  <label>Seleccionadas</label>
                    <select
                      className="form-select form-select-sm"
                      type="text"
                      name="check"
                      id="check"
                      onChange={() => this.Buscar(1)}
                    >
                  <option value="0">Sin seleccionar</option>
                  <option value="1">Seleccionadas</option>
                    </select>
                  </div>
                  <div className="col-xl-2">
                  <label>Estado</label>
                    <select
                      className="form-select form-select-sm"
                      type="text"
                      name="revisado"
                      id="revisado"
                      onChange={() => this.Buscar(1)}
                    >
                  <option value="Revisado">Revisados</option>
                  <option value="0">Sin Revisar</option>
                    </select>
                  </div>
                  <div className="col-xl-2">
                    <label>Fecha Registro</label>
                    <input
                      className="form-control form-control-sm"
                      type="date"
                      id="registro"
                      name="registro"
                      placeholder="Año"
                      defaultValue={fecha}
                     
                    />
                  </div>
                  <div className="col-xl-2">
                    <label>Fecha Inicial</label>
                    <input
                      className="form-control form-control-sm"
                      type="date"
                      id="inicio"
                      name="inicio"
                      placeholder="Año"
                      defaultValue={fecha1}
                    />
                  </div>
                  <div className="col-xl-2">
                    <label>Fecha Final</label>
                    <input
                      className="form-control form-control-sm"
                      type="date"
                      id="fin"
                      name="fin"
                      placeholder="Año"
                      defaultValue={fecha_final}
                    />
                  </div>
                  <div className="col-xl-2">
                  <label>Completados</label>
                    <select
                      className="form-select form-select-sm"
                      type="text"
                      name="completado"
                      id="completado"
                    >
                  <option value="100">Completado</option>
                  <option value="0">Todos</option>
                    </select>
                  </div>
                  <div className="col-xl-2">
                  <label>Facturar paciente</label>
                  <input
                      className="form-control form-control-sm"
                      type="hidden"
                      id="pac"
                      name="pac"
                    />
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      id="nombre"
                      name="nombre"
                      placeholder="Nombre del paciente"
                      data-bs-toggle='modal' data-bs-target='#asignarPaciente'
                    />
                    
                  </div>
                  

                  <div className="col-xl-2 pt-4">
                    <button
                      type="button"
                      id="bt"
                      name="bt"
                      className="btn btn-primary btn-sm"
                      onClick={() => this.Buscar(1)}
                    >
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp;&nbsp;Filtrar
                    </button>
                    <button
                      type="button"
                      id="bt"
                      name="bt"
                      className="btn btn-danger btn-sm"
                      onClick={() => this.Limpiar()}
                    >
                   
                      &nbsp;&nbsp;Limpiar
                    </button>

                  </div>
                </div>
              </form>
              <br></br>

              <div className="row">
                <div id="form1">
                <table className="table table-hover table-condensed table-sm">
                  <thead className="thead-inverse">
                    <tr>
                      <th>ORDEN EXT</th>
                      <th>NOMBRE PACIENTE</th>
                      <th>CODIGO</th>
                      <th>CONSULTA</th>
                      <th>PRECIO A EPS</th>
                      <th>CANTIDAD</th>
                      <th>COPAGO</th>
                      <th>TOTAL</th>
                      <th>FECHA REGISTRO</th>
                      <th>ATENDIDO POR</th>
                      <th><input type="checkbox" name="item" id="item"/></th>
                      <th>OPC</th>
                    </tr>
                  </thead>
                  <tbody>
                   
                  {facturas.map((item) => (
                      <tr key={item.Id}>
                        
                        <td><input type="text" id={'codext'+item.orden_servicio} defaultValue={item.orden_externa} className="form-control form-control-sm" onChange={()=>this.ActualizarAtencion(item.orden_servicio)}/></td>
                        <td>{item.nombres} {item.apellidos} <br></br>{item.tipo_s}<br></br>{item.orden_servicio}</td>
                        <td><input type="text" id={'codate'+item.orden_servicio} defaultValue={item.cod_aten} onChange={()=>this.ActualizarAtencion(item.orden_servicio)} className="form-control form-control-sm"/></td>
                        <td>{item.Description} <br></br>{item.Location==''?
                        <button type="button" className="btn btn-danger btn-sm" onClick={() => this.revisar(item.orden_servicio)}>Sin Revisar</button>
                        :<span className="badge bg-success">Revisado</span>}</td>
                        <td><input type="text" id={'precio'+item.orden_servicio} defaultValue={item.precio_total} onChange={()=>this.ActualizarAtencion(item.orden_servicio)} className="form-control form-control-sm"/></td>
                        <td>{item.cant}</td>
                        <td><input type="text" id={'cuota'+item.orden_servicio} defaultValue={item.cuota_pagada?item.cuota_pagada:'0'} onChange={()=>this.ActualizarAtencion(item.orden_servicio)} className="form-control form-control-sm"/></td>
                        <td id="total_items">{ (item.cant * item.precio_total)}</td>
                        <td>{item.StartTime}</td>
                        <td>{item.user}<br></br>{item.id_contacto==''?<span className="badge bg-danger">0 %</span>: item.id_contacto>=99? <span className="badge bg-success">100%</span>:<span className="badge bg-warning">{item.id_contacto}%</span>} </td>
                        <td>{item.id_contacto==''?<span className="badge bg-success">En Proceso</span>:item.id_contacto>=99?<><input type="checkbox" name="item" id={item.orden_servicio} defaultValue={item.orden_servicio} checked={item.checki=='1'?'checked':''} onClick={() => this.checkear(item.orden_servicio,item.checki)} />{item.checki}</>:<span className="badge bg-success">En Proceso</span>}</td>
                        <td>{item.checki}= <button type="button" className="btn btn-info btn-sm" onClick={() => this.Ver(item.orden_servicio)} data-bs-toggle="modal" data-bs-target="#exampleModal" >[+]</button></td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={7}>Total:</td>
                      <td></td>
                    </tr>
                  </tbody>

                </table>
                </div>
              </div>
              
            </div>
            <div className="card-footer text-muted">
          
              <b> | Total : </b> <span className="badge bg-warning">{pagina}</span>
              <div className="text-center">
                <div className="row">
                <div className="col-xl-3">
                  <label>Consecutivo Factura:</label>
                   <input type="text" className="form-control" readOnly defaultValue={t}  name="t" id="t" />
                   <input type="hidden" className="form-control" readOnly defaultValue={pagina}  name="tc" id="tc" />
                  </div>
                  <div className="col-xl-3 pt-4">
                    <button className="btn btn-primary" disabled={Disabled} onClick={()=>this.facturar()}>Facturar</button>
                  </div>
                </div>
               
                 
              </div>
              
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
    );
  }
}

export default Facturar;
