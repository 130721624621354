import React from "react";
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import FechaActual from "../../servicios/FechaGlobal";
import $ from "jquery";
import findImage from "../../images/find.png"
import FormPaciente from "../atencion/Pacientes"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleDoubleRight,
  faAngleRight,
  faPrint,
  faPlus,
  faFilter,
  faDownload,
  faEdit,
  faSave,
  faSignOutAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
const fechag =  new Date();
    let meses = (fechag.getMonth() + 1).toString().padStart(2, '0')
    const Fecha_unos  = fechag.getFullYear()+'-'+meses+'-01';
    
class Facturar extends React.Component {
  constructor(props) {
    super(props);
    this.agregarPaciente = this.agregarPaciente.bind(this);
    

    this.state = {
      total : 0,
      empresas: [],
      facturas: [],
      fecha: FechaActual,
      fecha1: Fecha_unos,
      fecha_final: FechaActual,
      pagina : 0,
      t:0,
      gt:0,
      nombre: "",
      pac: "",
    };
  }
  cargarEmpresas() {

    fetch(Api + "Empresas")
      .then((respuesta) => respuesta.json())
      .then((datosEmpresas) => {
        
        this.setState({ empresas: datosEmpresas.data });
      })
      .catch(console.log);
  }
  agregarPaciente(pac, nombre) {
    this.setState({ pac: pac, nombre: nombre });
}
  componentDidMount() {
    this.cargarEmpresas();
    //this.Buscar(1);
    
  }
  Buscar() {
    let desde = document.getElementById('desde').value;
    let hasta = document.getElementById('hasta').value;
    fetch(Api + "Facturas/generar_rips", {
      method: "POST",
      headers: {
          "Content-Type": "application/json"
      },
      body: JSON.stringify({ facturai: desde, facturaf: hasta })
    })
      .then(respuesta => respuesta.json())
      .then(datosRespuesta => {
          console.log(datosRespuesta.data);
          this.descargarArchivoJSON(datosRespuesta, `Rips_${desde}_${hasta}.json`);
      })
      .catch(console.log);
}

descargarArchivoJSON(data, filename) {
    const json = JSON.stringify(data, null, 2); // Formato legible
    const blob = new Blob([json], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    
    // Crear enlace temporal para descargar el archivo
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;

    // Simular clic para iniciar la descarga
    document.body.appendChild(link);
    link.click();

    // Limpiar el enlace temporal
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}
 

Limpiar(){
  $("#empresa").val('');
  $("#codigo").val('');
  $("#nombre").val('');
  $("#pac").val('');
  $("#regimen").val('');
}
  render() {
    const {
      fecha,
      empresas,
      fecha1,
      fecha_final,
      facturas,
      pagina,t,gt,
    } = this.state;
   
    return (
      <div className="content">
        <div class="modal fade" id="asignarPaciente" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="asignarPacienteLabel" aria-hidden="true">
                    <FormPaciente agregarPaciente={this.agregarPaciente} />
                </div>

                    <div className="content-wrapper">
      <div className="content">
      
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
            <h4>Generar Rips JSON</h4>
            </div>
            <div className="card-body">
              
              <form id="formulario">
                <div className="row">
        
                  <div className="col-xl-2">
                  <label>Factura desde</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      id="desde"
                      name="desde"
                      placeholder="1"
                    />
                  </div>
                  <div className="col-xl-2">
                  <label>Factura hasta</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      id="hasta"
                      name="hasta"
                      placeholder="1000"
                    />
                  </div>
                  <div className="col-xl-2 pt-4">
                    <button
                      type="button"
                      id="bt"
                      name="bt"
                      className="btn btn-primary btn-sm"
                      onClick={() => this.Buscar()}
                    >
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp;&nbsp; Generar jSON
                    </button> 

                  </div>
                </div>
              </form>
              <br></br>

              
              
            </div>
            
          </div>
        </div>
      </div>
      </div>
      </div>
    );
  }
}

export default Facturar;
