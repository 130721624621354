import React from "react";
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import Formulario from "./Crear";
import EditarFormulario from "./Editar";
//import ItemInsumos from "./ItemInsumos";
import { currencyFormat } from '../../js/Functions';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleDoubleRight,
  faAngleRight,
  faPrint,
  faPlus,
  faFilter,
  faDownload,
  faEdit,
  faSave,
  faSignOutAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

class Listar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editar: "No",
      datosCargados: false,
      total : 0,
      copago:0,co:0,
      empresas: [],
      detalle: [],
      pagina: [],
      tf:0,
      facturas: [],
      detalles: [],
      medicamentos: [],
        insumos: [],
      date: new Date(),
    };
  }
  cargarEmpresas() {
    fetch(Api + "Empresas")
      .then((respuesta) => respuesta.json())
      .then((datosEmpresas) => {
        //console.log(datosEmpresas.data);
        this.setState({ empresas: datosEmpresas.data });
      })
      .catch(console.log);
  }
  DescargarSiigo() {
    window.open('https://idb.softmediko.com/vistas/rango.php', 'popup', 'width=400,height=400');
  }
  componentDidMount() {
    this.cargarEmpresas();
    this.Buscar(1);
  }
  Buscar(page) {
    Swal.showLoading();
    let empresa = document.getElementById('empresax').value;
    let numero = document.getElementById('numero').value;
    let tipo = document.getElementById('tipo').value;
    let fecha1 = document.getElementById('fecha1').value;
    let fecha2 = document.getElementById('fecha2').value;
    
    console.log("Formulario Enviado...");
    if(empresa==''){
      empresa = 0;
    }
    if(numero==''){
      numero = 0;
    }
    if(tipo==''){
      tipo = 0;
    }
    if(fecha1==''){
      fecha1 = 0;
    }
    if(fecha2==''){
      fecha2 = 0;
    }
    fetch(Api + "Facturas/Listadofac/"+empresa+"/"+numero+"/"+tipo+"/"+fecha1+"/"+fecha2+"?page=" + page)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
        this.setState({
          datosCargados: true,
          facturas: datosRespuesta.facturas.data,
          pagina: datosRespuesta.facturas.total,
          ultimo: datosRespuesta.facturas.last_page,
          actual: datosRespuesta.facturas.current_page,
          tf:datosRespuesta.total_factura
        });
        Swal.close();
      })
      .catch(console.log);
  }
  anular(id){
    Swal.fire({
      title: '¿Estás seguro?',
      text: "No podrás revertir esto",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, borrar',
      cancelButtonText: 'Cancelar'
  }).then((result) => {
      if (result.isConfirmed) {
          fetch(Api + "Facturas/anular/" + id)
              .then((respuesta) => respuesta.json())
              .then((datosRespuesta) => {
                  Swal.fire(
                      'Anulado!',
                      datosRespuesta.msj,
                      'success'
                  );
                  this.Buscar(1);
                 
              })
              .catch((error) => {
                  console.log(error);
                  Swal.fire(
                      'Error',
                       error,
                      'error'
                  );
              });
      }
  });
  }
  VerFactura(ID) {
    Swal.showLoading();
    fetch(Api + "Facturas/VerFactura/" + ID)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
        var f = datosRespuesta.factura;
        var detalles = datosRespuesta.detalles;

        var med = datosRespuesta.medicamentos;
        var ins = datosRespuesta.insumos;
        document.getElementById("idf").value = ID;
        document.getElementById("fac").value = f.numero_factura;
        document.getElementById("fv").value = f.fecha_ven;
        document.getElementById("ff").value = f.fechaf;
        document.getElementById("fi").value = f.fechai;
        document.getElementById("fr").value = f.fecha_registro;
        document.getElementById("fp").value = f.forma_pago;
        document.getElementById("mes").value = f.meses;
        document.getElementById("copago").value = f.copagos;
        document.getElementById("cliente").value = datosRespuesta.paciente;
        document.getElementById("empresa").value = datosRespuesta.empresa.nombre_emp;
        this.Buscar(1);
        const copa = detalles.reduce((acc, detalle) => acc + detalle.pagos, 0);
        this.setState({
            detalles: datosRespuesta.detalles,
            medicamentos: datosRespuesta.medicamentos,
            insumos: datosRespuesta.insumos,
            total:datosRespuesta.totales,
            copago:f.copagos,
            co:copa
          });
        Swal.close();
        
      })
      .catch(console.log);
  }
  upitemsmed(id){
    var codigo = document.getElementById("codigo"+id).value;
    var anexo = document.getElementById("anexo"+id).value;
    var precio = document.getElementById("precio"+id).value;
 
    var orden = id;
    var datos = {
        codigo: codigo,
        anexo: anexo,
        precio: precio,
        orden: orden
    }
      console.log(datos);
      fetch(Api+'Facturas/EditarItemsmed', {
        method: 'POST',
        body: JSON.stringify(datos),
    })
    .then(res => res.json())
    .then(res => {
        console.log(res);
    }).catch(console.log);
  }
  upcopago() {
    var factura = document.getElementById("fac").value;
    var copago = document.getElementById("copago").value;
    var idf = document.getElementById("idf").value;
    var datos = {
        idf: idf,
        copago: copago
    };
    console.log(datos);

    fetch(Api + 'Facturas/EditarCopago', {
        method: 'POST',
        body: JSON.stringify(datos),
    })
    .then(res => res.json())
    .then(res => {
        console.log(res);
        this.VerFactura(idf); // Llamada a otra función después de recibir la respuesta
    })
    .catch(console.log);
}
  upitemsins(id){
    var codigo = document.getElementById("codigo"+id).value;
    var anexo = document.getElementById("anexo"+id).value;
    var precio = document.getElementById("precio"+id).value;
 
    var orden = id;
    var datos = {
        codigo: codigo,
        anexo: anexo,
        precio: precio,
        orden: orden
    }
      console.log(datos);
      fetch(Api+'Facturas/EditarItemsins', {
        method: 'POST',
        body: JSON.stringify(datos),
    })
    .then(res => res.json())
    .then(res => {
        console.log(res);
    }).catch(console.log);
  }
 upitems(id){
  var idf = document.getElementById("idf").value;
  var codigo = document.getElementById("codigo"+id).value;
  var anexo = document.getElementById("anexo"+id).value;
  var precio = document.getElementById("precio"+id).value;

  var fv = document.getElementById("fv").value;
  var fr = document.getElementById("fr").value;
  var fi = document.getElementById("fi").value;
  var ff = document.getElementById("ff").value;
  var fp = document.getElementById("fp").value;
  var mes = document.getElementById("mes").value;

  var orden = id;
  var datos = {
      codigo: codigo,
      anexo: anexo,
      precio: precio,
      orden: orden,
      idf: idf,
      fv: fv,
      fr: fr,
      fi: fi,
      ff: ff,
      fp: fp,
      mes: mes
  }
    fetch(Api+'Facturas/EditarItems', {
        method: 'POST',
        body: JSON.stringify(datos),
    })
    .then(res => res.json())
  
    .then(res => {
        console.log('Edito:'+res);
        this.VerFactura(idf);
        
    }).catch(console.log);
 }

  Imprimir(fac, tipo, masivo) {
    if (tipo == 0) {
      if (masivo == 1) {
        window.open(
          "https://idb.softmediko.com/php-mysql.php?imprimir=" +
            fac +
            "&t=FAC&estado="
        );
      } else {
        window.open(
          "https://idb.softmediko.com/FacturaBloque.php?imprimir=" +
            fac +
            "&est"
        );
        window.open(
          "https://idb.softmediko.com/FacturaBloque_1.php?imprimir=" +
            fac +
            "&est"
        );
      }
    } else if (tipo == 1) {
      window.open(
        "https://idb.softmediko.com/imprimir_ventas.php?imprimir=" +
          fac +
          "&estado="
      );
    } else {
      window.open(
        "https://idb.softmediko.com/imprimir_alquiler_det.php?imprimir=" +
          fac +
          "&estado="
      );
    }
  }
  Descarga(fac) {
    window.open(
      "https://idb.softmediko.com/vistas/archivo_siigo_1.php?f=" + fac, 'popup', 'width=400,height=200'
    );
  }
  DescargaSiigo() {
    window.open('https://idb.softmediko.com/vistas/rango.php', 'popup', 'width=400,height=200');
  }

  render() {
    const {
      datosCargados,
      empresas,
      detalle,
      bodega,
      facturas,
      pagina,
      ultimo,
      actual,
      detalles,
      medicamentos,co,
      insumos,total,date,tf,copago
    } = this.state;
    if (!datosCargados) {
      var load = "Cargando datos....";
    } else {
      var load = "";
    }
    return (
      <div className="content">
        <div className="modal" id="ModalFactura">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Detalle Factura</h4>

                <button type="button" className="close" data-bs-dismiss="modal">
                  &times;
                </button>
              </div>

              <form id="formulario_factura">
                <div className="modal-body">
                  <div className="card">
                    <div className="card-body">
                    <div className="row">
                    <div className="col-md-4">
                        <label>Paciente.</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="cliente"
                            placeholder=""
                            name="cliente"
                            readOnly="true"
                          />
                          <input
                            type="hidden"
                            className="form-control form-control-sm"
                            id="idf"
                            placeholder=""
                            name="idf"
                          />
                        </div>
                        <div className="col-md-4">
                        <label>Empresa Cliente.</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="empresa"
                            placeholder=""
                            name="empresa"
                            readOnly="true"
                          />
                        </div>
                        <div className="col-md-4">
                        <label>Factura No.</label>
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            id="fac"
                            placeholder=""
                            name="fac"
                            readOnly="true"
                          />
                        </div>
                    </div>
                      <div className="form-row">
                        
                        <div className="col">
                          <label>Fecha Vencimiento</label>
                          <input
                            type="date"
                            className="form-control form-control-sm"
                            id="fv"
                            placeholder=""
                            name="fv"
                          />
                        </div>
                        <div className="col">
                          <label>Fecha Inicio</label>
                          <input
                            type="date"
                            className="form-control form-control-sm"
                            id="fi"
                            placeholder=""
                            name="fi"
                          />
                        </div>
                        <div className="col">
                          <label>Fecha Final</label>
                          <input
                            type="date"
                            className="form-control form-control-sm"
                            placeholder=""
                            name="ff"
                            id="ff"
                          />
                        </div>{" "}
                        <br></br>
                      </div>

                      <div className="form-row">
                        <div className="col">
                          <label>Fecha Registro</label>
                          <input
                            type="date"
                            className="form-control form-control-sm"
                            id="fr"
                            placeholder=""
                            name="fr"
                          />
                        </div>
                        <div className="col">
                          <label>Forma de Pago</label>
                          <select
                            className="form-control form-control-sm"
                            name="fp"
                            id="fp"
                          >
                            <option value="">Seleccione</option>
                            <option value="Credito">Credito</option>
                            <option value="Contado">Contado</option>
                            <option value="Cheque">Cheque</option>
                          </select>
                        </div>
                        <div className="col">
                          <label>Meses</label>
                          <input
                            type="number"
                            className="form-control form-control-sm"
                            placeholder=""
                            name="mes"
                            id="mes"
                          />
                        </div>
                      </div>

                      <br></br>
                      <div className="form-row">
                        <table className="table table-sm table-bordered">
                          <thead>
                            <tr>
                              <th>ORDEN</th>
                              <th>CODIGO</th>
                              <th>DESCRIPCION</th>
                              <th>ANEXOS</th>
                              <th>CANTIDAD</th>
                              <th>MES</th>
                              <th>FECHA INI</th>
                              <th>FECHA FIN</th>
                              <th>PRECIO</th>
                              <th>TOTAL</th>
                            </tr>
                          </thead>
                          <tbody>
                         
                            {detalles.map((d) => (
                                <tr key={d.orden}>
                                    <td>{d.orden}</td>
                                    <td><input type="text" name={'codigo'+d.orden} id={'codigo'+d.orden} defaultValue={d.codigo} className="form-control" onChange={()=> this.upitems(d.orden)}/></td>
                                    <td>{d.descripcion}<br/><b>Copago:{d.pagos}</b></td>
                                    <td><input type="text" name={'anexo'+d.orden} id={'anexo'+d.orden} defaultValue={d.anexo} className="form-control" onChange={()=> this.upitems(d.orden)}/></td>
                                    <td>{d.cantidad}</td>
                                    <td>{d.mes}</td>
                                    <td>{d.fechai}</td>
                                    <td>{d.fechaf}</td>
                                    <td><input type="text" name="precio" id={'precio'+d.orden} defaultValue={d.precio} className="form-control" onBlur={()=> this.upitems(d.orden)}/></td>
                                    <td>{d.precio * d.cantidad}</td>
                              
                                    </tr>
                                
                            ))}

                           {medicamentos.map((d) => (
                                <tr key={d.id}>
                                    <td>{d.rel_atencion}</td>
                                    <td><input type="text" name={'codigo'+d.id} id={'codigo'+d.id} defaultValue={d.cod_med} className="form-control" onChange={()=> this.upitemsmed(d.id)}/></td>
                                    <td>{d.id} {d.nombre_medicamento}</td>
                                    <td><input type="text" name={'anexo'+d.id} id={'anexo'+d.id} defaultValue={d.info} className="form-control" onChange={()=> this.upitemsmed(d.id)}/></td>
                                    <td>{d.cantidad_usada}</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td><input type="text" name={'precio'+d.id} id={'precio'+d.id} defaultValue={d.sub_precio_m} className="form-control" onChange={()=> this.upitemsmed(d.id)}/></td>
                                    <td>{d.sub_precio_m * d.cantidad_usada}</td>
                                  
                                    </tr>
                            ))} 
                            {insumos.map((d) => (
                                <tr key={d.id_ia}>
                                    <td>{d.rel_atencion}</td>
                                    <td><input type="text" name={'codigo'+d.id_ia} id={'codigo'+d.id_ia} defaultValue={d.cod_insumo} className="form-control" onChange={()=> this.upitemsins(d.id_ia)}/></td>
                                    <td>{d.id_ia} - {d.nombre_insumo}</td>
                                    <td><input type="text" name={'anexo'+d.id_ia} id={'anexo'+d.id_ia} defaultValue={d.inf_adicional} className="form-control" onChange={()=> this.upitemsins(d.id_ia)}/></td>
                                    <td>{d.cant_usada}</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td><input type="text" name="precio" id={'precio'+d.id_ia} defaultValue={d.sub_precio} className="form-control" onChange={()=> this.upitemsins(d.id_ia)}/></td>
                                    <td>{d.sub_precio * d.cant_usada}</td>
                            
                                    </tr>
                            ))} 
                            <tr><td colSpan={8}></td><td>Copagos {co}</td><td><input type="text" className="form-control" name="copago" id="copago" onBlur={()=> this.upcopago()} defaultValue={co}/></td></tr>
                            <tr><td colSpan={8}></td><td>Totales</td><td><input type="text" className="form-control" name="total" id="total" value={total}/></td></tr>
                            <tr><td colSpan={8}></td><td>Gran Total</td><td><input type="text" className="form-control" name="total" id="total" value={total-copago}/></td></tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    data-bs-dismiss="modal"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} />
                    &nbsp;&nbsp;Cerrar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
            <h4>Lista de Facturas <button
                      type="button"
                      id="bt"
                      name="bt"
                      className="btn btn-success btn-sm"
                      onClick={() => this.DescargaSiigo()}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                      &nbsp;&nbsp;Descargar Archivo siigo
                    </button></h4>
           
            </div>
            <div className="card-body">
              
              <form id="formulario">
                <div className="row">
                  <div className="col-xl-2">
                  <label>Empresa</label>
                    <select
                      className="form-select form-select-sm"
                      type="text"
                      name="empresax"
                      id="empresax"
                    >
                      <option value="">Seleccione la Empresa</option>
                      {empresas.map((itemEmpresa) => (
                        <option
                          key={itemEmpresa.id_empresa}
                          value={itemEmpresa.rips}
                        >
                          {itemEmpresa.nombre_emp}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-xl-2">
                  <label>Numero de factura</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      id="numero"
                      name="numero"
                      placeholder="Numero de Factura"
                    />
                  </div>
                  <div className="col-xl-2">
                  <label>Tipo de Factura</label>
                    <select
                      className="form-select form-select-sm"
                      type="text"
                      name="tipo"
                      id="tipo"
                    >
                      <option value="0">Seleccione</option>
                      
                        <option value="atencion">Atencion</option>
                        <option value="alquiler">Alquiler</option>
                        <option value="ventas">Ventas</option>
                
                    </select>
                  </div>
                  <div className="col-xl-2">
                    <label>Fecha Inicial</label>
                    <input
                      className="form-control form-control-sm"
                      type="date"
                      id="fecha1"
                      name="fecha1"
                      placeholder="Año"
                      
                    />
                  </div>
                  <div className="col-xl-2">
                    <label>Fecha Final</label>
                    <input
                      className="form-control form-control-sm"
                      type="date"
                      id="fecha2"
                      name="fecha2"
                      placeholder="Año"
                     
                    />
                  </div>
                  

                  <div className="col-xl-2 pt-4">
                    <button
                      type="button"
                      id="bt"
                      name="bt"
                      className="btn btn-primary btn-sm"
                      onClick={() => this.Buscar(1)}
                    >
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp;&nbsp;Filtrar
                    </button>
                  </div>
                </div>
              </form>
              <br></br>
              <div className="row">
                <table className="table table-hover table-condensed table-sm">
                  <thead className="thead-inverse">
                    <tr>
                      <th>CONSECUTIVO</th>
                      <th>PACIENTE</th>
                      <th>EMPRESA</th>
                      <th>TIPO</th>
                      <th>TOTAL</th>
                      <th>FECHA REGISTRO</th>
                      <th>EXP SIIGO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {load}
                    {facturas.map((itemMovimiento) => (
                      <tr key={itemMovimiento.id_factura}>
                        <td>{itemMovimiento.numero_factura}</td>
                        <td>
                          {itemMovimiento.paciente == null ? (
                            itemMovimiento.id_empresa
                          ) : (
                            <Link
                              to={"InfoPacientes/" + itemMovimiento.id_paciente}
                            >
                              {itemMovimiento.paciente}
                            </Link>
                          )}
                        </td>
                        <td>{itemMovimiento.empresa}</td>
                        <td>
                          <span
                            class={
                              itemMovimiento.cod_alquiler == ""
                                ? "badge bg-success"
                                : "badge bg-secondary"
                            }
                          >
                            {itemMovimiento.cod_alquiler == ""
                              ? "Atencion"
                              : itemMovimiento.cod_alquiler}
                          </span>
                        </td>
                        <td>{currencyFormat(itemMovimiento.total)}</td>
                        <td>{itemMovimiento.fecha_registro}</td>
                        <td>
                          <div className="btn-group" role="group" aria-label="">
                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                              data-bs-toggle="modal"
                              data-bs-target="#nuevoMovimiento"
                              onClick={() =>
                                this.Descarga(itemMovimiento.numero_factura)
                              }
                            >
                              <FontAwesomeIcon icon={faDownload} />
                            </button>
                            &nbsp;&nbsp;
                            <button
                              type="button"
                              className="btn btn-info btn-sm"
                              onClick={() =>
                                this.Imprimir(
                                  itemMovimiento.numero_factura,
                                  itemMovimiento.cod_alquiler == ""
                                    ? 0
                                    : itemMovimiento.cod_alquiler == "ventas"
                                    ? 1
                                    : 2,
                                  itemMovimiento.paciente == null ? 0 : 1
                                )
                              }
                            >
                              
                              <FontAwesomeIcon icon={faPrint} />
                            </button>
                            &nbsp;&nbsp;
                             <button
                              type="button"
                              className="btn btn-warning btn-sm"
                              data-bs-toggle="modal"
                              data-bs-target="#ModalFactura"
                              onClick={() =>
                                this.VerFactura(itemMovimiento.id_factura)
                              }
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </button>
                            &nbsp;&nbsp;
                             <button
                              type="button"
                              className="btn btn-danger btn-sm"
                             
                              onClick={() =>
                                this.anular(itemMovimiento.id_factura)
                              }
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                            
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className="page-item">
                    <a
                      className="page-link"
                      href="#"
                      onClick={() => this.Buscar(1)}
                    >
                      <FontAwesomeIcon icon={faAngleDoubleLeft} />
                    </a>
                  </li>
                  <li className="page-item">
                    <a
                      className="page-link"
                      href="#"
                      onClick={() => this.Buscar(actual - 1)}
                    >
                      <FontAwesomeIcon icon={faAngleLeft} />
                    </a>
                  </li>
                  {(() => {
                    let p = [];
                    /*if (ultimo >= 20) {
                                            var ult = 20;
                                        } else {
                                            ult = ultimo;
                                        }
                                        var ult = ultimo
                                        for (let i = 1; i <= ult; i++) {
                                            p.push(<li className="page-item" key={i}><a className="page-link" href="#" onClick={() => this.Buscar(i)}>{i}</a></li>);
                                        }*/
                    p.push(
                      <li className="page-item" key={actual}>
                        <a
                          className="page-link"
                          href="#"
                          onClick={() => this.Buscar(actual)}
                        >
                          {actual}
                        </a>
                      </li>
                    );
                    return p;
                  })()}
                  <li className="page-item">
                    <a
                      className="page-link"
                      href="#"
                      onClick={() => this.Buscar(actual + 1)}
                    >
                      <FontAwesomeIcon icon={faAngleRight} />
                    </a>
                  </li>
                  <li className="page-item">
                    <a
                      className="page-link"
                      href="#"
                      onClick={() => this.Buscar(ultimo)}
                    >
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="card-footer text-muted">
              <b>Pagina</b> <span class="badge bg-primary">{actual}</span>{" "}
              <b>de</b> <span class="badge bg-primary">{ultimo}</span> |{" "}
              <b>Registros</b> <span class="badge bg-success">{pagina}</span>
              <b> | Total Facturado:</b> <span class="badge bg-warning">{tf}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Listar;
