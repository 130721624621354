import React from 'react';
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'

class Crear extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            codigo: "",
            nombre: "",
            estado: "",
            observacion: "",
            errores: []
        }
    }
    cambioValor = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState({ state, errores: [] })
    }
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    enviarDatos = (e) => {
        e.preventDefault();
        console.log("Formulario Enviado...");
        const { nombre, codigo, estado, observacion } = this.state;
        var errores = [];
        if (!codigo) { errores.push("error_codigo"); }
        if (!nombre) { errores.push("error_nombre"); }
        if (!estado) { errores.push("error_estado"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        var datosEnviar = { nombre: nombre, codigo: codigo, estado: estado, observacion: observacion }
        fetch(Api + "AddBodegas", {
            method: "POST",
            body: JSON.stringify(datosEnviar)
        })
            .then(respuesta => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                Swal.fire(
                    'Bodegas!',
                    datosRespuesta.msj,
                    'success'
                )
                this.props.history.push("/MasterBodegas");
            })
            .catch(console.log)
    }
    render() {
        const { nombre, codigo, estado, observacion } = this.state;
        return (
            <div className="content-wrapper">
                <div className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header">
                                Registro de Bodegas
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.enviarDatos}>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className='row g-3'>
                                                <div className="col-md-2">
                                                    <label htmlFor="codigo">Código</label>
                                                    <input className={((this.verificarError("error_codigo")) ? "is-invalid" : "") + " form-control form-control-sm"} maxLength={4} type="text" name="codigo" id="codigo" value={codigo} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback">Digite el Código</small>
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor="nombre">Nombre</label>
                                                    <input className={((this.verificarError("error_nombre")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="nombre" id="nombre" value={nombre} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="estado">Estado</label>
                                                    <select name="estado"
                                                        id="estado"
                                                        className={((this.verificarError("error_estado")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                        onChange={this.cambioValor}>
                                                        <option value="">Seleccione el Estado</option>
                                                        <option value="Activo">Activo</option>
                                                        <option value="No Activo">No Activo</option>
                                                    </select>
                                                    <small id="helpId" className="invalid-feedback">Seleccione el Estado</small>
                                                </div>
                                                <div className="col-md-5">
                                                    <label htmlFor="observacion">Observaciones</label>
                                                    <input className="form-control form-control-sm" type="text" name="observacion" id="observacion" value={observacion} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="btn-group" role="group" aria-label="">
                                        <button type="submit" className="btn btn-success btn-sm"><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Agregar</button>&nbsp;&nbsp;
                                        <Link className="btn btn-danger btn-sm" to={"/MasterBodegas"}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Crear;