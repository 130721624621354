import './App.css';
import index from "./componentes/atenciones/index";
import editar from "./componentes/atenciones/Editar";
import login from "./componentes/login";
import Header from "./layout/Header";
import Menu from './layout/Menu';
import Historia from './layout/Historias';
import Footer from './layout/Footer';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import Crear from './componentes/atenciones/Crear';

import CrearAtencion from './componentes/atencion/Crear';
import CrearAtencionOrden from './componentes/ordenes/CrearAtencion';
import HistorialClinico from './componentes/atencion/HistorialClinico';
import Ordenes from './componentes/ordenes/index';
import VerOrdenInterna from "./componentes/ordenes/VerOrdenInterna";
import VerArchivo from "./componentes/ordenes/VerArchivo";

import Medicamentos from "./componentes/medicamentos/medicamentos";
import CrearMed from './componentes/medicamentos/Crear';
import editarMed from "./componentes/medicamentos/Editar";

import Insumos from "./componentes/insumos/index";
import CrearIns from './componentes/insumos/Crear';
import editarIns from "./componentes/insumos/Editar";

import Empresas from "./componentes/empresas/index";
import CrearEmp from './componentes/empresas/Crear';
import editarEmp from "./componentes/empresas/Editar";
import AtencionesEmp from "./componentes/empresas/atenciones";
import InsumosEmp from "./componentes/empresas/insumos";
import VentasEmp from "./componentes/empresas/Ventas";
import AlquilerEmp from "./componentes/empresas/Alquiler";

import Roles from "./componentes/roles/index";
import CrearRol from './componentes/roles/Crear';
import editarRol from "./componentes/roles/Editar";

import Cargos from "./componentes/cargos/index";
import CrearCarg from './componentes/cargos/Crear';
import editarCarg from "./componentes/cargos/Editar";

import Documentos from "./componentes/documentos/index";
import CrearDoc from './componentes/documentos/Crear';
import EditarDoc from "./componentes/documentos/Editar";

import Pacientes from "./componentes/pacientes/index";
import CrearPac from './componentes/pacientes/Crear';
import infoPac from "./componentes/pacientes/Historial";

import AudAtenciones from "./componentes/aud_atenciones/index";
import AudPacientes from "./componentes/aud_pacientes/index";
import AudAtencionesPacientes from "./componentes/aud_atencionespacientes/index";
import AudOrdenes from "./componentes/aud_ordenes/index";
import Informe from "./componentes/aud_atenciones/informe";
import InformeFactura from "./componentes/aud_atenciones/informe_factura";
import PacientesEmpresa from "./componentes/aud_pacientes/Cantidad";

import Proveedores from "./componentes/proveedores/index";
import CrearProv from './componentes/proveedores/Crear';
import EditarProv from "./componentes/proveedores/Editar";

import Bodegas from "./componentes/bodegas/index";
import CrearBod from './componentes/bodegas/Crear';
import EditarBod from "./componentes/bodegas/Editar";

import Operaciones from "./componentes/operaciones/index";
import CrearOper from './componentes/operaciones/Crear';
import EditarOper from "./componentes/operaciones/Editar";

import Movimientos from "./componentes/listamovimientos/index";
//import CrearBod from './componentes/bodegas/Crear';
//import EditarBod from "./componentes/bodegas/Editar";

import Ventas from "./componentes/productosventa/index";
import CrearVen from './componentes/productosventa/Crear';
import EditarVen from "./componentes/productosventa/Editar";

import Alquiler from "./componentes/productosalquiler/index";
import CrearAlq from './componentes/productosalquiler/Crear';
import EditarAlq from "./componentes/productosalquiler/Editar";

import Enfermedades from "./componentes/enfermedades/index";
import CrearEnf from './componentes/enfermedades/Crear';
import EditarEnf from "./componentes/enfermedades/Editar";

import Noticias from "./componentes/noticias/index";
import CrearNot from './componentes/noticias/Crear';
import EditarNot from "./componentes/noticias/Editar";

import Usuario from "./componentes/usuarios/index";

import Soporte from "./componentes/soportes/index";
import CrearSop from './componentes/soportes/Crear';
import EditarSop from "./componentes/soportes/Editar";

import Facturas from "./componentes/facturacion/index";
import FacturasEmp from "./componentes/facturacion/Facturar";
import Rips from "./componentes/facturacion/Rips";
import RipsNew from "./componentes/facturacion/RipsNew";
import Liquidacion from "./componentes/liquidacion/index";

import MasterAlquiler from "./componentes/alquiler/index";
import MasterSegAlquiler from "./componentes/alquiler/Master";
import MasterVentas from "./componentes/alquiler/Ventas";
function App() {
  const usuario = localStorage.getItem("k_usuario");
  return (
    <Router>
      {usuario != null ? <Header /> : ''}
      {usuario != null ? <Menu /> : ''}
      {usuario != null ? <Historia /> : ''}
      {usuario != null ?
        <Route exact path="/" component={Ordenes}></Route>
        :
        <Route exact path="/" component={login}></Route>}
      <Route exact path="/MasterAtenciones" component={index}></Route>
      <Route exact path="/UpAtenciones/:id" component={editar}></Route>
      <Route exact path="/AddAtenciones" component={Crear}></Route>

      <Route exact path="/AlquilerProceso" component={MasterAlquiler}></Route>
      <Route exact path="/SeguimientoAlquiler" component={MasterSegAlquiler}></Route>
      <Route exact path="/VentasProceso" component={MasterVentas}></Route>
      
      <Route exact path="/AddAtencion" component={CrearAtencion}></Route>
      <Route exact path="/HistorialClinico/:id_paciente" component={HistorialClinico}></Route>
      <Route exact path="/MasterOrdenes" component={Ordenes}></Route>
      <Route exact path="/AddAtencionOrden/:id" component={CrearAtencionOrden}></Route>
      <Route exact path="/VerOrdenInterna/:id" component={VerOrdenInterna}></Route>
      <Route exact path="/VerArchivo/:id" component={VerArchivo}></Route>

      <Route exact path="/MasterMedicamentos" component={Medicamentos}></Route>
      <Route exact path="/AddMedicamentos" component={CrearMed}></Route>
      <Route exact path="/UpMedicamentos/:id" component={editarMed}></Route>

      <Route exact path="/MasterSoporte" component={Soporte}></Route>
      <Route exact path="/AddSoporte" component={CrearSop}></Route>
      <Route exact path="/UpSoporte/:id" component={EditarSop}></Route>

      <Route exact path="/MasterInsumos" component={Insumos}></Route>
      <Route exact path="/AddInsumos" component={CrearIns}></Route>
      <Route exact path="/UpInsumos/:id" component={editarIns}></Route>

      <Route exact path="/MasterEmpresas" component={Empresas}></Route>
      <Route exact path="/AddEmpresas" component={CrearEmp}></Route>
      <Route exact path="/UpEmpresas/:id" component={editarEmp}></Route>

      <Route exact path="/MasterRoles" component={Roles}></Route>
      <Route exact path="/AddRoles" component={CrearRol}></Route>
      <Route exact path="/UpRoles/:id" component={editarRol}></Route>

      <Route exact path="/MasterCargos" component={Cargos}></Route>
      <Route exact path="/AddCargos" component={CrearCarg}></Route>
      <Route exact path="/UpCargos/:id" component={editarCarg}></Route>

      <Route exact path="/ListaPaciente" component={Pacientes}></Route>
      <Route exact path="/AddPacientes" component={CrearPac}></Route>
      <Route exact path="/InfoPacientes/:id" component={infoPac}></Route>

      <Route exact path="/MasterAudAtenciones" component={AudAtenciones}></Route>
      <Route exact path="/MasterAudPacientes" component={AudPacientes}></Route>
      <Route exact path="/MasterAudAtencionesPacientes" component={AudAtencionesPacientes}></Route>
      <Route exact path="/MasterAudOrdenes" component={AudOrdenes}></Route>
      <Route exact path="/Informe" component={Informe}></Route>
      <Route exact path="/InformeFactura" component={InformeFactura}></Route>

      <Route exact path="/MasterProveedores" component={Proveedores}></Route>
      <Route exact path="/AddProveedores" component={CrearProv}></Route>
      <Route exact path="/UpProveedores/:id" component={EditarProv}></Route>

      <Route exact path="/MasterBodegas" component={Bodegas}></Route>
      <Route exact path="/AddBodegas" component={CrearBod}></Route>
      <Route exact path="/UpBodegas/:id" component={EditarBod}></Route>

      <Route exact path="/MasterOperaciones" component={Operaciones}></Route>
      <Route exact path="/AddOperaciones" component={CrearOper}></Route>
      <Route exact path="/UpOperaciones/:id" component={EditarOper}></Route>

      <Route exact path="/MasterMovimientos" component={Movimientos}></Route>

      <Route exact path="/Facturacion" component={Facturas}></Route>
      <Route exact path="/FacturarEmpresa" component={FacturasEmp}></Route>
      <Route exact path="/GenerarRips" component={Rips}></Route>
      <Route exact path="/GenerarRipsNew" component={RipsNew}></Route>
      {/*<Route exact path="/AddBodegas" component={CrearBod}></Route>
      <Route exact path="/UpBodegas/:id" component={EditarBod}></Route>*/}

      <Route exact path="/MasterVentas" component={Ventas}></Route>
      <Route exact path="/AddVentas" component={CrearVen}></Route>
      <Route exact path="/UpVentas/:id" component={EditarVen}></Route>

      <Route exact path="/MasterAlquiler" component={Alquiler}></Route>
      <Route exact path="/AddAlquiler" component={CrearAlq}></Route>
      <Route exact path="/UpAlquiler/:id" component={EditarAlq}></Route>

      <Route exact path="/MasterEnfermedades" component={Enfermedades}></Route>
      <Route exact path="/AddEnfermedades" component={CrearEnf}></Route>
      <Route exact path="/UpEnfermedades/:id" component={EditarEnf}></Route>

      <Route exact path="/MasterUsuarios" component={Usuario}></Route>

      <Route exact path="/AddInsEmp/:id" component={InsumosEmp}></Route>
      <Route exact path="/AddAteEmp/:id" component={AtencionesEmp}></Route>
      <Route exact path="/AddVenEmp/:id" component={VentasEmp}></Route>
      <Route exact path="/AddAlqEmp/:id" component={AlquilerEmp}></Route>

      <Route exact path="/MasterDocumentos" component={Documentos}></Route>
      <Route exact path="/AddDocumentos" component={CrearDoc}></Route>
      <Route exact path="/UpDocumentos/:id" component={EditarDoc}></Route>

      <Route exact path="/MasterNoticias" component={Noticias}></Route>
      <Route exact path="/AddNoticias" component={CrearNot}></Route>
      <Route exact path="/UpNoticias/:id" component={EditarNot}></Route>

      <Route exact path="/liquidacion" component={Liquidacion}></Route>
      <Route exact path="/MasterPacientesEmpresa" component={PacientesEmpresa}></Route>

      {usuario != null ? <Footer /> : ''}
    </Router>
  );
}

export default App;
