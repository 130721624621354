import React from "react";
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft, faAngleLeft, faAngleDoubleRight, faAngleRight, faEdit, faTrashAlt, faPlus, faFilter } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2';

class Listar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datosCargados: false,
            bodegas: [],
            pagina: [],
        };
    }
    borrarRegistros = (id) => {
        console.log(id);
        Swal.fire({
            title: 'Estas seguro de eliminar el registro?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Borrar',
            denyButtonText: `No borrar`,
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                fetch(Api + "DelBodegas/" + id)
                    .then((respuesta) => respuesta.json())
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        Swal.fire('Borrado!', datosRespuesta.msj, 'success')
                        this.Buscar(1);
                    })
                    .catch(console.log);
            } else if (result.isDenied) {
                Swal.fire('Cancelado', '', 'info')
            }
        })
    };
    componentDidMount() {
        this.Buscar(1);
    }
    Buscar(page) {
        let bname = document.getElementById('bnombre').value;
        console.log("Formulario Enviado...");
        if (bname === '') {
            bname = 0;
        }
        fetch(Api + "SearchBodegas/" + bname + "?page=" + page)
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                this.setState({ datosCargados: true, bodegas: datosRespuesta.data, pagina: datosRespuesta.total, ultimo: datosRespuesta.last_page, actual: datosRespuesta.current_page });
            })
            .catch(console.log);
    }
    render() {
        const { datosCargados, bodegas, pagina, ultimo, actual } = this.state;
        if (!datosCargados) {
            var load = 'Cargando datos....';
        } else {
            var load = '';
        }
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <Link className="btn btn-success btn-sm" to={"/AddBodegas"}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nueva Bodega</Link>
                        </div>
                        <div className="card-body">
                            <h4>Lista de Bodegas</h4>
                            <div className="row">
                                <div className="col-xl-3">
                                    <input type="text" id="bnombre" name="bnombre" placeholder="Bodega" className="form-control form-control-sm" />
                                </div>
                                <div className="col-xl-3">
                                    <button id="bt" name="bt" className="btn btn-primary btn-sm" onClick={() => this.Buscar(1)}><FontAwesomeIcon icon={faFilter} />&nbsp;&nbsp;Filtrar</button>
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <table className="table table-hover table-condensed table-sm">
                                    <thead className="thead-inverse">
                                        <tr>
                                            <th>CÓDIGO</th>
                                            <th>BODEGA</th>
                                            <th>OBSERVACIONES</th>
                                            <th>ESTADO</th>
                                            <th>ACCIONES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {load}
                                        {bodegas.map((itemBodega) => (
                                            <tr key={itemBodega.id_bodega}>
                                                <td>{itemBodega.codigo_bod}</td>
                                                <td>{itemBodega.bodega}</td>
                                                <td>{itemBodega.Observacion}</td>
                                                <td>
                                                    {
                                                        itemBodega.estado_bod === "No Activo" ? (
                                                            <span style={{ fontSize: 11, textTransform: 'uppercase' }} className="badge bg-danger">{itemBodega.estado_bod}</span>
                                                        ) : (
                                                            <span style={{ fontSize: 11, textTransform: 'uppercase' }} className="badge bg-success">{itemBodega.estado_bod}</span>
                                                        )
                                                    }
                                                </td>
                                                <td>
                                                    <div className="btn-group" role="group" aria-label="">
                                                        <Link
                                                            className="btn btn-primary btn-sm"
                                                            to={"/UpBodegas/" + itemBodega.id_bodega}
                                                        >
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </Link>&nbsp;&nbsp;
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger btn-sm"
                                                            onClick={() =>
                                                                this.borrarRegistros(itemBodega.id_bodega)
                                                            }
                                                        >
                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Buscar(1)}><FontAwesomeIcon icon={faAngleDoubleLeft} /></a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Buscar(actual - 1)}><FontAwesomeIcon icon={faAngleLeft} /></a></li>
                                    {(() => {
                                        let p = [];
                                        /*if (ultimo >= 20) {
                                            var ult = 20;
                                        } else {
                                            ult = ultimo;
                                        }
                                        var ult = ultimo;
                                        for (let i = 1; i <= ult; i++) {
                                            p.push(<li className="page-item" key={i}><a className="page-link" href="#" onClick={() => this.Buscar(i)}>{i}</a></li>);
                                        }*/
                                        p.push(<li className="page-item" key={actual}><a className="page-link" href="#" onClick={() => this.Buscar(actual)}>{actual}</a></li>);
                                        return p;
                                    })()}
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Buscar(actual + 1)}><FontAwesomeIcon icon={faAngleRight} /></a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Buscar(ultimo)}><FontAwesomeIcon icon={faAngleDoubleRight} /></a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="card-footer text-muted">
                            <b>Pagina</b> <span class="badge bg-primary">{actual}</span> <b>de</b> <span class="badge bg-primary">{ultimo}</span> | <b>Registros</b> <span class="badge bg-success">{pagina}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Listar;