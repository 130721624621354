import React from "react";
import Api from "../../servicios/api";
import Swal from 'sweetalert2';
import ItemInsumos from "./ItemInsumos";
import ItemMedicamentos from "./ItemMedicamentos";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faPrint, faTrashAlt, faSave } from '@fortawesome/free-solid-svg-icons'

class Editar extends React.Component {
    constructor(props) {
        super(props);
        this.agregarItem = this.agregarItem.bind(this);
        this.state = {
            datosCargados: false,
            estado: props.estado,
            detalle: props.detalleMov,
            operaciones: [],
            proveedores: [],
            bodegas: [],
            errores: []
        };
    }
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    /*currencyFormat(num) {
        return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }*/
    cargarOperaciones() {
        fetch(Api + "SearchOperaciones")
            .then(respuesta => respuesta.json())
            .then((datosOperaciones) => {
                console.log(datosOperaciones);
                this.setState({ operaciones: datosOperaciones })
            })
            .catch(console.log);
    }
    cargarBodegas() {
        fetch(Api + "SearchBodegas/0?page=1")
            .then(respuesta => respuesta.json())
            .then((datosBodegas) => {
                console.log(datosBodegas);
                this.setState({ bodegas: datosBodegas.data })
            })
            .catch(console.log);
    }
    cargarProveedores() {
        fetch(Api + "SearchProveedores/0?page=1")
            .then(respuesta => respuesta.json())
            .then((datosProveedores) => {
                console.log(datosProveedores);
                this.setState({ proveedores: datosProveedores.data })
            })
            .catch(console.log);
    }
    onCloseModal() {
        document.getElementById('form').reset();
        //window.$("#resultDetalleMov").html('');
    }
    agregarItem(item) {
        this.setState({ detalle: [...this.state.detalle, item] });
    }
    borrarItem = (id_mi) => {
        let remove = this.state.detalle.map(function (item) { return item.id_mi }).indexOf(id_mi)
        Swal.fire({
            title: 'Está seguro de Eliminar el Registro?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Borrar',
            denyButtonText: `No Borrar`,
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(Api + "DelMovimientosItem/" + id_mi)
                    .then((respuesta) => respuesta.json())
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        Swal.fire('Borrado!', datosRespuesta.msj, 'success')
                        this.setState({ detalle: this.state.detalle.filter((_, i) => i !== remove) })
                    })
                    .catch(console.log);
            } else if (result.isDenied) {
                Swal.fire('Cancelado', '', 'info')
            }
        })
    }
    enviarDatosItems = (e) => {
        e.preventDefault();
        console.log("Formulario Enviado...");
        console.log(this.props.idMovimiento);
        fetch(Api + "SaveMovimientos/" + this.props.idMovimiento, {
            method: "GET"
        })
            .then(respuesta => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                Swal.fire(
                    'Movimientos!',
                    datosRespuesta.msj,
                    'success'
                )
                this.setState({ estado: datosRespuesta.Estado })
                window.$("#nuevoMovimiento").modal('hide');
                this.props.history.push("/MasterMovimientos");
            })
            .catch(console.log)
    }
    componentDidMount() {
        this.cargarOperaciones();
        this.cargarProveedores();
        this.cargarBodegas();
    }
    componentWillReceiveProps(newEstado) {
        if (this.props.estado !== newEstado.estado) {
            this.setState({ estado: newEstado.estado })
            this.setState({ detalle: newEstado.detalleMov })
        }
    }
    render() {
        const { operaciones, proveedores, bodegas } = this.state;
        return (
            <div className="content">
                <div class="modal fade" id="nuevoItemInsumo" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoItemLabel" aria-hidden="true">
                    <ItemInsumos agregarItem={this.agregarItem} />
                </div>
                <div class="modal fade" id="nuevoItemMedicamento" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoItemLabel" aria-hidden="true">
                    <ItemMedicamentos agregarItem={this.agregarItem} />
                </div>
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="nuevoPacienteSubLabel">Detalle Movimiento</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.onCloseModal}></button>
                        </div>
                        <div className="modal-body">
                            <div className="card">
                                <div style={{ backgroundImage: 'linear-gradient(#2FB3FB, #003153)', color: '#FFFFFF', borderTopLeftRadius: 5, borderTopRightRadius: 5 }} className="card-header text-center">
                                    <h5 style={{ fontSize: 18 }}>Formulario de Registro</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <form className='row g-3' id='form'>
                                                <div style={{ marginTop: 0 }} className='row g-3'>
                                                    <div className="col-md-2">
                                                        <button className='btn btn-secondary btn-sm' type='button' onClick={() => window.open("https://idb.softmediko.com/reporte.php?id=" + this.props.idMovimiento + "&imp=" + this.props.ordenServicio, "Popup", "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30")} ><FontAwesomeIcon icon={faPrint} />&nbsp;&nbsp;Imprimir</button>
                                                    </div>
                                                </div>
                                                <div style={{ marginTop: 0 }} className='row g-3'>
                                                    <hr style={{ marginBottom: 0 }} />
                                                </div>
                                                <div style={{ marginTop: 0 }} className='row g-3'>
                                                    <div className="col-md-2">
                                                        <label htmlFor="orden">Orden / Factura</label>
                                                        <input className="form-control form-control-sm" type="text" name="orden" id="orden" readOnly />
                                                    </div>
                                                    <div className='col-md-2'>
                                                        <label htmlFor="mov">Consecutivo</label>
                                                        <input className="form-control form-control-sm" type="text" name="mov" id="mov" readOnly />
                                                    </div>
                                                    <div className='col-md-2'>
                                                        <label htmlFor="fecha_mov">Fecha Registro</label>
                                                        <input className="form-control form-control-sm" type="text" name="fecha_mov" id="fecha_mov" readOnly />
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label htmlFor="usuario">Registrado Por</label>
                                                        <input type="hidden" name="id_usuario" id='id_usuario' value={window.localStorage.getItem('k_idusuario')} />
                                                        <input className="form-control form-control-sm" type="text" name="usuario" id="usuario" readOnly />
                                                    </div>
                                                    <div className='col-md-2'>
                                                        <label htmlFor="estado">Estado</label>
                                                        <input style={{ fontWeight: 'bold' }} className={((this.state.estado === 0) ? "bg-danger " : "bg-success ") + "form-control form-control-sm text-center"} type="text" name="estado" id="estado" readOnly />
                                                    </div>
                                                </div>
                                                <div style={{ marginTop: 0 }} className='row g-3'>
                                                    <div className="col-md-3">
                                                        <label htmlFor="grupo">Grupo</label>
                                                        <select name="grupo"
                                                            id="grupo"
                                                            className="form-select form-select-sm"
                                                            onChange={this.cambioValor} disabled>
                                                            <option value="">Seleccione el Grupo</option>
                                                            <option value="Factura">Factura</option>
                                                            <option value="Orden">Orden</option>
                                                        </select>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <label htmlFor="id_ope">Operación</label>
                                                        <select name="id_ope"
                                                            id="id_ope"
                                                            className="form-select form-select-sm"
                                                            onChange={this.cambioValor} disabled>
                                                            <option value="">Seleccione la Operación</option>
                                                            {
                                                                operaciones.map(itemOperaciones => (
                                                                    <option key={itemOperaciones.id_operaciones} value={itemOperaciones.id_operaciones}>{itemOperaciones.descripcion}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <label htmlFor="id_pro">Proveedor</label>
                                                        <select name="id_pro"
                                                            id="id_pro"
                                                            className="form-select form-select-sm"
                                                            onChange={this.cambioValor} disabled>
                                                            <option value="">Seleccione el Proveedor</option>
                                                            {
                                                                proveedores.map(itemProveedor => (
                                                                    <option key={itemProveedor.id} value={itemProveedor.id}>{itemProveedor.nombre}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <label htmlFor="bod">Bodega</label>
                                                        <select name="bod"
                                                            id="bod"
                                                            className="form-select form-select-sm"
                                                            onChange={this.cambioValor} disabled>
                                                            <option value="">Seleccione la Bodega</option>
                                                            {
                                                                bodegas.map(itemBodegas => (
                                                                    <option key={itemBodegas.id_bodega} value={itemBodegas.id_bodega}>{itemBodegas.bodega}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='row g-3'>
                                                    <hr style={{ marginTop: 0 }} />
                                                </div>
                                                <div style={{ marginTop: 0 }} className='row text-center'>
                                                    <div className='col'></div>
                                                    <div className='col'>
                                                        <button type="submit" className="btn btn-primary btn-sm" id='btnGuardarMovimientoItems' onClick={this.enviarDatosItems} disabled={this.props.estado === 0 ? "" : true}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Guardar</button>
                                                    </div>
                                                    <div className='col'></div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card'>
                                <div style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5 }} className="card-header">
                                    {
                                        (this.props.bodega === 1) ? (
                                            <button className='btn btn-success btn-sm' id='btnNuevoItemInsumo' type='button' data-bs-toggle='modal' data-bs-target='#nuevoItemInsumo' disabled={this.props.estado === 0 ? "" : true}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nuevo Ítem</button>
                                        ) : (
                                            <button className='btn btn-success btn-sm' id='btnNuevoItemMedicamento' type='button' data-bs-toggle='modal' data-bs-target='#nuevoItemMedicamento' disabled={this.props.estado === 0 ? "" : true}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nuevo Ítem</button>
                                        )
                                    }
                                </div>
                                <div className='card-body'>
                                    <div className="table-responsive">
                                        <table className="table table-hover table-condensed table-sm">
                                            <thead className="thead-inverse">
                                                <tr>
                                                    <th>CÓDIGO</th>
                                                    <th>DESCRIPCIÓN</th>
                                                    <th>CANTIDAD</th>
                                                    <th>PRECIO</th>
                                                    <th>ACCIONES</th>
                                                </tr>
                                            </thead>
                                            <tbody id="resultDetalleMov">
                                                {this.state.detalle.map((itemDetalleMovimiento) => (
                                                    <tr key={itemDetalleMovimiento.id_mi}>
                                                        <td>{itemDetalleMovimiento.codigo}</td>
                                                        <td>{itemDetalleMovimiento.nn}</td>
                                                        <td>{itemDetalleMovimiento.cant}</td>
                                                        <td><b style={{ fontSize: 13 }}>$</b> {itemDetalleMovimiento.precio_ins}</td>
                                                        <td>
                                                            {
                                                                this.props.estado === 0 ? (
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-danger btn-sm"
                                                                            onClick={() =>
                                                                                this.borrarItem(itemDetalleMovimiento.id_mi)
                                                                            }
                                                                        >
                                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                                        </button>
                                                                    </div>
                                                                ) : null
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Editar;