import React from "react";
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2';

class Listar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datosCargados: false,
      cargos: [],
      pagina: [],
    };
  }
  borrarRegistros = (id) => {
    console.log(id);
    Swal.fire({
      title: 'Estas seguro de eliminar el registro?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Borrar',
      denyButtonText: `No borrar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        fetch(Api + "DelCargos/" + id)
          .then((respuesta) => respuesta.json())
          .then((datosRespuesta) => {
            console.log(datosRespuesta);
            Swal.fire('Borrado!', datosRespuesta.msj, 'success')
            this.Buscar(1);
          })
          .catch(console.log);
      } else if (result.isDenied) {
        Swal.fire('Cancelado', '', 'info')
      }
    })
  };
  cargarCargos() {
    console.log("Formulario Enviado...");
    fetch(Api + "Cargos")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({ datosCargados: true, cargos: datosRespuesta });
      })
      .catch(console.log);
  }
  componentDidMount() {
    this.cargarCargos();
  }
  render() {
    const { datosCargados, cargos } = this.state;
    if (!datosCargados) {
      var load = 'Cargando datos....';
    } else {
      var load = '';
    }
    return (
      <div className="content">
        <div className="container-fluid">
          <br />
          <div className="card">
            <div className="card-header">
              <Link className="btn btn-success btn-sm" to={"/AddCargos"}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nuevo Cargo</Link>
            </div>
            <div className="card-body">
              <h4>Lista de Cargos</h4>
              <div className="row">
                <table className="table table-hover table-condensed table-sm">
                  <thead className="thead-inverse">
                    <tr>
                      <th>NOMBRE CARGO</th>
                      <th>ACCIONES</th>
                    </tr>
                  </thead>
                  <tbody>
                    {load}
                    {cargos.map((itemCargo) => (
                      <tr key={itemCargo.id_cargo}>
                        <td>{itemCargo.nombre_cargo}</td>
                        <td>
                          <div className="btn-group" role="group" aria-label="">
                            <Link
                              className="btn btn-primary btn-sm"
                              to={"/UpCargos/" + itemCargo.id_cargo}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </Link>&nbsp;&nbsp;
                            <button
                              type="button"
                              className="btn btn-danger btn-sm"
                              onClick={() =>
                                this.borrarRegistros(itemCargo.id_cargo)
                              }
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Listar;