import React from 'react';
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import Swal from 'sweetalert2';
class Crear extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            codigo:"",
            nombre:"",
            tipo:"",
            cuenta:"",
            forma:"",
            errores:[]
        }
    }
    cambioValor = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState({ state, errores:[] })
    }
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    enviarDatos = (e) => {
        e.preventDefault();
        console.log("Formulario Enviado...");
        const {nombre, tipo} = this.state;
        console.log(nombre);
        console.log(tipo);
    
        var errores = [];
    
        if (!nombre) {
            errores.push("error_nombre");
        }
        if (!tipo) {
            errores.push("error_tipo");
        }
        this.setState({ errores:errores });
        if (errores.length > 0) {
            return false;
        }
        var datosEnviar = {nombre:nombre, tipo:tipo}
        fetch(Api + "AddNoticias", {
            method:"POST",
            body:JSON.stringify(datosEnviar)
        })
        .then(respuesta=>respuesta.json())
        .then((datosRespuesta)=> {
            console.log(datosRespuesta)
            Swal.fire(
                'Noticias!',
                datosRespuesta.msj,
                'success'
              )
            this.props.history.push("/MasterNoticias");
        })
        .catch(console.log)
    }
    render() {
        const {nombre, tipo,codigo,cuenta,forma} = this.state;
        return (
            <div className="content-wrapper">
          <div className="content">
            <div className="container-fluid">
            <div className="card">
                <div className="card-header">
                    Registro de Noticias
                </div>
                <div className="card-body">
                    <form onSubmit={this.enviarDatos}>
                        
                        <div className="form-group">
                          <label htmlFor="nombre">Descripcion de la noticia</label>
                          <textarea className={((this.verificarError("error_nombre"))?"is-invalid":"")+" form-control form-control-sm"}  name="nombre" id="nombre" value={nombre} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} ></textarea>
                          <small id="helpId" className="invalid-feedback">Digite la noticia</small>
                        </div>
                        <div className="form-group">
                          <label htmlFor="tipo">Estado</label>
                          <select name="tipo"
                        id="tipo"
         
                        className={((this.verificarError("error_tipo"))?"is-invalid":"")+" form-select form-select-sm"}
                        onChange={this.cambioValor}>
                       <option value="">Seleccione...</option>  
                        <option value="Publicado">Publicado</option>   
                        <option value="Archivado">Archivado</option>  
                       
                      </select>

                          <small id="helpId" className="invalid-feedback">Seleccione el estado</small>
                        </div>

                        
                        <br />
                        <div className="btn-group" role="group" aria-label="">
                            <button type="submit" className="btn btn-success btn-sm">Agregar</button>&nbsp;&nbsp;
                            <Link className="btn btn-danger btn-sm" to={"/MasterNoticias"}>Cancelar</Link>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            </div>
            </div>
        );
    }
}
 
export default Crear;