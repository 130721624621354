import React from "react";
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'

class Editar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datosCargados: false,
            bodega: [],
            errores: []
        };
    }
    cambioValor = (e) => {
        const state = this.state.bodega;
        state[e.target.name] = e.target.value;
        this.setState({ bodega: state });
    };
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    enviarDatos = (e) => {
        e.preventDefault();
        console.log("Formulario Enviado...");
        const codigo = document.getElementById('codigo').value;
        const nombre = document.getElementById('nombre').value;
        const estado_bod = document.getElementById('estado_bod').value;
        var errores = [];
        if (!codigo) { errores.push("error_codigo"); }
        if (!nombre) { errores.push("error_nombre"); }
        if (!estado_bod) { errores.push("error_estado"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let form = document.getElementById('form');
        let formData = new FormData(form);
        console.log(formData);

        fetch(Api + "UpBodegas", {
            method: "POST",
            body: formData
        })
            .then(respuesta => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                Swal.fire(
                    'Bodega!',
                    datosRespuesta.msj,
                    'success'
                )
                this.props.history.push("/MasterBodegas");
            })
            .catch(console.log)
    }
    componentDidMount() {
        console.log(this.props.match.params.id);
        fetch(Api + "Bodegas/" + this.props.match.params.id,
            {
                method: "GET",
                headers: new Headers({
                    'Authorization': 'koala2021'
                }),
            })
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                this.setState({ datosCargados: true, bodega: datosRespuesta }); //
            })
            .catch(console.log);
    }
    render() {
        const { datosCargados, bodega } = this.state;
        if (!datosCargados) {
            var load = 'Cargando datos....';
        } else {
            var load = '';
        }
        return (
            <div>
                <div className="content-wrapper">
                    <div className="content">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-header">
                                    {
                                        load ? (
                                            load
                                        ) : (
                                            "Editar Bodega"
                                        )
                                    }
                                </div>
                                <div className="card-body">
                                    <form onSubmit={this.enviarDatos} id="form">
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div style={{marginBottom: 16}} className='row g-3'>
                                                    <div className="col-md-2">
                                                        <label htmlFor="id">Id :</label>
                                                        <input type="text" name="id" id="id"
                                                            value={bodega.id_bodega} className="form-control form-control-sm"
                                                            placeholder="" aria-describedby="helpId"
                                                            onChange={this.cambioValor} readOnly
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row g-3">
                                                    <div className="col-md-2">
                                                        <label htmlFor="codigo">Código:</label>
                                                        <input type="text" name="codigo" id="codigo" defaultValue={bodega.codigo_bod}
                                                            className={((this.verificarError("error_codigo")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                                            onChange={this.cambioValor}
                                                        />
                                                        <small id="helpId" className="invalid-feedback">Digita el Código</small>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label htmlFor="nombre">Nombre:</label>
                                                        <input type="text" name="nombre" id="nombre"
                                                            defaultValue={bodega.bodega}
                                                            className={((this.verificarError("error_nombre")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                                            onChange={this.cambioValor}
                                                        />
                                                        <small id="helpId" className="invalid-feedback">Digita el Nombre</small>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label htmlFor="estado_bod">Estado:</label>
                                                        <select name="estado_bod" id="estado_bod" value={bodega.estado_bod}
                                                            options={bodega.estado_bod}
                                                            className={((this.verificarError("error_estado")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                            onChange={this.cambioValor}>
                                                            <option value="">Seleccione el Estado</option>
                                                            <option value="Activo">Activo</option>
                                                            <option value="No Activo">No Activo</option>
                                                        </select>
                                                        <small id="helpId" className="invalid-feedback">Seleccione el Estado</small>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <label htmlFor="observacion">Observaciones:</label>
                                                        <input type="text" name="observacion" id="observacion"
                                                            defaultValue={bodega.Observacion}
                                                            className="form-control form-control-sm"
                                                            onChange={this.cambioValor}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="btn-group" role="group" aria-label="">
                                            <button type="submit" className="btn btn-primary btn-sm"><FontAwesomeIcon icon={faEdit} />&nbsp;&nbsp;Editar</button>&nbsp;&nbsp;
                                            <Link className="btn btn-danger btn-sm" to={"/MasterBodegas"}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Editar;