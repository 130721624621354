import React from 'react';
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faSave, faArrowRight } from '@fortawesome/free-solid-svg-icons'

class Historial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mensaje: '',
            historialClinico: [],
            infoPaciente: [],
            errores: []
        }
    }
    cambioValor = (e) => {
        const state = this.state.historialClinico;
        state[e.target.name] = e.target.value;
        this.setState({ historialClinico: state });
    };
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    SaveArchivo() {
      
        console.log("Formulario Enviado...");
        const { } = this.state;
        var errores = [];

        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let form = document.getElementById('form');
        let formData = new FormData(form);
        fetch(Api + "Ordenes/AddHistorial", {
            method: "POST",
            body: formData
        })
            .then(respuesta => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                Swal.fire(
                    'Historial Clínico!',
                    datosRespuesta.msj,
                    'success'
                )
                this.props.history.push("/VerArchivo/"+datosRespuesta.orden);
            })
            .catch(console.log)
    }
    async componentDidMount() {
        console.log(this.props.match.params.id_paciente);
        await fetch(Api + "Ordenes/Historia/" + this.props.match.params.id_paciente,
            {
                method: "GET",
                headers: new Headers({
                    'Authorization': 'koala2021'
                }),
            })
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                if(datosRespuesta.datos){
                    this.setState({ datosCargados: true, historialClinico: datosRespuesta.datos, mensaje: datosRespuesta.msj }); //
                }else{
                    this.setState({ mensaje:datosRespuesta.msj  });
                }
               
            })
            .catch(console.log);
        await fetch(Api + "Pacientes/" + this.props.match.params.id_paciente,
            {
                method: "GET",
                headers: new Headers({
                    'Authorization': 'koala2021'
                }),
            })
            .then((respuesta) => respuesta.json())
            .then((datosPaciente) => {
                console.log(datosPaciente);
                this.setState({ infoPaciente: datosPaciente });
            })
            .catch(console.log);
    }
    render() {
        const { historialClinico, infoPaciente, mensaje, errores } = this.state;
        return (
            <div className="content-wrapper">
                <div className="content">
                    <div className="container-fluid">
                        <br />
                        <div className="card">
                            <div className="card-header text-center">
                                <b>HISTORIAL CLÍNICO</b>
                            </div>
                            <div className="card-body">
                                <h4 className="profile-username text-center">{infoPaciente.nombres} {infoPaciente.nombre2} {infoPaciente.apellidos} {infoPaciente.apellido2}</h4>
                                <p className="text-muted text-center">{infoPaciente.documento}: {infoPaciente.numero_doc}</p>
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="historia_familiar-tab" data-bs-toggle="tab" data-bs-target="#historia_familiar" type="button" role="tab" aria-controls="historia_familiar" aria-selected="true">Historia Familiar</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="antecedentes-tab" data-bs-toggle="tab" data-bs-target="#antecedentes" type="button" role="tab" aria-controls="antecedentes" aria-selected="false">Antecedentes Personales</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="examenes-tab" data-bs-toggle="tab" data-bs-target="#examenes" type="button" role="tab" aria-controls="examenes" aria-selected="false">Exámenes Complementarios</button>
                                    </li>
                                </ul>
                                <form id='form'>
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="historia_familiar" role="tabpanel" aria-labelledby="historia_familiar-tab">
                                            <br />
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div style={{ marginTop: 0 }} className='row g-3'>
                                                                    <div className="col-md-2">
                                                                        <label htmlFor="id_paciente" className="form-label">Historial No.</label>
                                                                        <input type="text" id="id_paciente" name="id_paciente" className="form-control form-control-sm" 
                                                                         defaultValue={mensaje === "Datos cargados" ? this.state.historialClinico.id_paciente : ""}
                                                                               onChange={this.cambioValor}/>
                                                                        <input type="hidden" id="pac" name="pac" className="form-control form-control-sm"   defaultValue={mensaje === "Datos cargados" ? this.state.historialClinico.id_paciente : ""}
                                                                               onChange={this.cambioValor} />
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <label htmlFor="orden" className="form-label">Archivo</label>
                                                                        <input type="text" id="orden" name="orden" className="form-control form-control-sm" value={window.localStorage.getItem("orden")} readOnly />
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginTop: 0 }} className='row g-3'>
                                                                    <div className='col-md-2'>
                                                                        <label htmlFor="Cancer1" className="form-label">Cancer</label>
                                                                        <select className="form-select form-select-sm" type="text"
                                                                                name="Cancer1" id="Cancer1"
                                                                                value={mensaje === "Datos cargados" ? historialClinico.Cancer1 : "No"}
                                                                                onChange={this.cambioValor}
                                                                                >
                                                                            <option value="">Seleccione...</option>
                                                                            <option value="Si">Si</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <label htmlFor="Ataques1" className="form-label">Ataque Corazón</label>
                                                                        <select className="form-select form-select-sm" type="text"
                                                                                name="Ataques1" id="Ataques1"
                                                                                value={mensaje === "Datos cargados" ? historialClinico.Ataques1 : "No"}
                                                                                onChange={this.cambioValor}
                                                                                >
                                                                            <option value="">Seleccione...</option>
                                                                            <option value="Si">Si</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <label htmlFor="Enfermedades1" className="form-label">Enf. Renales</label>
                                                                        <select className="form-select form-select-sm" type="text"
                                                                                name="Enfermedades1" id="Enfermedades1"
                                                                                value={mensaje === "Datos cargados" ? historialClinico.Enfermedades1 : "No"}
                                                                                onChange={this.cambioValor}
                                                                                >
                                                                            <option value="">Seleccione...</option>
                                                                            <option value="Si">Si</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <label htmlFor="Diabetes1" className="form-label">Diabetes</label>
                                                                        <select className="form-select form-select-sm" type="text"
                                                                                name="Diabetes1" id="Diabetes1"
                                                                                value={mensaje === "Datos cargados" ? historialClinico.Diabetes1 : "No"}
                                                                                onChange={this.cambioValor}
                                                                                >
                                                                            <option value="">Seleccione...</option>
                                                                            <option value="Si">Si</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <label htmlFor="Hipertencion1" className="form-label">Hipertensión</label>
                                                                        <select className="form-select form-select-sm" type="text"
                                                                                name="Hipertencion1" id="Hipertencion1"
                                                                                value={mensaje === "Datos cargados" ? historialClinico.Hipertencion1 : "No"}
                                                                                onChange={this.cambioValor}
                                                                                >
                                                                            <option value="">Seleccione...</option>
                                                                            <option value="Si">Si</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <label htmlFor="Tuberculosis1" className="form-label">Tuberculosis</label>
                                                                        <select className="form-select form-select-sm" type="text"
                                                                                name="Tuberculosis1" id="Tuberculosis1"
                                                                                value={mensaje === "Datos cargados" ? historialClinico.Tuberculosis1 : "No"}
                                                                                onChange={this.cambioValor}
                                                                                >
                                                                            <option value="">Seleccione...</option>
                                                                            <option value="Si">Si</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginTop: 0 }} className='row g-3'>
                                                                    <div className="col-md-2">
                                                                        <label htmlFor="Otras1" className="form-label">Otra</label>
                                                                        <select className="form-select form-select-sm" type="text"
                                                                                name="Otras1" id="Otras1"
                                                                                value={mensaje === "Datos cargados" ? historialClinico.Otras1 : "No"}
                                                                                onChange={this.cambioValor}
                                                                                >
                                                                            <option value="">Seleccione...</option>
                                                                            <option value="Si">Si</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <label htmlFor="Especifique1" className="form-label">Especifique</label>
                                                                        <input type="text" id="Especifique1" name="Especifique1" className="form-control form-control-sm"
                                                                               value={mensaje === "Datos cargados" ? this.state.historialClinico.Especifique1 : ""}
                                                                               onChange={this.cambioValor}
                                                                               />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="antecedentes" role="tabpanel" aria-labelledby="antecedentes-tab">
                                            <br />
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div style={{ marginTop: 0 }} className='row g-3'>
                                                                    <div className="col-md-2">
                                                                        <label htmlFor="Alcohol" className="form-label">Alcohol</label>
                                                                        <select className="form-select form-select-sm" type="text"
                                                                                name="Alcohol" id="Alcohol"
                                                                                value={mensaje === "Datos cargados" ? historialClinico.Alcohol : "No"}
                                                                                onChange={this.cambioValor}
                                                                                >
                                                                            <option value="">Seleccione...</option>
                                                                            <option value="Si">Si</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <label htmlFor="Tabaco" className="form-label">Tabaco</label>
                                                                        <select className="form-select form-select-sm" type="text"
                                                                                name="Tabaco" id="Tabaco"
                                                                                value={mensaje === "Datos cargados" ? historialClinico.Tabaco : "No"}
                                                                                onChange={this.cambioValor}
                                                                                >
                                                                            <option value="">Seleccione...</option>
                                                                            <option value="Si">Si</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <label htmlFor="Drogas" className="form-label">Drogas</label>
                                                                        <select className="form-select form-select-sm" type="text"
                                                                                name="Drogas" id="Drogas"
                                                                                value={mensaje === "Datos cargados" ? historialClinico.Drogas : "No"}
                                                                                onChange={this.cambioValor}
                                                                                >
                                                                            <option value="">Seleccione...</option>
                                                                            <option value="Si">Si</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginTop: 0 }} className='row g-3'>
                                                                    <div className='col-md-2'>
                                                                        <label htmlFor="Cancer" className="form-label">Cancer</label>
                                                                        <select className="form-select form-select-sm" type="text"
                                                                                name="Cancer" id="Cancer"
                                                                                value={mensaje === "Datos cargados" ? historialClinico.Cancer : "No"}
                                                                                onChange={this.cambioValor}
                                                                                >
                                                                            <option value="">Seleccione...</option>
                                                                            <option value="Si">Si</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <label htmlFor="Ataques" className="form-label">Ataque Corazón</label>
                                                                        <select className="form-select form-select-sm" type="text"
                                                                                name="Ataques" id="Ataques"
                                                                                value={mensaje === "Datos cargados" ? historialClinico.Ataques : "No"}
                                                                                onChange={this.cambioValor}
                                                                                >
                                                                            <option value="">Seleccione...</option>
                                                                            <option value="Si">Si</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <label htmlFor="emfermedades" className="form-label">Enf. Renales</label>
                                                                        <select className="form-select form-select-sm" type="text"
                                                                                name="emfermedades" id="emfermedades"
                                                                                value={mensaje === "Datos cargados" ? historialClinico.emfermedades : "No"}
                                                                                onChange={this.cambioValor}
                                                                                >
                                                                            <option value="">Seleccione...</option>
                                                                            <option value="Si">Si</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <label htmlFor="Diabetes" className="form-label">Diabetes</label>
                                                                        <select className="form-select form-select-sm" type="text"
                                                                                name="Diabetes" id="Diabetes"
                                                                                value={mensaje === "Datos cargados" ? historialClinico.Diabetes : "No"}
                                                                                onChange={this.cambioValor}
                                                                                >
                                                                            <option value="">Seleccione...</option>
                                                                            <option value="Si">Si</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <label htmlFor="Hipertencion" className="form-label">Hipertensión</label>
                                                                        <select className="form-select form-select-sm" type="text"
                                                                                name="Hipertencion" id="Hipertencion"
                                                                                value={mensaje === "Datos cargados" ? historialClinico.Hipertencion : "No"}
                                                                                onChange={this.cambioValor}
                                                                                >
                                                                            <option value="">Seleccione...</option>
                                                                            <option value="Si">Si</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <label htmlFor="Tuberculosis" className="form-label">Tuberculosis</label>
                                                                        <select className="form-select form-select-sm" type="text"
                                                                                name="Tuberculosis" id="Tuberculosis"
                                                                                value={mensaje === "Datos cargados" ? historialClinico.Tuberculosis : "No"}
                                                                                onChange={this.cambioValor}
                                                                                >
                                                                            <option value="">Seleccione...</option>
                                                                            <option value="Si">Si</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginTop: 0 }} className='row g-3'>
                                                                    <div className="col-md-2">
                                                                        <label htmlFor="Otras" className="form-label">Otra</label>
                                                                        <select className="form-select form-select-sm" type="text"
                                                                                name="Otras" id="Otras"
                                                                                value={mensaje === "Datos cargados" ? historialClinico.Otras : "No"}
                                                                                onChange={this.cambioValor}
                                                                                >
                                                                            <option value="">Seleccione...</option>
                                                                            <option value="Si">Si</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <label htmlFor="Especifique" className="form-label">Especifique</label>
                                                                        <input type="text" id="Especifique" name="Especifique" className="form-control form-control-sm"
                                                                               value={mensaje === "Datos cargados" ? historialClinico.Especifique : ""}
                                                                               onChange={this.cambioValor}
                                                                               />
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginTop: 0 }} className='row g-3'>
                                                                    <div className="col-md-2">
                                                                        <label htmlFor="Alergias" className="form-label">Alergias</label>
                                                                        <select className="form-select form-select-sm" type="text"
                                                                                name="Alergias" id="Alergias"
                                                                                value={mensaje === "Datos cargados" ? historialClinico.Alergias : "No"}
                                                                                onChange={this.cambioValor}
                                                                                >
                                                                            <option value="">Seleccione...</option>
                                                                            <option value="Si">Si</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <label htmlFor="Cuales1" className="form-label">Especifique</label>
                                                                        <input type="text" id="Cuales1" name="Cuales1" className="form-control form-control-sm"
                                                                               value={mensaje === "Datos cargados" ? historialClinico.Cuales1 : ""}
                                                                               onChange={this.cambioValor}
                                                                               />
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginTop: 0 }} className='row g-3'>
                                                                    <div className="col-md-12">
                                                                        <label htmlFor="Medicamentos" className="form-label">Medicamentos (Especifique la Dosis o Número de Píldoras por día)</label>
                                                                        <input type="text" id="Medicamentos" name="Medicamentos" className="form-control form-control-sm"
                                                                               value={mensaje === "Datos cargados" ? historialClinico.Medicamentos : ""}
                                                                               onChange={this.cambioValor}
                                                                               />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="examenes" role="tabpanel" aria-labelledby="examenes-tab">
                                            <br />
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div style={{ marginTop: 0 }} className='row g-3'>
                                                                    <div className="col-md-2">
                                                                        <label htmlFor="Laboratorios" className="form-label">Laboratorios</label>
                                                                        <select className="form-select form-select-sm" type="text"
                                                                                name="Laboratorios" id="Laboratorios"
                                                                                value={mensaje === "Datos cargados" ? historialClinico.Laboratorios : "No"}
                                                                                onChange={this.cambioValor}
                                                                                >
                                                                            <option value="">Seleccione...</option>
                                                                            <option value="Si">Si</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <label htmlFor="Cuales4" className="form-label">Especifique</label>
                                                                        <input type="text" id="Cuales4" name="Cuales4" className="form-control form-control-sm"
                                                                               value={mensaje === "Datos cargados" ? historialClinico.Cuales4 : ""}
                                                                               onChange={this.cambioValor}
                                                                               />
                                                                    </div>
                                                                </div>
                                                                <div style={{ marginTop: 0 }} className='row g-3'>
                                                                    <div className="col-md-2">
                                                                        <label htmlFor="Otros" className="form-label">Otros Estudios</label>
                                                                        <select className="form-select form-select-sm" type="text"
                                                                                name="Otros" id="Otros"
                                                                                value={mensaje === "Datos cargados" ? historialClinico.Otros : "No"}
                                                                                onChange={this.cambioValor}
                                                                                >
                                                                            <option value="">Seleccione...</option>
                                                                            <option value="Si">Si</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <label htmlFor="Cuales7" className="form-label">Especifique</label>
                                                                        <input type="text" id="Cuales7" name="Cuales7" className="form-control form-control-sm"
                                                                               value={mensaje === "Datos cargados" ? historialClinico.Cuales7 : ""}
                                                                               onChange={this.cambioValor}
                                                                               />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btn-group" role="group" aria-label="">
                                        {((this.verificarError("error_global")) ?
                                            <span style={{ backgroundColor: '#F8D7DA', borderColor: '#F5C2C7', color: '#842029' }} class="badge bg-danger">Faltan campos por Digitar!</span> : "")}
                                        <button type="button" className="btn btn-primary btn-sm"  onClick={() => this.SaveArchivo()}><FontAwesomeIcon icon={faArrowRight}/>&nbsp;&nbsp;Siguiente</button>&nbsp;&nbsp;
                                        <Link className="btn btn-danger btn-sm" to={"/MasterOrdenes"}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Historial;