import React from "react";
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faSearch, faUpload, faPlus, faViruses } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2';

class Listar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datosCargados: false,
      query: [],
      pagina: [],
    };
  }
  borrarRegistros = (id) => {
    console.log(id);
    Swal.fire({
      title: 'Estas seguro de eliminar el registro?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Borrar',
      denyButtonText: `No borrar`, 
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        
        fetch(Api + "DelEmpresas/" + id)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
        Swal.fire('Borrado!', datosRespuesta.msj, 'success')
        this.Buscar(1);
      })
      .catch(console.log);
      } else if (result.isDenied) {
        Swal.fire('Cancelado', '', 'info')
      }
    })

    
  };
 
  componentDidMount() {
    this.Buscar(1);
  }
  Buscar(page){
 
    
    let btipo = document.getElementById('buscar').value;
    let bname = document.getElementById('bnombre').value;
    let bcliente = document.getElementById('bcliente').value;
    console.log("Formulario Enviado...");
    if(btipo==''){
      btipo = 0;
    }
    if(bname==''){
      bname = 0;
    }
    fetch(Api + "SearchEmpresas/"+btipo+"/"+bname+"/"+bcliente+"?page="+page)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta.last_page);
        this.setState({ datosCargados: true, query: datosRespuesta.data, pagina:datosRespuesta.total, ultimo: datosRespuesta.last_page, actual: datosRespuesta.current_page });
      })
      .catch(console.log);
  }
  render() {
    const { datosCargados, query,pagina, ultimo, actual } = this.state;
    if (!datosCargados) {
       var load = 'Cargando datos....';
    } else {
       var load = '';
    }
       
    console.log(ultimo);
      return (
        
          <div className="content">
            <div className="container-fluid">
              <div className="card">
                <div className="card-header">
                  <Link className="btn btn-success btn-sm" to={"/AddEmpresas"}>
                    Agregar Empresas
                  </Link>
                </div>
                <div className="card-body">
                  <h4>Lista de Empresas</h4>
                  <div className="row">
               
                      <div className="col-xl-3">
                     
                      <input type="text" id="buscar" name="buscar" placeholder="Rips" className="form-control form-control-sm"  />

                      </div>
                      <div className="col-xl-3">
                       
                          <input type="text" id="bnombre" name="bnombre" placeholder="Nombre de la Empresa" className="form-control form-control-sm"  />
                      </div>
                      <div className="col-xl-3">
                     
                      <select className="form-select form-select-sm" type="text" name="bcliente" id="bcliente"  placeholder="" aria-describedby="helpId"  >
                          
                          <option value="Si">Empresas Convenio</option>
                          <option value="No">Empresas Sin Convenio</option>
              
                          </select>

                      </div>
                      <div className="col-xl-3">
                        
                          <input type="button" id="bt" name="bt" className="btn btn-primary btn-sm" value="Filtrar" onClick={() =>this.Buscar(1)} />
                      </div>
               
                   </div>
                   <br></br>
                   <div className="row">
                  <table className="table table-hover table-condensed table-sm">
                  <thead className="thead-inverse">
                      <tr>
                        <th>NIT</th>
                        <th>NOMBRE DE LA EMPRESA</th>
                        <th>RIPS</th>
                        <th>TELÉFONO</th>
                        <th>CONVENIO</th>
                        <th>ACCIONES</th>
                      </tr>
                    </thead>
                    <tbody> 
                      {load}
                      {query.map((empleado) => (
                        <tr key={empleado.id_empresa}>
                          <td>{empleado.nit_emp}</td>
                          <td>{empleado.nombre_emp}</td>
                          <td>{empleado.rips}</td>
                          <td>{empleado.tel_oficina_emp}</td>
                          <td>{empleado.cliente}</td>
                          <td>
                          
                            <div
                              className="btn-group"
                              role="group"
                              aria-label=""
                            >
                              <Link
                                className="btn btn-warning btn-sm"
                                to={"/AddInsEmp/" + empleado.id_empresa}
                                title="Agregar Insumos"
                              >
                   
                   <i class="fas fa-syringe"></i>
                              </Link>&nbsp;&nbsp;
                              <Link
                                className="btn btn-warning btn-sm"
                                to={"/AddAteEmp/" + empleado.id_empresa}
                                title="Agregar Atenciones"
                              >
                   
                   <i class="fas fa-pills"></i>
                              </Link>&nbsp;&nbsp;
                              <Link
                                className="btn btn-warning btn-sm"
                                to={"/AddVenEmp/" + empleado.id_empresa}
                                title="Agregar productos Ventas"
                              >
                   
                   <i class="fas fa-microscope"></i>
                              </Link>&nbsp;&nbsp;
                              <Link
                                className="btn btn-warning btn-sm"
                                to={"/AddAlqEmp/" + empleado.id_empresa}
                                title="Agregar Productos de alquiler"
                              >
                   
                   <i class="fas fa-microscope"></i>
                              </Link>&nbsp;&nbsp;
                              <Link
                                className="btn btn-primary btn-sm"
                                to={"/UpEmpresas/" + empleado.id_empresa}
                              >
                   
                   <i class="fas fa-edit"></i>
                              </Link>&nbsp;&nbsp;
                              <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={() =>
                                  this.borrarRegistros(empleado.id_empresa)
                                }
                              >
                                <i class="far fa-trash-alt"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  </div>
                  <nav aria-label="Page navigation example">
                    <ul className="pagination">
                        <li class="page-item"><a className="page-link" href="#" onClick={() => this.Buscar(1)}>Atras</a></li>
                        {(() => {
                            let p = [];
                            if(ultimo>=20){
                              var ult = 20;
                            }else{
                              ult = ultimo;
                            }
                            for(let i = 1; i <= ult ; i++){
                                
                                p.push(<li class="page-item" key={i}><a className="page-link" href="#" onClick={() => this.Buscar(i)}>{i}</a></li>); 
                            }
                            return p;
                        })()}
                       
                        <li class="page-item"><a className="page-link" href="#" onClick={() => this.Buscar(ultimo)}>Siguiente</a></li>
                    </ul>
                    Pagina {actual} de {ultimo} Registros {pagina}
                    </nav>
                </div>
              </div>
              </div>
              </div>
              
            
      );
    }
  }


export default Listar;
