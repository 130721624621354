import React from "react";
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import Swal from 'sweetalert2';

class Editar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datosCargados: false,
      rol: [],
      errores: []
    };
  }
  cambioValor = (e) => {
    const state = this.state.rol;
    state[e.target.name] = e.target.value;
    this.setState({ rol: state });
  };
  verificarError(elemento) {
    return this.state.errores.indexOf(elemento) !== -1;
  }
  enviarDatos = (e) => {
    e.preventDefault();
    console.log("Formulario Enviado...");
    let form = document.getElementById('form');
    let formData = new FormData(form);
    console.log(formData);
    fetch(Api + "UpRoles", {
      method: "POST",
      body: formData
    })
      .then(respuesta => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta)
        Swal.fire(
          'Roles!',
          datosRespuesta.msj,
          'success'
        )
        this.props.history.push("/MasterRoles");
      })
      .catch(console.log)
  }
  componentDidMount() {
    console.log(this.props.match.params.id);
    fetch(Api + "Roles/" + this.props.match.params.id,
      {
        method: "GET",
        headers: new Headers({
          'Authorization': 'koala2021'
        }),
      })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
        this.setState({ datosCargados: true, rol: datosRespuesta }); //
      })
      .catch(console.log);
  }
  render() {
    const { datosCargados, rol } = this.state;
    if (!datosCargados) {
      var load = 'Cargando datos....';
    } else {
      var load = '';
    }
    return (
      <div>
        <div className="content-wrapper">
          <div className="content">
            <div className="container-fluid">
              <div className="card">
                <div className="card-header">
                  {
                    load ? (
                      load
                    ) : (
                      "Editar Rol"
                    )
                  }
                </div>
                <div className="card-body">
                  <form onSubmit={this.enviarDatos} id="form">
                    <div className="form-group">
                      <label htmlFor="id">Id :</label>
                      <input type="text" name="id" id="id" value={rol.id_roles} className="form-control form-control-sm" placeholder="" aria-describedby="helpId" onChange={this.cambioValor} readOnly />
                    </div>
                    <div className="form-group">
                      <label htmlFor="nombre">Nombre:</label>
                      <input type="text" name="nombre" id="nombre" defaultValue={rol.nombre} className={((this.verificarError("error_nombre")) ? "is-invalid" : "") + " form-control form-control-sm"} onChange={this.cambioValor} required />
                    </div>
                    <div className="form-group">
                      <label htmlFor="descripcion">Descripción:</label>
                      <input type="text" name="descripcion" id="descripcion" defaultValue={rol.descripcion} onChange={this.cambioValor} className="form-control form-control-sm" />
                    </div>
                    <br />
                    <div className="btn-group" role="group" aria-label="">
                      <button type="submit" className="btn btn-warning btn-sm">
                        Editar
                      </button>&nbsp;&nbsp;
                      <Link className="btn btn-danger btn-sm" to={"/MasterRoles"}>
                        Cancelar
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Editar;
