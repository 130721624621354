import React from 'react';
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import Swal from 'sweetalert2';
class Crear extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            codigo:"",
            nombre:"",
            tipo:"",
            cuenta:"",
            errores:[]
        }
    }
    cambioValor = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState({ state, errores:[] })
    }
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    enviarDatos = (e) => {
        e.preventDefault();
        console.log("Formulario Enviado...");
        const {nombre, tipo,codigo,cuenta} = this.state;
        console.log(nombre);
        console.log(tipo);
        console.log(cuenta);
        console.log(codigo);
        var errores = [];
        if (!codigo) {
            errores.push("error_codigo");
        }
        if (!nombre) {
            errores.push("error_nombre");
        }
        if (!tipo) {
            errores.push("error_tipo");
        }
        this.setState({ errores:errores });
        if (errores.length > 0) {
            return false;
        }
        var datosEnviar = {nombre:nombre, tipo:tipo,codigo:codigo,cuenta:cuenta}
        fetch(Api + "AddAtenciones", {
            method:"POST",
            body:JSON.stringify(datosEnviar)
        })
        .then(respuesta=>respuesta.json())
        .then((datosRespuesta)=> {
            console.log(datosRespuesta)
            Swal.fire(
                'Atenciones!',
                datosRespuesta.msj,
                'success'
              )
            this.props.history.push("/MasterAtenciones");
        })
        .catch(console.log)
    }
    render() {
        const {nombre, tipo,codigo,cuenta} = this.state;
        return (
            <div className="content-wrapper">
          <div className="content">
            <div className="container-fluid">
            <div className="card">
                <div className="card-header">
                    Registro de Atenciones
                </div>
                <div className="card-body">
                    <form onSubmit={this.enviarDatos}>
                    <div className="form-group">
                          <label htmlFor="codigo">Código</label>
                          <input className={((this.verificarError("error_codigo"))?"is-invalid":"")+" form-control form-control-sm"} type="text" name="codigo" id="codigo" value={codigo} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                          <small id="helpId" className="invalid-feedback">Digita el Código</small>
                        </div>
                        <div className="form-group">
                          <label htmlFor="nombre">Nombre</label>
                          <input className={((this.verificarError("error_nombre"))?"is-invalid":"")+" form-control form-control-sm"} type="text" name="nombre" id="nombre" value={nombre} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                          <small id="helpId" className="invalid-feedback">Digita el Nombre de la Atención</small>
                        </div>
                        <div className="form-group">
                          <label htmlFor="tipo">Tipo Atención</label>
                          <select className={((this.verificarError("error_tipo"))?"is-invalid":"")+" form-select form-select-sm"} type="text" name="tipo" id="tipo" value={tipo} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} >
                          <option value="">Selecciona...</option>
                          <option value="1">Procedimiento</option>
                          <option value="2">Consulta</option>
                          <option value="3">Otra</option>
                          </select>

                          <small id="helpId" className="invalid-feedback">Seleccione el Tipo de Atención</small>
                        </div>
                        <div className="form-group">
                          <label htmlFor="cuenta">Número de Cuenta</label>
                          <input className={((this.verificarError("error_cuenta"))?"is-invalid":"")+" form-control form-control-sm"} type="text" name="cuenta" id="cuenta" value={cuenta} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                          <small id="helpId" className="invalid-feedback">Digita el Número de Cuenta</small>
                        </div>
                        <br />
                        <div className="btn-group" role="group" aria-label="">
                            <button type="submit" className="btn btn-success btn-sm">Agregar</button>&nbsp;&nbsp;
                            <Link className="btn btn-danger btn-sm" to={"/"}>Cancelar</Link>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            </div>
            </div>
        );
    }
}
 
export default Crear;