import React from "react";
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUpload, faFileUpload, faUserPlus, faSignOutAlt, faSave, faViruses, faPrint } from '@fortawesome/free-solid-svg-icons'
import Listar from './InfoPaciente';
class Historial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datosCargados: false,
      usuario: [],
      atenciones: [],
      Alquiler: [],
      incidencias: [],
      oi:'',oe:'',es:'',
      Cargando:0
    };
  }
  cambioValor = (e) => {
    const state = this.state.rol;
    state[e.target.name] = e.target.value;
    this.setState({ rol: state });
  };
  verificarError(elemento) {
    return this.state.errores.indexOf(elemento) !== -1;
  }
  componentDidMount() {
    //console.log(this.props.match.params.id);
    fetch(Api + "Pacientes/" + this.props.match.params.id,
      {
        method: "GET",
        headers: new Headers({
          'Authorization': 'koala2021'
        }),
      })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        //console.log(datosRespuesta);
        this.setState({ datosCargados: true, usuario: datosRespuesta }); //
      })
      .catch(console.log);
    this.cargarAtenciones();
    this.cargarSoporte();
    this.cargarAlquiler();
  }
  cargarAlquiler() {
    console.log(this.props.match.params.id);
    fetch(Api + "alquiler/lista/" + this.props.match.params.id)
      .then(respuesta => respuesta.json())
      .then((datosAtenciones) => {
        this.setState({ Alquiler: datosAtenciones })
        console.log(datosAtenciones);
      })
      .catch(console.log);
  }
  cargarAtenciones() {
    console.log("Formulario Enviado...");
    this.setState({ Cargando: 1 })
    let b_oi = document.getElementById('b_oi').value;
    let b_oe = document.getElementById('b_oe').value;
    let b_es = document.getElementById('b_es').value;
    let b_fecha_registro = document.getElementById('b_fecha_registro').value;


    let datos = JSON.stringify({ b_oi,b_oe,b_es,b_fecha_registro,id:this.props.match.params.id  });
    let formData = datos;

    fetch(Api + "Ordenes/AtencionesPacientes", {
        method: "POST",
        body: formData
    })
        .then(respuesta => respuesta.json())
        .then((datosRespuesta) => {
            console.log(datosRespuesta)
            this.setState({ atenciones: datosRespuesta.datos })
            this.setState({ Cargando: 0 })
            //this.setState({ datosCargados: true, load: '', ordenes: datosRespuesta.datos.data, pagina: datosRespuesta.datos.total, ultimo: datosRespuesta.datos.last_page, actual: datosRespuesta.datos.current_page });
        })
        .catch(console.log)
    }
  cargarSoporte() {
    console.log(this.props.match.params.id);
    fetch(Api + "incidencias/paciente/" + this.props.match.params.id)
      .then(respuesta => respuesta.json())
      .then((datosSoporte) => {
        this.setState({ incidencias: datosSoporte })
        console.log(datosSoporte);
      })
      .catch(console.log);
  }
  InfoFirma(oi) {
    window.open('https://idb.softmediko.com/vistas/firmas_digitadas.php?oi=' + oi, '_blank');
  }
  InfoFirmaCons(oi) {
    window.open('https://idb.softmediko.com/vistas/pacientes/consentimiento.php?oi=' + oi, '_blank');
  }
  Imprimir1(id) {
    window.open('https://idb.softmediko.com/imprimir_historial.php?imprimir=' + id, '_blank');
  }
  Imprimir2(id) {
    window.open('https://idb.softmediko.com/resumen_atenciones_1.php?imprimir=' + id, '_blank');
  }
  Imprimir3(id) {
    window.open('https://idb.softmediko.com/resumen_evolucion.php?imprimir=' + id, '_blank');
  }
  Imprimir4(id) {
    window.open('https://idb.softmediko.com/resumen_de_atenciones.php?imprimir=' + id, '_blank');
  }
  render() {
    const { datosCargados, usuario, atenciones, incidencias, oi,oe,Alquiler,Cargando } = this.state;
    if (!datosCargados) {
      var load = 'Cargando datos....';
    } else {
      var load = '';
    }
    return (
      <div>
        <div className="content-wrapper">
          <div className="content">
            <div className="container-fluid">
              <br />
              <div className="card">
                <div className="card-header text-center">
                  {
                    load ? (
                      load
                    ) : (<>
                      <b>HISTORIAL DEL PACIENTE</b>
                      <h3 className="profile-username text-center">{usuario.nombres}{usuario.nombre2} {usuario.apellidos} {usuario.apellido2} </h3>
                      <p className="text-muted text-center">{usuario.documento}: {usuario.numero_doc}</p>
                      <Listar numero_cedula={usuario.numero_doc}/>
                      <form style={{ float: 'right' }} className="row g-3">
                  <div className="col-md-12">
                  <button className='btn btn-primary btn-sm' id='btnNuevoPacienteSub' type='button'  onClick={() => this.Imprimir1(usuario.id_paciente)}><FontAwesomeIcon icon={faPrint} />&nbsp;&nbsp;Antecedentes.</button>&nbsp;&nbsp;
                  <button className='btn btn-primary btn-sm' id='btnNuevoPacienteSub' type='button'  onClick={() => this.Imprimir2(usuario.id_paciente)}><FontAwesomeIcon icon={faPrint} />&nbsp;&nbsp;Atenciones.</button>&nbsp;&nbsp;
                  <button className='btn btn-primary btn-sm' id='btnNuevoPacienteSub' type='button'  onClick={() => this.Imprimir3(usuario.id_paciente)}><FontAwesomeIcon icon={faPrint} />&nbsp;&nbsp;Evolucion.</button>&nbsp;&nbsp;
                   
                  <button className='btn btn-primary btn-sm' id='btnNuevoPacienteSub' type='button'  onClick={() => this.Imprimir4(usuario.id_paciente)}><FontAwesomeIcon icon={faPrint} />&nbsp;&nbsp;Resumen de Atenciones.</button>&nbsp;&nbsp;
                   
                  </div>
                </form>
                      </>
                    )
                  }
            
                </div>
                <div className="card-body">
                  <div className="card" style={{ display: usuario.nombres == undefined ? 'none' : 'block' }}>
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Datos Basicos</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Atenciones Generadas</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#alquiler" type="button" role="tab" aria-controls="alquiler" aria-selected="false">Lista de alquileres</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Soporte</button>
                      </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                      <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className="row">
                          <div className="col-md-12">
                            {/* Profile Image */}
                            <div className="card card-primary card-outline">
                              <div className="card-body box-profile">
                                <h3 className="profile-username text-center">{usuario.nombres}{usuario.nombre2} {usuario.apellidos} {usuario.apellido2} </h3>
                                <p className="text-muted text-center">{usuario.documento}: {usuario.numero_doc}</p>
                                <div className="row">
                                  <div className="col-md-4">
                                    <ul className="list-group list-group-unbordered mb-3">
                                      <li className="list-group-item">
                                        <b>Eps</b> <a className="float-right">{usuario.id_empresa}</a>
                                      </li>
                                      <li className="list-group-item">
                                        <b>Estado Civil  </b> <a className="float-right">{usuario.civil}</a>
                                      </li>
                                      <li className="list-group-item">
                                        <b>Regimen </b> <a className="float-right">{usuario.regimen}</a>
                                      </li>
                                      <li className="list-group-item">
                                        <b>Direccion Principal </b> <a className="float-right">{usuario.direccion1}</a>
                                      </li>
                                      <li className="list-group-item">
                                        <b>Barrio </b> <a className="float-right">{usuario.barrio}</a>
                                      </li>
                                      <li className="list-group-item">
                                        <b>Zona </b> <a className="float-right">{usuario.zona}</a>
                                      </li>
                                      <li className="list-group-item">
                                        <b>Cryogas</b> <a className="float-right">{usuario.subcodigo}</a>
                                      </li>
                                      <li className="list-group-item">
                                        <b>Esp. Que Ordena Tratamiento</b> <a className="float-right">{usuario.observaciones}</a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="col-md-4">
                                    <ul className="list-group list-group-unbordered mb-3">
                                      <li className="list-group-item">
                                        <b>Sexo </b> <a className="float-right">{usuario.sexo}</a>
                                      </li>
                                      <li className="list-group-item">
                                        <b>Fecha De Nacimiento</b> <a className="float-right">{usuario.fecha_nacimiento}</a>
                                      </li>
                                      <li className="list-group-item">
                                        <b>Edad </b> <a className="float-right">{usuario.edad}</a>
                                      </li>
                                      <li className="list-group-item">
                                        <b>Estado</b> <a className="float-right">{usuario.estado}</a>
                                      </li>
                                      <li className="list-group-item">
                                        <b>Email </b> <a className="float-right">{usuario.email1}</a>
                                      </li>
                                      <li className="list-group-item">
                                        <b>Celular </b> <a className="float-right">{usuario.celular}</a>
                                      </li>
                                      <li className="list-group-item">
                                        <b>Diagnostico Principal</b> <a className="float-right">{usuario.enfermedad}</a>
                                      </li>
                                      <li className="list-group-item">
                                        <b>Descripcion </b> <a className="float-right">{usuario.descripcion_enf}</a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="col-md-4">
                                    <ul className="list-group list-group-unbordered mb-3">
                                      <li className="list-group-item">
                                        <b>Nombre Del Responsable</b> <a className="float-right">{usuario.nombre_acudiente} {usuario.parentesco}</a>
                                      </li>
                                      <li className="list-group-item">
                                        <b>Telefono Del Responsable</b> <a className="float-right">{usuario.telefono_acudiente}</a>
                                      </li>
                                      <li className="list-group-item">
                                        <b>Nombre Del Acompañante</b> <a className="float-right">{usuario.cedula_acudiente} {usuario.parentesco2}</a>
                                      </li>
                                      <li className="list-group-item">
                                        <b>Telefono Acompañante</b> <a className="float-right">{usuario.dir_pariente}</a>
                                      </li>
                                      <li className="list-group-item">
                                        <b>Contrato</b> <a className="float-right">{usuario.contrato}</a>
                                      </li>
                                      <li className="list-group-item">
                                        <b>Cotizante</b> <a className="float-right">{usuario.tipo_s}</a>
                                      </li>
                                      <li className="list-group-item">
                                        <b>Diagnostico 2</b> <a className="float-right">{usuario.diagnostico2}</a>
                                      </li>
                                      <li className="list-group-item">
                                        <b>Descripcion 2</b> <a className="float-right">{usuario.descripcion_diag2}</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* /.card-body */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <br />
                        <table className="table table-hover table-condensed table-sm">
                          <thead className="thead-inverse">
                            <tr>
                              <th>Orden Interna</th>
                              <th>Orden Externa</th>
                              <th>Atencion</th>
                              <th>Cantidad</th>
                              <th>Porcentaje</th>
                              <th>Estado</th>
                              <th>Revisado</th>
                              <th>Factura</th>
                              <th>Fecha de Registro</th>
                              <th>Usuario</th>
                              <th>Liquidacion</th>
                              <th>Facturar</th>
                              
                              <th>Firma.....</th>
                              <th>Consentimiento</th>
                            </tr>
                          </thead>
                          <tr>
                            <td>
                              <input type="text" id="b_oi" className="form form-control" onChange={()=>this.cargarAtenciones()}></input>
                            </td>
                            <td>
                              <input type="text" id="b_oe" className="form form-control" onChange={()=>this.cargarAtenciones()}></input>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                                 
                                 <select id="b_es" className="form form-control" onChange={()=>this.cargarAtenciones()}>
                                    <option value={""}>Todos</option>
                                    <option value={"0"}>Sin  Revisar</option>
                                    <option value={"Revisado"}>Revisado</option>
                                 </select>
                            </td>
                            <td></td>
                            <td><input type="text" id="b_fecha_registro" className="form form-control" onChange={()=>this.cargarAtenciones()} defaultValue={'2024'}></input></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tbody>
                            {Cargando==1?<img src="../dist/img/loading.gif" alt="logo" />:''}
                            {atenciones.map((row) => (
                              <tr key={row.id_paciente}>
                                <td><Link to={"/VerOrdenInterna/" + row.orden_servicio}>{row.orden_servicio}</Link></td>
                                <td>{row.orden_externa} </td>
                                <td>{row.Description}</td>
                                <td>{row.cant}</td>
                                <td>{new Intl.NumberFormat().format(row.id_contacto)} %</td>
                                <td>
                                  {
                                    row.id_contacto >=99 ? (
                                      <span style={{ fontSize: 11, textTransform: 'uppercase' }} className="badge bg-success">{row.estado}</span>
                                    ) : (
                                      <span style={{ fontSize: 11, textTransform: 'uppercase' }} className="badge bg-danger">En proceso</span>
                                    )
                                  }
                                </td>
                                <td>{row.Location}</td>
                                <td>{row.relacionado}</td>
                                <td>{row.fecha_reg_ta}</td>
                                <td>{row.user}</td>
                                <td>{row.id_liq}</td>
                                <td>{row.prioridad != 'Facturado' && row.Location != 'Revisado' ? '' : row.estado == 'Anulada' ? 'Anulada' : ''}</td>
                                <td>
                                  <div
                                    className="btn-group"
                                    role="group"
                                    aria-label=""
                                  >

                                    <button
                                      type="button"
                                      className={row.fimadas==row.cant?"btn btn-success btn-sm":"btn btn-warning btn-sm"}
                                      data-bs-toggle='modal' data-bs-target='#infoPaciente'
                                      onClick={() =>
                                        this.InfoFirma(row.orden_servicio)
                                      }
                                    >
                                      {row.fimadas}/{row.cant}
                                 
                                    </button>&nbsp;&nbsp;
                                    

                                  </div>
                                </td>
                                <td>
                                  {
                                     row.firmac ? (
                                      <button
                                      type="button"
                                      className="btn btn-primary btn-sm"
                                      data-bs-toggle='modal' data-bs-target='#infoPaciente'
                                      onClick={() =>
                                        this.InfoFirmaCons(row.orden_servicio)
                                      }
                                    >
                                      <i class="fas fa-info-circle"></i>
                                    </button>
                                    ) : (
                                      <span style={{ fontSize: 11, textTransform: 'uppercase' }} className="badge bg-danger">Sin Firmar</span>
                                    )
                                  }
                                
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div class="tab-pane fade" id="alquiler" role="tabpanel" aria-labelledby="alquiler-tab">
                        <br />
                        <table className="table table-hover table-condensed table-sm">
                                        <thead className="thead-inverse">
                                            <tr>
                                            <th>ORDEN</th>
                                            <th>AUTORIZACION</th>
                                            <th>DESCRIPCION ALQUILER</th>
                                            <th>RANGO DE FECHA</th>
                                            <th>ESTADO</th>
                                            <th>FACTURADO</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                     
                                            {Alquiler.map((itemProductoAlq) => (
                                                <tr key={itemProductoAlq.id}>
                                                   <td>{itemProductoAlq.id}</td>
                                                    <td>{itemProductoAlq.autorizacion}</td>
                                                    <td>{itemProductoAlq.equipo}</td>
                                                    <td>{itemProductoAlq.fecha_a} al {itemProductoAlq.fecha_f}</td>
                                                    <td>{itemProductoAlq.estado_ord}</td>
                                                    <td>{itemProductoAlq.facturado}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                      </div>
                      <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                        <br />
                        <table className="table table-hover table-condensed table-sm">
                          <thead className="thead-inverse">
                            <tr>
                              <th>Radicado</th>
                              <th>Asunto</th>
                              <th>Tipo</th>
                              <th>Prioridad</th>
                              <th>Estado</th>
                              <th>Asignado a</th>
                              <th>Fecha de Registro</th>
                              <th>Fecha de solucion</th>
                            </tr>
                          </thead>
                          <tbody>
                            {incidencias.map((row) => (
                              <tr key={row.id_incidencia}>
                                <td>{row.id_incidencia}</td>
                                <td>{row.asunto_inc}</td>
                                <td>{row.tipo_inc} </td>
                                <td>{row.prioridad_inc}</td>
                                <td>
                                  {
                                    row.estado_inc !== "Solucionado" ? (
                                      <span style={{ fontSize: 11, textTransform: 'uppercase' }} className="badge bg-danger">{row.estado_inc}</span>
                                    ) : (
                                      <span style={{ fontSize: 11, textTransform: 'uppercase' }} className="badge bg-success">{row.estado_inc}</span>
                                    )
                                  }</td>
                                <td>{row.asignado_inc}</td>
                                <td>{row.fecha_registro_inc}</td>
                                <td>{row.fecha_mod_reg}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Historial;