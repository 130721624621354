import React from "react";
import Api from "../../servicios/api";
import { Link } from "react-router-dom";
import FormUsuario from "./Usuarios"
import findImage from "../../images/find.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Swal from 'sweetalert2';
import { faAngleDoubleLeft, faAngleLeft, faAngleDoubleRight, faAngleRight, faFilter, faTimes } from '@fortawesome/free-solid-svg-icons'

class Listar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editar: 'No',
            load: '',
            datosCargados: false,
            ordenes: [],
            empresas: [],
            municipios: [],
            excel:0,
        };
    }
    resetForm() {
        document.getElementById('form').reset();
        document.getElementById('ordenint').focus();
        //this.BuscarInsumo(1);
    }
    cargarEmpresas() {
        fetch(Api + "Empresas")
            .then(respuesta => respuesta.json())
            .then((datosEmpresas) => {
                //console.log(datosEmpresas.data);
                this.setState({ empresas: datosEmpresas.data })
            })
            .catch(console.log);
    }
    cargarMunicipios() {
        fetch(Api + "Municipios/08")
            .then(respuesta => respuesta.json())
            .then((datosMunicipios) => {
                //console.log(datosMunicipios);
                this.setState({ municipios: datosMunicipios });
                this.municipio.focus();
            })
            .catch(console.log)
    }
    componentDidMount() {
        this.BuscarOrden(1);
        this.cargarEmpresas();
        this.cargarMunicipios();
    }
    Excel() {
        let ordenint = document.getElementById('ordenint').value;
        let ordenext = document.getElementById('ordenext').value;
        let cedula = document.getElementById('cedula').value;
        let nombre = document.getElementById('nombre').value;
        let empresa = document.getElementById('empresa').value;
        let municipio = document.getElementById('municipio').value;
        let covid = document.getElementById('covid').value;
        let apellidox = document.getElementById('apellidox').value;
        let estado = document.getElementById('estado').value;
        let factura = document.getElementById('factura').value;
        let revisada = document.getElementById('revisada').value;
        let usuario = document.getElementById('usuario').value;
        let fechain = document.getElementById('fechain').value;
        let fechaout = document.getElementById('fechaout').value;
        let prueba = document.getElementById('prueba').value;
        let resultado = document.getElementById('resultado').value;
        window.open('https://idb.softmediko.com/vistas/excel_ordenes.php?estado='+estado+'&empresa='+empresa+'&interna='+ordenint+'&externa='+ordenext+'&nombre='+nombre+'&apellido='+apellidox+'&cedula='+cedula+'&facturadas='+factura+'&revisadas='+revisada+'&user='+usuario+'&desde='+fechain+'&hasta='+fechaout+'&admin=admin', '_blank');
      }
    BuscarOrden(page) {
        console.log("Formulario Enviado...");
        let ordenint = document.getElementById('ordenint').value;
        let ordenext = document.getElementById('ordenext').value;
        let cedula = document.getElementById('cedula').value;
        let nombre = document.getElementById('nombre').value;
        let empresa = document.getElementById('empresa').value;
        let municipio = document.getElementById('municipio').value;
        let covid = document.getElementById('covid').value;
        let apellidox = document.getElementById('apellidox').value;
        let estado = document.getElementById('estado').value;
        let factura = document.getElementById('factura').value;
        let revisada = document.getElementById('revisada').value;
        let usuario = document.getElementById('usuario').value;
        let fechain = document.getElementById('fechain').value;
        let fechaout = document.getElementById('fechaout').value;
        let prueba = document.getElementById('prueba').value;
        let resultado = document.getElementById('resultado').value;

        this.setState({ load: 'Cargando Datos...' })
        let datos = JSON.stringify({ ordenint,ordenext,cedula,nombre,apellidox,empresa,municipio,covid,estado,factura,revisada,usuario,fechain, fechaout, prueba,resultado  });
        let formData = datos;
        this.setState({ load: 'Cargando Datos...' })
        fetch(Api + "Ordenes/Lista?page=" + page, {
            method: "POST",
            body: formData
        })
            .then(respuesta => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                this.setState({ excel:1, datosCargados: true, load: '', ordenes: datosRespuesta.datos.data, pagina: datosRespuesta.datos.total, ultimo: datosRespuesta.datos.last_page, actual: datosRespuesta.datos.current_page });
            })
            .catch(console.log)
    }
    BuscarOrdenOld(page) {
        let ordenint = document.getElementById('ordenint').value;
        let ordenext = document.getElementById('ordenext').value;

        let cedula = document.getElementById('cedula').value;
        let nombre = document.getElementById('nombre').value;
        let apellidox = document.getElementById('apellidox').value;
        let empresa = document.getElementById('empresa').value;
        let municipio = document.getElementById('municipio').value;
        let covid = document.getElementById('covid').value;
        let estado = document.getElementById('estado').value;
        let factura = document.getElementById('factura').value;
        let revisada = document.getElementById('revisada').value;
        let usuario = document.getElementById('usuario').value;
        let fechain = document.getElementById('fechain').value;
  
        this.setState({ load: 'Cargando Datos...' })
        let datos = JSON.stringify({ ordenint,ordenext,cedula,nombre,apellidox,empresa,municipio,covid,estado,factura,revisada,usuario,fechain});
        console.log('arra:'+datos);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: { ordenint:ordenint }
        };
        fetch(Api + "Ordenes/Lista?page=" + page, requestOptions)
        .then(respuesta => respuesta.json())
        .then((datosRespuesta) => {
            console.log(datosRespuesta)
            this.setState({ datosCargados: true, load: '', ordenes: datosRespuesta.datos.data, pagina: datosRespuesta.datos.total, ultimo: datosRespuesta.datos.last_page, actual: datosRespuesta.datos.current_page });
        })
        .catch(console.log)
      }
      quitar(id) {
        Swal.fire({
            title: 'Cual es el motivo para anular esta orden?',
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Anular',
            showLoaderOnConfirm: true,
            preConfirm: (login) => {
              return fetch(`${Api}Ordenes/QuitarAtencion/${id}/${login}`)
                .then(response => {
                  if (!response.ok) {
                    throw new Error(response.statusText)
                  }
                  return response.json()
                })
                .catch(error => {
                  Swal.showValidationMessage(
                    `Request failed: ${error}`
                  )
                })
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire('Se anulo con exito','','success')
              this.BuscarOrden(1);
            }
          })

    
      }
    render() {
        const { excel, load, ordenes, empresas, municipios, pagina, ultimo, actual } = this.state;
        return (
            <div className="content">
                <div class="modal fade" id="usuarioAsignado" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="usuarioAsignadoLabel" aria-hidden="true">
                    <FormUsuario />
                </div>
                <div className="container-fluid">
                    <br />
                    <div className="card">
                        <div className="card-header">
                        <h4>Ordenes Internas</h4>
                        </div>
                        <div className="card-body">
                         
                            <div id="form">
                                <div className="row">
                                    <div className="col-xl-2">
                                        <label htmlFor="ordenint">Orden Interna</label>
                                        <input type="text" id="ordenint" name="ordenint" placeholder="Orden Int." className="form-control form-control-sm" />
                                    </div>
                                    <div className="col-xl-2">
                                        <label htmlFor="ordenext">Orden Externa</label>
                                        <input type="text" id="ordenext" name="ordenext" placeholder="Orden Ext." className="form-control form-control-sm" />
                                    </div>
                                    <div className="col-xl-2">
                                        <label htmlFor="cedula">Identificación</label>
                                        <input type="text" id="cedula" name="cedula" placeholder="Identificación" className="form-control form-control-sm" />
                                    </div>
                                    <div className="col-xl-3">
                                        <label htmlFor="nombre">Nombre Paciente</label>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <input type="text" id="nombre" name="nombre" placeholder="Nombre Paciente" className="form-control form-control-sm" />
                                            </div>
                                            <div className="col-md-6">
                                                <input type="text" id="apellidox" name="apellidox" placeholder="apellido Paciente" className="form-control form-control-sm" />
                                            </div>

                                            </div>
                                        
                                        
                                    </div>
                                    <div className="col-xl-3"> 
                                        <label htmlFor="empresa">Empresa</label>
                                        <select className="form-select form-select-sm" type="text" name="empresa" id="empresa">
                                            <option value="">Seleccione la Empresa</option>
                                            {
                                                empresas.map(itemEmpresa => (
                                                    <option key={itemEmpresa.id_empresa} value={itemEmpresa.rips}>{itemEmpresa.nombre_emp}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-xl-2">
                                        <label htmlFor="estado">Estado Orden</label>
                                        <select className="form-select form-select-sm" type="text" name="estado" id="estado">
                                            <option value="">Seleccione el Estado</option>
                                            <option value="0">No Iniciada</option>
                                            <option value="97">En Proceso</option>
                                            <option value="99">Completada</option>
                                        </select>
                                    </div>
                                    <div className="col-xl-2">
                                        <label htmlFor="factura">Facturadas</label>
                                        <select className="form-select form-select-sm" type="text" name="factura" id="factura">
                                            <option value="">Seleccione el Estado</option>
                                            <option value="activa">No Facturado</option>
                                            <option value="Facturado">Facturado</option>
                                            <option value="No Facturable">No Facturable</option>
                                        </select>
                                    </div>
                                    <div className="col-xl-2">
                                        <label htmlFor="revisada">Ordenes Revisadas</label>
                                        <select className="form-select form-select-sm" type="text" name="revisada" id="revisada">
                                            <option value="">Seleccione el Estado</option>
                                            <option value="x">Sin Revisar</option>
                                            <option value="Revisado">Revisadas</option>
                                        </select>
                                    </div>
                                    <div className="col-xl-3">
                                        <label htmlFor="municipio">Municipio</label>
                                        <select className="form-select form-select-sm" type="text" name="municipio" id="municipio">
                                            <option value="">Seleccione el Municipio</option>
                                            {
                                                municipios.map(itemMunicipio => (
                                                    <option key={itemMunicipio.id} value={itemMunicipio.cod_mun}>{itemMunicipio.nombre_mun}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="usuario" className="form-label">Asignado a</label>
                                        <input type="text" id="usuario" name="usuario" placeholder="Asignado a" className="form-control form-control-sm" readOnly />
                                    </div>
                                    <div className='col-md-1'>
                                        <label htmlFor="" className="form-label">&nbsp;</label>
                                        <img style={{ width: 40 }} className='form-control form-control-sm' src={findImage} alt="Asignado a" data-bs-toggle='modal' data-bs-target='#usuarioAsignado'
                                        />
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-xl-2">
                                        <label htmlFor="covid">Posible Covid</label>
                                        <select className="form-select form-select-sm" type="text" name="covid" id="covid">
                                            <option value="">Seleccione el Estado</option>
                                            <option value="Si">Si</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                    <div className="col-xl-2">
                                        <label htmlFor="prueba">Prueba Tomada</label>
                                        <select className="form-select form-select-sm" type="text" name="prueba" id="prueba">
                                            <option value="">Seleccione el Estado</option>
                                            <option value="Si">Si</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                    <div className="col-xl-2">
                                        <label htmlFor="resultado">Resultado Covid</label>
                                        <select className="form-select form-select-sm" type="text" name="resultado" id="resultado">
                                            <option value="">Seleccione el Resultado</option>
                                            <option value="Positivo">Positivo</option>
                                            <option value="Negativo">Negativo</option>
                                        </select>
                                    </div>
                                    <div className="col-xl-3">
                                        <label htmlFor="fechain">Fecha Ingreso Inicial</label>
                                        <input type="date" id="fechain" name="fechain" className="form-control form-control-sm" />
                                    </div>
                                    <div className="col-xl-3">
                                        <label htmlFor="fechaout">Fecha Ingreso Final</label>
                                        <input type="date" id="fechaout" name="fechaout" className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <br />
                                <div className="row text-center">
                                    <div className="col-xl-12">
                                        <button id="bt" name="bt" className="btn btn-primary btn-sm" onClick={() => this.BuscarOrden(1)}><FontAwesomeIcon icon={faFilter} />&nbsp;&nbsp;Filtrar</button>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-danger btn-sm" onClick={() => this.resetForm()} ><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                        <button type="button" className="btn btn-success btn-sm" onClick={() => this.Excel()} ><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Exportar Excel</button>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <table className="table table-hover table-condensed table-sm">
                                    <thead className="thead-inverse">
                                        <tr>
                                            <th className="align-middle">ORDEN INT.</th>
                                            <th className="align-middle">ORDEN EXT.</th>
                                            <th className="align-middle">CEDULA</th>
                                            <th className="align-middle">PACIENTE</th>
                                            <th className="align-middle">FECHA ING.</th>
                                            <th className="align-middle">FECHA ATE.</th>
                                            <th className="align-middle">ATENCIÓN</th>
                                            <th className="align-middle">CANT.</th>
                                            <th className="align-middle">PORC.</th>
                                            <th className="align-middle">ESTADO</th>
                                            <th className="align-middle">REVISADO</th>
                                            <th className="align-middle">FACTURADO</th>
                                            <th className="align-middle">ASIGNADO</th>
                                            <th className="align-middle">OPC</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {load}
                                        {
                                            ordenes.map((itemOrdenes) => (
                                                <tr key={itemOrdenes.Id}>
                                                    <td>
                                                        <Link to={"/VerOrdenInterna/" + itemOrdenes.orden_servicio}>{itemOrdenes.orden_servicio}</Link>
                                                    </td>
                                                    <td><b className={itemOrdenes.Location=='Revisado' ? "bg-success":""}>{itemOrdenes.orden_externa}</b></td>
                                                    <td>{itemOrdenes.numero_doc}</td>
                                                    <td><Link to={"/InfoPacientes/" + itemOrdenes.id_paciente}>{itemOrdenes.nombres} {itemOrdenes.nombre2} {itemOrdenes.apellidos} {itemOrdenes.apellido2}</Link></td>
                                                    <td>{itemOrdenes.fecha_reg_ta}</td>
                                                    <td>{itemOrdenes.StartTime} - {itemOrdenes.EndTime}</td>
                                                    <td>{itemOrdenes.Description}</td>
                                                    <td>{itemOrdenes.cant}</td>
                                                    <td>{parseFloat(itemOrdenes.id_contacto?itemOrdenes.id_contacto:0).toFixed(2) + "%"}</td>
                                                    <td></td>
                                                    <td><b className={itemOrdenes.Location=='Revisado' ? "bg-success":""}>{itemOrdenes.Location}</b></td>
                                                    <td>{itemOrdenes.prioridad}</td>
                                                    <td>{itemOrdenes.user}</td>
                                                    <td><button className="btn btn-danger" onClick={() =>this.quitar(itemOrdenes.orden_servicio)}>-</button></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.BuscarOrden(1)}><FontAwesomeIcon icon={faAngleDoubleLeft} /></a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.BuscarOrden(actual - 1)}><FontAwesomeIcon icon={faAngleLeft} /></a></li>
                                    {(() => {
                                        let p = [];
                                        /*if (ultimo >= 20) {
                                          var ult = 20;
                                        } else {
                                          ult = ultimo;
                                        }
                                        var ult = ultimo;
                                        for (let i = 1; i <= ult; i++) {
                                          p.push(<li className="page-item" key={i}><a className="page-link" href="#" onClick={() => this.BuscarOrden(i)}>{i}</a></li>);
                                        }*/
                                        p.push(<li className="page-item" key={actual}><a className="page-link" href="#" onClick={() => this.BuscarOrden(actual)}>{actual}</a></li>);
                                        return p;
                                    })()}
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.BuscarOrden(actual + 1)}><FontAwesomeIcon icon={faAngleRight} /></a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.BuscarOrden(ultimo)}><FontAwesomeIcon icon={faAngleDoubleRight} /></a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="card-footer text-muted">
                            <b>Pagina</b> <span class="badge bg-primary">{actual}</span> <b>de</b> <span class="badge bg-primary">{ultimo}</span> | <b>Registros</b> <span class="badge bg-success">{pagina}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Listar;