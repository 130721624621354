import React from "react";
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import Swal from 'sweetalert2';

class Editar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datosCargados: false,
      nombre:"",
            tipo:"",
      empleado: [],
      errores:[]
    };
  }
  cambioValor = (e) => {
    const state = this.state.empleado;
    state[e.target.name] = e.target.value;
    this.setState({ empleado: state });
  };
verificarError(elemento) {
  return this.state.errores.indexOf(elemento) !== -1;
}
enviarDatos = (e) => {
  e.preventDefault();
  console.log("Formulario Enviado...");
 // const {nombre_atencion, tipo,id_atencion,codigo_atencion,cuenta} = this.state.empleado;

  let form = document.getElementById('form');
  let formData = new FormData(form);
  console.log(formData);
  //var datosEnviar = {nombre:nombre_atencion, tipo:tipo,id:id_atencion,cuenta:cuenta,codigo:codigo_atencion}

 
  fetch(Api + "UpNoticias", {
      method:"POST",
      body:formData
  })
  .then(respuesta=>respuesta.json())
  .then((datosRespuesta)=> {
      console.log(datosRespuesta)
      Swal.fire(
        'Noticias!',
        datosRespuesta.msj,
        'success'
      )
      this.props.history.push("/MasterNoticias");
  })
  .catch(console.log)
}
  componentDidMount() {
   
    console.log(this.props.match.params.id);
    fetch(Api + "Noticias/" + this.props.match.params.id,
    {
      method:"GET",
      headers: new Headers({
        'Authorization': 'koala2021'
      }), 
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
        this.setState({ datosCargados: true, empleado: datosRespuesta }); //
      })
      .catch(console.log);
  }
  render() {
    const { datosCargados, empleado } = this.state;
    if (!datosCargados) {
      var load = 'Cargando datos....';
   } else {
      var load = '';
   }
      return (
        <div>
    
        <div className="content-wrapper">
          <div className="content">
            <div className="container-fluid">
              <div className="card">
                <div className="card-header">Editar Noticias {load}</div>
                <div className="card-body">
                  <form onSubmit={this.enviarDatos} id="form">
                    
                    <div className="form-group">
                      <label htmlFor="id">Id :</label>
                      <input
                        type="text"
                        name="id"
                        id="id"
                        value={empleado.id_noticias}
                        className="form-control form-control-sm"
                        placeholder=""
                        aria-describedby="helpId"
                        onChange={this.cambioValor}
                        readOnly
                      />
                      
                    </div>
                   
                    <div className="form-group">
                      <label htmlFor="nombre">Nombre:</label>
                      <input
                        type="text"
                        name="nombre"
                        id="nombre"
                        defaultValue={empleado.noticia}
                        className={((this.verificarError("error_nombre"))?"is-invalid":"")+" form-control form-control-sm"}
                        onChange={this.cambioValor}
                      />
                      
                    </div>
                    <div className="form-group">
                      <label htmlFor="tipo">Estado:</label>
                      
                      
                      <select name="tipo"
                        id="tipo"
                        
                        className={((this.verificarError("error_tipo"))?"is-invalid":"")+" form-select form-select-sm"}
                        onChange={this.cambioValor}>
                       <option value={empleado.estado}>{empleado.estado}</option>  
                        <option value="Publicado">Publicado</option>   
                        <option value="Archivado">Archivado</option>  
                       
                      </select>
                      <small id="" className="text-muted">
                          Seleccione el estado
                      </small>
                    </div>
                    
                    
                    <br />
                    <div className="btn-group" role="group" aria-label="">
                      <button type="submit" className="btn btn-warning btn-sm">
                        Editar
                      </button>&nbsp;&nbsp;
                      <Link className="btn btn-danger btn-sm" to={"/MasterNoticias"}>
                        Cancelar
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        </div>
      );
    }
  }


export default Editar;
