import React from "react";
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faUpload, faPlus, faViruses } from '@fortawesome/free-solid-svg-icons'

class Listar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datosCargados: false,
      query: [],
      pagina: [],
    };
  }
  borrarRegistros = (id) => {
    console.log(id);
    fetch(Api + "DelAtenciones/" + id)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
        this.cargarDatos(1);
      })
      .catch(console.log);
  };
  cargarDatos(page) {
    fetch(Api + "Atenciones?page="+page)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
        this.setState({ datosCargados: true, query: datosRespuesta.data, pagina:datosRespuesta.total, ultimo: datosRespuesta.last_page, actual: datosRespuesta.current_page });
      })
      .catch(console.log);
  }
 
  componentDidMount() {
    this.Buscar(1);
  }
  Buscar(page){
 
    
    let btipo = document.getElementById('buscar').value;
    let bname = document.getElementById('bnombre').value;
    console.log("Formulario Enviado...");
    if(btipo==''){
      btipo = 0;
    }
    if(bname==''){
      bname = 0;
    }
    fetch(Api + "SearchAtenciones/"+btipo+"/"+bname+"?page="+page)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
        this.setState({ datosCargados: true, query: datosRespuesta.data, pagina:datosRespuesta.total, ultimo: datosRespuesta.last_page, actual: datosRespuesta.current_page });
      })
      .catch(console.log);
  }
  render() {
    const { datosCargados, query,pagina, ultimo, actual } = this.state;
    if (!datosCargados) {
       var load = 'Cargando datos....';
    } else {
       var load = '';
    }
       
    
      return (
        
          <div className="content">
            <div className="container-fluid">
              <div className="card">
                <div className="card-header">
                  <Link className="btn btn-success btn-sm" to={"/AddAtenciones"}>
                    Agregar Atención
                  </Link>
                </div>
                <div className="card-body">
                  <h4>Lista de Atenciones</h4>
                  <div className="row">
               
                      <div className="col-xl-3">
                     
                      <select className="form-select form-select-sm" type="text" name="buscar" id="buscar"  placeholder="" aria-describedby="helpId"  >
                          <option value="">Todos los Tipos</option>
                          <option value="1">Procedimiento</option>
                          <option value="2">Consulta</option>
                          <option value="3">Otra</option>
                          </select>

                      </div>
                      <div className="col-xl-3">
                       
                          <input type="text" id="bnombre" name="bnombre" placeholder="Nombre Atención" className="form-control form-control-sm"  />
                      </div>
                      <div className="col-xl-3">
                        
                          <input type="button" id="bt" name="bt" className="btn btn-primary btn-sm" value="Filtrar" onClick={() =>this.Buscar(1)} />
                      </div>
               
                   </div>
                   <br></br>
                   <div className="row">
                  <table className="table table-hover table-condensed table-sm">
                  <thead className="thead-inverse">
                      <tr>
                        <th>CÓDIGO</th>
                        <th>NOMBRE DE LA ATENCIÓN</th>
                        <th>TIPO</th>
                        <th>ACCIONES</th>
                      </tr>
                    </thead>
                    <tbody> 
                      {load}
                      {query.map((empleado) => (
                        <tr key={empleado.id_atencion}>
                          <td>{empleado.codigo_atencion}</td>
                          <td >{empleado.nombre_atencion}</td>
                          <td>{empleado.tipo}</td>
                          <td>
                            <div
                              className="btn-group"
                              role="group"
                              aria-label=""
                            >
                              <Link
                                className="btn btn-warning btn-sm"
                                to={"/UpAtenciones/" + empleado.id_atencion}
                              >
                                 <i class="fas fa-edit"></i>
                              </Link>&nbsp;&nbsp;
                              <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={() =>
                                  this.borrarRegistros(empleado.id_atencion)
                                }
                              >
                                 <i class="far fa-trash-alt"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  </div>
                  <nav aria-label="Page navigation example">
                    <ul className="pagination">
                        <li class="page-item"><a className="page-link" href="#" onClick={() => this.Buscar(1)}>Atras</a></li>
                        {(() => {
                            let p = [];
                            for(let i = 1; i <= ultimo ; i++){
                                
                                p.push(<li class="page-item" key={i}><a className="page-link" href="#" onClick={() => this.Buscar(i)}>{i}</a></li>); 
                            }
                            return p;
                        })()}
                       
                        <li class="page-item"><a className="page-link" href="#" onClick={() => this.Buscar(ultimo)}>Siguiente</a></li>
                    </ul>
                    Pagina {actual} de {ultimo} Registros {pagina}
                    </nav>
                </div>
              </div>
              </div>
              </div>
              
            
      );
    }
  }


export default Listar;
