import React from 'react';
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleLeft, faAngleDoubleRight, faAngleRight, faFilter, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

class Listar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datosCargados: false,
            load: '',
            atenciones: [],
            pagina: [],
            ultimo: 0,
            actual: 0
        };
    }

    Buscar(page) {
        let bano = document.getElementById('bano').value;
        let bmes = document.getElementById('bmes').value;
        if (bano === '') {
            bano = 0;
        }
        if (bmes === '') {
            bmes = 0;
        }
        this.setState({ load: 'Cargando Datos...' });
        fetch(Api + "Auditoria/Atenciones/" + bmes + "/" + bano)
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                this.setState({ datosCargados: true, load: '', atenciones: datosRespuesta.datos, pagina: datosRespuesta.total, ultimo: datosRespuesta.last_page, actual: datosRespuesta.current_page });
            })
            .catch(console.log);
    }

    exportToExcel() {
        const { atenciones } = this.state;
        const headers = [
            "ÍTEM",
            "ATENCIÓN",
            "CANT. ATENCIONES",
            "CANT. ORDENES",
            "FECHA INGRESO"
        ];
        const data = atenciones.map((itemAtenciones, i) => ({
            "ÍTEM": i + 1,
            "ATENCIÓN": itemAtenciones.Description,
            "CANT. ATENCIONES": itemAtenciones.cantidad,
            "CANT. ORDENES": itemAtenciones.ca,
            "FECHA INGRESO": itemAtenciones.fecha_reg_ta
        }));
        const worksheet = XLSX.utils.json_to_sheet(data, { header: headers });
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Atenciones");
        XLSX.writeFile(workbook, "AuditoriaAtenciones.xlsx");
    }

    render() {
        const { datosCargados, load, atenciones, pagina, ultimo, actual } = this.state;
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            &nbsp;
                        </div>
                        <div className="card-body">
                            <h4>Auditoría de Atenciones por Mes</h4>
                            <div className="row">
                                <div className="col-xl-1">
                                    <input type="text" id="bano" name="bano" placeholder="Año" className="form-control form-control-sm" maxLength="4" />
                                </div>
                                <div className="col-xl-2">
                                    <select className="form-select form-select-sm" type="text" name="bmes" id="bmes">
                                        <option value="">Seleccione el Mes</option>
                                        <option value="01">01 - Enero</option>
                                        <option value="02">02 - Febrero</option>
                                        <option value="03">03 - Marzo</option>
                                        <option value="04">04 - Abril</option>
                                        <option value="05">05 - Mayo</option>
                                        <option value="06">06 - Junio</option>
                                        <option value="07">07 - Julio</option>
                                        <option value="08">08 - Agosto</option>
                                        <option value="09">09 - Septiembre</option>
                                        <option value="10">10 - Octubre</option>
                                        <option value="11">11 - Noviembre</option>
                                        <option value="12">12 - Diciembre</option>
                                    </select>
                                </div>
                                <div className="col-xl-1">
                                    <button id="bt" name="bt" className="btn btn-primary btn-sm" onClick={() => this.Buscar(1)}><FontAwesomeIcon icon={faFilter} />&nbsp;&nbsp;Filtrar</button>
                                </div>
                                <div className='col-xl-2'>
                                    {
                                        datosCargados ? (
                                            <button style={{ color: '#FFFFFF' }} id="btn_exportar" onClick={() => this.exportToExcel()} name="btn_exportar" className="btn btn-info btn-sm"><FontAwesomeIcon icon={faFileDownload} />&nbsp;&nbsp;Exportar</button>
                                        ) : null
                                    }
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <table className="table table-hover table-condensed table-sm">
                                    <thead className="thead-inverse">
                                        <tr>
                                            <th>ÍTEM</th>
                                            <th>ATENCIÓN</th>
                                            <th>CANT. ATENCIONES</th>
                                            <th>CANT. ORDENES</th>
                                            <th>FECHA INGRESO</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {load}
                                        {atenciones.map((itemAtenciones, i) => (
                                            <tr key={itemAtenciones.cod_aten}>
                                                <td>{i + 1}</td>
                                                <td>{itemAtenciones.Description}</td>
                                                <td>{itemAtenciones.cantidad}</td>
                                                <td>{itemAtenciones.ca}</td>
                                                <td>{itemAtenciones.fecha_reg_ta}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Buscar(1)}><FontAwesomeIcon icon={faAngleDoubleLeft} /></a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Buscar(actual - 1)}><FontAwesomeIcon icon={faAngleLeft} /></a></li>
                                    {(() => {
                                        let p = [];
                                        p.push(<li className="page-item" key={actual}><a className="page-link" href="#" onClick={() => this.Buscar(actual)}>{actual}</a></li>);
                                        return p;
                                    })()}
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Buscar(actual + 1)}><FontAwesomeIcon icon={faAngleRight} /></a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Buscar(ultimo)}><FontAwesomeIcon icon={faAngleDoubleRight} /></a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="card-footer text-muted">
                            <b>Pagina</b> <span className="badge bg-primary">{actual}</span> <b>de</b> <span className="badge bg-primary">{ultimo}</span> | <b>Registros</b> <span className="badge bg-success">{pagina}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Listar;
