import React from "react";
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import axios from 'axios';
//import ItemInsumos from "./ItemInsumos";
import { currencyFormat } from '../../js/Functions';
import FormPaciente from "../modales/Pacientes"
import FormEquipo from "../modales/Equipos"
import FormHistorial from "../modales/AlquileresPac"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleDoubleRight,
  faAngleRight,
  faPrint,
  faPlus,
  faFilter,
  faDownload,
  faEdit,
  faSave,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

class Master extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editar: "No",pac: "",nombre: "",
      datosCargados: false,
      total: 0,
      TipoModal:'Pacientes',
      empresas: [],
      paciente: 0,
      pagina: [],
      tf: 0,
      checkboxIds: [],
      facturas: [],
      detalles: [],
      medicamentos: [],
      insumos: [],
      date: new Date(),
    };
  }
  cargarEmpresas() {
    fetch(Api + "Empresas")
      .then((respuesta) => respuesta.json())
      .then((datosEmpresas) => {
        //console.log(datosEmpresas.data);
        this.setState({ empresas: datosEmpresas.data });
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarEmpresas();
    this.Buscar(1);
  }
  Modal (nombre){
    this.setState({
      TipoModal: nombre
    });
  }
  actuali = () => {
    this.Buscar(1);
  }
  Buscar(page) {
    Swal.showLoading();
    let empresa = document.getElementById('empresax').value;
    let estado = document.getElementById('estado').value;
    let estado_ord = document.getElementById('estado_ord').value;
    let estado_orden = document.getElementById('estado_orden').value;
    let documento = document.getElementById('documento').value;
    let nombre = document.getElementById('nombre').value;
    let apellido = document.getElementById('apellido').value;
    let ano = document.getElementById('ano').value;
 
    let regimen = document.getElementById('regimen').value;
    //var datos = {empresa:empresa,estado,estado_ord,estado_orden,documento,nombre,apellido,ano,mes,regimen}
    var datos = new FormData(document.getElementById("formulario"));
    fetch(Api + "alquiler/estados", {
      method: "POST",
      body: datos,
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
        this.setState({
          datosCargados: true,
          facturas: datosRespuesta.data,
          tf: datosRespuesta.cantidad,
        });
        Swal.close();
      })
      .catch(console.log);
  }
  Guardar() {

    var orden = document.getElementById('orden').value ;
    var doc = document.getElementById('doc').value ;
    var id_paciente = document.getElementById('id_paciente').value;
    var nombre_paciente = document.getElementById('nombre_paciente').value;
    var direccion_paciente = document.getElementById('direccion_paciente').value;
    var fi = document.getElementById('fi').value;
    var ff = document.getElementById('ff').value;
    var llamada = document.getElementById('llamada').value ;
    var autorizacion = document.getElementById('autorizacion').value ;
    var numero = document.getElementById('numero').value ;
    var pedido = document.getElementById('pedido').value;
    var estado = document.getElementById('estado').value ;
    var cod_e = document.getElementById('cod_e').value ;
    var id_e = document.getElementById('id_e').value;
    var nom_e = document.getElementById('nombre_e').value;
    var pre_e = document.getElementById('pre_e').value;
    var pag_e = document.getElementById('pag_e').value;
    var dia_e = document.getElementById('dia_e').value;
    var obs = document.getElementById('obs').value;
    //var dato = new FormData(document.getElementById("formcrear"));
    Swal.showLoading();
    var dato = {orden:orden,id_paciente,fi,ff,llamada,numero,pedido,estado,cod_e,id_e,nom_e,pre_e,pag_e,dia_e,autorizacion,obs}
    
    console.log(dato);

    axios.post(Api + 'alquiler/registro', dato)
      .then(response => {
        console.log(response.data);
        //var m = response.data.factura;
        alert("Se ha Guardado con exito");
        this.Buscar(1);
      })
      .catch(error => {
        console.log(error);
      });
  }
  handleCheckboxChange = (id) => {
    // Desestructura el estado actual
    const { checkboxIds } = this.state;

    // Verifica si el ID ya está en el array de checkboxIds
    if (checkboxIds.includes(id)) {
      // Si está presente, lo elimina (deselección)
      this.setState({
        checkboxIds: checkboxIds.filter((checkboxId) => checkboxId !== id),
      });
    } else {
      // Si no está presente, lo agrega (selección)
      this.setState({
        checkboxIds: [...checkboxIds, id],
      });
    }
  };
  handleSelectAll = () => {
    const { facturas } = this.state;
    // Obtiene todos los IDs de checkboxes de tu tabla (supongamos que están en la prop 'datos')
    const allCheckboxIds = facturas.map((item) => item.numero_orden_a);
    // Actualiza el estado con todos los IDs
    this.setState({
      checkboxIds: allCheckboxIds,
    });
  };
  Mostrar(id) {
      axios.get(Api + 'alquiler/mostrar/'+id)
      .then(response => {
        console.log(response.data);
        var o = response.data.orden;
        var p = response.data.paciente;
        var a = response.data.alquiler;
        document.getElementById('orden').value = o.id;
        document.getElementById('doc').value = p.numero_doc;
        document.getElementById('id_paciente').value = p.id_paciente;
        document.getElementById('nombre_paciente').value = p.nombres+' '+p.nombre2+' '+p.apellidos+' '+p.apellido2;
        document.getElementById('direccion_paciente').value = p.direccion1;
        document.getElementById('fi').value = a.fecha_a;
        document.getElementById('ff').value = a.fecha_f;
        document.getElementById('llamada').value = o.llamada;
        document.getElementById('autorizacion').value = o.orden;
        document.getElementById('numero').value = o.num_pedido;
        document.getElementById('pedido').value = o.fecha_pedido;
        document.getElementById('estado').value = o.estado_ord;
        document.getElementById('cod_e').value = a.cod_equipo;
        document.getElementById('id_e').value = a.id_equipo_a;
        document.getElementById('nombre_e').value = a.equipo;
        document.getElementById('pre_e').value = a.precio_a;
        document.getElementById('pag_e').value = a.copagos;
        document.getElementById('dia_e').value = a.cantidad;
        document.getElementById('obs').value=o.obs ;
        
      })
      .catch(error => {
        console.log(error);
      });
   }
   Historial(id) {
    this.setState({
      paciente: id
    });
      this.Modal("HistorialAlquiler");
   }
   Repetir() {
    
    
    Swal.fire({
      title: "Estas seguro de repetir los alquileres seleccionados?",
      showDenyButton: true,
    
      confirmButtonText: "Repetir",
      denyButtonText: `Cancelar`
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.showLoading();
        //var datos = {empresa:empresa,estado,estado_ord,estado_orden,documento,nombre,apellido,ano,mes,regimen}
        const { checkboxIds } = this.state;
        var dato = {datos:checkboxIds}
        console.log(dato);
        //return false;
        axios.post(Api +'alquiler/repetirtodo',dato)
        .then(response => {
          console.log(response.data);
          var m = response.data;
          console.log(m);
          Swal.fire({
            title: "Se han duplicado los alquileres con exito",
            text: 'Registro',
            icon: "success",
            willClose: () => {
              this.Buscar(1);
            }
        }); 
        })
        .catch(error => {
          console.log(error);
        });
      } else if (result.isDenied) {
        Swal.fire("Sera en otra ocasion", "", "info");
      }
    });
 
   
  }
  render() {
    const {
      datosCargados,
      empresas,
      paciente,
      pac,TipoModal,
      facturas,
      pagina,
      nombre,
      actual,
      detalles,
      insumos, total, date, tf, checkboxIds
    } = this.state;
    if (!datosCargados) {
      var load = "Cargando datos....";
    } else {
      var load = "";
    }
    return (
      <div className="content">
        <div class="modal fade" id="asignarPaciente" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="asignarPacienteLabel" aria-hidden="true">
                    {
                    TipoModal=='Pacientes'?
                    <FormPaciente agregarPaciente={this.agregarPaciente} />
                    :
                    TipoModal=='HistorialAlquiler'?
                    <FormHistorial agregarPaciente={paciente} actuali={this.actuali} />
                    :
                    <FormEquipo agregarPaciente={this.agregarPaciente}  />
                    }
                </div>
               
        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Formulario de Alquiler</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <form id="formcrear">
                  <div className="row">
                  <div className="col-sm-2">
                      <label for="doc_p" className="col-form-label">Documento</label>
                      <div class="input-group mb-3">
                      <input type="text" class="form-control" id="doc" placeholder="Numero de documento" aria-label="umero de documento" aria-describedby="button-addon2" />
                      <button class="btn btn-outline-primary" type="button" id="button-addon2"  data-bs-toggle='modal' data-bs-target='#asignarPaciente' onClick={()=>this.Modal('Pacientes')}>Buscar</button>
                     
                    </div>
                    </div>
                    <div className="col-sm-2">
                      <label for="recipient-name" className="col-form-label">Nombre del Paciente:</label>
                      <input type="hidden" className="form-control" id="id_paciente" />
                      <input type="text" className="form-control" id="nombre_paciente" />
                    </div>
                    <div className="col-sm-2">
                      <label for="recipient-name" className="col-form-label">Direccion:</label>
                      <input type="text" className="form-control" id="direccion_paciente" />
                    </div>
                    
                    <div className="col-sm-2">
                      <label for="recipient-name" className="col-form-label">Fecha Inicial:</label>
                      <input type="date" className="form-control" id="fi" name="fi" />
                    </div>
                    <div className="col-sm-2">
                      <label for="recipient-name" className="col-form-label">Fecha Final:</label>
                      <input type="date" className="form-control"  id="ff" name="ff" />
                    </div>
                    <div className="col-sm-2">
                      <label for="recipient-name" className="col-form-label">Orden Servicio:</label>
                      <input type="text" className="form-control" id="orden" name="orden" readOnly/>
                    </div>
                    <div className="col-sm-2">
                      <label for="recipient-name" className="col-form-label">Llamada:</label>
                      <select class="form-select" aria-label="Default select example" id="llamada" name="llamada">
                      
                        <option value="Pendiente">Pendiente</option>
                        <option value="Realizada">Realizada</option>
                        <option value="No aplica">No aplica</option>
                    </select>
                    </div>
                    <div className="col-sm-2">
                      <label for="recipient-name" className="col-form-label">Autorizacion:</label>
                      <input type="text" className="form-control" id="autorizacion" name="autorizacion" />
                    </div>
                    <div className="col-sm-2">
                      <label for="recipient-name" className="col-form-label">No. Pedido:</label>
                      <input type="text" className="form-control" id="numero" name="numero" />
                    </div>
                    <div className="col-sm-2">
                      <label for="recipient-name" className="col-form-label">Fecha Pedido:</label>
                      <input type="date" className="form-control" id="pedido" name="pedido" />
                    </div>
                    
                    <div className="col-sm-2">
                      <label for="recipient-name" className="col-form-label">Estado:</label>
                      <select class="form-select" aria-label="Default select example" id="estado" name="estado">
                      
                        <option value="En proceso">En proceso</option>
                        <option value="Completada">Completada</option>
                    </select>
                    </div>
                    <div className="col-sm-12">
                      <label for="recipient-name" className="col-form-label">Observaciones:</label>
                      <input type="text" className="form-control" id="obs" iobs="obs" />
                    </div>
                  </div>
                  <hr></hr>
                  <h5>Datos del Equipo</h5>
                  <table className="table table-hover table-condensed table-sm">
                     <thead>
                      <tr>
                        <th>Cododigo del equipo</th>
                        <th style={{ width: '50px' }}>Id</th>
                        <th>Nombre del equipo</th>
                        <th>Precio alquiler</th>
                        <th>Copagos</th>
                        <th>Dias</th>
                        </tr>
                     </thead>
                     <tr>
                         <td>
                         <div class="input-group mb-3">
                          <input type="text" class="form-control" id="cod_e" name="cod_e" placeholder="Numero de documento" aria-label="umero de documento" aria-describedby="button-addon2" />
                        
                          <button class="btn btn-outline-primary" type="button" id="button-addon2"  data-bs-toggle='modal' data-bs-target='#asignarPaciente' onClick={()=>this.Modal('Equipos')}>Buscar</button>
                        </div>
                         </td>
                         <td>
                           <input type="text" className="form-control" id="id_e" name="id_e" readOnly/>
                         </td>
                         <td>
                           <input type="text" className="form-control" id="nombre_e" name="nombre_e" />
                         </td>
                         <td>
                           <input type="text" className="form-control" id="pre_e" name="pre_e" />
                         </td>
                         <td>
                           <input type="text" className="form-control" id="pag_e" name="pag_e" />
                         </td>
                         <td>
                           <input type="text" className="form-control" id="dia_e" name="dia_e"/>
                         </td>
                     </tr>
                  </table>
                  <button type="button" className="btn btn-primary" onClick={()=>this.Guardar()}>Guardar Alquiler</button>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
               
              </div>
            </div>
          </div>
        </div>
        <div className="content-wrapper">
          <div className="content">

            <div className="container-fluid">
              <div className="card">
                <div className="card-header">
                  <h4>Lista de Alquiler </h4>
                  <button
                    type="button"
                    id="bt"
                    name="bt"
                    data-bs-toggle="modal" data-bs-target="#exampleModal" 
                    className="btn btn-primary btn-sm"
                    onClick={() => this.Mostrar(0)}
                  >Nuevo Alquiler</button>
                </div>
                <div className="card-body">

                  <form id="formulario">
                    <div className="row">
                      <div className="col-xl-2">
                        <label>Empresa</label>
                        <select
                          className="form-select form-select-sm"
                          type="text"
                          name="empresax"
                          id="empresax"
                        >
                          <option value="">Seleccione la Empresa</option>
                          {empresas.map((itemEmpresa) => (
                            <option
                              key={itemEmpresa.id_empresa}
                              value={itemEmpresa.rips}
                            >
                              {itemEmpresa.nombre_emp}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-xl-2">
                        <label>Documento Paciente</label>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          id="documento"
                          name="documento"
                          placeholder="Numero de Factura"
                        />
                      </div>
                      <div className="col-xl-2">
                        <label>Nombre Paciente</label>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          id="nombre"
                          name="nombre"
                          placeholder="Numero de Factura"
                        />
                      </div>
                      <div className="col-xl-2">
                        <label>Apellido Paciente</label>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          id="apellido"
                          name="apellido"
                          placeholder="Numero de Factura"
                        />
                      </div>
                      <div className="col-xl-2">
                        <label>Regimen</label>
                        <select
                          className="form-select form-select-sm"
                          type="text"
                          name="regimen"
                          id="regimen"
                        >
                          <option value="">Todos</option><option value="1">Contributivo</option><option value="2">Subsidiado</option><option value="3">Vinculado</option><option value="4">Particular</option><option value="5">Otro</option><option value="NO APLICA">NO APLICA</option><option value="7">Desplazado con afilacion al regimen Contributivo</option><option value="8">Desplazado con afilacion al regimen Subsidiado</option><option value="9">Desplazado no asegurado</option>

                        </select>
                      </div>
                      
                      <div className="col-xl-2">
                        <label>Estado Alquiler</label>
                        <select
                          className="form-select form-select-sm"
                          type="text"
                          name="estado_ord"
                          id="estado_ord"
                        >
                          <option value="">En alquiler</option><option value="Retirado">Retirado</option>

                        </select>
                      </div>
                      <div className="col-xl-2">
                        <label>Estado orden</label>
                        <select
                          className="form-select form-select-sm"
                          type="text"
                          name="estado_orden"
                          id="estado_orden"
                        >
                          <option value="En proceso">En proceso</option><option value="Completada">Completada</option>

                        </select>
                      </div>
                      <div className="col-xl-2">
                        <label>Año</label>
                        <input
                          className="form-control form-control-sm"
                          type="number"
                          id="ano"
                          name="ano"
                          placeholder="Año"
                          defaultValue={'2024'}
                        />
                      </div>
                     
                      <div className="col-xl-2">
                        <label>Autorizacion</label>
                        <input
                          className="form-control form-control-sm"
                          type="number"
                          id="orden"
                          name="orden"


                        />
                      </div>

                      <div className="col-xl-2 pt-4">
                        <button
                          type="button"
                          id="bt"
                          name="bt"
                          className="btn btn-primary btn-sm"
                          onClick={() => this.Buscar(1)}
                        >
                          <FontAwesomeIcon icon={faFilter} />
                          &nbsp;&nbsp;Filtrar
                        </button>
                      </div>
                    </div>
                  </form>
                  <br></br>
                  <div className="row">
                    <table className="table table-hover table-condensed table-sm">
                      <thead className="thead-inverse">
                        <tr>
                          <th>INGRESO</th>
                          <th>PACIENTE</th>
                          <th>REGIMEN</th>
                          <th>EQUIPO</th>
                          <th>ENERO</th>
                          <th>FEBRERO</th>
                          <th>MARZO</th>
                          <th>ABRIL</th>
                          <th>MAYO</th>
                          <th>JUNIO</th>
                          <th>JULIO</th>
                          <th>AGOSTO</th>
                          <th>SEPTIEMBRE</th>
                          <th>OCTUBRE</th>
                          <th>NOVIEMBRE</th>
                          <th>DICIEMBRE</th>
                          <th>R</th>
                          <th>H</th>

                        </tr>
                      </thead>
                      <tbody>
                        {load}
                        {facturas.map((itemMovimiento) => (
                          <tr key={itemMovimiento.id_equipo_a}>
                            <td>{itemMovimiento.numero_doc}</td>
                            <td>

                              <Link
                                to={"InfoPacientes/" + itemMovimiento.id_paciente}
                              >
                                {itemMovimiento.nombres} {itemMovimiento.apellidos}
                              </Link>

                            </td>
                            <td>{itemMovimiento.regimen}</td>
                            <td>{itemMovimiento.equipo}</td>
                            <td>
                            {itemMovimiento.enero==''?<img src={process.env.PUBLIC_URL + '/dist/img/red-flag.png'} className="brand-image "  />:
                              <button
                                type="button"
                                id="bt"
                                
                                name="bt" data-bs-toggle="modal" data-bs-target="#exampleModal" 
                                className={itemMovimiento.enero=="Pendiente"?"btn btn-danger":"btn btn-success"}
                                onClick={() => this.Mostrar(itemMovimiento.idenero)}
                              >{itemMovimiento.enero}</button>}
                            </td>
                            <td>
                            {itemMovimiento.febrero==''?<img src={process.env.PUBLIC_URL + '/dist/img/red-flag.png'} className="brand-image "  />:
                              <button
                                type="button"
                                id="bt"
                                name="bt" data-bs-toggle="modal" data-bs-target="#exampleModal" 
                                className={itemMovimiento.febrero=="Pendiente"?"btn btn-danger btn-xs":"btn btn-success btn-xs"}
                                onClick={() => this.Mostrar(itemMovimiento.idfebrero)}
                              >{itemMovimiento.febrero}</button>}
                            </td>
                            <td>
                            {itemMovimiento.marzo==''?<img src={process.env.PUBLIC_URL + '/dist/img/red-flag.png'} className="brand-image "  />:
                              <button
                                type="button"
                                id="bt"
                                name="bt" data-bs-toggle="modal" data-bs-target="#exampleModal" 
                                className={itemMovimiento.marzo=="Pendiente"?"btn btn-danger btn-xs":"btn btn-success btn-xs"}
                                onClick={() => this.Mostrar(itemMovimiento.idmarzo)}
                              >{itemMovimiento.marzo}</button>}
                            </td>
                            <td>
                            {itemMovimiento.abril==''?<img src={process.env.PUBLIC_URL + '/dist/img/red-flag.png'} className="brand-image "  />:
                              <button
                                type="button"
                                id="bt"
                                name="bt" data-bs-toggle="modal" data-bs-target="#exampleModal" 
                                className={itemMovimiento.abril=="Pendiente"?"btn btn-danger btn-xs":"btn btn-success btn-xs"}
                                onClick={() => this.Mostrar(itemMovimiento.idabril)}
                              >{itemMovimiento.abril}</button>}
                            </td>
                            <td>
                            {itemMovimiento.mayo==''?<img src={process.env.PUBLIC_URL + '/dist/img/red-flag.png'} className="brand-image "  />:
                              <button
                                type="button"
                                id="bt"
                                name="bt" data-bs-toggle="modal" data-bs-target="#exampleModal" 
                                className={itemMovimiento.mayo=="Pendiente"?"btn btn-danger btn-xs":"btn btn-success btn-xs"}
                                onClick={() => this.Mostrar(itemMovimiento.idmayo)}
                              >{itemMovimiento.mayo}</button>}
                            </td>
                            <td>
                            {itemMovimiento.junio==''?<img src={process.env.PUBLIC_URL + '/dist/img/red-flag.png'} className="brand-image "  />:
                              <button
                                type="button"
                                id="bt"
                                name="bt" data-bs-toggle="modal" data-bs-target="#exampleModal" 
                                className={itemMovimiento.junio=="Pendiente"?"btn btn-danger btn-xs":"btn btn-success btn-xs"}
                                onClick={() => this.Mostrar(itemMovimiento.idjunio)}
                              >{itemMovimiento.junio}</button>}
                            </td>
                            <td>
                            {itemMovimiento.julio==''?<img src={process.env.PUBLIC_URL + '/dist/img/red-flag.png'} className="brand-image "  />:
                              <button
                                type="button"
                                id="bt"
                                name="bt" data-bs-toggle="modal" data-bs-target="#exampleModal" 
                                className={itemMovimiento.julio=="Pendiente"?"btn btn-danger btn-xs":"btn btn-success btn-xs"}
                                onClick={() => this.Mostrar(itemMovimiento.idjulio)}
                              >{itemMovimiento.julio}</button>}
                            </td>
                            <td>
                            {itemMovimiento.agosto==''?<img src={process.env.PUBLIC_URL + '/dist/img/red-flag.png'} className="brand-image "  />:
                              <button
                                type="button"
                                id="bt"
                                name="bt" data-bs-toggle="modal" data-bs-target="#exampleModal" 
                                className={itemMovimiento.agosto=="Pendiente"?"btn btn-danger btn-xs":"btn btn-success btn-xs"}
                                onClick={() => this.Mostrar(itemMovimiento.idagosto)}
                              >{itemMovimiento.agosto}</button>}
                            </td>
                            <td>
                            {itemMovimiento.septiembre==''?<img src={process.env.PUBLIC_URL + '/dist/img/red-flag.png'} className="brand-image "  />:
                              <button
                                type="button"
                                id="bt"
                                name="bt" data-bs-toggle="modal" data-bs-target="#exampleModal" 
                                className={itemMovimiento.septiembre=="Pendiente"?"btn btn-danger btn-xs":"btn btn-success btn-xs"}
                                onClick={() => this.Mostrar(itemMovimiento.idseptiembre)}
                              >{itemMovimiento.septiembre}</button>}
                            </td>
                            <td>
                            {itemMovimiento.octubre==''?<img src={process.env.PUBLIC_URL + '/dist/img/red-flag.png'} className="brand-image "  />:
                              <button
                                type="button"
                                id="bt"
                                name="bt"  data-bs-toggle="modal" data-bs-target="#exampleModal" 
                                className={itemMovimiento.octubre=="Pendiente"?"btn btn-danger btn-xs":"btn btn-success btn-xs"}
                                onClick={() => this.Mostrar(itemMovimiento.idoctubre)}
                              >{itemMovimiento.octubre}</button>}
                            </td>
                            <td>
                            {itemMovimiento.noviembre==''?<img src={process.env.PUBLIC_URL + '/dist/img/red-flag.png'} className="brand-image "  />:
                              <button
                                type="button"
                                id="bt"
                                name="bt" data-bs-toggle="modal" data-bs-target="#exampleModal" 
                                className={itemMovimiento.noviembre=="Pendiente"?"btn btn-danger btn-xs":"btn btn-success btn-xs"}
                                onClick={() => this.Mostrar(itemMovimiento.idnoviembre)}
                              >{itemMovimiento.noviembre}</button>}
                            </td>
                            <td>
                            {itemMovimiento.diciembre==''?<img src={process.env.PUBLIC_URL + '/dist/img/red-flag.png'} className="brand-image "  />:
                              <button
                                type="button"
                                id="bt"
                                name="bt" data-bs-toggle="modal" data-bs-target="#exampleModal" 
                                className={itemMovimiento.diciembre=="Pendiente"?"btn btn-danger btn-xs":"btn btn-success btn-xs"}
                                onClick={() => this.Mostrar(itemMovimiento.iddiciembre)}
                              >{itemMovimiento.diciembre}</button>}
                            </td>
                            
                            <td>
                              
                                <input type="checkbox" checked={checkboxIds.includes(itemMovimiento.id_paciente)}
                                  onChange={() => this.handleCheckboxChange(itemMovimiento.id_paciente)} />
                              
                            </td>
                            <td>
                            <button
                                type="button"
                                id="bt"
                                name="bt"   data-bs-toggle='modal' data-bs-target='#asignarPaciente'
                                className="btn btn-info btn-sm"
                                onClick={() => this.Historial(itemMovimiento.id_paciente)}
                              >[+]</button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div>
                      <p>IDs seleccionados: {checkboxIds.join(', ')}</p>
                    </div>
                  </div>

                </div>
                <div className="card-footer text-muted">

                  <b> | Total de Alquileres:</b> <span class="badge bg-warning">{tf}</span> <button
                    type="button"
                    id="bt"
                    name="bt"
                    className="btn btn-primary btn-sm"
                    onClick={() => this.Repetir()}
                  >Repetir Alquiler</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Master;
