import React from "react";
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import Formulario from "./Crear"

import FormularioEnf1 from "./Enfermedades1"
import FormularioEnf2 from "./Enfermedades2"

import EditarFormulario from "./Editar"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faUpload, faFileUpload, faUserPlus, faSignOutAlt, faSave, faViruses } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2';

class Listar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datosCargados: false,
      editar: 'No',
      ver:'No',
      numero_doc: '',
      query: [],
      pagina: [],
      empresas: [],
      pacienteinfo:[],
      atenciones:[]
    };
  }
  cargarEmpresas() {
    fetch(Api + "Empresas")
      .then(respuesta => respuesta.json())
      .then((datosEmpresas) => {
        //console.log(datosEmpresas.data);
        this.setState({ empresas: datosEmpresas.data })
      })
      .catch(console.log);
  }
  borrarRegistros = (id) => {
    //console.log(id);
    Swal.fire({
      title: 'Estas seguro de eliminar el registro?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Borrar',
      denyButtonText: `No borrar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        fetch(Api + "DelPacientes/" + id)
          .then((respuesta) => respuesta.json())
          .then((datosRespuesta) => {
            console.log(datosRespuesta);
            Swal.fire('Borrado!', datosRespuesta.msj, 'success')
            this.Buscar(1);
          })
          .catch(console.log);
      } else if (result.isDenied) {
        Swal.fire('Cancelado', '', 'info')
      }
    })
  };
  EditRegistros = async (idp) => {
    this.setState({ editar: 'Si' })
    //console.log('dato:'+idp);
    await fetch(Api + "Pacientes/" + idp,
      {
        method: "GET",
        headers: new Headers({
          'Authorization': 'koala2021'
        }),
      })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        //console.log(datosRespuesta);
        this.setState({ datosCargados: true, paciente: datosRespuesta });
        document.getElementById('fin').value = datosRespuesta.fecha_reg;
        document.getElementById('fde').value = datosRespuesta.fecha_mod;
        document.getElementById('uat').value = datosRespuesta.ultima_atencion;
        document.getElementById('ual').value = datosRespuesta.ultimo_alquiler;
        document.getElementById('id_paciente').value = datosRespuesta.id_paciente;
        document.getElementById('alta').value = datosRespuesta.alta;
        document.getElementById('documento').value = datosRespuesta.documento;
        document.getElementById('numero_doc').value = datosRespuesta.numero_doc;
        document.getElementById('sexo').value = datosRespuesta.sexo;
        document.getElementById('nombres').value = datosRespuesta.nombres;
        document.getElementById('nombre2').value = datosRespuesta.nombre2;
        document.getElementById('apellidos').value = datosRespuesta.apellidos;
        document.getElementById('apellido2').value = datosRespuesta.apellido2;
        document.getElementById('fecha_nacimiento').value = datosRespuesta.fecha_nacimiento;
        document.getElementById('edad').value = datosRespuesta.edad;
        document.getElementById('civil').value = datosRespuesta.civil;
        document.getElementById('ocupacion').value = datosRespuesta.ocupacion;
        document.getElementById('empresa').value = datosRespuesta.id_empresa;
        document.getElementById('estado').value = datosRespuesta.estado;
        document.getElementById('regimen').value = datosRespuesta.regimen;
        document.getElementById('tipo_s').value = datosRespuesta.tipo_s;
        document.getElementById('departamento').value = datosRespuesta.departamento;
        document.getElementById('municipio').value = datosRespuesta.municipio;
        document.getElementById('zona').value = datosRespuesta.zona;
        document.getElementById('direccion1').value = datosRespuesta.direccion1;
        document.getElementById('barrio').value = datosRespuesta.barrio;
        document.getElementById('telefono1').value = datosRespuesta.tel_1;
        document.getElementById('celular').value = datosRespuesta.celular;
        document.getElementById('email1').value = datosRespuesta.email1;
        document.getElementById('nombre_familiar').value = datosRespuesta.nombre_acudiente;
        document.getElementById('telefono_familiar').value = datosRespuesta.telefono_acudiente;
        document.getElementById('parentesco').value = datosRespuesta.parentesco;
        document.getElementById('nombre_acudiente').value = datosRespuesta.cedula_acudiente;
        document.getElementById('telefono_acudiente').value = datosRespuesta.dir_pariente;
        document.getElementById('parentesco').value = datosRespuesta.parentesco;
        document.getElementById('parentesco2').value = datosRespuesta.parentesco2;
        document.getElementById('enfermedad').value = datosRespuesta.enfermedad;
        document.getElementById('descripcion_enf').value = datosRespuesta.descripcion_enf;
        document.getElementById('enfermedad2').value = datosRespuesta.enfermedad2;
        document.getElementById('descripcion_enf2').value = datosRespuesta.descripcion_enf2;
        document.getElementById('deposito').value = datosRespuesta.deposito_alq;
        document.getElementById('subcodigo').value = datosRespuesta.subcodigo;
        document.getElementById('prof_eps').value = datosRespuesta.prof_eps;
        document.getElementById('contrato').value = datosRespuesta.contrato;
        document.getElementById('covid').value = datosRespuesta.covid;
        document.getElementById('observacion_covid').value = datosRespuesta.obs_covid;
        this.setState({ numero_doc: datosRespuesta.numero_doc });
      })
      .catch(console.log);
    fetch(Api + "CondicionesPaciente/" + this.state.numero_doc,
      {
        method: "GET",
        headers: new Headers({
          'Authorization': 'koala2021'
        }),
      })
      .then((respuestaCond) => respuestaCond.json())
      .then((datosRespuestaCond) => {
        //console.log(datosRespuestaCond[0]);
        this.setState({ condiciones: datosRespuestaCond });
        document.getElementById('cedula_cond').value = datosRespuestaCond[0].cedula;
        document.getElementById('cond1').value = datosRespuestaCond[0].cond1;
        document.getElementById('cond2').value = datosRespuestaCond[0].cond2;
        document.getElementById('cond3').value = datosRespuestaCond[0].cond3;
        document.getElementById('cond4').value = datosRespuestaCond[0].cond4;
        document.getElementById('cond5').value = datosRespuestaCond[0].cond5;
        document.getElementById('cond6').value = datosRespuestaCond[0].cond6;
        document.getElementById('cond7').value = datosRespuestaCond[0].cond7;
        document.getElementById('cond8').value = datosRespuestaCond[0].cond8;
        document.getElementById('cond9').value = datosRespuestaCond[0].cond9;
        document.getElementById('cond10').value = datosRespuestaCond[0].cond10;
        document.getElementById('cond11').value = datosRespuestaCond[0].cond11;
        document.getElementById('obs1').value = datosRespuestaCond[0].obs1;
        document.getElementById('obs2').value = datosRespuestaCond[0].obs2;
        document.getElementById('obs3').value = datosRespuestaCond[0].obs3;
        document.getElementById('obs4').value = datosRespuestaCond[0].obs4;
        document.getElementById('obs5').value = datosRespuestaCond[0].obs5;
        document.getElementById('obs6').value = datosRespuestaCond[0].obs6;
        document.getElementById('obs7').value = datosRespuestaCond[0].obs7;
        document.getElementById('obs8').value = datosRespuestaCond[0].obs8;
        document.getElementById('obs9').value = datosRespuestaCond[0].obs9;
        document.getElementById('obs10').value = datosRespuestaCond[0].obs10;
        document.getElementById('obs11').value = datosRespuestaCond[0].obs11;
      })
      .catch(console.log)
    fetch(Api + "EncuestasPaciente/" + this.state.numero_doc,
      {
        method: "GET",
        headers: new Headers({
          'Authorization': 'koala2021'
        }),
      })
      .then((respuestaEnc) => respuestaEnc.json())
      .then((datosRespuestaEnc) => {
        //console.log(datosRespuestaEnc[0]);
        this.setState({ encuesta: datosRespuestaEnc });
        document.getElementById('cedula_enc').value = datosRespuestaEnc[0].cedula;
        document.getElementById('enc1').value = datosRespuestaEnc[0].enc1;
        document.getElementById('enc2').value = datosRespuestaEnc[0].enc2;
        document.getElementById('enc3').value = datosRespuestaEnc[0].enc3;
        document.getElementById('enc4').value = datosRespuestaEnc[0].enc4;
        document.getElementById('enc5').value = datosRespuestaEnc[0].enc5;
        document.getElementById('enc6').value = datosRespuestaEnc[0].enc6;
        document.getElementById('enc7').value = datosRespuestaEnc[0].enc7;
        document.getElementById('enc8').value = datosRespuestaEnc[0].enc8;
        document.getElementById('enc9').value = datosRespuestaEnc[0].enc9;
        document.getElementById('enc10').value = datosRespuestaEnc[0].enc10;
        document.getElementById('enc11').value = datosRespuestaEnc[0].enc11;
        document.getElementById('enc12').value = datosRespuestaEnc[0].enc12;
        document.getElementById('enc13').value = datosRespuestaEnc[0].enc13;
        document.getElementById('enc14').value = datosRespuestaEnc[0].enc14;
        document.getElementById('enc15').value = datosRespuestaEnc[0].enc15;
        document.getElementById('enc16').value = datosRespuestaEnc[0].enc16;
        document.getElementById('enc17').value = datosRespuestaEnc[0].enc17;
        document.getElementById('enc18').value = datosRespuestaEnc[0].enc18;
        document.getElementById('enc19').value = datosRespuestaEnc[0].enc19;
        document.getElementById('enc20').value = datosRespuestaEnc[0].enc20;
        document.getElementById('enc21').value = datosRespuestaEnc[0].enc21;
        document.getElementById('enc22').value = datosRespuestaEnc[0].enc22;
        document.getElementById('enc23').value = datosRespuestaEnc[0].enc23;
      })
      .catch(console.log)
  }
  
  componentDidMount() {
    this.Buscar(1);
    this.cargarEmpresas()
  }
  Buscar(page) {
    let btipo = document.getElementById('buscar').value;
    let bname = document.getElementById('bnombre').value;
    let bemp = document.getElementById('bempresa').value;
    console.log("Formulario Enviado...");
    if (btipo == '') {
      btipo = 0;
    }
    if (bname == '') {
      bname = 0;
    }
    fetch(Api + "SearchPacientes/" + btipo + "/" + bname + "/" + bemp + "?page=" + page)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {

        this.setState({ datosCargados: true, query: datosRespuesta.data, pagina: datosRespuesta.total, ultimo: datosRespuesta.last_page, actual: datosRespuesta.current_page });
      })
      .catch(console.log);
  }
  render() {
    const { datosCargados, paciente, query, pagina, ultimo, actual, empresas, editar,pacienteinfo, ver,atenciones } = this.state;
    if (!datosCargados) {
      var load = 'Cargando datos....';
    } else {
      var load = '';
    }
    return (
      <div className="content">
        <div class="modal fade" id="nuevaEnfermedad1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevaEnfermedad1Label" aria-hidden="true">
          <FormularioEnf1 />
        </div>
        <div className="modal fade" id="nuevoPacienteSub" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
          {
            editar === "No" ? (
              <Formulario />
            ) : (
              <EditarFormulario usuario={paciente} func={this.Buscar} />
            )
          }
        </div>
        
        <div class="modal fade" id="nuevaEnfermedad2" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevaEnfermedad2Label" aria-hidden="true">
          <FormularioEnf2 />
        </div>
        
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <div className="col-xl-12">
                <form style={{ float: 'right' }} className="row g-3">
                  <div className="col-md-12">
                    <button className='btn btn-success btn-sm' id='btnSubirOrdenes' type='button' data-bs-toggle='modal' data-bs-target='#subirOrdenes'><FontAwesomeIcon icon={faFileUpload} />&nbsp;&nbsp;Subir Orden Interna</button>&nbsp;&nbsp;
                    <button style={{ color: '#FFFFFF' }} className='btn btn-info btn-sm' id='btnSubirArchivo' type='button' data-bs-toggle='modal' data-bs-target='#subirArchivos'><FontAwesomeIcon icon={faUpload} />&nbsp;&nbsp;Subir Archivos</button>&nbsp;&nbsp;
                    <button className='btn btn-primary btn-sm' id='btnNuevoPacienteSub' type='button' data-bs-toggle='modal' data-bs-target='#nuevoPacienteSub' onClick={() => (this.setState({ editar: 'No' }))}><FontAwesomeIcon icon={faUserPlus} />&nbsp;&nbsp;Nuevo Paciente Sub.</button>
                    {/*<button className='btn btn-primary btn-sm' id='btnPacienteCovid' type='button' data-bs-toggle='modal' data-bs-target='#pacienteCovid'><FontAwesomeIcon icon={faViruses} />&nbsp;&nbsp;Paciente Covid-19</button>*/}
                  </div>
                </form>
              </div>
            </div>
            <div className="card-body">
              <h4>Lista de Pacientes</h4>
              <div className="row">

                <div className="col-xl-3">

                  <input type="text" id="buscar" name="buscar" placeholder="Cedula del Paciente" className="form-control form-control-sm" />

                </div>
                <div className="col-xl-3">

                  <input type="text" id="bnombre" name="bnombre" placeholder="Nombre del Paciente" className="form-control form-control-sm" />
                </div>

                <div className="col-xl-3">
                  <select className="form-select form-select-sm" name="bempresa" id="bempresa" placeholder="" aria-describedby="helpId" >
                    <option value="0">Todas las EPS</option>
                    {
                      empresas.map(itemEmpresa => (
                        <option key={itemEmpresa.id_empresa} value={itemEmpresa.rips}>{itemEmpresa.nombre_emp}</option>
                      ))
                    }
                  </select>
                </div>
                <div className="col-xl-3">

                  <input type="button" id="bt" name="bt" className="btn btn-primary btn-sm" value="Filtrar" onClick={() => this.Buscar(1)} />
                </div>
              </div>
              <br></br>
              <div className="row">
                <table className="table table-hover table-condensed table-sm">
                  <thead className="thead-inverse">
                    <tr>
                      <th>IDENTIFICACIÓN</th>
                      <th>NOMBRE PACIENTE</th>
                      <th>EMPRESA</th>
                      <th>TELÉFONO</th>
                      <th>ESTADO</th>
                      <th>ACCIONES</th>
                    </tr>
                  </thead>
                  <tbody>
                    {load}
                    {query.map((empleado) => (
                      <tr key={empleado.id_paciente}>
                        <td>{empleado.numero_doc}</td>
                        <td>{empleado.nombres} {empleado.apellidos}</td>
                        <td>{empleado.id_empresa}</td>
                        <td>{empleado.celular}</td>
                        <td>
                          {
                            empleado.estado === "No Activo" ? (
                              <span style={{ fontSize: 11, textTransform: 'uppercase' }} className="badge bg-danger">{empleado.estado}</span>
                            ) : (
                              <span style={{ fontSize: 11, textTransform: 'uppercase' }} className="badge bg-success">{empleado.estado}</span>
                            )
                          }
                        </td>
                        <td>
                          <div
                            className="btn-group"
                            role="group"
                            aria-label=""
                          >
                            <Link
                              className="btn btn-primary btn-sm"
                              to={"/InfoPacientes/" + empleado.id_paciente}
                            >
                              <i class="fas fa-info-circle"></i>
                            </Link>&nbsp;&nbsp;
                            
                            <button
                              type="button"
                              className="btn btn-warning btn-sm"
                              data-bs-toggle='modal' data-bs-target='#nuevoPacienteSub'
                              onClick={() =>
                                this.EditRegistros(empleado.id_paciente)
                              }
                            >
                              <i className="fas fa-edit"></i>
                            </button>&nbsp;&nbsp;
                            <button
                              type="button"
                              className="btn btn-danger btn-sm"
                              onClick={() =>
                                this.borrarRegistros(empleado.id_paciente)
                              }
                            >
                              <i className="far fa-trash-alt"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className="page-item"><a className="page-link" href="#" onClick={() => this.Buscar(1)}>Atras</a></li>
                  {(() => {
                    let p = [];
                    if (ultimo >= 20) {
                      var ult = 20;
                    } else {
                      ult = ultimo;
                    }
                    for (let i = 1; i <= ult; i++) {

                      p.push(<li className="page-item" key={i}><a className="page-link" href="#" onClick={() => this.Buscar(i)}>{i}</a></li>);
                    }
                    return p;
                  })()}

                  <li className="page-item"><a className="page-link" href="#" onClick={() => this.Buscar(ultimo)}>Siguiente</a></li>
                </ul>
                Pagina {actual} de {ultimo} Registros {pagina}
              </nav>
            </div>
          </div>
        </div>
      </div>


    );
  }
}


export default Listar;
