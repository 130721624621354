import React from 'react';
import Api from "../../servicios/api";
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes, faArrowRight, faAngleDoubleLeft, faAngleLeft, faAngleDoubleRight, faAngleRight, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

class ItemInsumos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datosCargados: false,
            id_mi: '',
            pagina: [],
            insumos: []
        }
    }
    resetForm() {
        //console.log("Entra acá");
        document.getElementById('bcodigo').value = "";
        document.getElementById('bnombre').value = "";
        document.getElementById('bcodigo').focus();
        this.BuscarInsumo(1);
    }
    onCloseModal() {
        window.$("#nuevoItemInsumo").modal('hide');
    }
    enviarInsumo = async (idInsumo, codInsumo, descripcionInsumo, precioInsumo) => {
        const id_mov = document.getElementById('mov').value;
        const id_ope = document.getElementById('id_ope').value;
        const bod = document.getElementById('bod').value;
        const cant_insumo = document.getElementById('cant_insumo' + idInsumo).value;
        var datosEnviar = {
            id: id_mov,
            id_ope: id_ope,
            bod: bod,
            cantidad: cant_insumo,
            idInsumo: idInsumo,
            codigo: codInsumo,
            descripcionInsumo: descripcionInsumo,
            precio: precioInsumo,
            usuario: window.localStorage.getItem('k_usuario')
        }
        //console.log(datosEnviar);
        await fetch(Api + "AddMovimientosDetalle", {
            method: "POST",
            body: JSON.stringify(datosEnviar)
        })
            .then(respuesta => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                Swal.fire(
                    'Item Movimiento!',
                    datosRespuesta.msj,
                    'success'
                )
                //console.log(datosRespuesta.id_mi);
                this.setState({ id_mi: datosRespuesta.id_mi });
                //console.log(this.state.id_mi);
            })
            .catch(console.log)
        const insumo = {
            id_mi: this.state.id_mi,
            id_ins: idInsumo,
            codigo: codInsumo,
            nn: descripcionInsumo,
            cant: cant_insumo,
            precio_ins: precioInsumo
        }
        //console.log(insumo);
        this.props.agregarItem(insumo);
        //console.log(codInsumo + " - " + descripcionInsumo);
    }
    componentDidMount() {
        this.BuscarInsumo(1);
    }
    BuscarInsumo(page) {
        let bcodigo = document.getElementById('bcodigo').value;
        let bnombre = document.getElementById('bnombre').value;
        if (bcodigo == '') {
            bcodigo = 0;
        }
        if (bnombre == '') {
            bnombre = 0;
        }
        fetch(Api + "SearchInsumos/" + bcodigo + "/" + bnombre + "?page=" + page)
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                this.setState({ datosCargados: true, insumos: datosRespuesta.data, pagina: datosRespuesta.total, ultimo: datosRespuesta.last_page, actual: datosRespuesta.current_page });
            })
            .catch(console.log);
    }
    render() {
        const { datosCargados, insumos, pagina, ultimo, actual } = this.state;
        if (!datosCargados) {
            var load = 'Cargando datos....';
        } else {
            var load = '';
        }
        return (
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="nuevoItemLabel">Registro de Insumos</h5>
                        {/*<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>*/}
                    </div>
                    <div className="modal-body">
                        <div className="card">
                            <div style={{ backgroundImage: 'linear-gradient(#2FB3FB, #003153)', color: '#FFFFFF', borderTopLeftRadius: 5, borderTopRightRadius: 5 }} className="card-header text-center">
                                <h5 style={{ fontSize: 18 }} >Lista de Insumos</h5>
                            </div>
                            <div className='card-body'>
                                <div className="row">
                                    <div className="col-xl-2">
                                        <input type="text" id="bcodigo" name="bcodigo" placeholder="Código" className="form-control form-control-sm" />
                                    </div>
                                    <div className="col-xl-6">
                                        <input type="text" id="bnombre" name="bnombre" placeholder="Nombre" className="form-control form-control-sm" />
                                    </div>
                                    <div className="col-xl-4">
                                        <button type="button" className="btn btn-primary btn-sm" onClick={() => this.BuscarInsumo(1)}><FontAwesomeIcon icon={faSearch} />&nbsp;&nbsp;Buscar</button>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-danger btn-sm" onClick={() => this.resetForm()} ><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <table className="table table-hover table-condensed table-sm">
                                        <thead className="thead-inverse">
                                            <tr>
                                                <th>CÓDIGO</th>
                                                <th>DESCRIPCIÓN</th>
                                                <th>PRECIO</th>
                                                <th>CANTIDAD</th>
                                                <th>SELECCIONAR</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {load}
                                            {insumos.map((itemInsumo) => (
                                                <tr key={itemInsumo.id}>
                                                    <td>{itemInsumo.codigo}</td>
                                                    <td>{itemInsumo.nombre_insumo}</td>
                                                    <td>{itemInsumo.precio_ins}</td>
                                                    <td><input type="number" name={"cant_insumo" + itemInsumo.id} id={"cant_insumo" + itemInsumo.id} /></td>
                                                    <td>
                                                        <div className="btn-group" role="group" aria-label="">
                                                            <button type="button" className="btn btn-primary btn-sm" onClick={() => this.enviarInsumo(itemInsumo.id, itemInsumo.codigo, itemInsumo.nombre_insumo, itemInsumo.precio_ins)}><FontAwesomeIcon icon={faArrowRight} /></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row">
                                    <div className='col-xs-12'>
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination">
                                                <li className="page-item"><a className="page-link" href="#" onClick={() => this.BuscarInsumo(1)}><FontAwesomeIcon icon={faAngleDoubleLeft} /></a></li>
                                                <li className="page-item"><a className="page-link" href="#" onClick={() => this.BuscarInsumo(actual - 1)}><FontAwesomeIcon icon={faAngleLeft} /></a></li>
                                                {(() => {
                                                    let p = [];
                                                    /*if (ultimo >= 86) {
                                                        var ult = 86;
                                                    } else {
                                                        ult = ultimo;
                                                    }
                                                    for (let i = 1; i <= ult; i++) {
                                                        p.push(<li className="page-item" key={i}><a className="page-link" href="#" onClick={() => this.BuscarEnf2(i)}>{i}</a></li>);
                                                    }*/
                                                    p.push(<li className="page-item" key={actual}><a className="page-link" href="#" onClick={() => this.BuscarInsumo(actual)}>{actual}</a></li>);
                                                    return p;
                                                })()}
                                                <li className="page-item"><a className="page-link" href="#" onClick={() => this.BuscarInsumo(actual + 1)}><FontAwesomeIcon icon={faAngleRight} /></a></li>
                                                <li className="page-item"><a className="page-link" href="#" onClick={() => this.BuscarInsumo(ultimo)}><FontAwesomeIcon icon={faAngleDoubleRight} /></a></li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <div className="card-footer text-muted">
                                        <b>Pagina</b> <span class="badge bg-primary">{actual}</span> <b>de</b> <span class="badge bg-primary">{ultimo}</span> | <b>Registros</b> <span class="badge bg-success">{pagina}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn btn-secondary btn-sm" onClick={this.onCloseModal}><FontAwesomeIcon icon={faSignOutAlt} />&nbsp;&nbsp;Cerrar</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ItemInsumos;