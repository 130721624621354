import React from 'react';
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import Swal from 'sweetalert2';
class Crear extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            codigo: "",
            nombre: "",
            tipo: "",
            cuenta: "",
            forma: "",
            errores: []
        }
    }
    cambioValor = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState({ state, errores: [] })
    }
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    enviarDatos = (e) => {
        e.preventDefault();
        console.log("Formulario Enviado...");
        const { nombre, tipo, codigo, cuenta, forma } = this.state;
        var errores = [];
        if (!codigo) { errores.push("error_codigo"); }
        if (!nombre) { errores.push("error_nombre"); }
        if (!tipo) { errores.push("error_tipo"); }
        if (!forma) { errores.push("error_forma"); }
        if (!cuenta) { errores.push("error_cuenta"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        var datosEnviar = { nombre: nombre, tipo: tipo, codigo: codigo, cuenta: cuenta, forma: forma }
        fetch(Api + "AddEnfermedades", {
            method: "POST",
            body: JSON.stringify(datosEnviar)
        })
            .then(respuesta => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                Swal.fire(
                    'Enfermedades!',
                    datosRespuesta.msj,
                    'success'
                )
                this.props.history.push("/MasterEnfermedades");
            })
            .catch(console.log)
    }
    render() {
        const { nombre, tipo, codigo, cuenta, forma } = this.state;
        return (
            <div className="content-wrapper">
                <div className="content">
                    <div className="container-fluid">
                        <br />
                        <div className="card">
                            <div className="card-header">
                                Registro de Enfermedades
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.enviarDatos}>
                                    <div className="form-group">
                                        <label htmlFor="codigo">Código</label>
                                        <input className={((this.verificarError("error_codigo")) ? "is-invalid" : "") + " form-control form-control-sm"} maxLength={4} type="text" name="codigo" id="codigo" value={codigo} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                        <small id="helpId" className="invalid-feedback">Digita el Código</small>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="nombre">Nombre</label>
                                        <input className={((this.verificarError("error_nombre")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="nombre" id="nombre" value={nombre} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                        <small id="helpId" className="invalid-feedback">Digita el Nombre</small>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="tipo">Sexo</label>
                                        <select name="tipo"
                                            id="tipo"
                                            className={((this.verificarError("error_tipo")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                            onChange={this.cambioValor}>
                                            <option value="">Seleccione el Sexo</option>
                                            <option value="A">Ambos</option>
                                            <option value="F">Femenino</option>
                                            <option value="M">Masculino</option>
                                        </select>
                                        <small id="helpId" className="invalid-feedback">Seleccione el Sexo que Aplica</small>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="forma">Límite Inferior</label>
                                        <input className={((this.verificarError("error_forma")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="forma" id="forma" value={forma} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                        <small id="helpId" className="invalid-feedback">Digite el Límite Inferior</small>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="cuenta">Límite Superior</label>
                                        <input className={((this.verificarError("error_cuenta")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="cuenta" id="cuenta" value={cuenta} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                        <small id="helpId" className="invalid-feedback">Digita el Límite Superior</small>
                                    </div>
                                    <br />
                                    <div className="btn-group" role="group" aria-label="">
                                        <button type="submit" className="btn btn-success btn-sm">Agregar</button>&nbsp;&nbsp;
                                        <Link className="btn btn-danger btn-sm" to={"/MasterEnfermedades"}>Cancelar</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Crear;