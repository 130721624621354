import React from "react";
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import Formulario from "./Crear"
import EditarFormulario from "./Editar"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faUpload, faFileUpload, faUserPlus, faSignOutAlt, faSave, faViruses } from '@fortawesome/free-solid-svg-icons'

import Swal from 'sweetalert2';

class Listar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datosCargados: false,
      editar: 'No',
      ciudad: '',
      query: [],
      pagina: [],
    };
  }
  borrarRegistros = (id) => {
    console.log(id);
    Swal.fire({
      title: 'Estas seguro de eliminar el registro?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Borrar',
      denyButtonText: `No borrar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        fetch(Api + "DelUsuarios/" + id)
          .then((respuesta) => respuesta.json())
          .then((datosRespuesta) => {
            console.log(datosRespuesta);
            Swal.fire('Borrado!', datosRespuesta.msj, 'success')
            this.Buscar(1);
          })
          .catch(console.log);
      } else if (result.isDenied) {
        Swal.fire('Cancelado', '', 'info')
      }
    })


  };
  EditRegistros = (idp) => {
    this.setState({ editar: 'Si' })
    console.log('dato:' + idp);

    fetch(Api + "Usuarios/" + idp,
      {
        method: "GET",
        headers: new Headers({
          'Authorization': 'koala2021'
        }),
      })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
       // this.setState({ datosCargados: true, empleado: datosRespuesta, foto: datosRespuesta.foto, firma: datosRespuesta.ruta });
        document.getElementById('id').value = datosRespuesta.id;
        document.getElementById('cedula').value = datosRespuesta.cedula;
        document.getElementById('nombre').value = datosRespuesta.nombre;
        document.getElementById('apellido').value = datosRespuesta.apellido;
        document.getElementById('email').value = datosRespuesta.email;
        document.getElementById('fecha').value = datosRespuesta.descripcion;
        document.getElementById('firma_hidden').value = datosRespuesta.ruta;
        document.getElementById('foto_hidden').value = datosRespuesta.foto;
        document.getElementById('telefono').value = datosRespuesta.telefono;
        document.getElementById('celular').value = datosRespuesta.celular;
        document.getElementById('direccion').value = datosRespuesta.direccion;
        document.getElementById('barrio').value = datosRespuesta.barrio_u;
        document.getElementById('depa').value = datosRespuesta.ciudad;
        document.getElementById('muni').value = datosRespuesta.municipio;
        document.getElementById('user').value =  datosRespuesta.usuario;
        document.getElementById('pass').value = '';
        document.getElementById('cargo').value = datosRespuesta.cargo;
        document.getElementById('area').value = datosRespuesta.area;
        document.getElementById('rol').value = datosRespuesta.id_roles;
        document.getElementById('admin').value = datosRespuesta.administrador;
        document.getElementById('esta').value = datosRespuesta.estado_empleado;

      })
      .catch(console.log);
  }
  componentDidMount() {
    this.Buscar(1);
  }
  Buscar(page) {


    let btipo = document.getElementById('buscar').value;
    let bname = document.getElementById('bnombre').value;
    let besta = document.getElementById('bestado').value;
    console.log("Formulario Enviado...");
    if (btipo == '') {
      btipo = 0;
    }
    if (bname == '') {
      bname = 0;
    }
    fetch(Api + "SearchUsuarios/" + btipo + "/" + bname + "/" + besta + "?page=" + page)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
        this.setState({ datosCargados: true, query: datosRespuesta.data, pagina: datosRespuesta.total, ultimo: datosRespuesta.last_page, actual: datosRespuesta.current_page });
      })
      .catch(console.log);
  }
  render() {
    const { datosCargados, empleado, query, pagina, ultimo, actual, editar, ciudad, firma, foto } = this.state;
    if (!datosCargados) {
      var load = 'Cargando datos....';
    } else {
      var load = '';
    }


    return (

      <div className="content">
        <div className="modal fade" id="nuevoRegistro" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
          {
            editar === "No" ? (
              <Formulario />
            ) : (
              <EditarFormulario usuario={empleado} firma={firma} foto={foto} />
            )
          }
        </div>
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">

              <button className='btn btn-primary btn-sm' id='btnNuevoPacienteSub' type='button' data-bs-toggle='modal' data-bs-target='#nuevoRegistro' onClick={() => (this.setState({ editar: 'No' }))}><FontAwesomeIcon icon={faUserPlus} />&nbsp;&nbsp;Nuevo Usuario.</button>
            </div>
            <div className="card-body">
              <h4>Lista de Usuarios</h4>
              <div className="row">

                <div className="col-xl-3">

                  <input type="text" id="buscar" name="buscar" placeholder="Usuario" className="form-control form-control-sm" />

                </div>
                <div className="col-xl-3">

                  <input type="text" id="bnombre" name="bnombre" placeholder="Nombre y Apellido" className="form-control form-control-sm" />
                </div>
                <div className="col-xl-3">

                  <select className="form-select form-select-sm" type="text" name="bestado" id="bestado" placeholder="" aria-describedby="helpId"  >

                    <option value="Activo">Activo</option>
                    <option value="No Activo">No Activo</option>

                  </select>

                </div>
                <div className="col-xl-3">

                  <input type="button" id="bt" name="bt" className="btn btn-primary btn-sm" value="Filtrar" onClick={() => this.Buscar(1)} />
                </div>

              </div>
              <br></br>
              <div className="row">
                <table className="table table-hover table-condensed table-sm">
                  <thead className="thead-inverse">
                    <tr>
                      <th>USUARIO</th>
                      <th>NOMBRE</th>
                      <th>TELÉFONO</th>
                      <th>CARGO</th>
                      <th>ESTADO</th>
                      <th>ACCIONES</th>
                    </tr>
                  </thead>
                  <tbody>
                    {load}
                    {query.map((empleado) => (
                      <tr key={empleado.id}>
                        <td>{empleado.usuario}</td>
                        <td>{empleado.nombre} {empleado.apellido}</td>
                        <td>{empleado.celular}</td>
                        <td>{empleado.cargo}</td>
                        <td>
                          {
                            empleado.estado_empleado === "No Activo" ? (
                              <span style={{ fontSize: 11, textTransform: 'uppercase' }} className="badge bg-danger">{empleado.estado_empleado}</span>
                            ) : (
                              <span style={{ fontSize: 11, textTransform: 'uppercase' }} className="badge bg-success">{empleado.estado_empleado}</span>
                            )
                          }
                        </td>
                        <td>
                          <div
                            className="btn-group"
                            role="group"
                            aria-label=""
                          >

                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                              data-bs-toggle='modal' data-bs-target='#nuevoRegistro'
                              onClick={() =>
                                this.EditRegistros(empleado.id)
                              }
                            >
                              <i className="fas fa-edit"></i>
                            </button>&nbsp;&nbsp;
                            <button
                              type="button"
                              className="btn btn-danger btn-sm"
                              onClick={() =>
                                this.borrarRegistros(empleado.id)
                              }
                            >
                              <i className="far fa-trash-alt"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className="page-item"><a className="page-link" href="#" onClick={() => this.Buscar(1)}>Atras</a></li>
                  {(() => {
                    let p = [];
                    if (ultimo >= 20) {
                      var ult = 20;
                    } else {
                      ult = ultimo;
                    }
                    for (let i = 1; i <= ult; i++) {

                      p.push(<li className="page-item" key={i}><a className="page-link" href="#" onClick={() => this.Buscar(i)}>{i}</a></li>);
                    }
                    return p;
                  })()}

                  <li className="page-item"><a className="page-link" href="#" onClick={() => this.Buscar(ultimo)}>Siguiente</a></li>
                </ul>
                Pagina {actual} de {ultimo} Registros {pagina}
              </nav>
            </div>
            <div className="card-footer text-muted"></div>
          </div>
        </div>
      </div>


    );
  }
}


export default Listar;
