import React from "react";
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import FormUsuario from "../ordenes/UsuariosOrd"
import Formliquidacion from "../ordenes/Liquidacion"
import Swal from 'sweetalert2';
import axios from 'axios';
import $ from 'jquery';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleDoubleLeft,faAngleLeft,faAngleDoubleRight,faAngleRight,faSignOutAlt,faCheck,faLowVision} from "@fortawesome/free-solid-svg-icons";
const currentYear = new Date().getFullYear();
const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, '0');

class Listar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datosCargados: false,
      query: [],
      pagina:0, ultimo:0, actual:1,
      query2: [],
      pagina2:0, ultimo2:0, actual2:1,
      ano:currentYear,
      mes:currentMonth,
      cargados:0,
      firmada_ate:0,
      firmada_con:0,
      cantidad:0,
      evolucion:0,
      Orden:0,
      IdOrden:0
    };
    //this.Buscar(1);
    
  }
  VerAtencion = (orden) => {

      this.setState({ IdOrden: orden }, () => {
        // Puedes abrir el modal después de actualizar el estado
        //$('#modalliquidacion').modal('show'); // Asegúrate de que tienes jQuery y Bootstrap configurados
    });
  };
  VerEvolucion = (orden) => {

    window.open('https://idb.softmediko.com/resumen/evolucion.php?c od='+orden, 'popup', 'width=1000,height=800');

    
  };
  actualizar = () => {

    //alert("Ola mundo");
    var pag = document.getElementById('page').value;
        console.log('pagina '+pag);
      this.Buscar(pag);
  };
  componentDidMount() {
    //this.Buscar(1);
    /* const interval = setInterval(() => {
      // Llamar a la función deseada aquí
      const {actual2} = this.state;
      let asignado = document.getElementById('asignado').value;
      if(asignado!=''){
        var pag = document.getElementById('page').value;
        console.log('pagina '+pag);
      this.Buscar(pag);
      }
    }, 97000);  */
  }
  Buscar(page){
    var Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 2000
    });
    
    let orden = document.getElementById('buscar').value;
    let paciente = document.getElementById('bnombre').value;
    let asignado = document.getElementById('asignado').value;
    let f1 = document.getElementById('f1').value;
    let f2 = document.getElementById('f2').value;
    let estado = document.getElementById('estado_a').value;
    if(asignado==''){
      Toast.fire({
        icon: 'error',
        title: 'Debes de seleccionar el profesional!'
      });
      return false;
    }
    this.setState({ datosCargados: true });
    
    var datos = {usuario:asignado, orden:orden,paciente:paciente,f1:f1,f2:f2,estado:estado}
    console.log(datos);
    axios.post(Api +'liquidacion/atenciones?page='+page,datos)
  .then(response => {
    console.log(response.data);
    console.log("Formulario Enviado..."+response.data.current_page);
    document.getElementById('page').value = response.data.current_page;
        this.setState({ datosCargados: false, query: response.data.data, pagina:response.data.total, ultimo: response.data.last_page, actual: response.data.current_page });
  })
  .catch(error => {
    console.log(error);
  });

  
  }
  Exportar(){
    let a = document.getElementById('ano').value;
    let m = document.getElementById('mes').value;
    var fi = a+'-'+m+'-01';
    var ff = a+'-'+m+'-31';
    window.open('https://idb.softmediko.com/vistas/liquidacion/exportar.php?fi='+fi+'&ff='+ff, 'popup', 'width=400,height=200');

  }
  BuscarLiq(page){
    var Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000
    });
    let ano = document.getElementById('ano').value;
    let mes = document.getElementById('mes').value;
    let orden = document.getElementById('servicio').value;
    let pro = document.getElementById('pro').value;
   
 
    if(ano==''){
      Toast.fire({
        icon: 'error',
        title: 'Debes de digitar el año!'
      });
      return false;
    }
    if(mes==''){
      Toast.fire({
        icon: 'error',
        title: 'Debes de digitar el mes!'
      });
      return false;
    }
    
    console.log("Formulario Enviado...");
    var datos = {pro:pro, orden:orden,ano:ano,mes:mes}
    console.log(datos);
    axios.post(Api +'liquidacion/liquidaciones?page='+page,datos)
  .then(response => {
    console.log(response.data);
        this.setState({ cargados: 1, query2: response.data.data, pagina2:response.data.total, ultimo2: response.data.last_page, actual2: response.data.current_page });
  })
  .catch(error => {
    console.log(error);
  });

  
  }
  Activar(){
    var Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000
    });
    const { Orden} = this.state;
    let vence = document.getElementById('vencimiento').value;
    let acivar = document.getElementById('acivar').value;

   
 
    if(vence==''){
      Toast.fire({
        icon: 'error',
        title: 'Debes de digitar la fecha de vencimiento!'
      });
      return false;
    }
    if(acivar=='0'){
      Toast.fire({
        icon: 'error',
        title: 'Para activar la atencion debes de selecionar si!'
      });
      return false;
    }
    
    console.log("Formulario Enviado...");
    var datos = {vence:vence, orden:Orden}
    console.log(datos);
    axios.post(Api +'liquidacion/activaratencion',datos)
  .then(response => {
    console.log(response.data);
      alert("Se ha activado con exito");
  })
  .catch(error => {
    console.log(error);
  });

  
  }
  Auditar(orden){
   

    var datos = {orden:orden}
    console.log(datos);
    axios.post(Api +'liquidacion/auditarfirmas',datos)
  .then(response => {
    console.log(response.data);
      var da = response.data;
        this.setState({Orden:orden, firmada_ate : da.atenciones_firmadas,firmada_con : da.consentimiento, cantidad:da.cantidad, evolucion:da.evolucion});
  })
  .catch(error => {
    console.log(error);
  });

  
  }

  render() {
      const { datosCargados, query,pagina, ultimo, actual, query2,pagina2, ultimo2, actual2 ,ano,mes,cargados,firmada_ate,firmada_con, cantidad, evolucion,Orden,IdOrden} = this.state;
    if (datosCargados==false) {
       var load = '';
    } else {
       var load = 'Cargando datos....';
    }
       
    
      return (
        
          <div className="content">
            <div className="container-fluid">
              <div className="card">
               
                <div className="card-body">
                  <h4>Lista de Atenciones a liquidar</h4> 
                  <div className="row">
                  <div className="col-xl-2">
                  <label className="label">Profesional</label>
                  <div className="input-group mb-3">
                                        <input type="text" className="form-control form-control-sm" id="asignado" placeholder="" readonly  />
                                        <div className="input-group-append">
                                            <button className="btn btn-success btn-sm" type="button" data-bs-toggle='modal' data-bs-target='#usuarioAsignado'>Buscar</button>
                                        </div>
                                        </div>
                                        </div>
                      <div className="col-xl-2">
                      <label className="label">Orden Interna</label>
                      <input type="text" id="buscar" name="buscar" placeholder="Orden Interna" className="form-control form-control-sm"  />

                      </div>
                      <div className="col-xl-2">
                      <label className="label">Nombre del paciente</label>
                          <input type="text" id="bnombre" name="bnombre" placeholder="Paciente" className="form-control form-control-sm"  />
                      </div>
                      <div className="col-xl-2">
                      <label className="label">Fecha Inicial</label>
                      <input type="date" id="f1" name="f1" placeholder="Orden Interna" className="form-control form-control-sm"  />

                      </div>
                      <div className="col-xl-2">
                      <label className="label">Fecha Final</label>
                      <input type="date" id="f2" name="f2" placeholder="Orden Interna" className="form-control form-control-sm"  />

                      </div>
                      <div className="col-xl-2">
                      <label className="label">Estado</label>
                      <select id="estado_a" name="estado_a" placeholder="Orden Interna" className="form-control form-control-sm"  >
                          <option value={'99'}>Completada</option>
                          <option value={'98'}>En Proceso</option>
                      </select>

                      </div>
                      <div className="col-xl-2">
                     
                          <input type="button" id="bt" name="bt" className="btn btn-primary btn-sm" value="Filtrar" onClick={() =>this.Buscar(1)} />
                          <input type="hidden" id="page" name="page" placeholder="Pagina"  className="form-control form-control-sm" disabled />
                      </div>
               
                   </div>
                   <br></br>
                 
                   <div className="row">
                  <table className="table table-hover table-condensed table-sm">
                  <thead className="thead-inverse">
                      <tr>
                        <th>ORDEN</th>
                        <th>NOMBRE DEL PACIENTE</th>
                        
                        <th>ATENCION</th>
                        <th>CANTIDAD</th>
                        <th>EVOLUCION</th>
                        <th>% TOTAL</th>
                        <th>LIQ</th>
                        <th>FECHA-LIQ</th>
                        <th>PENDIENTE</th>
                        <th>AUDITORIA</th>
                      </tr>
                    </thead>
                    <tbody> 
                      {load}
                      {query.map((empleado) => (
                        <tr key={empleado.Id}>
                          <td> <button
                                type="button"
                                className={empleado.evo==0?"btn btn-danger btn-sm":"btn btn-success btn-sm"}
                                 data-bs-toggle='modal' data-bs-target='#modalliquidacion'
                                disabled={empleado.evo>=1?"":"disabled"}
                               /*  onClick={() =>
                                  this.VerAtencion(empleado.orden_servicio,empleado.pendientes==null?0:empleado.pendientes,empleado.id_liq==null?0:empleado.id_liq)
                                } */
                                  onClick={() =>
                                    this.VerAtencion(empleado.orden_servicio)
                                  }
                              >
                               {empleado.orden_servicio}
                              </button></td>
              
                          <td><Link to={"/InfoPacientes/" + empleado.id_paciente}>{empleado.nombres} {empleado.apellidos} </Link></td>
                       
                    
                          <td>{empleado.Description}<br></br>{empleado.StartTime}{empleado.EndTime}</td>
                          <td>{empleado.cant}</td>
                        
                          <td>
                            <div
                              className="btn-group"
                              role="group"
                              aria-label=""
                            >
                             {empleado.evo>=1?
                              <button
                                type="button"
                                className="btn btn-info btn-sm"
                                onClick={() =>
                                  this.VerEvolucion(empleado.orden_servicio)
                                }
                              >
                                Ver 
                              </button>:empleado.evo}
                            </div>
                          </td>
                          <td>{empleado.id_contacto}</td>
                          <td>{empleado.id_liq}</td>
                          <td>{empleado.liqui}</td>
                          <td>{empleado.pendientes}</td>
                          <td> <button
                                type="button"
                                className={"btn btn-primary"}
                                data-bs-toggle='modal' data-bs-target='#myModalEvolucion'
                                onClick={() =>
                                  this.Auditar(empleado.orden_servicio)
                                }
                              >
                               Ver
                              </button></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  </div>
                  <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className="page-item">
                    <a
                      className="page-link"
                      href="#"
                      onClick={() => this.Buscar(1)}
                    >
                      <FontAwesomeIcon icon={faAngleDoubleLeft} />
                    </a>
                  </li>
                  <li className="page-item">
                    <a
                      className="page-link"
                      href="#"
                      onClick={() => this.Buscar(actual - 1)}
                    >
                      <FontAwesomeIcon icon={faAngleLeft} />
                    </a>
                  </li>
                  {(() => {
                    let p = [];
                    /*if (ultimo >= 20) {
                                            var ult = 20;
                                        } else {
                                            ult = ultimo;
                                        }
                                        var ult = ultimo
                                        for (let i = 1; i <= ult; i++) {
                                            p.push(<li className="page-item" key={i}><a className="page-link" href="#" onClick={() => this.Buscar(i)}>{i}</a></li>);
                                        }*/
                    p.push(
                      <li className="page-item" key={actual}>
                        <a
                          className="page-link"
                          href="#"
                          onClick={() => this.Buscar(actual)}
                        >
                          {actual}
                        </a>
                      </li>
                    );
                    return p;
                  })()}
                  <li className="page-item">
                    <a
                      className="page-link"
                      href="#"
                      onClick={() => this.Buscar(actual + 1)}
                    >
                      <FontAwesomeIcon icon={faAngleRight} />
                    </a>
                  </li>
                  <li className="page-item">
                    <a
                      className="page-link"
                      href="#"
                      onClick={() => this.Buscar(ultimo)}
                    >
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </a>
                  </li>
                </ul>
              </nav> Registros ({pagina})
                </div>
              </div>
              </div>
              <div class="modal fade" id="usuarioAsignado" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="usuarioAsignadoLabel" aria-hidden="true"  data-backdrop="static">
                    <FormUsuario agregarPaciente={this.agregarPaciente} />
                </div>
                 <div class="modal fade" id="modalliquidacion" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="modalliquidacionLabel" aria-hidden="true"  data-backdrop="static">
                    <Formliquidacion IdOrden={this.state.IdOrden} actualizar={this.actualizar}/>
                </div> 

                <div className="card">
               
                <div className="card-body">
                  <h4>Lista de Liquidaciones</h4> 
                  <div className="row">
                
                      <div className="col-xl-2">
                      <label className="label">Año</label>
                      <input type="text" id="ano" name="ano" placeholder="Año" value={ano} className="form-control form-control-sm"  />

                      </div>
                      <div className="col-xl-3">
                      <label className="label">Mes</label>
                         
                          <select className="form-control form-control-sm" id="mes" name="mes" >
                            <option value="01" selected={mes=='01'?true:false} >Enero</option>
                            <option value="02" selected={mes=='02'?true:false}>Febrero</option>
                            <option value="03" selected={mes=='03'?true:false}>Marzo</option>
                            <option value="04" selected={mes=='04'?true:false}>Abril</option>
                            <option value="05" selected={mes=='05'?true:false}>Mayo</option>
                            <option value="06" selected={mes=='06'?true:false}>Junio</option>
                            <option value="07" selected={mes=='07'?true:false}>Julio</option>
                            <option value="08" selected={mes=='08'?true:false}>Agosto</option>
                            <option value="09" selected={mes=='09'?true:false}>Septiembre</option>
                            <option value="10" selected={mes=='10'?true:false}>Octubre</option>
                            <option value="11" selected={mes=='11'?true:false}>Noviembre</option>
                            <option value="12" selected={mes=='12'?true:false}>Diciembre</option>
                          </select>
                      </div>
                      <div className="col-xl-2">
                      <label className="label">Profesional</label>
                      <input type="text" id="pro" name="pro" placeholder="Profesional"  className="form-control form-control-sm"  />

                      </div>
                      <div className="col-xl-2">
                      <label className="label">Orden Servicio</label>
                      <input type="text" id="servicio" name="servicio" placeholder="Orden Interna" className="form-control form-control-sm"  />

                      </div>
                      <div className="col-xl-2 pt-4">
                     
                          <input type="button" id="bt" name="bt" className="btn btn-primary btn-sm" value="Buscar" onClick={() =>this.BuscarLiq(1)} />
                          {cargados==1?<input type="button" id="bt" name="bt" className="btn btn-success btn-sm" value="Exportar Excel" onClick={() =>this.Exportar()} />:''}
                      </div>
               
                   </div>
                   <br></br>
                   <div className="row">
                  <table className="table table-hover table-condensed table-sm">
                  <thead className="thead-inverse">
                      <tr>
                        <th>LIQ</th>
                        <th>ORDEN</th>
                        <th>NOMBRE DEL PROFESIONAL</th>
                        <th>ATENCION</th>
                        <th>PEN</th>
                        <th>CANT</th>
                        <th>VALOR</th>
                        <th>TOTAL</th>
                        <th>F INICIAL</th>
                        <th>F FINAL</th>
                        <th>FECHA REG</th>
                      </tr>
                    </thead>
                    <tbody> 
                      {load}
                      {query2.map((empleado) => (
                        <tr key={empleado.Id}>
                          <td>{empleado.id_liq}</td>
                          <td>{empleado.orden}</td>
                          <td>{empleado.nombre} {empleado.apellido}</td>
                          <td>{empleado.atencion}</td>
                          <td>{empleado.pendientes}</td>
                          <td>{empleado.cantidad}</td>
                          <td>{empleado.valor}</td>
                          <td>{empleado.total}</td>
                          <td>{empleado.fechain}</td>
                          <td>{empleado.fechafi}</td>
                          <td>{empleado.fecha_registro}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  </div>
                  <nav aria-label="Page navigation example">
                    <ul className="pagination">
                        <li class="page-item"><a className="page-link" href="#" onClick={() => this.BuscarLiq(1)}>Atras</a></li>
                        {(() => {
                            let p = [];
                            if(ultimo2>=20){
                              var ultj = 20;
                            }
                            for(let j = 1; j <= ultj ; j++){
                                
                                p.push(<li class="page-item" key={j}><a className="page-link" href="#" onClick={() => this.BuscarLiq(j)}>{j}</a></li>); 
                            }
                            return p;
                        })()}
                       
                        <li class="page-item"><a className="page-link" href="#" onClick={() => this.BuscarLiq(ultimo2)}>Siguiente</a></li>
                    </ul>
                    Pagina {actual2} de {ultimo2} Registros {pagina2}
                    </nav>
                </div>
              </div>
              <div className="modal" id="myModalEvolucion">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">


                        <div className="modal-header">
                            <h4 className="modal-title">Auditoria de atenciones firmadas</h4>
                            
                            <button type="button" className="close" data-bs-dismiss="modal">&times;</button>
                        </div>

                        
                        <div className="modal-body">
                        <div className="card">
                                    <div className="card-body">
                                    <div className="row">
                                <div className="col-12">
                                    <label>Nombre del Paciente</label>
                                    {cantidad.Subject}
                                </div>
                                <div className="col-12">
                                    <label>Nombre de la atencion</label>
                                    {cantidad.Description}
                                </div>
                                <div className="col-12">
                                    <label>Cantidad Firmadas: </label>
                                    {firmada_ate.firmada} de {cantidad.cant} {firmada_ate.firmada==cantidad.cant?<FontAwesomeIcon icon={faCheck} />:<FontAwesomeIcon icon={faLowVision} />}

                                </div>
                                <div className="col-12">
                                    <label>Consentimiento firmado</label>
                                    {firmada_con.confirmado} {firmada_con.confirmado!=0?<FontAwesomeIcon  icon={faCheck} />:<FontAwesomeIcon icon={faLowVision} color="danger"/>}
                                </div>
                                <div className="col-12">
                                    <label>Porcentaje completado</label>
                                    {cantidad.id_contacto} % {cantidad.id_contacto>=98?<FontAwesomeIcon icon={faCheck} />:<FontAwesomeIcon icon={faLowVision} />}
                                </div>
                                <div className="col-12">
                                    <label>Evolucion:</label>
                                    {evolucion.evolucion} {evolucion.evolucion==1?<FontAwesomeIcon icon={faCheck} />:<FontAwesomeIcon icon={faLowVision} />}
                                </div>
                                <div className="col-12">
                                    <label>Atencion para editar: </label>
                                    {cantidad.editar=='1'?'Si':'No'}
                                </div>
                                <div className="col-12">
                                    <label>Fecha vencimiento: </label>
                                    {cantidad.vencimiento}
                                </div>

                               
                            </div>
                            
                            </div>
                            </div>
                        </div>

                    
                        <div className="modal-footer">
                         Fecha vencimiento: <input type="date" name="vencimiento" id="vencimiento" className="form-control" />
                         Activar: 
                         <select id="acivar" className="form-control form-select">
                              <option value="0">No</option>
                              <option value="1">Si</option>
                         </select>

                        <button type="button" className="btn btn-INFO btn-sm"  onClick={() => this.Activar()}>Activar Atencion</button>
                        {/* <button type="button" className="btn btn-primary btn-sm">Liquidar Atencion</button> */}
                            <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><FontAwesomeIcon icon={faSignOutAlt} />&nbsp;&nbsp;Cerrar</button>
                        </div>

                        </div>
                    </div>
                    </div>
              </div>
              
            
      );
    }
  }


export default Listar;
