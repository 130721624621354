import React from 'react';
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft, faAngleLeft, faAngleDoubleRight, faAngleRight, faFilter, faFileDownload } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2';
import { currencyFormat } from '../../js/Functions';
import * as XLSX from 'xlsx';

class Listar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datosCargados: false,
            load: '',
            atenciones: [],
            pagina: [],
            total: 0,
            registros: 0,
            sin_f: 0,
            con_f: 0,
            Precio: 0,
            empresas: []
        }
    }

    cargarEmpresas() {
        fetch(Api + "Empresas")
            .then(respuesta => respuesta.json())
            .then((datosEmpresas) => {
                //console.log(datosEmpresas.data);
                this.setState({ empresas: datosEmpresas.data })
            })
            .catch(console.log);
    }

    Buscar(page) {
        this.setState({ Precio: 0, total: 0, registros: 0, sin_f: 0, con_f: 0, atenciones: [] });
        let bano = document.getElementById('bano').value;
        let bmes = document.getElementById('bmes').value;
        let bate = document.getElementById('bate').value;
        let liq = document.getElementById('bliq').value;
        //let empresa = document.getElementById('empresa').value;
        let empresa = 0;
        console.log("Formulario Enviado...");
        if (bano === '') {
            bano = 0;
        }
        if (bmes === '') {
            bmes = 0;
        }
        if (bate === '') {
            bate = 0;
        }
        if (empresa === '') {
            empresa = 0;
        }
        Swal.showLoading();
        this.setState({ load: 'Cargando Datos...' })
        fetch(Api + "Auditoria/InformeFinanciero1/" + bano + "/" + bmes + "/" + bate + "/" + liq + "/" + empresa)
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                Swal.close();
                console.log(datosRespuesta);
                this.setState({ datosCargados: true, load: '', atenciones: datosRespuesta.datos, Precio: datosRespuesta.precio, total: datosRespuesta.total, registros: datosRespuesta.registro, sin_f: datosRespuesta.con_facturar, con_f: datosRespuesta.sin_facturar });
            })
            .catch(console.log);
    }

    exportToExcel() {
        const { atenciones } = this.state;
        const headers = [
            "FECHA REGISTRO",
            "ORDEN INT",
            "ATENCIÓN",
            "CANT. ATENCIONES",
            "VLR UND",
            "TOTAL a PAGAR",
            "PRECIO ATENCION",
            "LIQUIDADO",
            "POR LIQUIDAR"
        ];
        const data = atenciones.map(itemAtenciones => ({
            "FECHA REGISTRO": itemAtenciones.fecha_registro,
            "ORDEN INT": itemAtenciones.orden,
            "ATENCIÓN": itemAtenciones.atencion,
            "CANT. ATENCIONES": itemAtenciones.cantidad,
            "VLR UND": currencyFormat(itemAtenciones.valor),
            "TOTAL a PAGAR": currencyFormat(itemAtenciones.total),
            "PRECIO ATENCION": currencyFormat(itemAtenciones.precio * itemAtenciones.cantidad),
            "LIQUIDADO": itemAtenciones.facturada != 0 ? currencyFormat(itemAtenciones.total) : '0',
            "POR LIQUIDAR": itemAtenciones.facturada == 0 ? currencyFormat(itemAtenciones.total) : '0'
        }));
        const worksheet = XLSX.utils.json_to_sheet(data, { header: headers });
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Atenciones");
        XLSX.writeFile(workbook, "InformeFinanciero.xlsx");
    }

    componentDidMount() {
        this.cargarEmpresas();
    }

    render() {
        const { datosCargados, load, atenciones, pagina, registros, total, sin_f, con_f, Precio, empresas } = this.state;
        return (
            <div className="content">
                <div className="content-wrapper">
                    <div className="content">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-header">
                                    &nbsp;
                                </div>
                                <div className="card-body">
                                    <h4>Informe Financiero "Liquidacion"</h4>
                                    <div className="row">
                                        <div className="col-xl-1">
                                            <input type="text" id="bano" name="bano" placeholder="Año" className="form-control form-control-sm" maxLength="4" />
                                        </div>
                                        <div className="col-xl-2">
                                            <select className="form-select form-select-sm" type="text" name="bmes" id="bmes">
                                                <option value="">Seleccione el Mes</option>
                                                <option value="01">01 - Enero</option>
                                                <option value="02">02 - Febrero</option>
                                                <option value="03">03 - Marzo</option>
                                                <option value="04">04 - Abril</option>
                                                <option value="05">05 - Mayo</option>
                                                <option value="06">06 - Junio</option>
                                                <option value="07">07 - Julio</option>
                                                <option value="08">08 - Agosto</option>
                                                <option value="09">09 - Septiembre</option>
                                                <option value="10">10 - Octubre</option>
                                                <option value="11">11 - Noviembre</option>
                                                <option value="12">12 - Diciembre</option>
                                            </select>
                                        </div>
                                        <div className="col-xl-2">
                                            <select className="form-select form-select-sm" type="text" name="bliq" id="bliq">
                                                <option value="0">Todas las atenciones</option>
                                                <option value="1">Liquidado</option>
                                                <option value="2">Por Liquidar</option>
                                            </select>
                                        </div>
                                        {/* <div className="col-xl-2">
                                            <select className="form-select form-select-sm" type="text" name="empresa" id="empresa">
                                                <option value="">Seleccione la Empresa</option>
                                                {
                                                    empresas.map(itemEmpresa => (
                                                        <option key={itemEmpresa.id_empresa} value={itemEmpresa.rips}>{itemEmpresa.nombre_emp}</option>
                                                    ))
                                                }
                                            </select>
                                        </div> */}
                                        <div className="col-xl-4">
                                            <input type="text" id="bate" name="bate" placeholder="Nombre de la atencion" className="form-control form-control-sm" />
                                        </div>
                                        <div className="col-xl-1">
                                            <button id="bt" name="bt" className="btn btn-primary btn-sm" onClick={() => this.Buscar(1)}><FontAwesomeIcon icon={faFilter} />&nbsp;&nbsp;Filtrar</button>
                                        </div>
                                        <div className='col-xl-2'>
                                            {
                                                datosCargados ? (
                                                    <button style={{ color: '#FFFFFF' }} id="btn_exportar" name="btn_exportar" onClick={() => this.exportToExcel()} className="btn btn-info btn-sm"><FontAwesomeIcon icon={faFileDownload} />&nbsp;&nbsp;Exportar</button>
                                                ) : null
                                            }
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row">
                                        <table className="table table-hover table-condensed table-sm">
                                            <thead className="thead-inverse">
                                                <tr>
                                                    <th>FECHA REGISTRO</th>
                                                    <th>ORDEN INT</th>
                                                    <th>ATENCIÓN</th>
                                                    <th>CANT. ATENCIONES</th>
                                                    <th>VLR UND</th>
                                                    <th>TOTAL a PAGAR</th>
                                                    <th>PRECIO ATENCION</th>
                                                    <th>LIQUIDADO</th>
                                                    <th>POR LIQUIDAR</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {load}
                                                {atenciones.map((itemAtenciones, i) => (
                                                    <tr key={itemAtenciones.orden}>
                                                        <td>{itemAtenciones.fecha_registro}</td>
                                                        <td>{itemAtenciones.orden}</td>
                                                        <td>{itemAtenciones.atencion}</td>
                                                        <td>{itemAtenciones.cantidad}</td>
                                                        <td>{currencyFormat(itemAtenciones.valor)}</td>
                                                        <td>{currencyFormat(itemAtenciones.total)}</td>
                                                        <td>{currencyFormat(itemAtenciones.precio * itemAtenciones.cantidad)}</td>
                                                        <td style={{ backgroundColor: itemAtenciones.facturada != 0 ? "green" : "" }}>{itemAtenciones.facturada != 0 ? currencyFormat(itemAtenciones.total) : '0'}</td>
                                                        <td style={{ backgroundColor: itemAtenciones.facturada == 0 ? "red" : "" }} >{itemAtenciones.facturada == 0 ? currencyFormat(itemAtenciones.total) : '0'}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            <tr>
                                                <td colSpan={5}>Totales</td>
                                                <td>{currencyFormat(total)}</td>
                                                <td>{currencyFormat(Precio)}</td>
                                                <td>{currencyFormat(sin_f)}</td>
                                                <td>{currencyFormat(con_f)}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div className="card-footer text-muted">
                                    <b>Total de registros: </b> <span className="badge bg-primary">{registros}</span>
                                    <b>--</b>Total En Atenciones: <span className="badge bg-success">{currencyFormat(Precio)}</span>
                                    | Total a Pagar: <span className="badge bg-success">{currencyFormat(total)} </span>
                                    | Total Liquidado : <span className="badge bg-warning">{currencyFormat(sin_f)} </span>
                                    | Pendientes por liquidar : <span className="badge bg-danger">{currencyFormat(con_f)} </span>
                                    | Ganancia Esperada : <span className="badge bg-info"> {currencyFormat(Precio - total)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Listar;
