import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderOpen, faShoppingCart, faCogs, faChevronRight, faUser, faUserGraduate, faBookMedical, faClipboardCheck, faNetworkWired, faBoxes, faBriefcase, faCalendar ,faCashRegister} from '@fortawesome/free-solid-svg-icons'
import Api from "../servicios/api";

 class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userProps: this.props,
      fullname: localStorage.getItem("k_nombre"),
      fotoperfil:''

    };
  }
  BuscarPaciente() {
    var cc = document.getElementById('searchx').value;
     if(cc==''){
      alert("Debes de digitar el numero de cedula");
      return false;
     }
    fetch(Api + "buscarpacientes/"+cc)
      .then(respuesta => respuesta.json())
      .then((datosRoles) => {
        console.log(datosRoles.id);
        if(datosRoles.id!=0){
          window.location.href = 'InfoPacientes/'+datosRoles.id;
        }else{
            alert(datosRoles.msj);
        }
        //this.setState({ roles: datosRoles })
      })
      .catch(console.log);
  }

  //const fullname = localStorage.getItem("k_nombre");
  render() {
    const {fullname } = this.state;
  return (
    <aside className="main-sidebar sidebar-light-primary elevation-4">
      {/* Brand Logo */}
      <Link to="/home" className="brand-link navbar-blue">
        <img src={process.env.PUBLIC_URL + '/dist/img/logo.png'} className="brand-image "  />
        
        <span className="brand-text font-weight-light text-white">Koala Med</span>
      </Link>
      {/* Sidebar */}
      <div className="sidebar">
        {/* Sidebar user panel (optional) */}
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img src={process.env.PUBLIC_URL + '/dist/img/avatar3.png'} className="img-circle elevation-2" alt="User Image" />
          </div>
          <div className="info">
            <a href="#" className="d-block">{fullname}</a>
          </div>
        </div>
        {/* SidebarSearch Form */}
        <div className="form-inline">
          <div className="input-group">
            <input className="form-control form-control-sidebar" type="text" placeholder="Buscar por cedula"  id="searchx" />
            <div className="input-group-append">
              <button className="btn btn-sidebar text-white" onClick={this.BuscarPaciente}>
                <i className="fas fa-search text-black" />
              </button>
            </div>
          </div>
        </div>
        <div className="form-inline">
        
        </div>
        {/* Sidebar Menu */}
        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
         
            <li className="nav-item menu-close">
              <a href="#" className="nav-link">
                <FontAwesomeIcon style={{ marginRight: 15 }} icon={faBookMedical} />
                <p>
                  Ordenes Internas
                  <i className="right fas fa-angle-left" />
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link to="/AddAtencion" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Ingresar Atención</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/MasterOrdenes" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Lista de Ordenes</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/MasterOrdenesBloq" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Ordenes Bloqueadas</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/MasterOrdenesUbic" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Ordenes x Ubicación</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item menu-close">
              <a href="#" className="nav-link">
                <FontAwesomeIcon style={{ marginRight: 15 }} icon={faCalendar} />
                <p>
                  Alquileres
                  <i className="right fas fa-angle-left" />
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link to="/AlquilerProceso" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Master de Alquiler</p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to="/SeguimientoAlquiler" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Seguimiento de Alq.</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item menu-close">
              <a href="#" className="nav-link">
                <FontAwesomeIcon style={{ marginRight: 15 }} icon={faCalendar} />
                <p>
                  Ventas
                  <i className="right fas fa-angle-left" />
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link to="/VentasProceso" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Master de Ventas</p>
                  </Link>
                </li>

                
              </ul>
            </li>
            <li className="nav-item menu-close">
              <a href="#" className="nav-link">
                <FontAwesomeIcon style={{ marginRight: 15 }} icon={faCashRegister} />
                <p>
                  Facturacion
                  <i className="right fas fa-angle-left" />
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link to="/Facturacion" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Lista de Facturas</p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to="/FacturarEmpresa" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Facturar x Empresa</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/GenerarRips" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Generar Rips TXT</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/GenerarRipsNew" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Generar Rips Json</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/liquidacion" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Modulo de Liquidacion.</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item menu-close">
              <a href="#" className="nav-link">
                <FontAwesomeIcon style={{ marginRight: 15 }} icon={faUser} />
                <p>
                  Pacientes
                  <i className="right fas fa-angle-left" />
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link to="/ListaPaciente" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Lista Pacientes</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/MasterEmpresas" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Empresas</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item menu-close">
              <a href="#" className="nav-link">
                <FontAwesomeIcon style={{ marginRight: 15 }} icon={faBriefcase} />
                <p>
                  Soporte
                  <i className="right fas fa-angle-left" />
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link to="/AddSoporte" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Nuevo Soporte</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/MasterSoporte" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Lista de Soportes</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item menu-close">
              <a href="#" className="nav-link">
                <FontAwesomeIcon style={{ marginRight: 15 }} icon={faUserGraduate} />
                <p>
                  Admin de Usuarios
                  <i className="right fas fa-angle-left" />
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link to="/MasterUsuarios" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Lista de Usuarios</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/MasterRoles" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Lista de Roles</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/MasterCargos" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Lista de Cargos</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item menu-close">
              <a href="#" className="nav-link">
                <FontAwesomeIcon style={{ marginRight: 15 }} icon={faNetworkWired} />
                <p>
                  Publicaciones
                  <i className="right fas fa-angle-left" />
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link to="/MasterNoticias" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Publicar Noticias</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/MasterDocumentos" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Documentos</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item menu-close">
              <a href="#" className="nav-link">
                <FontAwesomeIcon style={{ marginRight: 15 }} icon={faClipboardCheck} />
                <p>
                  Auditorías
                  <i className="right fas fa-angle-left" />
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link to="/MasterAudAtenciones" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Atenciones</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/MasterAudPacientes" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Pacientes Ingresados</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/MasterPacientesEmpresa" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Pacientes x Empresa</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/MasterAudAtencionesPacientes" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Atenciones x Pacientes</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/MasterAudOrdenes" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Ord. Ingresadas Hoy</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/Informe" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Informe financiero Liq</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/InformeFactura" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Informe financiero Fact.</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item menu-close">
              <a href="#" className="nav-link">
                <FontAwesomeIcon style={{ marginRight: 15 }} icon={faBoxes} />
                <p>
                  Inventario
                  <i className="right fas fa-angle-left" />
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link to="/MasterProveedores" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Proveedores</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/MasterBodegas" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Bodegas</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/MasterOperaciones" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Operaciones</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/MasterMovimientos" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Lista Movimientos</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item menu-close">
              <a href="#" className="nav-link">
                <FontAwesomeIcon style={{ marginRight: 15 }} icon={faCogs} />
                <p>
                  Datos Maestros
                  <i className="right fas fa-angle-left" />
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link to="/MasterAtenciones" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Atenciones</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/MasterMedicamentos" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Medicamentos</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/MasterInsumos" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Insumos</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/MasterVentas" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Productos Ventas</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/MasterAlquiler" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Productos Alquiler</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/MasterEnfermedades" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Enfermedades CIE10</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/MasterBarrios" className="nav-link">
                    <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                    <p>Barrios</p>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
        {/* /.sidebar-menu */}
      </div>
      {/* /.sidebar */}
    </aside>
  );
   }
}
export default Menu;