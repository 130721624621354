import React from "react";
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import Formulario from "./Crear"
import EditarFormulario from "./Editar"
//import ItemInsumos from "./ItemInsumos";
//import ItemMedicamentos from "./ItemMedicamentos";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft, faAngleLeft, faAngleDoubleRight, faAngleRight, faThList, faPlus, faFilter } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2';

class Listar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editar: 'No',
            datosCargados: false,
            encabezado: [],
            detalle: [],
            movimientos: [],
            operaciones: [],
            bodegas: [],
            pagina: [],
            date: new Date(),
        };
    }
    cargarOperaciones() {
        fetch(Api + "SearchOperaciones")
            .then(respuesta => respuesta.json())
            .then((datosOperaciones) => {
                console.log(datosOperaciones);
                this.setState({ operaciones: datosOperaciones })
            })
            .catch(console.log);
    }
    cargarBodegas() {
        fetch(Api + "SearchBodegas/0?page=1")
            .then(respuesta => respuesta.json())
            .then((datosBodegas) => {
                console.log(datosBodegas);
                this.setState({ bodegas: datosBodegas.data })
            })
            .catch(console.log);
    }
    EditRegistros = async (idp) => {
        this.setState({ editar: 'Si' })
        console.log('dato:' + idp);
        await fetch(Api + "VerDetalleMovimientos/" + idp,
            {
                method: "GET",
                headers: new Headers({
                    'Authorization': 'koala2021'
                }),
            })
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                this.setState({
                    datosCargados: true,
                    encabezado: datosRespuesta.encabezado,
                    detalle: datosRespuesta.detalle,
                    estado: datosRespuesta.encabezado.save,
                    bodega: datosRespuesta.encabezado.id_bod,
                    ordenServicio: datosRespuesta.encabezado.orden_servicio,
                    idMovimiento: datosRespuesta.encabezado.id_mov
                 });
                document.getElementById('orden').value = this.state.encabezado.orden_servicio;
                document.getElementById('mov').value = this.state.encabezado.id_mov;
                if (this.state.encabezado.save === 0) {
                    document.getElementById('estado').value = "Sin Guardar"
                } else {
                    document.getElementById('estado').value = "Guardado"
                }
                document.getElementById('fecha_mov').value = this.state.encabezado.fecha_mov;
                document.getElementById('usuario').value = this.state.encabezado.nombre + " " + this.state.encabezado.apellido;
                document.getElementById('grupo').value = this.state.encabezado.grupo;
                document.getElementById('id_ope').value = this.state.encabezado.id_operaciones;
                document.getElementById('id_pro').value = this.state.encabezado.id_pro;
                document.getElementById('bod').value = this.state.encabezado.id_bod;
            })
            .catch(console.log);
    }
    componentDidMount() {
        this.cargarOperaciones();
        this.cargarBodegas();
        this.Buscar(1);
    }
    Buscar(page) {
        let bfec = document.getElementById('bfec').value;
        let bbod = document.getElementById('bbod').value;
        let bdoc = document.getElementById('bdoc').value;
        let bope = document.getElementById('bope').value;
        console.log("Formulario Enviado...");
        if (bfec == '') { bfec = 0; }
        if (bbod == '') { bbod = 0; }
        if (bdoc == '') { bdoc = 0; }
        if (bope == '') { bope = 0; }
        //console.log(bdoc + " - " + bmov + " - " + bbod);
        fetch(Api + "SearchMovimientos/" + bfec + '/' + bbod + '/' + bdoc + '/' + bope + "?page=" + page)
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                this.setState({ datosCargados: true, movimientos: datosRespuesta.data, pagina: datosRespuesta.total, ultimo: datosRespuesta.last_page, actual: datosRespuesta.current_page });
            })
            .catch(console.log);
    }
    render() {
        const { datosCargados, encabezado, detalle, bodega, movimientos, operaciones, bodegas, pagina, ultimo, actual, editar, estado, ordenServicio, idMovimiento } = this.state;
        if (!datosCargados) {
            var load = 'Cargando datos....';
        } else {
            var load = '';
        }
        return (
            <div className="content">
                <div className="modal fade" id="nuevoMovimiento" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoMovimientoLabel" aria-hidden="true">
                    {
                        editar === "No" ? (
                            <Formulario />
                        ) : (
                            <EditarFormulario estado={estado} bodega={bodega} detalleMov={detalle} ordenServicio={ordenServicio} idMovimiento={idMovimiento} />
                        )
                    }
                </div>
                {/*<div class="modal fade" id="nuevoItemInsumo" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoItemLabel" aria-hidden="true">
                    <ItemInsumos prueba={this.prueba} />
                </div>
                <div class="modal fade" id="nuevoItemMedicamento" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoItemLabel" aria-hidden="true">
                    <ItemMedicamentos />
                </div>*/}
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <button className='btn btn-success btn-sm' id='btnNuevoMovimiento' type='button' data-bs-toggle='modal' data-bs-target='#nuevoMovimiento' onClick={() => (this.setState({ editar: 'No' }))}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nuevo Movimiento</button>
                        </div>
                        <div className="card-body">
                            <h4>Lista de Movimientos</h4>
                            <div className="row">
                                <div className="col-xl-2">
                                    <input className="form-control form-control-sm" type="date" id="bfec" name="bfec" />
                                </div>
                                <div className="col-xl-2">
                                    <input className="form-control form-control-sm" type="text" id="bdoc" name="bdoc" placeholder="Documento" />
                                </div>
                                <div className="col-xl-3">
                                    <select className="form-select form-select-sm" type="text" name="bbod" id="bbod">
                                        <option value="">Seleccione la Bodega</option>
                                        {
                                            bodegas.map(itemBodegas => (
                                                <option key={itemBodegas.id_bodega} value={itemBodegas.id_bodega}>{itemBodegas.bodega}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-xl-3">
                                    <select className="form-select form-select-sm" type="text" name="bope" id="bope">
                                        <option value="">Seleccione la Operación</option>
                                        {
                                            operaciones.map(itemOperaciones => (
                                                <option key={itemOperaciones.id_operaciones} value={itemOperaciones.id_operaciones}>{itemOperaciones.descripcion}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-xl-2">
                                    <button id="bt" name="bt" className="btn btn-primary btn-sm" onClick={() => this.Buscar(1)}><FontAwesomeIcon icon={faFilter} />&nbsp;&nbsp;Filtrar</button>
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <table className="table table-hover table-condensed table-sm">
                                    <thead className="thead-inverse">
                                        <tr>
                                            <th>CONSECUTIVO</th>
                                            <th>NO. ORDEN / FACTURA</th>
                                            <th>OPERACIÓN</th>
                                            <th>BODEGA</th>
                                            <th>REGISTRADO</th>
                                            <th>FECHA REGISTRO</th>
                                            <th>DETALLE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {load}
                                        {movimientos.map((itemMovimiento) => (
                                            <tr key={itemMovimiento.id_mov}>
                                                <td>{itemMovimiento.id_mov}</td>
                                                <td>{itemMovimiento.orden_servicio}</td>
                                                <td>{itemMovimiento.descripcion}</td>
                                                <td>{itemMovimiento.bodega}</td>
                                                <td>{itemMovimiento.nombre} {itemMovimiento.apellido}</td>
                                                <td>{itemMovimiento.fecha_mov}</td>
                                                <td>
                                                    <div className="btn-group" role="group" aria-label="">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary btn-sm"
                                                            data-bs-toggle='modal' data-bs-target='#nuevoMovimiento'
                                                            onClick={() =>
                                                                this.EditRegistros(itemMovimiento.id_mov)
                                                            }
                                                        >
                                                            <FontAwesomeIcon icon={faThList} />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Buscar(1)}><FontAwesomeIcon icon={faAngleDoubleLeft} /></a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Buscar(actual - 1)}><FontAwesomeIcon icon={faAngleLeft} /></a></li>
                                    {(() => {
                                        let p = [];
                                        /*if (ultimo >= 20) {
                                            var ult = 20;
                                        } else {
                                            ult = ultimo;
                                        }
                                        var ult = ultimo
                                        for (let i = 1; i <= ult; i++) {
                                            p.push(<li className="page-item" key={i}><a className="page-link" href="#" onClick={() => this.Buscar(i)}>{i}</a></li>);
                                        }*/
                                        p.push(<li className="page-item" key={actual}><a className="page-link" href="#" onClick={() => this.Buscar(actual)}>{actual}</a></li>);
                                        return p;
                                    })()}
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Buscar(actual + 1)}><FontAwesomeIcon icon={faAngleRight} /></a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Buscar(ultimo)}><FontAwesomeIcon icon={faAngleDoubleRight} /></a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="card-footer text-muted">
                            <b>Pagina</b> <span class="badge bg-primary">{actual}</span> <b>de</b> <span class="badge bg-primary">{ultimo}</span> | <b>Registros</b> <span class="badge bg-success">{pagina}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Listar;