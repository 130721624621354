import React from 'react';
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft, faAngleLeft, faAngleDoubleRight, faAngleRight, faFilter, faFileDownload } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2';

class Listar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datosCargados: false,
            load: '',
            atenciones: [],
            pagina: [],
        }
    }
  
    Descargar() {
        let bano = document.getElementById('bano').value;
         if(bano==''){
            alert('Debes de digitar el año para la consulta');
            return false;
         }
        window.open('https://idb.softmediko.com/vistas/reportes/?a='+bano, 'popup', 'width=600,height=600');
    }
    render() {
        const { datosCargados, load, atenciones, pagina, ultimo, actual } = this.state;
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            &nbsp;
                        </div>
                        <div className="card-body">
                            <h4>Auditoría de Pacientes x Empresas</h4>
                            <div className="row">
                                <div className="col-xl-1">
                                    <input type="text" id="bano" name="bano" placeholder="Año" className="form-control form-control-sm" maxLength="4" />
                                </div>
                             
                                <div className="col-xl-1">
                                    <button id="bt" name="bt" className="btn btn-primary btn-sm" onClick={() => this.Descargar()}><FontAwesomeIcon icon={faFilter} />&nbsp;&nbsp;Filtrar</button>
                                </div>
                                <div className='col-xl-4'>
                                    {
                                        datosCargados ? (
                                            <button style={{ color: '#FFFFFF' }} id="btn_exportar" onClick={() => this.Descargar()} name="btn_exportar" className="btn btn-info btn-sm"><FontAwesomeIcon icon={faFileDownload} />&nbsp;&nbsp;Exportar</button>
                                        ) : null
                                    }
                                </div>
                            </div>
                            <br></br>
                           
                        </div>
                    
                    </div>
                </div>
            </div>
        );
    }
}

export default Listar;