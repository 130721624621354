import React from "react";
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2';

class Listar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datosCargados: false,
            operaciones: [],
            pagina: [],
        };
    }
    borrarRegistros = (id) => {
        console.log(id);
        Swal.fire({
            title: 'Estas seguro de eliminar el registro?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Borrar',
            denyButtonText: `No borrar`,
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                fetch(Api + "DelOperaciones/" + id)
                    .then((respuesta) => respuesta.json())
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        Swal.fire('Borrado!', datosRespuesta.msj, 'success')
                        this.cargarOperaciones();
                    })
                    .catch(console.log);
            } else if (result.isDenied) {
                Swal.fire('Cancelado', '', 'info')
            }
        })
    };
    cargarOperaciones() {
        console.log("Formulario Enviado...");
        fetch(Api + "SearchOperaciones")
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                this.setState({ datosCargados: true, operaciones: datosRespuesta });
            })
            .catch(console.log);
    }
    componentDidMount() {
        this.cargarOperaciones();
    }
    render() {
        const { datosCargados, operaciones } = this.state;
        if (!datosCargados) {
            var load = 'Cargando datos....';
        } else {
            var load = '';
        }
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <Link className="btn btn-success btn-sm" to={"/AddOperaciones"}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nueva Operación</Link>
                        </div>
                        <div className="card-body">
                            <h4>Lista de Operaciones</h4>
                            <div className="row">
                                <table className="table table-hover table-condensed table-sm">
                                    <thead className="thead-inverse">
                                        <tr>
                                            <th>OPERACIÓN</th>
                                            <th>ACCIONES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {load}
                                        {operaciones.map((itemOperacion) => (
                                            <tr key={itemOperacion.id_operaciones}>
                                                <td>{itemOperacion.descripcion}</td>
                                                <td>
                                                    <div className="btn-group" role="group" aria-label="">
                                                        <Link
                                                            className="btn btn-primary btn-sm"
                                                            to={"/UpOperaciones/" + itemOperacion.id_operaciones}
                                                        >
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </Link>&nbsp;&nbsp;
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger btn-sm"
                                                            onClick={() =>
                                                                this.borrarRegistros(itemOperacion.id_operaciones)
                                                            }
                                                        >
                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Listar;