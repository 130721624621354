import React, { useEffect } from 'react';
import Api from "../../servicios/api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes, faArrowRight, faAngleDoubleLeft, faAngleLeft, faAngleDoubleRight, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import Swal from "sweetalert2";
class AlquileresPac extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datosCargados: false,
            pagina: [],
            pacientes: [],
            idpac: props.agregarPaciente
        }
    }
    resetForm() {
        document.getElementById('bnombre').value = "";
        document.getElementById('btipo').value = "";
        document.getElementById('bnombre').focus();
        this.BuscarPaciente(1);
    }
    enviarPaciente = (id,cod, nombre, precio) => {
       
        document.getElementById('cod_e').value = cod;
        document.getElementById('nombre_e').value = nombre;
        document.getElementById('pre_e').value = precio ;
        //this.props.agregarPaciente(sidp, snombre + " " + sapellido);
        window.$('#asignarPaciente').modal('hide');
    }
    componentDidMount() {
        const {idpac } = this.state;
        this.BuscarPaciente(idpac);
    }
    componentDidUpdate(prevProps, prevState) {
        // Verifica si el ID actual es diferente al ID anterior
        if (this.props.agregarPaciente !== prevProps.agregarPaciente) {
          // Si es diferente, llama a la función para buscar el paciente
          this.BuscarPaciente(this.props.agregarPaciente);
        }
      }
    BuscarPaciente(id) {
        
        fetch(Api + "alquiler/lista/" + id)
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                this.setState({ datosCargados: true, pacientes: datosRespuesta });
            })
            .catch(console.log);
    }
    borrar(id) {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "No podrás revertir esto",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, borrar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(Api + "alquiler/borrar_id/" + id)
                    .then((respuesta) => respuesta.json())
                    .then((datosRespuesta) => {
                        Swal.fire(
                            '¡Borrado!',
                            datosRespuesta.msj,
                            'success'
                        );
                        const {idpac } = this.state;
                        this.props.actuali();
                        this.BuscarPaciente(idpac);
                       
                            
                       
                    })
                    .catch((error) => {
                        console.log(error);
                        Swal.fire(
                            'Error',
                            'Hubo un problema al intentar borrar el elemento',
                            'error'
                        );
                    });
            }
        });
    }
    render() {
        const { datosCargados, pacientes, pagina, ultimo, actual } = this.state;
        if (!datosCargados) {
            var load = 'Cargando datos....';
        } else {
            var load = '';
        }
        return (
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="nuevaEnfermedad1Label">Alquileres {this.props.agregarPaciente}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="card">
                            <div style={{ backgroundImage: 'linear-gradient(#2FB3FB, #003153)', color: '#FFFFFF', borderTopLeftRadius: 5, borderTopRightRadius: 5 }} className="card-header text-center">
                                <h5 style={{ fontSize: 18 }} >Lista de Alquileres por paciente</h5>
                            </div>
                            <div className='card-body'>
                                
                                <div className="row">
                                    <table className="table table-hover table-condensed table-sm">
                                        <thead className="thead-inverse">
                                            <tr>
                                            <th>ORDEN</th>
                                            <th>AUTORIZACION</th>
                                            <th>DESCRIPCION ALQUILER</th>
                                            <th>RANGO DE FECHA</th>
                                            <th>ESTADO</th>
                                            <th>FACTURADO</th>
                                            <th>BORRAR</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {load}
                                            {pacientes.map((itemProductoAlq) => (
                                                <tr key={itemProductoAlq.id}>
                                                   <td>{itemProductoAlq.id}</td>
                                                    <td>{itemProductoAlq.autorizacion}</td>
                                                    <td>{itemProductoAlq.equipo}</td>
                                                    <td>{itemProductoAlq.fecha_a} al {itemProductoAlq.fecha_f}</td>
                                                    <td>{itemProductoAlq.estado_ord}</td>
                                                    <td>{itemProductoAlq.facturado}</td>
                                                    <td>{itemProductoAlq.facturado=='nada'? <button onClick={()=>this.borrar(itemProductoAlq.id_equipo_a)}>[-]</button>:''}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default AlquileresPac;