import React from 'react';
import Api from "../../servicios/api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes, faArrowRight, faAngleDoubleLeft, faAngleLeft, faAngleDoubleRight, faAngleRight } from '@fortawesome/free-solid-svg-icons'

class Pacientes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datosCargados: false,
            pagina: [],
            enfermedades1: []
        }
    }
    resetForm() {
        console.log("Entra acá");
        document.getElementById('bdescripcion1').value = "";
        document.getElementById('bcodigo1').value = "";
        document.getElementById('bdescripcion1').focus();
        this.BuscarEnf2(1);
    }
    enviarEnfermedad1 = (sidp, snombre, sapellido) => {
        console.log(sidp, snombre, sapellido);
        document.getElementById('idp').value = sidp;
        document.getElementById('nom').value = snombre;
        document.getElementById('ape').value = sapellido;
        window.$('#nuevaEnfermedad1').modal('hide');
    }
    componentDidMount() {
        this.BuscarEnf2(1);
    }
    BuscarEnf2(page) {
        let bdescripcion1 = document.getElementById('bdescripcion1').value;
        let bcodigo1 = document.getElementById('bcodigo1').value;
        console.log("Formulario Enviado...");
        if (bdescripcion1 == '') {
            bdescripcion1 = 0;
        }
        if (bcodigo1 == '') {
            bcodigo1 = 0;
        }
        fetch(Api + "SearchPacientes/" + bcodigo1 + "/" + bdescripcion1 + "/0?page=" + page)
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                this.setState({ datosCargados: true, enfermedades1: datosRespuesta.data, pagina: datosRespuesta.total, ultimo: datosRespuesta.last_page, actual: datosRespuesta.current_page });
            })
            .catch(console.log);
    }
    render() {
        const { datosCargados, enfermedades1, pagina, ultimo, actual } = this.state;
        if (!datosCargados) {
            var load = 'Cargando datos....';
        } else {
            var load = '';
        }
        return (
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="nuevaEnfermedad1Label">Pacientes</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="card">
                            <div style={{ backgroundImage: 'linear-gradient(#2FB3FB, #003153)', color: '#FFFFFF', borderTopLeftRadius: 5, borderTopRightRadius: 5 }} className="card-header text-center">
                                <h5 style={{ fontSize: 18 }} >Lista de Pacientes</h5>
                            </div>
                            <div className='card-body'>
                                <div className="row">
                                    <div className="col-xl-5">
                                        <input type="text" id="bdescripcion1" name="bdescripcion1" placeholder="Nombre dl paciente" className="form-control form-control-sm" />
                                    </div>
                                    <div className="col-xl-3">
                                        <input type="text" id="bcodigo1" name="bcodigo1" placeholder="Documento" className="form-control form-control-sm" />
                                    </div>
                                    <div className="col-xl-4">
                                        <button type="button" className="btn btn-primary btn-sm" onClick={() => this.BuscarEnf2(1)}><FontAwesomeIcon icon={faSearch} />&nbsp;&nbsp;Buscar</button>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-danger btn-sm" onClick={() => this.resetForm()} ><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <table className="table table-hover table-condensed table-sm">
                                        <thead className="thead-inverse">
                                            <tr>
                                                <th>CEDULA</th>
                                                <th>PACIENTE</th>
                                                <th>SEXO</th>
                                                <th>TELEFONO</th>
                                               
                                                <th>SELECCIONAR</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {load}
                                            {enfermedades1.map((itemEnfermedad1) => (
                                                <tr key={itemEnfermedad1.id_paciente}>
                                                    <td>{itemEnfermedad1.numero_doc}</td>
                                                    <td>{itemEnfermedad1.nombres} {itemEnfermedad1.apellidos} </td>
                                                    <td>{itemEnfermedad1.sexo}</td>
                                                    <td>{itemEnfermedad1.tel_1}</td>
                                               
                                                    <td>
                                                        <div className="btn-group" role="group" aria-label="">
                                                            <button type="button" className="btn btn-primary btn-sm" onClick={() => this.enviarEnfermedad1(itemEnfermedad1.id_paciente, itemEnfermedad1.nombres, itemEnfermedad1.apellidos)}><FontAwesomeIcon icon={faArrowRight} /></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row">
                                    <div className='col-xs-12'>
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination">
                                                <li className="page-item"><a className="page-link" href="#" onClick={() => this.BuscarEnf2(1)}><FontAwesomeIcon icon={faAngleDoubleLeft} /></a></li>
                                                <li className="page-item"><a className="page-link" href="#" onClick={() => this.BuscarEnf2(actual - 1)}><FontAwesomeIcon icon={faAngleLeft} /></a></li>
                                                {(() => {
                                                    let p = [];
                                                    /*if (ultimo >= 86) {
                                                        var ult = 86;
                                                    } else {
                                                        ult = ultimo;
                                                    }
                                                    for (let i = 1; i <= ult; i++) {
                                                        p.push(<li className="page-item" key={i}><a className="page-link" href="#" onClick={() => this.BuscarEnf2(i)}>{i}</a></li>);
                                                    }*/
                                                    p.push(<li className="page-item" key={actual}><a className="page-link" href="#" onClick={() => this.BuscarEnf2(actual)}>{actual}</a></li>);
                                                    return p;
                                                })()}
                                                <li className="page-item"><a className="page-link" href="#" onClick={() => this.BuscarEnf2(actual + 1)}><FontAwesomeIcon icon={faAngleRight} /></a></li>
                                                <li className="page-item"><a className="page-link" href="#" onClick={() => this.BuscarEnf2(ultimo)}><FontAwesomeIcon icon={faAngleDoubleRight} /></a></li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <div className="card-footer text-muted">
                                        <b>Pagina</b> <span class="badge bg-primary">{actual}</span> <b>de</b> <span class="badge bg-primary">{ultimo}</span> | <b>Registros</b> <span class="badge bg-success">{pagina}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Pacientes;