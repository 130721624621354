import React from "react";
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import Servidor from "../../servicios/servidor";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faDownload } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2';

class Listar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datosCargados: false,
      query: [],
      pagina: [],
    };
  }
  borrarRegistros = (id) => {
    console.log(id);
    Swal.fire({
      title: 'Estas seguro de eliminar el registro?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Borrar',
      denyButtonText: `No borrar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        fetch(Api + "DelArchivos/" + id)
          .then((respuesta) => respuesta.json())
          .then((datosRespuesta) => {
            console.log(datosRespuesta);
            Swal.fire('Borrado!', datosRespuesta.msj, 'success')
            this.cargarCargos();
          })
          .catch(console.log);
      } else if (result.isDenied) {
        Swal.fire('Cancelado', '', 'info')
      }
    })
  };
  cargarCargos() {
    console.log("Formulario Enviado...");
    fetch(Api + "Archivos")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({ datosCargados: true, query: datosRespuesta });
      })
      .catch(console.log);
  }
  componentDidMount() {
    this.cargarCargos();
  }
  render() {
    const { datosCargados, query } = this.state;
    if (!datosCargados) {
      var load = 'Cargando datos....';
    } else {
      var load = '';
    }
    return (
      <div className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <Link className="btn btn-success btn-sm" to={"/AddDocumentos"}>
                Agregar Documento
              </Link>
            </div>
            <div className="card-body">
              <h4>Lista de Documentos</h4>
              <div className="row">
                <table className="table table-hover table-condensed table-sm">
                  <thead className="thead-inverse">
                    <tr>
                      <th>NOMBRE DEL ARCHIVO</th>
                      <th>RUTA DE DESCARGA</th>
                      <th>ACCIONES</th>
                    </tr>
                  </thead>
                  <tbody>
                    {load}
                    {query.map((cargos) => (
                      <tr key={cargos.id}>
                        <td>{cargos.nombre}</td>
                        <td>
                          <a style={{color: '#FFFFFF'}} className="btn-info btn-sm" target="_blank" href={Servidor + cargos.ruta}><FontAwesomeIcon icon={faDownload} /></a>
                        </td>
                        <td>
                          <div className="btn-group" role="group" aria-label="">

                            <button
                              type="button"
                              className="btn btn-danger btn-sm"
                              onClick={() =>
                                this.borrarRegistros(cargos.id)
                              }
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Listar;