import React from "react";
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'

class Editar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datosCargados: false,
            operacion: [],
            errores: []
        };
    }
    cambioValor = (e) => {
        const state = this.state.operacion;
        state[e.target.name] = e.target.value;
        this.setState({ operacion: state });
    };
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    enviarDatos = (e) => {
        e.preventDefault();
        console.log("Formulario Enviado...");
        var errores = [];
        const nombre = document.getElementById('nombre').value;
        if (!nombre) { errores.push("error_nombre"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let form = document.getElementById('form');
        let formData = new FormData(form);
        console.log(formData);
        fetch(Api + "UpOperaciones", {
            method: "POST",
            body: formData
        })
            .then(respuesta => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                Swal.fire(
                    'Operaciones!',
                    datosRespuesta.msj,
                    'success'
                )
                this.props.history.push("/MasterOperaciones");
            })
            .catch(console.log)
    }
    componentDidMount() {
        console.log(this.props.match.params.id);
        fetch(Api + "Operaciones/" + this.props.match.params.id,
            {
                method: "GET",
                headers: new Headers({
                    'Authorization': 'koala2021'
                }),
            })
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                this.setState({ datosCargados: true, operacion: datosRespuesta }); //
            })
            .catch(console.log);
    }
    render() {
        const { datosCargados, operacion } = this.state;
        if (!datosCargados) {
            var load = 'Cargando datos....';
        } else {
            var load = '';
        }
        return (
            <div>
                <div className="content-wrapper">
                    <div className="content">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-header">
                                    {
                                        load ? (
                                            load
                                        ) : (
                                            "Editar Operación"
                                        )
                                    }
                                </div>
                                <div className="card-body">
                                    <form onSubmit={this.enviarDatos} id="form">
                                        <div className="form-group">
                                            <label htmlFor="id">Id :</label>
                                            <input type="text" name="id" id="id" value={operacion.id_operaciones} className="form-control form-control-sm" placeholder="" aria-describedby="helpId" onChange={this.cambioValor} readOnly />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="nombre">Descripción:</label>
                                            <input type="text" name="nombre" id="nombre" defaultValue={operacion.descripcion} className={((this.verificarError("error_nombre")) ? "is-invalid" : "") + " form-control form-control-sm"} onChange={this.cambioValor} />
                                            <small id="helpId" className="invalid-feedback">Digite la Descripción</small>
                                        </div>
                                        <br />
                                        <div className="btn-group" role="group" aria-label="">
                                            <button type="submit" className="btn btn-primary btn-sm"><FontAwesomeIcon icon={faEdit} />&nbsp;&nbsp;Editar</button>&nbsp;&nbsp;
                                            <Link className="btn btn-danger btn-sm" to={"/MasterOperaciones"}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Editar;