import React from 'react';
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faUpload, faFileUpload, faUserPlus, faSignOutAlt, faSave, faViruses } from '@fortawesome/free-solid-svg-icons'
class Crear extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cedula: "", nombre: "", apellido: "", email: "", fecha: "", firma: "", foto: "", telefono: "", celular: "", direccion: "", barrio: "",
            depa: "", muni: "", user: "", pass: "", cargo: "", area: "", rol: "", admin: "", esta: "",
            departamentos: [],
            municipios: [],
            cargos: [],
            roles: [],
            errores: []
        }
    }
    cargarDepartamentos() {
        fetch(Api + "Departamentos")
            .then(respuesta => respuesta.json())
            .then((datosDepartamentos) => {
                //console.log(datosDepartamentos);
                this.setState({ departamentos: datosDepartamentos })
            })
            .catch(console.log);
    }
    cargarMunicipios = (e) => {
        fetch(Api + "Municipios/" + e.target.value)
            .then(respuesta => respuesta.json())
            .then((datosMunicipios) => {
                //console.log(datosMunicipios);
                this.setState({ municipios: datosMunicipios });
                this.municipio.focus();
            })
            .catch(console.log)
    }
    cargarCargos() {
        fetch(Api + "Cargos")
            .then(respuesta => respuesta.json())
            .then((datosCargos) => {
                //console.log(datosDepartamentos);
                this.setState({ cargos: datosCargos })
            })
            .catch(console.log);
    }
    cargarRoles() {
        fetch(Api + "Roles")
            .then(respuesta => respuesta.json())
            .then((datosRoles) => {
                //console.log(datosDepartamentos);
                this.setState({ roles: datosRoles })
            })
            .catch(console.log);
    }
    cambioValor = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState({ state, errores: [] })
    }
    onFirmChange = (e) => {
        this.setState({ firma: e.target.files[0].name });
        console.log(this.state.firma);
        /*const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file)
        reader.onload = (e) => {
            console.log('Result: ' + e.target.result);
            this.setState({ firma: e.target.result});
        }*/
    }
    onPhotoChange = (e) => {
        this.setState({ foto: e.target.files[0].name });
        console.log(this.state.foto);
        /*const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file)
        reader.onload = (e) => {
            console.log('Result: ' + e.target.result);
            this.setState({ foto: e.target.result});
        }*/
    }
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    enviarDatos = (e) => {
        e.preventDefault();
        console.log("Formulario Enviado...");
        const { cedula, nombre, apellido, email, fecha, firma, foto, telefono, celular, direccion, barrio, depa, muni, user, pass, cargo, area, rol, admin, esta } = this.state;
        var errores = [];
        if (!cedula) { errores.push("error_cedula"); errores.push("error_global"); }
        if (!nombre) { errores.push("error_nombre"); errores.push("error_global"); }
        if (!apellido) { errores.push("error_apellido"); errores.push("error_global"); }
        if (!fecha) { errores.push("error_fecha"); errores.push("error_global"); }
        //if (!firma) { errores.push("error_firma"); }
        //if (!foto) { errores.push("error_foto"); }
        if (!email) { errores.push("error_email"); errores.push("error_global"); }
        if (!telefono) { errores.push("error_telefono"); errores.push("error_global"); }
        if (!celular) { errores.push("error_celular"); errores.push("error_global"); }
        if (!direccion) { errores.push("error_direccion"); errores.push("error_global"); }
        if (!barrio) { errores.push("error_barrio"); }
        if (!depa) { errores.push("error_depa"); errores.push("error_global"); }
        if (!muni) { errores.push("error_muni"); errores.push("error_global"); }
        if (!user) { errores.push("error_user"); errores.push("error_global"); }
        if (!pass) { errores.push("error_pass"); errores.push("error_global"); }
        if (!cargo) { errores.push("error_cargo"); errores.push("error_global"); }
        if (!area) { errores.push("error_area"); errores.push("error_global"); }
        if (!rol) { errores.push("error_rol"); errores.push("error_global"); }
        if (!admin) { errores.push("error_admin"); errores.push("error_global"); }
        if (!esta) { errores.push("error_estado"); errores.push("error_global"); }

        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        //var datosEnviar = { cedula: cedula, nombre: nombre, apellido: apellido, email: email, fecha: fecha, firma: firma, foto: foto, telefono: telefono, celular: celular, direccion: direccion, barrio: barrio, depa: depa, muni: muni, user: user, pass: pass, cargo: cargo, area: area, rol: rol, admin: admin, esta: esta }
        let form = document.getElementById('form');
        let formData = new FormData(form);
        fetch(Api + "AddUsuarios", {
            method: "POST",
            body: formData
        })
            .then(respuesta => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                Swal.fire(
                    'Usuarios!',
                    datosRespuesta.msj,
                    'success'
                )
                document.getElementById('form').reset();
                window.$('#nuevoRegistro').modal('hide');
                this.props.history.push("/MasterUsuarios");
            })
            .catch(console.log)
    }
    componentDidMount() {
        this.cargarDepartamentos();
        this.cargarCargos();
        this.cargarRoles();
    }
    render() {
        const { cedula, nombre, apellido, email, fecha, firma, foto, telefono, celular, direccion, barrio, depa, muni, user, pass, cargo, area, rol, admin, esta, departamentos, municipios, cargos, roles } = this.state;
        return (
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="nuevoPacienteSubLabel">Registro de Usuarios</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="card">
                            <div style={{ backgroundImage: 'linear-gradient(#2FB3FB, #003153)', color: '#FFFFFF', borderTopLeftRadius: 5, borderTopRightRadius: 5 }} className="card-header text-center">
                                <h5 style={{ fontSize: 18 }}>Formulario de Registro</h5>
                            </div>
                            <div className="card-body">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="datos_basicos-tab" data-bs-toggle="tab" data-bs-target="#datos_basicos" type="button" role="tab" aria-controls="datos_basicos" aria-selected="true">Datos Básicos</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="direcciones-tab" data-bs-toggle="tab" data-bs-target="#direcciones" type="button" role="tab" aria-controls="direcciones" aria-selected="false">Direcciones</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="roles-tab" data-bs-toggle="tab" data-bs-target="#roles" type="button" role="tab" aria-controls="roles" aria-selected="false">Roles</button>
                                    </li>
                                </ul>
                                <form id="form">
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="datos_basicos" role="tabpanel" aria-labelledby="datos_basicos-tab">
                                            <br />
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div style={{ marginTop: 0 }} className='row g-3'>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="cedula">Cédula</label>
                                                                    <input className={((this.verificarError("error_cedula")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="cedula" id="cedula" value={cedula} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="nombre">Nombre</label>
                                                                    <input className={((this.verificarError("error_nombre")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="nombre" id="nombre" value={nombre} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="apellido">Apellido</label>
                                                                    <input className={((this.verificarError("error_apellido")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="apellido" id="apellido" value={apellido} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className='row g-3'>
                                                                <div className="col-md-8">
                                                                    <label htmlFor="email">Email</label>
                                                                    <input className={((this.verificarError("error_email")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="email" id="email" value={email} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label htmlFor="fecha">Fecha Nacimiento</label>
                                                                    <input className={((this.verificarError("error_fecha")) ? "is-invalid" : "") + " form-control form-control-sm"} type="date" name="fecha" id="fecha" value={fecha} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className='row g-3'>
                                                                <div className="col-md-6">
                                                                    <label htmlFor="firma">Firma</label>
                                                                    <input className={((this.verificarError("error_firma")) ? "is-invalid" : "") + " form-control form-control-sm"} type="file" name="firma" id="firma" accept='.jpeg, .png, .jpg' defaultValue={firma} placeholder="" aria-describedby="helpId" onChange={e => { this.cambioValor(e); this.onFirmChange(e) }} />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label htmlFor="foto">Foto de Perfil</label>
                                                                    <input className={((this.verificarError("error_foto")) ? "is-invalid" : "") + " form-control form-control-sm"} type="file" name="foto" id="foto" accept='.jpeg, .png, .jpg' defaultValue={foto} placeholder="" aria-describedby="helpId" onChange={e => { this.cambioValor(e); this.onPhotoChange(e) }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="direcciones" role="tabpanel" aria-labelledby="direcciones-tab">
                                            <br />
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div style={{ marginTop: 0 }} className='row g-3'>
                                                                <div className="col-md-6">
                                                                    <label htmlFor="telefono">Teléfeno</label>
                                                                    <input className={((this.verificarError("error_telefono")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="telefono" id="telefono" value={telefono} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label htmlFor="celular">Celular</label>
                                                                    <input className={((this.verificarError("error_celular")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="celular" id="celular" value={celular} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className='row g-3'>
                                                                <div className="col-md-6">
                                                                    <label htmlFor="direccion">Dirección</label>
                                                                    <input className={((this.verificarError("error_direccion")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="direccion" id="direccion" value={direccion} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label htmlFor="barrio">Barrio</label>
                                                                    <input className={((this.verificarError("error_barrio")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="barrio" id="barrio" value={barrio} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className='row g-3'>
                                                                <div className="col-md-6">
                                                                    <label htmlFor="depa">Departamento</label>
                                                                    <select
                                                                        className={((this.verificarError("error_depa")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                                        name="depa"
                                                                        id="depa"
                                                                        title='DEPARTAMENTO'
                                                                        onChange={e => {
                                                                            this.cambioValor(e);
                                                                            this.cargarMunicipios(e);
                                                                        }}
                                                                    >
                                                                        <option value="">Seleccionar</option>
                                                                        {
                                                                            departamentos.map(itemDepartamento => (
                                                                                <option key={itemDepartamento.cod_dep} value={itemDepartamento.cod_dep}>{itemDepartamento.nombre_dep}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label htmlFor="muni">Municipio</label>
                                                                    <select
                                                                        className={((this.verificarError("error_muni")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                                        name="muni"
                                                                        id="muni"
                                                                        title='MUNICIPIO'
                                                                        onChange={this.cambioValor}
                                                                        ref={(input) => { this.municipio = input }}
                                                                    >
                                                                        <option value="">Seleccionar</option>
                                                                        {
                                                                            municipios.map(itemMunicipio => (
                                                                                <option key={itemMunicipio.cod_mun} value={itemMunicipio.cod_mun}>{itemMunicipio.nombre_mun}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="roles" role="tabpanel" aria-labelledby="roles-tab">
                                            <br />
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div style={{ marginTop: 0 }} className='row g-3'>
                                                                <div className="col-md-6">
                                                                    <label htmlFor="user">Usuario</label>
                                                                    <input className={((this.verificarError("error_user")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="user" id="user" value={user} placeholder="" autoComplete="off" aria-describedby="helpId" onChange={this.cambioValor} />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label htmlFor="pass">Contraseña</label>
                                                                    <input className={((this.verificarError("error_pass")) ? "is-invalid" : "") + " form-control form-control-sm"} type="password" name="pass" id="pass" value={pass} placeholder="" autoComplete="off" aria-describedby="helpId" onChange={this.cambioValor} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className='row g-3'>
                                                                <div className="col-md-6">
                                                                    <label htmlFor="cargo">Cargo</label>
                                                                    <select
                                                                        className={((this.verificarError("error_cargo")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                                        name="cargo"
                                                                        id="cargo"
                                                                        title='CARGO'
                                                                        onChange={this.cambioValor}
                                                                        ref={(input) => { this.cargo = input }}
                                                                    >
                                                                        <option value="">Seleccionar</option>
                                                                        {
                                                                            cargos.map(itemCargo => (
                                                                                <option key={itemCargo.id_cargo} value={itemCargo.nombre_cargo}>{itemCargo.nombre_cargo}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label htmlFor="area">Área</label>
                                                                    <select
                                                                        className={((this.verificarError("error_admin")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                                        name="area"
                                                                        id="area"
                                                                        title='ÁREA'
                                                                        onChange={this.cambioValor}
                                                                        ref={(input) => { this.area = input }}
                                                                    >
                                                                        <option value="">Seleccionar</option>
                                                                        <option value="OFICINA">OFICINA</option>
                                                                        <option value="DOMICILIO">DOMICILIO</option>
                                                                        <option value="CONSULTORIO">CONSULTORIO</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className='row g-3'>
                                                                <div className="col-md-6">
                                                                    <label htmlFor="rol">Rol</label>
                                                                    <select
                                                                        className={((this.verificarError("error_rol")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                                        name="rol"
                                                                        id="rol"
                                                                        title='ROL'
                                                                        onChange={this.cambioValor}
                                                                        ref={(input) => { this.rol = input }}
                                                                    >
                                                                        <option value="">Seleccionar</option>
                                                                        {
                                                                            roles.map(itemRol => (
                                                                                <option key={itemRol.id_roles} value={itemRol.id_roles}>{itemRol.nombre}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <label htmlFor="admin">Administrador</label>
                                                                    <select
                                                                        className={((this.verificarError("error_admin")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                                        name="admin"
                                                                        id="admin"
                                                                        title='ADMINISTRADOR'
                                                                        onChange={this.cambioValor}
                                                                        ref={(input) => { this.admin = input }}
                                                                    >
                                                                        <option value="">Seleccionar</option>
                                                                        <option value="Si">Si</option>
                                                                        <option value="No">No</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <label htmlFor="estado">Estado</label>
                                                                    <select
                                                                        className={((this.verificarError("error_estado")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                                        name="esta"
                                                                        id="esta"
                                                                        title='ESTADO'
                                                                        onChange={this.cambioValor}
                                                                        ref={(input) => { this.admin = input }}
                                                                    >
                                                                        <option value="">Seleccionar</option>
                                                                        <option value="Activo">Activo</option>
                                                                        <option value="No Activo">No Activo</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                    {((this.verificarError("error_global"))?
                        <span style={{backgroundColor:'#F8D7DA', borderColor:'#F5C2C7', color:'#842029'}} class="badge bg-danger">Faltan campos por Digitar!</span>:"")}                  
                        <button type="submit" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><FontAwesomeIcon icon={faSignOutAlt} />&nbsp;&nbsp;Cerrar</button>
                        <button type="button" className="btn btn-primary btn-sm" id='btnGuardarNuevoPacienteSub' onClick={this.enviarDatos}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Guardar</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Crear;