import React from 'react';
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'

class Crear extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nit: "",
            nombre: "",
            depa: "",
            muni: "",
            direccion: "",
            telefono: "",
            celular: "",
            contacto: "",
            email1: "",
            email2: "",
            observaciones: "",
            banco: "",
            numero_cuenta: "",
            tipo_cuenta: "",
            departamentos: [],
            municipios: [],
            errores: []
        }
    }
    cargarDepartamentos() {
        fetch(Api + "Departamentos")
            .then(respuesta => respuesta.json())
            .then((datosDepartamentos) => {
                //console.log(datosDepartamentos);
                this.setState({ departamentos: datosDepartamentos })
            })
            .catch(console.log);
    }
    cargarMunicipios = (e) => {
        fetch(Api + "Municipios/" + e.target.value)
            .then(respuesta => respuesta.json())
            .then((datosMunicipios) => {
                //console.log(datosMunicipios);
                this.setState({ municipios: datosMunicipios });
                this.municipio.focus();
            })
            .catch(console.log)
    }
    cambioValor = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState({ state, errores: [] })
    }
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    enviarDatos = (e) => {
        e.preventDefault();
        console.log("Formulario Enviado...");
        const { nit, nombre, depa, muni, direccion, telefono, celular, contacto, tel_contacto, email1, email2, observaciones, banco, numero_cuenta, tipo_cuenta } = this.state;
        var errores = [];
        if (!nit) { errores.push("error_nit"); }
        if (!nombre) { errores.push("error_nombre"); }
        if (!depa) { errores.push("error_departamento"); }
        if (!muni) { errores.push("error_municipio"); }
        if (!direccion) { errores.push("error_direccion"); }
        if (!telefono) { errores.push("error_telefono"); }
        if (!celular) { errores.push("error_celular"); }
        if (!contacto) { errores.push("error_contacto"); }
        if (!tel_contacto) { errores.push("error_tel_contacto"); }
        if (!email1) { errores.push("error_email1"); }
        if (!email2) { errores.push("error_email2"); }
        if (!banco) { errores.push("error_banco"); }
        if (!numero_cuenta) { errores.push("error_numero_cuenta"); }
        if (!tipo_cuenta) { errores.push("error_tipo_cuenta"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let form = document.getElementById('form');
        let formData = new FormData(form);
        fetch(Api + "AddProveedores", {
            method: "POST",
            body: formData
        })
            .then(respuesta => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                Swal.fire(
                    'Proveedores!',
                    datosRespuesta.msj,
                    'success'
                )
                this.props.history.push("/MasterProveedores");
            })
            .catch(console.log)
    }
    componentDidMount() {
        this.cargarDepartamentos();
    }
    render() {
        const { nit, nombre, depa, departamentos, muni, municipios, direccion, telefono, celular, contacto, tel_contacto, email1, email2, observaciones, banco, numero_cuenta, tipo_cuenta } = this.state;
        return (
            <div className="content-wrapper">
                <div className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header">
                                Registro de Proveedores
                            </div>
                            <div className="card-body">
                                <form id='form' onSubmit={this.enviarDatos}>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div style={{ marginBottom: 16 }} className='row g-3'>
                                                <div className="col-md-2">
                                                    <label htmlFor="nit">NIT / C.C.</label>
                                                    <input className={((this.verificarError("error_nit")) ? "is-invalid" : "") + " form-control form-control-sm"} maxLength={12} type="text" name="nit" id="nit" value={nit} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback">Digite el NIT / C.C.</small>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label htmlFor="nombre">Nombre</label>
                                                    <input className={((this.verificarError("error_nombre")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="nombre" id="nombre" value={nombre} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label htmlFor="depa">Departamento</label>
                                                    <select
                                                        className={((this.verificarError("error_departamento")) ? "is-invalid" : "") + " form-select form-select-sm"} name="depa" id="depa" title='DEPARTAMENTO'
                                                        onChange={e => {
                                                            this.cambioValor(e);
                                                            this.cargarMunicipios(e);
                                                        }}
                                                    >
                                                        <option value="">Seleccionar</option>
                                                        {
                                                            departamentos.map(itemDepartamento => (
                                                                <option key={itemDepartamento.cod_dep} value={itemDepartamento.cod_dep}>{itemDepartamento.nombre_dep}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    <small id="helpId" className="invalid-feedback">Seleccione el Departamento</small>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label htmlFor="muni">Municipio</label>
                                                    <select className={((this.verificarError("error_municipio")) ? "is-invalid" : "") + " form-select form-select-sm"} name="muni" id="muni" title='MUNICIPIO'
                                                        onChange={this.cambioValor}
                                                        ref={(input) => { this.municipio = input }}
                                                    >
                                                        <option value="">Seleccionar</option>
                                                        {
                                                            municipios.map(itemMunicipio => (
                                                                <option key={itemMunicipio.cod_mun} value={itemMunicipio.cod_mun}>{itemMunicipio.nombre_mun}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    <small id="helpId" className="invalid-feedback">Seleccione el Municipio</small>
                                                </div>
                                            </div>
                                            <div style={{ marginBottom: 16 }} className='row g-3'>
                                                <div className="col-md-2">
                                                    <label htmlFor="telefono">Teléfono</label>
                                                    <input className={((this.verificarError("error_telefono")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="telefono" id="telefono" value={telefono} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback">Digite el Teléfono</small>
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="direccion">Dirección</label>
                                                    <input className={((this.verificarError("error_direccion")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="direccion" id="direccion" value={direccion} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback">Digite la Dirección</small>
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor="email1">Email 1</label>
                                                    <input className={((this.verificarError("error_email1")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="email1" id="email1" value={email1} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback">Digite el Email 1</small>
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor="email2">Email 2</label>
                                                    <input className={((this.verificarError("error_email2")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="email2" id="email2" value={email2} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback">Digite el Email 2</small>
                                                </div>
                                            </div>
                                            <div style={{ marginBottom: 16 }} className='row g-3'>
                                                <div className="col-md-2">
                                                    <label htmlFor="celular">Celular</label>
                                                    <input className={((this.verificarError("error_celular")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="celular" id="celular" value={celular} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback">Digite el Celular</small>
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor="contacto">Contacto</label>
                                                    <input className={((this.verificarError("error_contacto")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="contacto" id="contacto" value={contacto} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback">Digite el Contacto</small>
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="tel_contacto">Teléfono Contacto</label>
                                                    <input className={((this.verificarError("error_tel_contacto")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="tel_contacto" id="tel_contacto" value={tel_contacto} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback">Digite el Teléfono Contacto</small>
                                                </div>
                                            </div>
                                            <div style={{ marginBottom: 16 }} className='row g-3'>
                                                <div className="col-md-4">
                                                    <label htmlFor="banco">Banco</label>
                                                    <input className={((this.verificarError("error_banco")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="banco" id="banco" value={banco} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback">Digite el Banco</small>
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="numero_cuenta">No. Cuenta</label>
                                                    <input className={((this.verificarError("error_numero_cuenta")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="numero_cuenta" id="numero_cuenta" value={numero_cuenta} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback">Digite el No. Cuenta</small>
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="tipo_cuenta">Tipo Cuenta</label>
                                                    <select name="tipo_cuenta"
                                                        id="tipo_cuenta"
                                                        className={((this.verificarError("error_tipo_cuenta")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                        onChange={this.cambioValor}>
                                                        <option value="">Seleccione el T. Cuenta</option>
                                                        <option value="Ahorros">Ahorros</option>
                                                        <option value="Corriente">Corriente</option>
                                                    </select>
                                                    <small id="helpId" className="invalid-feedback">Seleccione el T. Cuenta</small>
                                                </div>
                                            </div>
                                            <div style={{ marginBottom: 16 }} className='row g-3'>
                                                <div className="col-md-8">
                                                    <label htmlFor="observaciones">Observaciones</label>
                                                    <input className="form-control form-control-sm" type="text" name="observaciones" id="observaciones" value={observaciones} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="btn-group" role="group" aria-label="">
                                        <button type="submit" className="btn btn-success btn-sm"><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Agregar</button>&nbsp;&nbsp;
                                        <Link className="btn btn-danger btn-sm" to={"/MasterProveedores"}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Crear;