import React from 'react';
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import Swal from 'sweetalert2';
class Crear extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            codigo:"",
            nombre:"",
            tipo:"",
            cuenta:"",
            forma:"",
            email:"",
            errores:[]
        }
    }
    cambioValor = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState({ state, errores:[] })
    }
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    enviarDatos = (e) => {
        e.preventDefault();
        console.log("Formulario Enviado...");
        const {nombre, tipo,codigo,cuenta,forma,email} = this.state;
        console.log(nombre);
        console.log(tipo);
        console.log(cuenta);
        console.log(codigo);
        var errores = [];
        if (!codigo) {
            errores.push("error_codigo");
        }
        if (!nombre) {
            errores.push("error_nombre");
        }
        if (!tipo) {
            errores.push("error_tipo");
        }
        if (!cuenta) {
            errores.push("error_cuenta");
        }
        if (!email) {
            errores.push("error_cuenta");
        }
        this.setState({ errores:errores });
        if (errores.length > 0) {
            return false;
        }
        var datosEnviar = {nombre:nombre, tipo:tipo,codigo:codigo,cuenta:cuenta,forma:forma,email:email}
        fetch(Api + "AddEmpresas", {
            method:"POST",
            body:JSON.stringify(datosEnviar)
        })
        .then(respuesta=>respuesta.json())
        .then((datosRespuesta)=> {
            console.log(datosRespuesta)
            Swal.fire(
                'Empresas!',
                datosRespuesta.msj,
                'success'
              )
            this.props.history.push("/MasterEmpresas");
        })
        .catch(console.log)
    }
    render() {
        const {nombre, tipo,codigo,cuenta,forma,email} = this.state;
        return (
            <div className="content-wrapper">
          <div className="content">
            <div className="container-fluid">
            <div className="card">
                <div className="card-header">
                    Registro de Empresas
                </div>
                <div className="card-body">
                    <form onSubmit={this.enviarDatos}>
                    <div className="form-group">
                          <label htmlFor="codigo">NIT</label>
                          <input className={((this.verificarError("error_codigo"))?"is-invalid":"")+" form-control form-control-sm"} type="text" name="codigo" id="codigo" value={codigo} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                          <small id="helpId" className="invalid-feedback">Digita el NIT</small>
                        </div>
                        <div className="form-group">
                          <label htmlFor="nombre">Nombre</label>
                          <input className={((this.verificarError("error_nombre"))?"is-invalid":"")+" form-control form-control-sm"} type="text" name="nombre" id="nombre" value={nombre} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                          <small id="helpId" className="invalid-feedback">Digita el Nombre</small>
                        </div>
                        <div className="form-group">
                          <label htmlFor="tipo">Rip</label>
                          <input className={((this.verificarError("error_tipo"))?"is-invalid":"")+" form-control form-control-sm"} type="text" name="tipo" id="tipo" value={tipo} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />

                          <small id="helpId" className="invalid-feedback">Digite el Código Rip</small>
                        </div>

                        <div className="form-group">
                          <label htmlFor="cuenta">Teléfono</label>
                          <input className={((this.verificarError("error_cuenta"))?"is-invalid":"")+" form-control form-control-sm"} type="text" name="cuenta" id="cuenta" value={cuenta} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                          <small id="helpId" className="invalid-feedback">Digita el Número de Teléfono</small>
                        </div>
                        <div className="form-group">
                          <label htmlFor="email">Email</label>
                          <input className={((this.verificarError("error_email"))?"is-invalid":"")+" form-control form-control-sm"} type="text" name="email" id="email" value={email} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                          <small id="helpId" className="invalid-feedback">Digita el email</small>
                        </div>
                        <br />
                        <div className="btn-group" role="group" aria-label="">
                            <button type="submit" className="btn btn-success btn-sm">Agregar</button>&nbsp;&nbsp;
                            <Link className="btn btn-danger btn-sm" to={"/MasterEmpresas"}>Cancelar</Link>
                        </div>
                    </form>
                </div>
            </div>
            </div>
            </div>
            </div>
        );
    }
}
 
export default Crear;