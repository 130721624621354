import React from "react";
import Api from "../servicios/api";

 class Historial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noticias:[],pac:[],usu:[]
    };
  }
  Noticias() {

    fetch(Api + "Home/noticias")
      .then((respuesta) => respuesta.json())
      .then((datos) => {
        console.log(datos);
        this.setState({ noticias: datos.noticias, pac:datos.pacientes, usu:datos.profesionales });
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.Noticias();
    
    //this.Buscar(1);
    
  }

  render() {
    const {noticias,pac,usu } = this.state;
  return (
    <div>
        <marquee bgcolor="F5FA9C">{noticias.map((item,i) => (
                      <b key={i}>
                        {item.nombre} {item.fecha} |||
                      </b>
                    ))}
                    </marquee>

                    <marquee bgcolor="F5FA9C">
                    {pac.map((item,i) => (
                      <b key={i}>
                        Hoy es el cumple del Paciente:{item.nombre} |||
                      </b>
                    ))}
                    </marquee>
                    <marquee bgcolor="F5FA9C">
                    {usu.map((item,i) => (
                      <b key={i}>
                        Nuestro compañer@ {item.nombre} esta cumpliendo hoy |||
                      </b>
                    ))}</marquee>
    </div>
  );
   }
}
export default Historial;