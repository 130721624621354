import React from 'react';
import Api from "../../servicios/api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes, faArrowRight, faAngleDoubleLeft, faAngleLeft, faAngleDoubleRight, faAngleRight } from '@fortawesome/free-solid-svg-icons'

class Enfermedades2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datosCargados: false,
            pagina: [],
            enfermedades2: []
        }
    }
    resetForm() {
        console.log("Entra acá");
        document.getElementById('bdescripcion2').value = "";
        document.getElementById('bcodigo2').value = "";
        document.getElementById('bdescripcion2').focus();
        this.BuscarEnf2(1);
    }
    enviarEnfermedad2 = (codEnf2, descripcionEnf2) => {
        console.log(codEnf2);
        document.getElementById('enfermedad2').value = codEnf2;
        document.getElementById('descripcion_enf2').value = descripcionEnf2;
        window.$('#nuevaEnfermedad2').modal('hide');
    }
    componentDidMount() {
        this.BuscarEnf2(1);
    }
    BuscarEnf2(page) {
        let bdescripcion2 = document.getElementById('bdescripcion2').value;
        let bcodigo2 = document.getElementById('bcodigo2').value;
        console.log("Formulario Enviado...");
        if (bdescripcion2 == '') {
            bdescripcion2 = 0;
        }
        if (bcodigo2 == '') {
            bcodigo2 = 0;
        }
        fetch(Api + "SearchEnfermedades/" + bcodigo2 + "/" + bdescripcion2 + "?page=" + page)
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                this.setState({ datosCargados: true, enfermedades2: datosRespuesta.data, pagina: datosRespuesta.total, ultimo: datosRespuesta.last_page, actual: datosRespuesta.current_page });
            })
            .catch(console.log);
    }
    render() {
        const { datosCargados, enfermedades2, pagina, ultimo, actual } = this.state;
        if (!datosCargados) {
            var load = 'Cargando datos....';
        } else {
            var load = '';
        }
        return (
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="nuevaEnfermedad1Label">Registro de Enfermedades 2</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="card">
                            <div style={{ backgroundImage: 'linear-gradient(#2FB3FB, #003153)', color: '#FFFFFF', borderTopLeftRadius: 5, borderTopRightRadius: 5 }} className="card-header text-center">
                                <h5 style={{ fontSize: 18 }} >Lista de Enfermedades</h5>
                            </div>
                            <div className='card-body'>
                                <div className="row">
                                    <div className="col-xl-5">
                                        <input type="text" id="bdescripcion2" name="bdescripcion2" placeholder="Descripción" className="form-control form-control-sm" />
                                    </div>
                                    <div className="col-xl-3">
                                        <input type="text" id="bcodigo2" name="bcodigo2" placeholder="Código" className="form-control form-control-sm" />
                                    </div>
                                    <div className="col-xl-4">
                                        <button type="button" className="btn btn-primary btn-sm" onClick={() => this.BuscarEnf2(1)}><FontAwesomeIcon icon={faSearch} />&nbsp;&nbsp;Buscar</button>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-danger btn-sm" onClick={() => this.resetForm()} ><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <table className="table table-hover table-condensed table-sm">
                                        <thead className="thead-inverse">
                                            <tr>
                                                <th>CÓDIGO</th>
                                                <th>DESCRIPCIÓN</th>
                                                <th>SEXO</th>
                                                <th>LIM. INFERIOR</th>
                                                <th>LIM. SUPERIOR</th>
                                                <th>SELECCIONAR</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {load}
                                            {enfermedades2.map((itemEnfermedad2) => (
                                                <tr key={itemEnfermedad2.id_enfermedad}>
                                                    <td>{itemEnfermedad2.codigo_enf}</td>
                                                    <td>{itemEnfermedad2.descripcion}</td>
                                                    <td>{itemEnfermedad2.sexo}</td>
                                                    <td>{itemEnfermedad2.lim_inf}</td>
                                                    <td>{itemEnfermedad2.lim_sup}</td>
                                                    <td>
                                                        <div className="btn-group" role="group" aria-label="">
                                                            <button type="button" className="btn btn-primary btn-sm" onClick={() => this.enviarEnfermedad2(itemEnfermedad2.codigo_enf, itemEnfermedad2.descripcion)}><FontAwesomeIcon icon={faArrowRight} /></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row">
                                    <div className='col-xs-12'>
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination">
                                                <li className="page-item"><a className="page-link" href="#" onClick={() => this.BuscarEnf2(1)}><FontAwesomeIcon icon={faAngleDoubleLeft} /></a></li>
                                                <li className="page-item"><a className="page-link" href="#" onClick={() => this.BuscarEnf2(actual - 1)}><FontAwesomeIcon icon={faAngleLeft} /></a></li>
                                                {(() => {
                                                    let p = [];
                                                    /*if (ultimo >= 86) {
                                                        var ult = 86;
                                                    } else {
                                                        ult = ultimo;
                                                    }
                                                    for (let i = 1; i <= ult; i++) {
                                                        p.push(<li className="page-item" key={i}><a className="page-link" href="#" onClick={() => this.BuscarEnf2(i)}>{i}</a></li>);
                                                    }*/
                                                    p.push(<li className="page-item" key={actual}><a className="page-link" href="#" onClick={() => this.BuscarEnf2(actual)}>{actual}</a></li>);
                                                    return p;
                                                })()}
                                                <li className="page-item"><a className="page-link" href="#" onClick={() => this.BuscarEnf2(actual + 1)}><FontAwesomeIcon icon={faAngleRight} /></a></li>
                                                <li className="page-item"><a className="page-link" href="#" onClick={() => this.BuscarEnf2(ultimo)}><FontAwesomeIcon icon={faAngleDoubleRight} /></a></li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <div className="card-footer text-muted">
                                        <b>Pagina</b> <span class="badge bg-primary">{actual}</span> <b>de</b> <span class="badge bg-primary">{ultimo}</span> | <b>Registros</b> <span class="badge bg-success">{pagina}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Enfermedades2;