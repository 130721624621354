import React from 'react';
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import FechaActual from "../../servicios/FechaGlobal";
import Swal from 'sweetalert2';
import findImage from "../../images/find.png"
import FormularioEnf1 from "./Pacientes"
import FormUsuario from "./Usuarios"
class Crear extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            codigo:"",
            nombre:"",
            tipo:"",
            cuenta:"",
            forma:"",
            errores:[],
  
         
        }
    }
    
   
    cambioValor = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState({ state, errores:[] })
    }
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    enviarDatos = (e) => {
        e.preventDefault();
        console.log("Formulario Enviado...");
        // const {nombre_atencion, tipo,id_atencion,codigo_atencion,cuenta} = this.state.empleado;
    
        let form = document.getElementById('form');
        let formData = new FormData(form);
        console.log(formData);
        //var datosEnviar = {nombre:nombre_atencion, tipo:tipo,id:id_atencion,cuenta:cuenta,codigo:codigo_atencion}
    
    
        fetch(Api + "incidencias/registrar", {
          method: "POST",
          body: formData
        })
          .then(respuesta => respuesta.json())
          .then((datosRespuesta) => {
            console.log(datosRespuesta)
            Swal.fire(
              'Soportes!',
              datosRespuesta.Msj,
              'success'
            )
            this.props.history.push("/MasterSoporte");
          })
          .catch(console.log)
      }
    render() {
        const {nombre, tipo,codigo,fecha} = this.state;
        return (
            <div>
            <div class="modal fade" id="nuevaEnfermedad1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevaEnfermedad1Label" aria-hidden="true">
                      <FormularioEnf1 />
                    </div>
                    <div class="modal fade" id="buscarUsuario" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevaEnfermedad1Label" aria-hidden="true">
                      <FormUsuario />
                    </div>
                    <div className="content-wrapper">
                      <div className="content">
                        <div className="container-fluid">
                          <div className="card">
                            <div className="card-header">Registrar Soporte</div>
                            <div className="card-body">
                              <form onSubmit={this.enviarDatos} id="form">
            
                                <div className="row">
                                  <div className="col-sm-8">
            
                                    <div className="form-group">
                                      <label htmlFor="asunto">Asunto:</label>
                                      <input
                                        type="text"
                                        name="asunto_inc"
                                        id="asunto"
                                       
                                        className="form-control form-control-sm"
                                       
                                        required
                                      />
            
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="descripcion">Descripcion:</label>
                                      <textarea
                                        rows="4"
                                        name="descripcion_inc"
                                        id="descripcion"
                                   
                                        className={((this.verificarError("error_nombre")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                      
                                      />
            
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="tipo">Solucion:</label>
            
                                      <textarea
                                        rows="4"
                                        name="registro_inc"
                                        id="solucion"
                                        className={((this.verificarError("error_tipo")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                     
                                    
                                      />
                                      <small id="" className="text-muted">
                                        Digite Concentración
                                      </small>
                                    </div>
                                    <div className="row">
                                      <div className="col-sm-6">
                                        <div className="form-group">
                                          <label htmlFor="id">Fecha registro :</label>
                                          <input
                                            type="text"
                                            name="fecha"
                                            id="fecha"
                                     
                                            className="form-control form-control-sm"
                                            value={FechaActual}
                                            aria-describedby="helpId"
                                        
                                            readOnly
                                          />
            
                                        </div>
                                      </div>
                                      <div className="col-sm-6">
                                        <div className="form-group">
                                          <label htmlFor="id">fecha solución :</label>
                                          <input
                                            type="text"
                                            name="fecha_mod_reg"
                                            id="fecha_mod"
                                            value={FechaActual}
                                            className="form-control form-control-sm"
                                            placeholder=""
                                            aria-describedby="helpId"
                                           
                                            readOnly
                                          />
                                       
                                        </div>
                                      </div>
                                    </div>
            
            
                                  </div>
                                  <div className="col-sm-4">
                                    <div className="form-group">
                                      <label htmlFor="id">Radicado :</label>
                                      <input
                                        type="text"
                                        name="id_incidencia"
                                        id="id_incidencia"
                                   
                                        className="form-control form-control-sm"
                                        placeholder=""
                                        aria-describedby="helpId"
                                     
                                        readOnly
                                      />
            
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="estado">Estado:</label>
                                      
                                      <input
                                        type="text"
                                        name="estado_inc"
                                        id="estado"
                              
                                        className={((this.verificarError("error_forma")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                     
                                      />
                                      <small id="" className="text-muted">
                                        Seleccione el estado
                                      </small>
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="forma">Paciente:
                                      
                                                                                    </label>
            
                                      <input
                                        type="hidden"
                                        name="id_paciente"
                                        id="idp"
                                 
                                        className={((this.verificarError("error_forma")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                     
                                      />
                                      <div className="row">
                                        <div className="col-sm-5">
                                        <input
                                        type="text"
                                        name="nom"
                                        id="nom"
                           
                                        className={((this.verificarError("error_forma")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                    
                                      />
                                        </div>
                                        <div className="col-sm-5">
                                        <input
                                        type="text"
                                        name="ape"
                                        id="ape"
                           
                                        className={((this.verificarError("error_forma")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                    
                                      />
                                        </div>
                                        <div className="col-sm-2">
                                        <img
                                                                                        style={{ width: 40 }}
                                                                                        className='form-control form-control-sm end'
                                                                                        src={findImage}
                                                                                        alt="Buscar Paciente"
                                                                                        data-bs-toggle='modal' data-bs-target='#nuevaEnfermedad1'
                                                                                    />
                                        </div>
            
                                      </div>
                                      
                                      
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="cuenta">Asignado:
                                      
                                                                                     </label>
                                                                                     <div className="row">
                                                                                       <div className="col-sm-10">
                                                                                       <input
                                        type="text"
                                        name="asignado_inc"
                                        id="asignado"
                                  
                                  
                                        className="form-control form-control-sm"
                                      />
                                                                                       </div>
                                                                                       <div className="col-sm-2">
                                                                                       <img
                                      style={{ width: 40 }}
                                      className='form-control form-control-sm end'
                                      src={findImage}
                                      alt="Buscar Usuario"
                                      data-bs-toggle='modal' data-bs-target='#buscarUsuario'
                                  />
                                                                                       </div>
            
                                                                                     </div>
                                      
            
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                        <div className="form-group">
                                      <label htmlFor="categoria">Categoria:</label>
                                      <input
                                        type="text"
                                        name="categoria_inc"
                                        id="categoria"
                              
                                    
                                        className="form-control form-control-sm"
                                      />
            
                                    </div>
                                        </div>
                                        <div className='col-sm-6'>
                                        <div className="form-group">
                                      <label htmlFor="tipo">Tipo:</label>
                                      <input
                                        type="text"
                                        name="tipo_inc"
                                        id="tipo"
                           
                               
                                        className="form-control form-control-sm"
                                      />
            
                                    </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                        <div className="form-group">
                                      <label htmlFor="tipo">Prioridad:</label>
                                      <input
                                        type="text"
                                        name="prioridad_inc"
                                        id="prioridad_inc"
                           
                               
                                        className="form-control form-control-sm"
                                      />
            
                                    </div>
                                        </div>
                                        <div className='col-sm-6'>
                                        <div className="form-group">
                                      <label htmlFor="tipo">Fuente:</label>
                                      <input
                                        type="text"
                                        name="fuente_inc"
                                        id="fuente_inc"
                           
                               
                                        className="form-control form-control-sm"
                                      />
            
                                    </div>
                                        </div>
                                    </div>
                                    
                                    
                                    
                                  </div>
            
                                </div>
            
            
            
                                <br />
                                <div className="btn-group" role="group" aria-label="">
                                  <button type="submit" className="btn btn-warning btn-sm">
                                    Guardar
                                  </button>&nbsp;&nbsp;
                                  <Link className="btn btn-danger btn-sm" to={"/MasterSoporte"}>
                                    Cancelar
                                  </Link>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
            
                  </div>
        );
    }
}
 
export default Crear;