import React from "react";
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'

class Editar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datosCargados: false,
            proveedor: [],
            departamentos: [],
            municipios: [],
            errores: []
        };
    }
    cargarDepartamentos() {
        fetch(Api + "Departamentos")
            .then(respuesta => respuesta.json())
            .then((datosDepartamentos) => {
                //console.log(datosDepartamentos);
                this.setState({ departamentos: datosDepartamentos })
            })
            .catch(console.log);
    }
    cargarMunicipios = (e) => {
        fetch(Api + "Municipios/" + e.target.value)
            .then(respuesta => respuesta.json())
            .then((datosMunicipios) => {
                //console.log(datosMunicipios);
                this.setState({ municipios: datosMunicipios });
                this.municipio.focus();
            })
            .catch(console.log)
    }
    cargarMunicipiosProv() {
        fetch(Api + "Municipios/08")
            .then(respuesta => respuesta.json())
            .then((datosMunicipios) => {
                //console.log(datosMunicipios);
                this.setState({ municipios: datosMunicipios });

            })
            .catch(console.log)
    }
    cambioValor = (e) => {
        const state = this.state.proveedor;
        state[e.target.name] = e.target.value;
        this.setState({ proveedor: state });
    };
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    enviarDatos = (e) => {
        e.preventDefault();
        console.log("Formulario Enviado...");
        const nit = document.getElementById('nit').value;
        const nombre = document.getElementById('nombre').value;
        const departamento = document.getElementById('depa').value;
        const municipio = document.getElementById('muni').value;
        const direccion = document.getElementById('direccion').value;
        const telefono = document.getElementById('telefono').value;
        const celular = document.getElementById('celular').value;
        const contacto = document.getElementById('contacto').value;
        const tel_contacto = document.getElementById('tel_contacto').value;
        const email1 = document.getElementById('email1').value;
        const email2 = document.getElementById('email2').value;
        const banco = document.getElementById('banco').value;
        const numero_cuenta = document.getElementById('numero_cuenta').value;
        const tipo_cuenta = document.getElementById('tipo_cuenta').value;
        var errores = [];
        if (!nit) { errores.push("error_nit"); }
        if (!nombre) { errores.push("error_nombre"); }
        if (!departamento) { errores.push("error_departamento"); }
        if (!municipio) { errores.push("error_municipio"); }
        if (!direccion) { errores.push("error_direccion"); }
        if (!telefono) { errores.push("error_telefono"); }
        if (!celular) { errores.push("error_celular"); }
        if (!contacto) { errores.push("error_contacto"); }
        if (!tel_contacto) { errores.push("error_tel_contacto"); }
        if (!email1) { errores.push("error_email1"); }
        if (!email2) { errores.push("error_email2"); }
        if (!banco) { errores.push("error_banco"); }
        if (!numero_cuenta) { errores.push("error_numero_cuenta"); }
        if (!tipo_cuenta) { errores.push("error_tipo_cuenta"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let form = document.getElementById('form');
        let formData = new FormData(form);
        console.log(formData);

        fetch(Api + "UpProveedores", {
            method: "POST",
            body: formData
        })
            .then(respuesta => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                Swal.fire(
                    'Proveedores!',
                    datosRespuesta.msj,
                    'success'
                )
                this.props.history.push("/MasterProveedores");
            })
            .catch(console.log)
    }
    componentDidMount() {
        this.cargarDepartamentos();
        this.cargarMunicipiosProv();
        console.log(this.props.match.params.id);
        fetch(Api + "Proveedores/" + this.props.match.params.id,
            {
                method: "GET",
                headers: new Headers({
                    'Authorization': 'koala2021'
                }),
            })
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                this.setState({ datosCargados: true, proveedor: datosRespuesta }); //
            })
            .catch(console.log);
    }
    render() {
        const { datosCargados, proveedor, departamentos, municipios } = this.state;
        if (!datosCargados) {
            var load = 'Cargando datos....';
        } else {
            var load = '';
        }
        return (
            <div>
                <div className="content-wrapper">
                    <div className="content">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-header">
                                    {
                                        load ? (
                                            load
                                        ) : (
                                            "Editar Proveedor"
                                        )
                                    }
                                </div>
                                <div className="card-body">
                                    <form onSubmit={this.enviarDatos} id="form">
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div style={{ marginBottom: 16 }} className='row g-3'>
                                                    <div className="col-md-2">
                                                        <label htmlFor="id">Id :</label>
                                                        <input type="text" name="id" id="id"
                                                            value={proveedor.id} className="form-control form-control-sm"
                                                            placeholder="" aria-describedby="helpId"
                                                            onChange={this.cambioValor} readOnly
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{ marginBottom: 16 }} className='row g-3'>
                                                    <div className="col-md-2">
                                                        <label htmlFor="nit">NIT / C.C.</label>
                                                        <input maxLength={12} type="text" name="nit" id="nit" defaultValue={proveedor.nitcc}
                                                            className={((this.verificarError("error_nit")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                                            onChange={this.cambioValor}
                                                        />
                                                        <small id="helpId" className="invalid-feedback">Digite el NIT / C.C.</small>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label htmlFor="nombre">Nombre</label>
                                                        <input type="text" name="nombre" id="nombre" defaultValue={proveedor.nombre}
                                                            className={((this.verificarError("error_nombre")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                                            onChange={this.cambioValor}
                                                        />
                                                        <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <label htmlFor="depa">Departamento</label>
                                                        <select name="depa" id="depa" value={proveedor.depa}
                                                            options={proveedor.depa}
                                                            className={((this.verificarError("error_departamento")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                            onChange={e => {
                                                                this.cambioValor(e);
                                                                this.cargarMunicipios(e);
                                                            }}
                                                        >
                                                            <option value="">Seleccionar</option>
                                                            {
                                                                departamentos.map(itemDepartamento => (
                                                                    <option key={itemDepartamento.cod_dep} value={itemDepartamento.cod_dep}>{itemDepartamento.nombre_dep}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        <small id="helpId" className="invalid-feedback">Seleccione el Departamento</small>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <label htmlFor="muni">Municipio</label>
                                                        <select name="muni" id="muni" value={proveedor.muni}
                                                            className={((this.verificarError("error_municipio")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                            options={proveedor.muni}
                                                            onChange={this.cambioValor}
                                                            ref={(input) => { this.municipio = input }}
                                                        >
                                                            <option value="">Seleccionar</option>
                                                            {
                                                                municipios.map(itemMunicipio => (
                                                                    <option key={itemMunicipio.cod_mun} value={itemMunicipio.cod_mun}>{itemMunicipio.nombre_mun}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        <small id="helpId" className="invalid-feedback">Seleccione el Municipio</small>
                                                    </div>
                                                </div>
                                                <div style={{ marginBottom: 16 }} className='row g-3'>
                                                    <div className="col-md-2">
                                                        <label htmlFor="telefono">Teléfono</label>
                                                        <input type="text" name="telefono" id="telefono" defaultValue={proveedor.telefono}
                                                            className={((this.verificarError("error_telefono")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                                            onChange={this.cambioValor} />
                                                        <small id="helpId" className="invalid-feedback">Digite el Teléfono</small>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label htmlFor="direccion">Dirección</label>
                                                        <input type="text" name="direccion" id="direccion" defaultValue={proveedor.direccion}
                                                            className={((this.verificarError("error_direccion")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                                            onChange={this.cambioValor} />
                                                        <small id="helpId" className="invalid-feedback">Digite la Dirección</small>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label htmlFor="email1">Email 1</label>
                                                        <input type="text" name="email1" id="email1" defaultValue={proveedor.email1}
                                                            className={((this.verificarError("error_email1")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                                            onChange={this.cambioValor} />
                                                        <small id="helpId" className="invalid-feedback">Digite el Email 1</small>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label htmlFor="email2">Email 2</label>
                                                        <input type="text" name="email2" id="email2" defaultValue={proveedor.email2}
                                                            className={((this.verificarError("error_email2")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                                            onChange={this.cambioValor} />
                                                        <small id="helpId" className="invalid-feedback">Digite el Email 2</small>
                                                    </div>
                                                </div>
                                                <div style={{ marginBottom: 16 }} className='row g-3'>
                                                    <div className="col-md-2">
                                                        <label htmlFor="celular">Celular</label>
                                                        <input type="text" name="celular" id="celular" defaultValue={proveedor.celular}
                                                            className={((this.verificarError("error_celular")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                                            onChange={this.cambioValor} />
                                                        <small id="helpId" className="invalid-feedback">Digite el Celular</small>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label htmlFor="contacto">Contacto</label>
                                                        <input type="text" name="contacto" id="contacto" defaultValue={proveedor.contacto}
                                                            className={((this.verificarError("error_contacto")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                                            onChange={this.cambioValor} />
                                                        <small id="helpId" className="invalid-feedback">Digite el Contacto</small>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label htmlFor="tel_contacto">Teléfono Contacto</label>
                                                        <input type="text" name="tel_contacto" id="tel_contacto" defaultValue={proveedor.tel_contacto}
                                                            className={((this.verificarError("error_tel_contacto")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                                            onChange={this.cambioValor} />
                                                        <small id="helpId" className="invalid-feedback">Digite el Teléfono Contacto</small>
                                                    </div>
                                                </div>
                                                <div style={{ marginBottom: 16 }} className='row g-3'>
                                                    <div className="col-md-4">
                                                        <label htmlFor="banco">Banco</label>
                                                        <input type="text" name="banco" id="banco" defaultValue={proveedor.banco}
                                                            className={((this.verificarError("error_banco")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                                            onChange={this.cambioValor} />
                                                        <small id="helpId" className="invalid-feedback">Digite el Banco</small>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label htmlFor="numero_cuenta">No. Cuenta</label>
                                                        <input type="text" name="numero_cuenta" id="numero_cuenta" defaultValue={proveedor.numero_cuenta}
                                                            className={((this.verificarError("error_numero_cuenta")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                                            onChange={this.cambioValor} />
                                                        <small id="helpId" className="invalid-feedback">Digite el No. Cuenta</small>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label htmlFor="tipo_cuenta">Tipo Cuenta</label>
                                                        <select name="tipo_cuenta" id="tipo_cuenta" value={proveedor.tipo_cuenta}
                                                            options={proveedor.tipo_cuenta}
                                                            className={((this.verificarError("error_tipo_cuenta")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                            onChange={this.cambioValor}>
                                                            <option value="">Seleccione el T. Cuenta</option>
                                                            <option value="Ahorros">Ahorros</option>
                                                            <option value="Corriente">Corriente</option>
                                                        </select>
                                                        <small id="helpId" className="invalid-feedback">Seleccione el T. Cuenta</small>
                                                    </div>
                                                </div>
                                                <div style={{ marginBottom: 16 }} className='row g-3'>
                                                    <div className="col-md-8">
                                                        <label htmlFor="observacion">Observaciones</label>
                                                        <input type="text" name="observaciones" id="observaciones"
                                                            value={proveedor.observaciones}
                                                            className="form-control form-control-sm"
                                                            onChange={this.cambioValor} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="btn-group" role="group" aria-label="">
                                            <button type="submit" className="btn btn-primary btn-sm"><FontAwesomeIcon icon={faEdit} />&nbsp;&nbsp;Editar</button>&nbsp;&nbsp;
                                            <Link className="btn btn-danger btn-sm" to={"/MasterProveedores"}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Editar;