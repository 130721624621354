import React from "react";
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import FechaActual from "../../servicios/FechaGlobal";
import $ from "jquery";
import findImage from "../../images/find.png"
import FormPaciente from "../atencion/Pacientes"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleDoubleRight,
  faAngleRight,
  faPrint,
  faPlus,
  faFilter,
  faDownload,
  faEdit,
  faSave,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
const fechag =  new Date();
    let meses = (fechag.getMonth() + 1).toString().padStart(2, '0')
    const Fecha_unos  = fechag.getFullYear()+'-'+meses+'-01';
    
class Facturar extends React.Component {
  constructor(props) {
    super(props);
    this.agregarPaciente = this.agregarPaciente.bind(this);
    

    this.state = {
      total : 0,
      empresas: [],
      facturas: [],
      fecha: FechaActual,
      fecha1: Fecha_unos,
      fecha_final: FechaActual,
      pagina : 0,
      t:0,
      gt:0,
      nombre: "",
      pac: "",
    };
  }
  cargarEmpresas() {

    fetch(Api + "Empresas")
      .then((respuesta) => respuesta.json())
      .then((datosEmpresas) => {
        
        this.setState({ empresas: datosEmpresas.data });
      })
      .catch(console.log);
  }
  agregarPaciente(pac, nombre) {
    this.setState({ pac: pac, nombre: nombre });
}
  componentDidMount() {
    this.cargarEmpresas();
    //this.Buscar(1);
    
  }
  Buscar() {

    let empresa = document.getElementById('empresa').value;
    let desde = document.getElementById('desde').value;
    let hasta = document.getElementById('hasta').value;
    let regimen = document.getElementById('regimen').value;
    let bloque = document.getElementById('bloque').value;
    let dia = document.getElementById('dia').value;

    window.open('https://idb.softmediko.com/vistas/GenerarRipsAll.php?empresa='+empresa+'&desde='+desde+'&hasta='+hasta+'&regimen='+regimen+'&bloque='+bloque+'&dia='+dia, 'popup', 'width=400,height=200');
}
 

Limpiar(){
  $("#empresa").val('');
  $("#codigo").val('');
  $("#nombre").val('');
  $("#pac").val('');
  $("#regimen").val('');
}
  render() {
    const {
      fecha,
      empresas,
      fecha1,
      fecha_final,
      facturas,
      pagina,t,gt,
    } = this.state;
   
    return (
      <div className="content">
        <div class="modal fade" id="asignarPaciente" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="asignarPacienteLabel" aria-hidden="true">
                    <FormPaciente agregarPaciente={this.agregarPaciente} />
                </div>

                    <div className="content-wrapper">
      <div className="content">
      
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
            <h4>Generar Rips</h4>
            </div>
            <div className="card-body">
              
              <form id="formulario">
                <div className="row">
                  <div className="col-xl-2">
                  <label>Empresa</label>
                    <select
                      className="form-select form-select-sm"
                      type="text"
                      name="empresa"
                      id="empresa"
                    >
                      <option value="">Seleccione la Empresa</option>
                      {empresas.map((itemEmpresa) => (
                        <option
                          key={itemEmpresa.id_empresa}
                          value={itemEmpresa.rips}
                        >
                          {itemEmpresa.nombre_emp}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-xl-2">
                  <label>Factura desde</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      id="desde"
                      name="desde"
                      placeholder="1"
                    />
                  </div>
                  <div className="col-xl-2">
                  <label>Factura hasta</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      id="hasta"
                      name="hasta"
                      placeholder="1000"
                    />
                  </div>
                  <div className="col-xl-2">
                  <label>Regimen</label>
                    <select
                      className="form-select form-select-sm"
                      type="text"
                      name="regimen"
                      id="regimen"
                    >
                  
                      <option value="">Seleccione</option>
                                                                    <option value="Contributivo">1. Contributivo</option>
                                                                    <option value="Subsidiado">2. Subsidiado</option>
                                                                    <option value="Particular">4. Particular</option>
                                                                    <option value="Vinculado">3. Vinculado</option>
                                                                    <option value="Otro">5. Otro</option>
                                                                    <option value="Plan Complementario">7. Plan Complementario</option>
                                                                    <option value="Poliza">8. Poliza</option>
                                                                    <option value="Arl">9. Arl</option>
                                                                    <option value="No aplica">No aplica</option>
                
                    </select>
                  </div>
                  <div className="col-xl-2">
                  <label>Facturas por bloques?</label>
                    <select
                      className="form-select form-select-sm"
                      type="text"
                      name="bloque"
                      id="bloque"
                    >
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                    </select>
                  </div>
                  <div className="col-xl-2">
                  <label>Con diagnostico?</label>
                    <select
                      className="form-select form-select-sm"
                      type="text"
                      name="dia"
                      id="dia"
                    >
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                    </select>
                  </div>
                  
                  

                  <div className="col-xl-2 pt-4">
                    <button
                      type="button"
                      id="bt"
                      name="bt"
                      className="btn btn-primary btn-sm"
                      onClick={() => this.Buscar()}
                    >
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp;&nbsp;Generar
                    </button>
                    <button
                      type="button"
                      id="bt"
                      name="bt"
                      className="btn btn-danger btn-sm"
                      onClick={() => this.Limpiar()}
                    >
                   
                      &nbsp;&nbsp;Limpiar
                    </button>

                  </div>
                </div>
              </form>
              <br></br>

              
              
            </div>
            
          </div>
        </div>
      </div>
      </div>
      </div>
    );
  }
}

export default Facturar;
