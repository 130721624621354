import React from 'react';
import Api from "../../servicios/api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes, faArrowRight, faAngleDoubleLeft, faAngleLeft, faAngleDoubleRight, faAngleRight } from '@fortawesome/free-solid-svg-icons'

class Usuarios extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datosCargados: false,
            pagina: [],
            usuarios: []
        }
    }
    resetForm = () => {
        document.getElementById('bnombre').value = "";
        document.getElementById('busuario').value = "";
        document.getElementById('bnombre').focus();
        this.BuscarEnf2(1);
    }
    enviarUsuario = (sidp) => {
        console.log(sidp);
        document.getElementById('asignado_reasignar').value = sidp;
        window.$('#usuarioReasignar').modal('hide');
    }
    componentDidMount() {
        this.BuscarUsuario(1);
    }
    BuscarUsuario = (page) => {
        var bnombre = document.getElementById('name').value;
        var busuario = document.getElementById('user').value;
        console.log("Formulario Enviado..."+bnombre);
        if (bnombre == '') {
            bnombre = 0;
        }
        if (busuario == '') {
            busuario = 0;
        }
        fetch(Api + "SearchUsuarios/" + busuario + "/" + bnombre + "/Activo?page=" + page)
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                this.setState({ datosCargados: true, usuarios: datosRespuesta.data, pagina: datosRespuesta.total, ultimo: datosRespuesta.last_page, actual: datosRespuesta.current_page });
            })
            .catch(console.log);
    }
    render() {
        const { datosCargados, usuarios, pagina, ultimo, actual } = this.state;
        if (!datosCargados) {
            var load = 'Cargando datos....';
        } else {
            var load = '';
        }
        return (
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="nuevaEnfermedad1Label">Usuarios</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="card">
                            <div style={{ backgroundImage: 'linear-gradient(#2FB3FB, #003153)', color: '#FFFFFF', borderTopLeftRadius: 5, borderTopRightRadius: 5 }} className="card-header text-center">
                                <h5 style={{ fontSize: 18 }} >Lista de Usuarios</h5>
                            </div>
                            <div className='card-body'>
                                <div className="row">
                                    <div className="col-xl-5">
                                    <input type="text" id="user" name="user" placeholder="usuario" className="form-control form-control-sm" />
                                    </div>
                                    <div className="col-xl-3">
                                        <input type="text" id="name" name="name" placeholder="Nombre" className="form-control form-control-sm" />
                                    </div>
                                    <div className="col-xl-4">
                                        <button type="button" className="btn btn-primary btn-sm" onClick={() => this.BuscarUsuario(1)}><FontAwesomeIcon icon={faSearch} />&nbsp;&nbsp;Buscar</button>&nbsp;&nbsp;
                                        <button type="button" className="btn btn-danger btn-sm" onClick={() => this.resetForm()} ><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <table className="table table-hover table-condensed table-sm">
                                        <thead className="thead-inverse">
                                            <tr>
                                                <th>USUARIO</th>
                                                <th>NOMBRE DEL USUARIO</th>
                                                <th>CARGO</th>
                                                <th>SELECCIONAR</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {load}
                                            {usuarios.map((itemUsuarios) => (
                                                <tr key={itemUsuarios.id}>
                                                    <td>{itemUsuarios.usuario}</td>
                                                    <td>{itemUsuarios.nombre} {itemUsuarios.apellido} </td>
                                                    <td>{itemUsuarios.cargo}</td>
                                                    <td>
                                                        <div className="btn-group" role="group" aria-label="">
                                                            <button type="button" className="btn btn-primary btn-sm" onClick={() => this.enviarUsuario(itemUsuarios.usuario)}><FontAwesomeIcon icon={faArrowRight} /></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row">
                                    <div className='col-xs-12'>
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination">
                                                <li className="page-item"><a className="page-link" href="#" onClick={() => this.BuscarUsuario(1)}><FontAwesomeIcon icon={faAngleDoubleLeft} /></a></li>
                                                <li className="page-item"><a className="page-link" href="#" onClick={() => this.BuscarUsuario(actual - 1)}><FontAwesomeIcon icon={faAngleLeft} /></a></li>
                                                {(() => {
                                                    let p = [];
                                                    /*if (ultimo >= 86) {
                                                        var ult = 86;
                                                    } else {
                                                        ult = ultimo;
                                                    }
                                                    for (let i = 1; i <= ult; i++) {
                                                        p.push(<li className="page-item" key={i}><a className="page-link" href="#" onClick={() => this.BuscarEnf2(i)}>{i}</a></li>);
                                                    }*/
                                                    p.push(<li className="page-item" key={actual}><a className="page-link" href="#" onClick={() => this.BuscarUsuario(actual)}>{actual}</a></li>);
                                                    return p;
                                                })()}
                                                <li className="page-item"><a className="page-link" href="#" onClick={() => this.BuscarUsuario(actual + 1)}><FontAwesomeIcon icon={faAngleRight} /></a></li>
                                                <li className="page-item"><a className="page-link" href="#" onClick={() => this.BuscarUsuario(ultimo)}><FontAwesomeIcon icon={faAngleDoubleRight} /></a></li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <div className="card-footer text-muted">
                                        <b>Pagina</b> <span class="badge bg-primary">{actual}</span> <b>de</b> <span class="badge bg-primary">{ultimo}</span> | <b>Registros</b> <span class="badge bg-success">{pagina}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Usuarios;