import React from 'react';
import { Link } from "react-router-dom";
import Api from "../servicios/api";
import axios from 'axios';
import Swal from 'sweetalert2';

class login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: "",
      clave: "",
      errores: []
    }
  }
  cambioValor = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState({ state, errores: [] })
  }
  verificarError(elemento) {
    return this.state.errores.indexOf(elemento) !== -1;
  }
  refreshPage() {
    window.location.reload(false);
  }
  enviarDatos = (e) => {
    e.preventDefault();
    console.log("Formulario Enviado...");
    const { usuario, clave } = this.state;
    var errores = [];
    if (!usuario) {
      errores.push("error_usuario");
    }
    if (!clave) {
      errores.push("error_clave");
    }
    this.setState({ errores: errores });
    if (errores.length > 0) {
      return false;
    }
    var datosEnviar = { usuario: usuario, clave: clave }
    let token = 'test';
    axios
      .post(Api + "Login", JSON.stringify(datosEnviar), {
        headers: {
          Authorization: `${token}`

        },
      })
      .then(
        (res) => {
          if (res.data.State) {
            window.localStorage.setItem('k_usuario', res.data.datos.usuario);
            window.localStorage.setItem('k_nombre', res.data.datos.nombre + ' ' + res.data.datos.apellido);
            window.localStorage.setItem('k_idusuario', res.data.datos.id);
            window.localStorage.setItem('k_token', res.data.datos.token);
            window.location.href = '/';
          } else {
            Swal.fire({
              title: "Ups!",
              text: res.data.Msj,
              icon: "danger",
              confirmButtonText: "Ok",
            });
          }
        },
        (error) => {
          alert('error' + error);
        }
      );
  }
  render() {
    const { nombre, tipo } = this.state
    return (
      <div className="hold-transition login-page">
        <div className="login-box">
          <div className="card card-outline card-primary">
          <div className="card-header text-center">
      
          <img src="dist/img/koala.png" alt="logo" />
        </div>
            
            <div className="card-body">
              <p className="login-box-msg">Internacion Domiciliaria Barraza</p>
              <form onSubmit={this.enviarDatos} id="formlogin">
                <div className="input-group mb-3">
                  <input className={((this.verificarError("error_usuario")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="usuario" id="usuario" placeholder="Digite su usuario" aria-describedby="helpId" onChange={this.cambioValor} />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-user" />
                    </div>
                  </div>
                  <small id="helpId" className="invalid-feedback">Digite el Usuario</small>
                </div>
                <div className="input-group mb-3">
                  <input className={((this.verificarError("error_clave")) ? "is-invalid" : "") + " form-control form-control-sm"} type="password" name="clave" id="clave" placeholder="Digite su contraseña" aria-describedby="helpId" onChange={this.cambioValor} />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock" />
                    </div>
                  </div>
                  <small id="helpId" className="invalid-feedback">Digite la Contraseña</small>
                </div>
                <div className="row text-center">
                  <div className="col-12">
                    <button type="submit" className="btn btn-primary btn-block btn-sm" onclick="validar()">Iniciar Sesión</button>
                  </div>
                </div>
              </form>
              <br /><br /><br /><br /><br />
      <div class="footer">
        <label>Developer By Navasys</label>
      </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default login;