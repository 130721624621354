import React from 'react';
import Listar from "./Listar";

class index extends React.Component {
    render() {
        return (
            <div>
                <div className="content">
                    <div className="content-wrapper">
                        <Listar />
                    </div>
                </div>
            </div>
        );
    }
}

export default index;