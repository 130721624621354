import React from "react";
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faSearch, faUpload, faPlus, faViruses } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2';

class Listar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datosCargados: false,
      query: [],
      pagina: [],
      relaciones:[],
 
    };

  }
  addmed(cod){
    console.log(this.props.match.params.id);
    var emp = this.props.match.params.id;
 
        fetch(Api + "AddRelinsumos/"+emp+"/"+cod)
            .then(respuesta => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                Swal.fire(
                    'Registro!',
                    datosRespuesta.msj,
                    datosRespuesta.icono
                )
                this.cargarRelacionados();
            })
            .catch(console.log)

  }
  borrarMed(cod){
    fetch(Api + "DelRelinsumos/"+cod)
            .then(respuesta => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                Swal.fire(
                    'Registro!',
                    datosRespuesta.msj,
                    'success'
                )
                this.cargarRelacionados();
            })
            .catch(console.log) 
  }
  Uprel = (e) => {
    console.log('cambiar precio '+e.target.value); 
    console.log('cambiar precio '+e.target.id); 
    fetch(Api + "UpRelinsumos/"+e.target.id+"/"+e.target.value)
            .then(respuesta => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                
            })
            .catch(console.log)
  }
  componentDidMount() {
    this.Buscar(1);
    this.cargarRelacionados();
  }
  Buscar(page){
 
    
    let btipo = document.getElementById('buscar').value;
    let bname = document.getElementById('bnombre').value;
    console.log("Formulario Enviado...");
    if(btipo==''){
      btipo = 0;
    }
    if(bname==''){
      bname = 0;
    }
    fetch(Api + "SearchInsumos/"+btipo+"/"+bname+"?page="+page)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
        this.setState({ datosCargados: true, query: datosRespuesta.data, pagina:datosRespuesta.total, ultimo: datosRespuesta.last_page, actual: datosRespuesta.current_page });
      })
      .catch(console.log);
  }
  cargarRelacionados() {
    
    fetch(Api + "Relinsumos/"+this.props.match.params.id)
    .then(respuesta=>respuesta.json())
    .then((datosRelacion)=>{
      console.log(datosRelacion);
        this.setState({relaciones: datosRelacion})
    })
    .catch(console.log);
}
  render() {
    const { datosCargados, query,pagina, ultimo, actual,relaciones } = this.state;
    if (!datosCargados) {
       var load = 'Cargando datos....';
    } else {
       var load = '';
    }
       
    
      return (
        <div class="content">
                <div class="content-wrapper">
          <div className="content">
            <div className="container-fluid">
              <div className="card">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="datos_basicos-tab" data-bs-toggle="tab" data-bs-target="#datos_basicos" type="button" role="tab" aria-controls="datos_basicos" aria-selected="true">Listados</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="direcciones-tab" data-bs-toggle="tab" data-bs-target="#direcciones" type="button" role="tab" aria-controls="direcciones" aria-selected="false">Insumos asignados a la empresa</button>
                                    </li>
                                    
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="datos_basicos" role="tabpanel" aria-labelledby="datos_basicos-tab">
                                        <br />
                <div className="card-body">
                  <h4>Agregar Insumos a la Empresa</h4>
                  <div className="row">
               
                      <div className="col-xl-3">
                     
                      <input type="text" id="buscar" name="buscar" placeholder="Código de insumo" className="form-control form-control-sm"  />

                      </div>
                      <div className="col-xl-3">
                       
                          <input type="text" id="bnombre" name="bnombre" placeholder="Nombre del insumo" className="form-control form-control-sm"  />
                      </div>
                      <div className="col-xl-3">
                        
                          <input type="button" id="bt" name="bt" className="btn btn-primary btn-sm" value="Filtrar" onClick={() =>this.Buscar(1)} />
                      </div>
               
                   </div>
                   <br></br>
                   <div className="row">
                  <table className="table table-hover table-condensed table-sm">
                  <thead className="thead-inverse">
                      <tr>
                        <th>CÓDIGO</th>
                        <th>NOMBRE INSUMO</th>
                        <th>PRECIO</th>
                      
                        <th>ACCIONES</th>
                      </tr>
                    </thead>
                    <tbody> 
                      {load}
                      {query.map((empleado) => (
                        <tr key={empleado.id}>
                          <td>{empleado.codigo}</td>
                          <td>{empleado.nombre_insumo}</td>
                          <td>{empleado.precio_ins}</td>
                 
                          <td>
                            <div
                              className="btn-group"
                              role="group"
                              aria-label=""
                            >
                               <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                onClick={() =>
                                  this.addmed(empleado.id)
                                }
                              >
                                <i class="fas fa-angle-double-down"></i>
                              </button>
                           
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  </div>
                  <nav aria-label="Page navigation example">
                    <ul className="pagination">
                        <li class="page-item"><a className="page-link" href="#" onClick={() => this.Buscar(1)}>Atras</a></li>
                        {(() => {
                            let p = [];
                            if(ultimo>=20){
                              var ult = 20;
                            }else{
                              ult = ultimo
                            }
                            for(let i = 1; i <= ult ; i++){
                                
                                p.push(<li class="page-item" key={i}><a className="page-link" href="#" onClick={() => this.Buscar(i)}>{i}</a></li>); 
                            }
                            return p;
                        })()}
                       
                        <li class="page-item"><a className="page-link" href="#" onClick={() => this.Buscar(ultimo)}>Siguiente</a></li>
                    </ul>
                    Pagina {actual} de {ultimo} Registros {pagina}
                    </nav>
                </div>

                </div>
                <div className="tab-pane fade" id="direcciones" role="tabpanel" aria-labelledby="direcciones-tab">
                                        <br />
                                        <div className="card-body">
                  <h4>Medicamentos Asignados</h4>
                  
                   <br></br>
                   <div className="row">
                  <table className="table table-hover table-condensed table-sm">
                  <thead className="thead-inverse">
                      <tr>
                        <th>CÓDIGO</th>
                        <th>NOMBRE INSUMO</th>
                     
                        <th>PRECIO VENTA</th>
                        <th>ACCIONES</th>
                      </tr>
                    </thead>
                    <tbody> 
                    
                      {relaciones.map((relaciones) => (
                        <tr key={relaciones.id_precio}>
                          <td>{relaciones.codigo}</td>
                          <td>{relaciones.nombre_insumo}</td>
                         
                          <td><input type="text" id={relaciones.id_precio} onChange={e =>
                                  this.Uprel(e)} defaultValue={relaciones.precio}></input></td>
                          <td>
                            <div
                              className="btn-group"
                              role="group"
                              aria-label=""
                            >
                              <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={() =>
                                  this.borrarMed(relaciones.id_precio)
                                }
                              >
                                <i className="far fa-trash-alt"></i>
                              </button>
                             
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  </div>
               
                </div>
                                        </div>
              </div>

              </div>
              </div>
              </div>
              </div>
              </div>
              
            
      );
    }
  }


export default Listar;
