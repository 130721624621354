import React from "react";
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import axios from 'axios';
//import ItemInsumos from "./ItemInsumos";
import FormPaciente from "../modales/Pacientes"
import FormEquipo from "../modales/Productos"
import FormHistorial from "../modales/AlquileresPac"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleDoubleRight,
  faAngleRight,
  faPrint,
  faPlus,
  faFilter,
  faDownload,
  faEdit,
  faSave,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

class Ventas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editar: "No",
      datosCargados: false,
      total : 0,
      TipoModal:'Pacientes',
      empresas: [],
      detalle: [],
      pagina: [],
      TipoModal:'Pacientes',
      tf:0,
      checkboxIds: [],
      facturas: [],
      detalles: [],
      medicamentos: [],
        insumos: [],
      date: new Date(),
    };
  }
  Modal (nombre){
    this.setState({
      TipoModal: nombre
    });
  }
  cargarEmpresas() {
    fetch(Api + "Empresas")
      .then((respuesta) => respuesta.json())
      .then((datosEmpresas) => {
        //console.log(datosEmpresas.data);
        this.setState({ empresas: datosEmpresas.data });
      })
      .catch(console.log);
  }
  
  componentDidMount() {
    this.cargarEmpresas();
    this.Buscar(1);
  }
  Buscar(page) {
    Swal.showLoading();
    let empresa = document.getElementById('empresax').value;
    let estado = document.getElementById('estado').value;
    let estado_ord = document.getElementById('estado_ord').value;
    let estado_orden = document.getElementById('estado_orden').value;
    let documento = document.getElementById('documento').value;
    let nombre = document.getElementById('nombre').value;
    let apellido = document.getElementById('apellido').value;
    let ano = document.getElementById('ano').value;
    let mes = document.getElementById('mesx').value;
    let regimen = document.getElementById('regimen').value;
    //var datos = {empresa:empresa,estado,estado_ord,estado_orden,documento,nombre,apellido,ano,mes,regimen}
    var datos = new FormData(document.getElementById("formulario"));
    fetch(Api + "ventas/lista", {
      method: "POST",
      body: datos,
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
        this.setState({
          datosCargados: true,
          facturas: datosRespuesta.data,
          tf:datosRespuesta.cantidad,
        });
        Swal.close();
      })
      .catch(console.log);
  }
  Mostrar(id) {
    axios.get(Api + 'ventas/detalle/'+id)
    .then(response => {
      console.log(response.data);
      var o = response.data.orden;
      var p = response.data.paciente;
      var a = response.data.alquiler;
      this.setState({
        detalles:a,
      });
      document.getElementById('orden_s').value = o.id;
      document.getElementById('doc').value = p.numero_doc;
      document.getElementById('id_paciente').value = p.id_paciente;
      document.getElementById('nombre_paciente').value = p.nombres+' '+p.nombre2+' '+p.apellidos+' '+p.apellido2;
      document.getElementById('direccion_paciente').value = p.direccion1;
      document.getElementById('fi').value = o.fecha_registro;
      document.getElementById('ff').value = o.fecha_final;
      document.getElementById('llamada').value = o.llamada;
      document.getElementById('autorizacion_d').value = o.orden;
      document.getElementById('numero').value = o.num_pedido;
      document.getElementById('pedido').value = o.fecha_pedido;
      document.getElementById('estado').value = o.estado_ord;
      /* document.getElementById('cod_e').value = a.cod_equipo;
      document.getElementById('id_e').value = a.id_equipo_a;
      document.getElementById('nombre_e').value = a.equipo;
      document.getElementById('pre_e').value = a.precio_a;
      document.getElementById('pag_e').value = a.copagos;
      document.getElementById('dia_e').value = a.cantidad; */
      document.getElementById('observaciones').value=o.obs ;
      
    })
    .catch(error => {
      console.log(error);
    });
 }
  Facturar() {
    
    let empresa = document.getElementById('empresax').value;
    if(empresa==''){
      alert("Debes de seleccionar la empresa");
      return false;
    }
    Swal.fire({
      title: "Estas seguro de generar la factura ? ",
      showDenyButton: true,
    
      confirmButtonText: "Facturar",
      denyButtonText: `Cancelar`
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
    Swal.showLoading();
    //var datos = {empresa:empresa,estado,estado_ord,estado_orden,documento,nombre,apellido,ano,mes,regimen}
    const { checkboxIds } = this.state;
    var dato = {empresa:empresa, datos:checkboxIds}
    console.log(dato);
    //return false;
    axios.post(Api +'ventas/facturar',dato)
    .then(response => {
      console.log(response.data);
      var m = response.data.factura;
         alert("Se ha generado la Factura No."+m);
         this.Buscar(1);
    })
    .catch(error => {
      console.log(error);
    });
  } else if (result.isDenied) {
    Swal.fire("No se genero ninguna factura", "", "info");
  }
});
  }
  handleCheckboxChange = (id) => {
    // Desestructura el estado actual
    const { checkboxIds } = this.state;

    // Verifica si el ID ya está en el array de checkboxIds
    if (checkboxIds.includes(id)) {
      // Si está presente, lo elimina (deselección)
      this.setState({
        checkboxIds: checkboxIds.filter((checkboxId) => checkboxId !== id),
      });
    } else {
      // Si no está presente, lo agrega (selección)
      this.setState({
        checkboxIds: [...checkboxIds, id],
      });
    }
  };
  handleSelectAll = () => {
    const {facturas} = this.state;
    // Obtiene todos los IDs de checkboxes de tu tabla (supongamos que están en la prop 'datos')
    const allCheckboxIds = facturas.map((item) => item.numero_orden_a);
    // Actualiza el estado con todos los IDs
    this.setState({
      checkboxIds: allCheckboxIds,
    });
  };
  calcular(){
    var pre = document.getElementById('pre_e').value;

     var can = document.getElementById('dia_e').value ;
     var t = pre * can;
     document.getElementById('tot_e').value = t ;
  }
  Guardar() {

    var orden = document.getElementById('orden_s').value ;
    var doc = document.getElementById('doc').value ;
    var id_paciente = document.getElementById('id_paciente').value;
    var nombre_paciente = document.getElementById('nombre_paciente').value;
    var direccion_paciente = document.getElementById('direccion_paciente').value;
    var fi = document.getElementById('fi').value;
    var ff = document.getElementById('ff').value;
    var llamada = document.getElementById('llamada').value ;
    var autorizacion = document.getElementById('autorizacion_d').value ;
    var numero = document.getElementById('numero').value ;
    var pedido = document.getElementById('pedido').value;
    var estado = document.getElementById('estado_d').value ;
    var cod_e = document.getElementById('cod_e').value ;
    var id_e = document.getElementById('id_e').value;
    var nom_e = document.getElementById('nombre_e').value;
    var pre_e = document.getElementById('pre_e').value;
    //var pag_e = document.getElementById('pag_e').value;
    var dia_e = document.getElementById('dia_e').value;
    var obs = document.getElementById('observaciones').value;
    //var dato = new FormData(document.getElementById("formcrear"));
    Swal.showLoading();
    var dato = {orden:orden,id_paciente,fi,ff,llamada,numero,pedido,estado,cod_e,id_e,nom_e,pre_e,dia_e,autorizacion,obs}
    
    console.log(dato);

    axios.post(Api + 'ventas/registrar', dato)
      .then(response => {
        console.log(response.data);
        //var m = response.data.factura;
        alert("Se ha Guardado con exito");
        this.Mostrar(orden) ;
      })
      .catch(error => {
        console.log(error);
      });
  }
  Borrar(id) {
    var orden = document.getElementById('orden_s').value ;
    
    Swal.fire({
      title: "Estas seguro de eliminar este registro ? "+id,
      showDenyButton: true,
    
      confirmButtonText: "Elimnar",
      denyButtonText: `Cancelar`
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.showLoading();
  
        //return false;
        axios.get(Api +'ventas/eliminaritems/'+id)
        .then(response => {
          console.log(response.data);
          var m = response.data.factura;
          Swal.fire("Se elimino el registro con exito", "", "success");
          this.Mostrar(orden) ;
        })
        .catch(error => {
          console.log(error);
        });
      } else if (result.isDenied) {
        Swal.fire("No se borro ningun registro", "", "info");
      }
    });
 
   
  }
  render() {
    const {
      datosCargados,
      empresas,
      detalle,
      bodega,
      facturas,
      pagina,
      ultimo,
      paciente,
      detalles,TipoModal,
      insumos,total,date,tf,checkboxIds 
    } = this.state;
    if (!datosCargados) {
      var load = "Cargando datos....";
    } else {
      var load = "";
    }
    return (
      <div className="content">
                <div className="content-wrapper">
      <div className="content">
      <div class="modal fade" id="asignarPaciente" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="asignarPacienteLabel" aria-hidden="true">
                    {
                    TipoModal=='Pacientes'?
                    <FormPaciente agregarPaciente={this.agregarPaciente} />
                    :
                    TipoModal=='HistorialAlquiler'?
                    <FormHistorial agregarPaciente={paciente} />
                    :
                    <FormEquipo agregarPaciente={this.agregarPaciente}  />
                    }
                </div>
      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Formulario de Ventas</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <form id="formcrear">
                  <div className="row">
                  <div className="col-sm-2">
                      <label for="doc_p" className="col-form-label">Documento</label>
                      <div class="input-group mb-3">
                      <input type="text" class="form-control" id="doc" placeholder="Numero de documento" aria-label="umero de documento" aria-describedby="button-addon2" />
                      <button class="btn btn-outline-primary" type="button" id="button-addon2"  data-bs-toggle='modal' data-bs-target='#asignarPaciente' onClick={()=>this.Modal('Pacientes')}>Buscar</button>
                     
                    </div>
                    </div>
                    <div className="col-sm-2">
                      <label for="recipient-name" className="col-form-label">Nombre del Paciente:</label>
                      <input type="hidden" className="form-control" id="id_paciente" />
                      <input type="text" className="form-control" id="nombre_paciente" />
                    </div>
                    <div className="col-sm-2">
                      <label for="recipient-name" className="col-form-label">Direccion:</label>
                      <input type="text" className="form-control" id="direccion_paciente" />
                    </div>
                   
                    <div className="col-sm-2">
                      <label for="recipient-name" className="col-form-label">Orden venta:</label>
                      <input type="text" className="form-control" id="orden_s" name="orden_s" readOnly/>
                    </div>
                    
                    <div className="col-sm-2">
                      <label for="recipient-name" className="col-form-label">Autorizacion:</label>
                      <input type="text" className="form-control" id="autorizacion_d" name="autorizacion_d" />
                    </div>
                    
                    <div className="col-sm-2">
                      <label for="recipient-name" className="col-form-label">Fecha Entrega:</label>
                      <input type="date" className="form-control" id="pedido" name="pedido" />
                    </div>
                    <div className="col-sm-2">
                      <label for="recipient-name" className="col-form-label">Entregado a:</label>
                      <input type="text" className="form-control" id="numero" name="numero" />
                    </div>
                    <div className="col-sm-2">
                      <label for="recipient-name" className="col-form-label">Fecha Registro:</label>
                      <input type="date" className="form-control" id="fi" name="fi" />
                    </div>
                    <div className="col-sm-2">
                      <label for="recipient-name" className="col-form-label">Fecha a Cancelar:</label>
                      <input type="date" className="form-control" id="ff" name="ff" />
                    </div>
                    <div className="col-sm-2">
                      <label for="recipient-name" className="col-form-label">Llamada:</label>
                      <select class="form-select" aria-label="Default select example" id="llamada" name="llamada">
                      
                        <option value="Pendiente">Pendiente</option>
                        <option value="Realizada">Realizada</option>
                        <option value="No aplica">No aplica</option>
                    </select>
                    </div>
                    <div className="col-sm-2">
                      <label for="recipient-name" className="col-form-label">Estado:</label>
                      <select class="form-select" aria-label="Default select example" id="estado_d" name="estado_d">
                      
                        <option value="Cancelado">Cancelado</option>
                        <option value="Completada">Completada</option>
                    </select>
                    </div>
                    <div className="col-sm-12">
                      <label for="recipient-name" className="col-form-label">Observaciones:</label>
                      <input type="text" className="form-control" id="observaciones" iobs="observaciones" />
                    </div>
                  </div>
                  <hr></hr>
                  <h5>Datos del Equipo</h5>
                  <table className="table table-hover table-condensed table-sm">
                     <thead>
                      <tr>
                        <th>Codigo del equipo</th>
                        <th style={{ width: '50px' }}>Id</th>
                        <th>Nombre del equipo</th>
                        <th>Precio Venta</th>
                        <th>Cantidad</th>
                        <th>Total</th>
                        <th>Opc</th>
                        </tr>
                     </thead>
                     <tr>
                         <td>
                         <div class="input-group mb-3">
                          <input type="text" class="form-control" id="cod_e" name="cod_e" placeholder="Numero de documento" aria-label="umero de documento" aria-describedby="button-addon2" />
                        
                          <button class="btn btn-outline-primary" type="button" id="button-addon2"  data-bs-toggle='modal' data-bs-target='#asignarPaciente' onClick={()=>this.Modal('Equipos')}>Buscar</button>
                        </div>
                         </td>
                         <td>
                           <input type="text" className="form-control" id="id_e" name="id_e" readOnly/>
                         </td>
                         <td>
                           <input type="text" className="form-control" id="nombre_e" name="nombre_e" />
                         </td>
                         <td>
                           <input type="text" className="form-control" id="pre_e" name="pre_e" />
                         </td>
                         
                         <td>
                           <input type="text" className="form-control" id="dia_e" name="dia_e" onChange={()=>this.calcular()}/>
                         </td>
                         <td>
                           <input type="text" className="form-control" id="tot_e" name="tot_e" />
                         </td>
                         <td></td>
                     </tr>
                     {detalles.map((itemMovimiento) => (
                      <tr key={itemMovimiento.id_equipo_a}>
                        <td>{itemMovimiento.cod_equipo}</td>
                        <td></td>
                        <td>{itemMovimiento.equipo}</td>
                        <td>{itemMovimiento.precio_a}</td>
                        <td>{itemMovimiento.cantidad}</td>
                        <td>{itemMovimiento.precio_a * itemMovimiento.cantidad}</td>
                        <td>
                        <button
                                type="button"
                                id="bt"
                                name="bt"
                                className="btn btn-danger btn-sm"
                                onClick={() => this.Borrar(itemMovimiento.id_equipo_a)}
                              >[-]</button>
                            </td>
                      </tr>
                    ))}
                  </table>
                  <button type="button" className="btn btn-primary" onClick={()=>this.Guardar()}>Guardar Venta</button>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
               
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
            <h4>Lista de Ventas </h4>
            <button
                    type="button"
                    id="bt"
                    name="bt"
                    data-bs-toggle="modal" data-bs-target="#exampleModal" 
                    className="btn btn-primary btn-sm"
                    onClick={() => this.Mostrar(0)}
                  >Nueva Venta</button>
            </div>
            <div className="card-body">
              
              <form id="formulario">
                <div className="row">
                  <div className="col-xl-2">
                  <label>Empresa</label>
                    <select
                      className="form-select form-select-sm"
                      type="text"
                      name="empresax"
                      id="empresax"
                    >
                      <option value="">Seleccione la Empresa</option>
                      {empresas.map((itemEmpresa) => (
                        <option
                          key={itemEmpresa.id_empresa}
                          value={itemEmpresa.rips}
                        >
                          {itemEmpresa.nombre_emp}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-xl-2">
                  <label>Documento Paciente</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      id="documento"
                      name="documento"
                      placeholder="Numero de Factura"
                    />
                  </div>
                  <div className="col-xl-2">
                  <label>Nombre Paciente</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      id="nombre"
                      name="nombre"
                      placeholder="Numero de Factura"
                    />
                  </div>
                  <div className="col-xl-2">
                  <label>Apellido Paciente</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      id="apellido"
                      name="apellido"
                      placeholder="Numero de Factura"
                    />
                  </div>
                  <div className="col-xl-2">
                  <label>Regimen</label>
                    <select
                      className="form-select form-select-sm"
                      type="text"
                      name="regimen"
                      id="regimen"
                    >
                      <option value="">Todos</option><option value="1">Contributivo</option><option value="2">Subsidiado</option><option value="3">Vinculado</option><option value="4">Particular</option><option value="5">Otro</option><option value="NO APLICA">NO APLICA</option><option value="7">Desplazado con afilacion al regimen Contributivo</option><option value="8">Desplazado con afilacion al regimen Subsidiado</option><option value="9">Desplazado no asegurado</option>
                
                    </select>
                  </div>
                  <div className="col-xl-2">
                  <label>Estado Factura</label>
                    <select
                      className="form-select form-select-sm"
                      type="text"
                      name="estado"
                      id="estado"
                    ><option value="No Facturado">No Facturado</option>
                      <option value="Facturado">Facturado</option>
                      
                
                    </select>
                  </div>
                  <div className="col-xl-2">
                  <label>Estado Alquiler</label>
                    <select
                      className="form-select form-select-sm"
                      type="text"
                      name="estado_ord"
                      id="estado_ord"
                    >
                      <option value="">En alquiler</option><option value="Retirado">Retirado</option>
                
                    </select>
                  </div>
                  <div className="col-xl-2">
                  <label>Estado orden</label>
                    <select
                      className="form-select form-select-sm"
                      type="text"
                      name="estado_orden"
                      id="estado_orden"
                    >
                      <option value="En proceso">En proceso</option><option value="Completada">Completada</option>
                
                    </select>
                  </div>
                  <div className="col-xl-2">
                    <label>Año</label>
                    <input
                      className="form-control form-control-sm"
                      type="number"
                      id="ano"
                      name="ano"
                      placeholder="Año" 
                      defaultValue={'2024'}
                    />
                  </div>
                  <div className="col-xl-2">
                    <label>Mes</label>
                    <select
                      className="form-select form-select-sm"
                      type="text"
                      name="mesx"
                      id="mesx"
                    >
                      <option value="">Todos</option><option value="01">Enero</option><option value="02">Febrero</option><option value="03">Marzo</option><option value="04">Abril</option><option value="05">Mayo</option><option value="06">Junio</option><option value="07">Julio</option><option value="08">Agosto</option><option value="09">Septiembre</option><option value="10">Octubre</option><option value="11">Noviembre</option><option value="12">Diciembre</option>
                    </select>
                  </div>
                  <div className="col-xl-2">
                    <label>Autorizacion</label>
                    <input
                      className="form-control form-control-sm"
                      type="number"
                      id="orden"
                      name="orden"
                     
                      
                    />
                  </div>

                  <div className="col-xl-2 pt-4">
                    <button
                      type="button"
                      id="bt"
                      name="bt"
                      className="btn btn-primary btn-sm"
                      onClick={() => this.Buscar(1)}
                    >
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp;&nbsp;Filtrar
                    </button>
                  </div>
                </div>
              </form>
              <br></br>
              <div className="row">
                <table className="table table-hover table-condensed table-sm">
                  <thead className="thead-inverse">
                    <tr>
                      <th>ORDEN VENTA</th>
                      <th>AUTORIZACION</th>
                      <th>PACIENTE</th>
                      <th>REGIMEN</th>
                      <th>FECHA REGISTRO</th>
                      <th>MES</th>
                      <th>ESTADO</th>
                      <th>LLAMADA?</th>
                      <th>FACTURA</th>
                      <th>OPC</th>
                      <th>VER</th>
                    </tr>
                  </thead>
                  <tbody>
                    {load}
                    {facturas.map((itemMovimiento) => (
                      <tr key={itemMovimiento.id_equipo_a}>
                        <td>{itemMovimiento.numero_orden_a}</td>
                        <td>{itemMovimiento.ult_orden}</td>
                        <td>
                          
                            <Link
                              to={"InfoPacientes/" + itemMovimiento.id_paciente}
                            >
                              {itemMovimiento.nombres} {itemMovimiento.apellidos}
                            </Link>
                        
                        </td>
                        <td>{itemMovimiento.regimen}</td>
                      
                        <td>{itemMovimiento.fecha_a}</td>
                        <td>{itemMovimiento.mes}</td>
                        <td>{itemMovimiento.estado_orden}</td>
                        <td>{itemMovimiento.llamada}</td>
                    
                        <td>
                        {itemMovimiento.facturado}
                        </td>
                        <td>
                        {itemMovimiento.facturado!='Facturado'?
                          <input type="checkbox" checked={checkboxIds.includes(itemMovimiento.numero_orden_a)}
                    onChange={() => this.handleCheckboxChange(itemMovimiento.numero_orden_a)} />
                          :''}
                        </td>
                        <td>
                        <button
                                type="button"
                                id="bt"
                                name="bt" data-bs-toggle="modal" data-bs-target="#exampleModal" 
                                className="btn btn-info btn-sm"
                                onClick={() => this.Mostrar(itemMovimiento.numero_orden_a)}
                              >[+]</button>
                            </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div>
          <p>IDs seleccionados: {checkboxIds.join(', ')}</p>
        </div>
              </div>
           
            </div>
            <div className="card-footer text-muted">
             
              <b> | Total de Alquileres:</b> <span class="badge bg-warning">{tf}</span> <button
                      type="button"
                      id="bt"
                      name="bt"
                      className="btn btn-primary btn-sm"
                      onClick={() => this.Facturar()}
                    >Facturar</button>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
    );
  }
}

export default Ventas;
