import React from "react";
import Api from "../../servicios/api";
import { Link } from "react-router-dom";
import smileImage from "../../images/smile.png"
import sadImage from "../../images/sad.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNotesMedical, faSignOutAlt, faSave, faPrint, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import findImage from "../../images/find.png"
import FormUsuario from "./UsuariosOrd"
import ListaUsuario from "./UsuariosReasignar"
import FormInsumo from "./Insumos"
import FormMedicamento from "./Medicamentos"
import FormAtencion from "./Atenciones"
import './style.css';
import Swal from 'sweetalert2';
class VerOrdenInterna extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            load: '',
            datosCargados: false,
            infoPaciente: '',
            Faltantes:0,
            infoAtenciones: [],
            infoDetalle: [],
            infoInsumos: [],
            infoMedicamentos: []
            // nombre: '',archivo: '',ordenint: '',ordenext: '',cantidad: '',cantidadhide: '',fechaini: '',fechafin: '',cada: '',veces: '',asignado: '',codigo: '',descripcion: '',observacion: '',ingreso: '',urg: '',prueba: '',resu: '',rescov: ''
        };
    }
    SavePac() {

        var nombre = document.getElementById('paciente').value;
        var archivo = document.getElementById('archivo').value;
        var ordenint = document.getElementById('ordenint').value;
        var ordenext = document.getElementById('ordenext').value;
        var cantidad = document.getElementById('cantidad').value;
        var cantidadhide = document.getElementById('cantidadhide').value;
        var fechaini = document.getElementById('fechaini').value;
        var fechafin = document.getElementById('fechafin').value;
        var cada = document.getElementById('cada').value;
        var veces = document.getElementById('veces').value;
        var asignado = document.getElementById('asignado').value;
        var codigo = document.getElementById('codigo').value;
        var descripcion = document.getElementById('descripcion').value;
        var observacion = document.getElementById('observacion').value;
        var ingreso = document.getElementById('ingreso').value;
        var urg = document.getElementById('urg').value;
        var prueba = document.getElementById('prueba').value;
        var resu = document.getElementById('resu').value;
        var rescov = document.getElementById('rescov').value;
        var urg = document.getElementById('urg').value;
        let datos = JSON.stringify({ nombre, archivo, ordenint, ordenext, cantidad, cantidadhide, fechaini, fechafin, cada, veces, asignado, codigo, descripcion, observacion, ingreso, urg, prueba, resu, rescov, urg });

        Swal.showLoading();
        fetch(Api + "Ordenes/AddAtencion", {
            method: "POST",
            body: datos
        })
            .then(respuesta => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                Swal.fire(
                    'Atencion!',
                    datosRespuesta.msj,
                    'success'
                )
                this.cargar();
                this.setState({ datosCargados: true, load: '' });
            })
            .catch(console.log)

    }
    saveana() {

        var ordenint = document.getElementById('ordenint').value;
        var registrorec = document.getElementById('registroana').value;
        var conciente = document.getElementById('conciente').value;
        var somnoliento = document.getElementById('somnoliento').value;
        var fc = document.getElementById('fc').value;
        var fr = document.getElementById('fr').value;
        var ax = document.getElementById('ax').value;
        var ta = document.getElementById('ta').value;
        var pulso = document.getElementById('pulso').value;
        var peso = document.getElementById('peso').value;
        var ana = document.getElementById('ana').value;
        var examen = document.getElementById('examen').value;
        var hallazgo = document.getElementById('hallazgo').value;
        var plan = document.getElementById('plan').value;
        let datos = JSON.stringify({ ordenint, registrorec, conciente, somnoliento, fc, fr, ax, ta, pulso, peso, ana, examen, hallazgo, plan });

        Swal.showLoading();
        fetch(Api + "Ordenes/SaveAnamnesis", {
            method: "POST",
            body: datos
        })
            .then(respuesta => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                Swal.fire(
                    'Anamnesis!',
                    datosRespuesta.msj,
                    'success'
                )
                this.cargar();
                this.setState({ datosCargados: true, load: '' });
            })
            .catch(console.log)

    }
    veranamnesis() {
        var ord = document.getElementById('ordenint').value;
        fetch(Api + "Ordenes/VerAnamnesis/" + ord,
            {
                method: "GET",
                headers: new Headers({
                    'Authorization': 'koala2021'
                }),
            })
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta);


                var registrorec = document.getElementById('registroana').value = datosRespuesta.fecha_registro;
                var conciente = document.getElementById('conciente').value = datosRespuesta.conciente;
                var somnoliento = document.getElementById('somnoliento').value = datosRespuesta.somnoliento;
                var fc = document.getElementById('fc').value = datosRespuesta.fc;
                var fr = document.getElementById('fr').value = datosRespuesta.fr;
                var ax = document.getElementById('ax').value = datosRespuesta.axilar;
                var ta = document.getElementById('ta').value = datosRespuesta.ta;
                var pulso = document.getElementById('pulso').value = datosRespuesta.pulso;
                var peso = document.getElementById('peso').value = datosRespuesta.peso;
                var ana = document.getElementById('ana').value = datosRespuesta.asunto;
                var examen = document.getElementById('examen').value = datosRespuesta.fisico;
                var hallazgo = document.getElementById('hallazgo').value = datosRespuesta.hallazgos;
                var plan = document.getElementById('plan').value = datosRespuesta.terapia;


            })
            .catch(console.log);
    }
    EstadoAte() {
        console.log('estado');
    }
    componentDidMount() {
        this.cargar();
    }
    cargar() {
        //console.log(this.props.match.params.id);
        Swal.showLoading();
        this.setState({ load: 'Cargando Datos...' })
        fetch(Api + "Ordenes/OrdenInterna/" + this.props.match.params.id,
            {
                method: "GET",
                headers: new Headers({
                    'Authorization': 'koala2021'
                }),
            })
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                this.setState({
                    datosCargados: true,
                    load: '',
                    infoAtenciones: datosRespuesta.datos,
                    infoDetalle: datosRespuesta.datos[0],
                    infoPaciente: datosRespuesta.paciente,
                    infoInsumos: datosRespuesta.insumos,
                    infoMedicamentos: datosRespuesta.medicamentos,
                    Faltantes:datosRespuesta.cantidad_faltantes

                });
                
                document.getElementById('estadorev').value = datosRespuesta.datos[0].Location;
                Swal.close();
            })
            .catch(console.log);
    }
    cambiarestado() {
        var ord = document.getElementById('ordenint').value;
        var est = document.getElementById('est').value;
        var motivo = document.getElementById('motivo').value;
        var porcentaje = document.getElementById('porcentaje').value;
        fetch(Api + "Ordenes/CambiarEstado/" + ord + "/" + est + "/" + motivo+ "/" + porcentaje,
            {
                method: "GET",
                headers: new Headers({
                    'Authorization': 'koala2021'
                }),
            })
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                Swal.fire('Editado!', datosRespuesta.msj, 'success');

            })
            .catch(console.log);
    }
    activaredicion() {
        var ord = document.getElementById('ordenint').value;
        var est = document.getElementById('activar').value;
        var fecha = document.getElementById('limite').value;
        fetch(Api + "Ordenes/ActivarEdicion/" + ord + "/" + est + "/" + fecha,
            {
                method: "GET",
                headers: new Headers({
                    'Authorization': 'koala2021'
                }),
            })
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                Swal.fire('Editado!', datosRespuesta.msj, 'success');

            })
            .catch(console.log);
    }
    saveevolucion() {
        var ord = document.getElementById('ordenint').value;
        var registro = document.getElementById('registroevo').value;
        var evo = encodeURIComponent(document.getElementById('evo').value);
        fetch(Api + "Ordenes/SaveEvolucion/" + ord + "/" + registro + "/" + evo,
            {
                method: "GET",
                headers: new Headers({
                    'Authorization': 'koala2021'
                }),
            })
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                Swal.fire('Exito!', datosRespuesta.msj, 'success');

            })
            .catch(console.log);
    }
    savereceta() {
        var ord = document.getElementById('ordenint').value;
        var registro = document.getElementById('registrorec').value;
        var evo = encodeURIComponent(document.getElementById('rec').value);
        var seguir = document.getElementById('seguirrec').value;
        fetch(Api + "Ordenes/SaveReceta/" + ord + "/" + registro + "/" + evo + "/" + seguir,
            {
                method: "GET",
                headers: new Headers({
                    'Authorization': 'koala2021'
                }),
            })
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                Swal.fire('Exito!', datosRespuesta.msj, 'success');

            })
            .catch(console.log);
    }
    verevolucion() {
        var ord = document.getElementById('ordenint').value;
        fetch(Api + "Ordenes/VerEvolucion/" + ord,
            {
                method: "GET",
                headers: new Headers({
                    'Authorization': 'koala2021'
                }),
            })
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                document.getElementById('registroevo').value = datosRespuesta.fecha;
                document.getElementById('evo').value = datosRespuesta.descripcion;
                document.getElementById('idevo').value = datosRespuesta.id_evolucion;

            })
            .catch(console.log);
    }
    verreceta() {
        var ord = document.getElementById('ordenint').value;
        fetch(Api + "Ordenes/VerReceta/" + ord,
            {
                method: "GET",
                headers: new Headers({
                    'Authorization': 'koala2021'
                }),
            })
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                document.getElementById('registrorec').value = datosRespuesta.fecha;
                document.getElementById('rec').value = datosRespuesta.descripcion;
                document.getElementById('idrec').value = datosRespuesta.id_receta;
                document.getElementById('seguirrec').value = datosRespuesta.seguir;
            })
            .catch(console.log);
    }
    Revisar() {
        var ord = document.getElementById('ordenrev').value;
        var obs = document.getElementById('obsrev').value;
        var estadorev = document.getElementById('estadorev').value;
        if (estadorev == '') {
            alert("Debes de seleccionar el estado en revision");
            return false;
        }
        if (obs == '') {
            obs = 'NA';
        }
        fetch(Api + "Ordenes/RevisarOrden/" + ord + "/" + obs,
            {
                method: "GET",
                headers: new Headers({
                    'Authorization': 'koala2021'
                }),
            })
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                alert("Se ha revisado con exito");
                this.cargar();
            })
            .catch(console.log);
    }
    EditInsumo(id) {
        fetch(Api + "Insumos/VerInsumosAsig/" + id,
            {
                method: "GET",
                headers: new Headers({
                    'Authorization': 'koala2021'
                }),
            })
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                document.getElementById('codigoins').value = datosRespuesta.insumo.cod_insumo;
                document.getElementById('stock').value = datosRespuesta.stock;
                document.getElementById('cantidadins').value = datosRespuesta.insumo.cantidad;
                document.getElementById('inventario').value = datosRespuesta.insumo.inventario;

            })
            .catch(console.log);
    }
    GastarInsumo(id,can) {
        document.getElementById('c_idi').value = id;
        document.getElementById('c_res').value = can;
        document.getElementById('c_can').value = can;
    }
    GastarMed(id,can) {
        document.getElementById('c_idim').value = id;
        document.getElementById('c_resm').value = can;
        document.getElementById('c_canm').value = can;
    }
    ConsumirInsumos() {
        var t =0;
        var id = document.getElementById('c_idi').value;
        var can = document.getElementById('c_can').value;
        var res = document.getElementById('c_res').value;
        if (parseFloat(can) > parseFloat(res) ) {
            alert("La cantidad digitada es mayor a la restante");
            return false;
        }
            fetch(Api + "Insumos/GastarIns/" + id + "/" + can,
            {
                method: "GET",
                headers: new Headers({
                    'Authorization': 'koala2021'
                }),
            })
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                 t = res - can;
                document.getElementById('c_res').value = t;
                alert("Se ha actualizado la cantidad con exito");
                this.cargar();
            })
            .catch(console.log);
    }
    Reasignar() {
        Swal.fire({
            title: "Estas seguro de reasignar esta orden?",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Reasignar",
            denyButtonText: `Cancelar`
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below fechafinalrea  */
            if (result.isConfirmed) {
                var ordenrea = document.getElementById('ordenrea').value;
                var asignado_reasignar = document.getElementById('asignado_reasignar').value;
                var restantes = document.getElementById('restantes').value;
                var fechafinalrea = document.getElementById('fechafinalrea').value;
                    fetch(Api + "Ordenes/Reasignar/" + ordenrea + "/" + asignado_reasignar+ "/" + restantes+ "/" + fechafinalrea,
                    {
                        method: "GET",
                        headers: new Headers({
                            'Authorization': 'koala2021'
                        }),
                    })
                    .then((respuesta) => respuesta.json())
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        var idp = datosRespuesta.maximo;
                        alert("Se ha reagsinado la atencion con exito, nueva orden generada No."+idp);
                        this.cargar();
                    })
                    .catch(console.log);
            } else if (result.isDenied) {
              Swal.fire("Se ha cancelado la reasignacion", "", "info");
            }
          });

    
        
    }
    ConsumirMed() {
        var t =0;
        var id = document.getElementById('c_idim').value;
        var can = document.getElementById('c_canm').value;
        var res = document.getElementById('c_resm').value;
        if (parseFloat(can) > parseFloat(res) ) {
            alert("La cantidad digitada es mayor a la restante");
            return false;
        }
            fetch(Api + "Insumos/GastarMed/" + id + "/" + can,
            {
                method: "GET",
                headers: new Headers({
                    'Authorization': 'koala2021'
                }),
            })
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                 t = res - can;
                document.getElementById('c_resm').value = t;
                alert("Se ha actualizado la cantidad con exito");
                this.cargar();
            })
            .catch(console.log);
    }
    EditMedicamento(id) {
        fetch(Api + "Insumos/VerMedicamentosAsig/" + id,
            {
                method: "GET",
                headers: new Headers({
                    'Authorization': 'koala2021'
                }),
            })
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                document.getElementById('codigomed').value = datosRespuesta.medicamento.cod_med;
                document.getElementById('stockmed').value = datosRespuesta.stock;
                document.getElementById('cantidadmed').value = datosRespuesta.medicamento.cantidad;
                document.getElementById('inventariomed').value = datosRespuesta.medicamento.AfecInv;

            })
            .catch(console.log);
    }
    imprimirevolucion() {
        var ord = document.getElementById('ordenint').value;
        if (document.getElementById('evo').value != '') {
            window.open("https://idb.softmediko.com/resumen/evolucion.php?cod=" + ord);
        }

    }
    imprimirreceta() {
        var ord = document.getElementById('ordenint').value;
        if (document.getElementById('rec').value != '') {
            window.open("https://idb.softmediko.com/resumen/receta.php?cod=" + ord);
        }

    }
    imprimiranamnesis() {
        var ord = document.getElementById('ordenint').value;
        if (document.getElementById('ana').value != '') {
            window.open("https://idb.softmediko.com/resumen/anamnesis.php?cod=" + ord);
        }

    }
    ImprimirMI() {
        var ord = document.getElementById('ordenint').value;

        window.open("https://idb.softmediko.com/insumos_asig.php?imp=" + ord);


    }
    ImprimirAte() {
        var ord = document.getElementById('ordenint').value;

        window.open("https://idb.softmediko.com/resumen_atenciones.php?imprimir=" + ord);


    }
    ImprimirAteFirmado() {
        var ord = document.getElementById('ordenint').value;

        window.open("https://idb.softmediko.com/vistas/reporte.php?imprimir=" + ord);


    }

    cambioValor = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState({ state, errores: [] })
    }
    principal(archivo) {

        this.props.history.push("/VerArchivo/" + archivo);
    }
    saveins() {
        var orden = document.getElementById('ordenint').value;
        var cod = document.getElementById('codigoins').value;
        var stock = document.getElementById('stock').value;
        var cant = document.getElementById('cantidadins').value;
        var inv = document.getElementById('inventario').value;
        var fac = document.getElementById('relacionado').value;
        if (fac == '') {
            fac = '0';
        }
        if (inv == 0) {
            if (cant > stock) {
                Swal.fire('Error!', 'La cantidad es mayor a la existente', 'error');
                return;
            }
        }

        fetch(Api + "Insumos/SaveInsumosAsig/" + orden + "/" + stock + "/" + cant + "/" + cod + "/" + inv + "/" + fac,
            {
                method: "GET",
                headers: new Headers({
                    'Authorization': 'koala2021'
                }),
            })
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                Swal.fire('Exito!', datosRespuesta.msj, 'success');
                this.cargar();

            })
            .catch(console.log);
    }
    DelInsumo(id) {
        Swal.fire({
            title: 'Esta seguro de eliminar este registro?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Eliminar',

        }).then((result) => {
            if (result.isConfirmed) {
                fetch(Api + "Insumos/DelInsumo/" + id,
                    {
                        method: "GET",
                        headers: new Headers({
                            'Authorization': 'koala2021'
                        }),
                    })
                    .then((respuesta) => respuesta.json())
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        Swal.fire(datosRespuesta.msj, '', 'success');
                        this.cargar();

                    })
                    .catch(Swal.fire('Error!', 'Sucedio un error', 'error'));
            } else if (result.isDenied) {
                Swal.fire('No se realizo ninguna accion', '', 'info')
            }
        })


    }
    DelMedicamento(id) {
        Swal.fire({
            title: 'Esta seguro de eliminar este registro?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Eliminar',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                fetch(Api + "Insumos/DelMedicamento/" + id,
                    {
                        method: "GET",
                        headers: new Headers({
                            'Authorization': 'koala2021'
                        }),
                    })
                    .then((respuesta) => respuesta.json())
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        Swal.fire('Exito!', datosRespuesta.msj, 'success');
                        this.cargar();

                    })
                    .catch(Swal.fire('Error!', 'Sucedio un error', 'error'));
            } else if (result.isDenied) {
                Swal.fire('No se realizo ninguna accion', '', 'info')
            }
        })


    }
    savemed() {
        var orden = document.getElementById('ordenint').value;
        var cod = document.getElementById('codigomed').value;
        var stock = document.getElementById('stockmed').value;
        var cant = document.getElementById('cantidadmed').value;
        var inv = document.getElementById('inventariomed').value;
        var fac = document.getElementById('relacionadomed').value;
        if (fac == '') {
            fac = '0';
        }
        if (inv == 0) {
            if (cant > stock) {
                Swal.fire('Error!', 'La cantidad es mayor a la existente', 'error');
                return;
            }
        }

        fetch(Api + "Insumos/SaveMedicamentoAsig/" + orden + "/" + stock + "/" + cant + "/" + cod + "/" + inv + "/" + fac,
            {
                method: "GET",
                headers: new Headers({
                    'Authorization': 'koala2021'
                }),
            })
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                Swal.fire('Exito!', datosRespuesta.msj, 'success');
                this.cargar();

            })
            .catch(console.log);
    }
    veritem(id) {
        fetch(Api + "Ordenes/OrdenItem/" + id,
            {
                method: "GET",
                headers: new Headers({
                    'Authorization': 'koala2021'
                }),
            })
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta.datos);
                function decodificarCaracteres(cadena) {
                    try {
                        return decodeURIComponent(cadena);
                    } catch (error) {
                        return cadena;
                    }
                }
                var d = datosRespuesta.datos;
                document.getElementById('Id').value = d.Id;
                document.getElementById('g_pulso').value = d.PULSO;
                document.getElementById('g_fr').value = d.FR;
                document.getElementById('g_pa').value = d.pa;
                document.getElementById('horages').value = d.duracion;
                document.getElementById('fechages').value = d.fecha_mod_ta;
                document.getElementById('g_val').value = decodificarCaracteres(d.Valoracion);
                document.getElementById('g_tra').value = decodificarCaracteres(d.inf_adicional);
                document.getElementById('g_prueba').value = d.prueba_realizada;
                document.getElementById('g_rescov').value = d.resultado;
                document.getElementById('g_resu').value = d.fechaprueba;




            })
            .catch(console.log);
    }
    SaveAte() {
        var archivos = new FormData(document.getElementById("formulario_archivos"));
        Swal.showLoading();
        fetch(Api + "Ordenes/ActualizarItem", {
            method: "POST",
            body: archivos
        })
            .then(respuesta => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                Swal.fire(
                    'Atencion!',
                    datosRespuesta.msj,
                    'success'
                )
                this.cargar();
                this.setState({ datosCargados: true, load: '' });
            })
            .catch(console.log)
    }

    render() {
        const { datosCargados, load, infoAtenciones, infoPaciente, infoInsumos, infoMedicamentos, infoDetalle,Faltantes } = this.state;
        return (
            <div className="content-wrapper">

                <div class="modal fade" id="atencionAsignado" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="atencionAsignadoLabel" aria-hidden="true" data-backdrop="static">
                    <FormAtencion agregarAtencion={this.agregarAtencion} />
                </div>
                <div className="modal" id="myModal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">


                            <div className="modal-header">
                                <h4 className="modal-title">Editar Atencion</h4>

                                <button type="button" className="close" data-bs-dismiss="modal">&times;</button>
                            </div>


                            <div className="modal-body">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-row">
                                            <div className="col">
                                                <label>Nombre del Paciente</label>
                                                <input type="text" className="form-control form-control-sm" id="paciente" placeholder="" name="paciente" value={infoPaciente.nombres + ' ' + infoPaciente.nombre2 + ' ' + infoPaciente.apellidos + ' ' + infoPaciente.apellido2} disabled />
                                            </div>

                                        </div>
                                        <div className="form-row">
                                            <div className="col">
                                                <label>Archivo</label>
                                                <input type="text" className="form-control form-control-sm" id="archivo" placeholder="" name="archivo" value={infoDetalle.archivo} disabled />
                                            </div>
                                            <div className="col">
                                                <label>Orden Interna</label>
                                                <input type="text" className="form-control form-control-sm" id="ordenint" placeholder="" name="ordenint" disabled value={infoDetalle.orden_servicio} />
                                            </div>
                                            <div className="col">
                                                <label>Orden Externa</label>
                                                <input type="text" className="form-control form-control-sm" placeholder="" name="ordenext" id="ordenext" defaultValue={infoDetalle.orden_externa} onChange={this.cambioValor} />
                                            </div> <br></br>
                                            <div className="col">
                                                <label>Cantidad</label>
                                                <input type="number" className="form-control form-control-sm" placeholder="" name="cantidad" id="cantidad" defaultValue={infoDetalle.cant} />
                                                <input type="hidden" className="form-control form-control-sm" placeholder="" name="cantidadhide" id="cantidadhide" value={infoDetalle.cant} />
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="col">
                                                <label>Fecha Inicial</label>
                                                <input type="date" className="form-control form-control-sm" id="fechaini" placeholder="" name="fechaini" defaultValue={infoDetalle.StartTime} />
                                            </div>
                                            <div className="col">
                                                <label>Fecha Final</label>
                                                <input type="date" className="form-control form-control-sm" placeholder="" name="fechafin" id="fechafin" defaultValue={infoDetalle.EndTime} />
                                            </div>
                                            <div className="col">
                                                <label>Atencion Cada</label>
                                                <input type="number" className="form-control form-control-sm" placeholder="" name="cada" id="cada" defaultValue={infoDetalle.cada} />
                                            </div>
                                            <div className="col">
                                                <label>En el dia</label>
                                                <input type="number" className="form-control form-control-sm" placeholder="" name="veces" id="veces" defaultValue={infoDetalle.vdias} />
                                            </div>

                                        </div>

                                        <br></br>
                                        <div className="form-group row">
                                            <label for="inputPassword3" className="col-sm-2 col-form-label">Asignado a</label>
                                            <div className="col-sm-8">
                                                <div className="input-group mb-3">
                                                    <input type="text" className="form-control form-control-sm" id="asignado" placeholder="" disabled defaultValue={infoDetalle.user} />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-success btn-sm" type="button" data-bs-toggle='modal' data-bs-target='#usuarioAsignado'>Buscar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-4">
                                                <label>Codigo atencion</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" className="form-control form-control-sm" placeholder="Codigo" id="codigo" defaultValue={infoDetalle.cod_aten} />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-success btn-sm" type="button" data-bs-toggle='modal' data-bs-target='#atencionAsignado'>Buscar</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-8">
                                                <label>Descripcion </label>
                                                <input type="text" className="form-control form-control-sm" placeholder="" name="descripcion" id="descripcion" defaultValue={infoDetalle.Description} />
                                            </div>

                                        </div>
                                        <div className="form-row">
                                            <div className="col">
                                                <label>Observaciones</label>
                                                <input type="text" className="form-control form-control-sm" id="observacion" placeholder="" name="observacion" defaultValue={infoDetalle.obs} />
                                            </div>


                                        </div>
                                        <div className="form-row">
                                            <div className="col">
                                                <label>Fecha de ingreso </label>
                                                <input type="date" className="form-control form-control-sm" id="ingreso" placeholder="" name="ingreso" defaultValue={infoDetalle.fecha_reg_ta} />
                                            </div>
                                            <div className="col">
                                                <label>Urgente</label>
                                                <select className="form-control form-control-sm" id="urg" defaultValue={infoDetalle.urgente} >
                                                    <option defaultValue={infoDetalle.urgente == 'No' ? 'No' : 'Si'}>{infoDetalle.urgente == 'No' ? 'No' : 'Si'}</option>
                                                    <option defaultValue={infoDetalle.urgente == 'Si' ? 'No' : 'Si'}>{infoDetalle.urgente == 'Si' ? 'No' : 'Si'}</option>
                                                </select>
                                            </div>
                                            <div className="col">
                                                <label>Prueba COVID</label>
                                                <select className="form-control form-control-sm" id="prueba" defaultValue={infoDetalle.prueba} >
                                                    <option defaultValue={infoDetalle.prueba == 'No' ? 'No' : 'Si'}>{infoDetalle.prueba == 'No' ? 'No' : 'Si'}</option>
                                                    <option defaultValue={infoDetalle.prueba == 'Si' ? 'No' : 'Si'}>{infoDetalle.prueba == 'Si' ? 'No' : 'Si'}</option>
                                                </select>
                                            </div>
                                            <div className="col">
                                                <label>Fecha Resultado:</label>
                                                <input type="date" className="form-control form-control-sm" placeholder="" name="resu" id="resu" defaultValue={infoDetalle.vencimiento} />
                                            </div>
                                            <div className="col">
                                                <label>Resultado</label>
                                                <select className="form-control form-control-sm" id="rescov" defaultValue={infoDetalle.resultado} >
                                                    <option value="">Sin resultado</option>
                                                    <option value="Positivo">Positivo</option>
                                                    <option value="Negativo">Negativo</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary btn-sm" data-bs-dismiss="modal" onClick={() => this.SavePac()}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Guardar</button>
                                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><FontAwesomeIcon icon={faSignOutAlt} />&nbsp;&nbsp;Cerrar</button>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="modal fade" id="usuarioAsignado" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="usuarioAsignadoLabel" aria-hidden="true" data-backdrop="static">
                    <FormUsuario agregarPaciente={this.agregarPaciente} />
                </div>

                <div className="modal" id="myModalEstado">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">


                            <div className="modal-header">
                                <h4 className="modal-title">Editar Estado Atencion</h4>

                                <button type="button" className="close" data-bs-dismiss="modal">&times;</button>
                            </div>


                            <div className="modal-body">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-row">
                                            <div className="col">
                                                <label>Nombre del Paciente</label>
                                                <input type="text" className="form-control form-control-sm" id="pacienteestado" placeholder="" name="pacienteestado" value={infoPaciente.nombres + ' ' + infoPaciente.nombre2 + ' ' + infoPaciente.apellidos + ' ' + infoPaciente.apellido2} disabled />
                                            </div>

                                        </div>
                                        <div className="form-row">

                                            <div className="col">
                                                <label>Orden Interna</label>
                                                <input type="text" className="form-control form-control-sm" id="ordeninterna" placeholder="" name="ordeninterna" disabled value={infoDetalle.orden_servicio} />
                                            </div>
                                            <div className="col">
                                                <label>Estado</label>
                                                <select className="form-control form-control-sm" id="est" defaultValue={infoDetalle.est_motivo} >

                                                    <option defaultValue={infoDetalle.est_motivo == 'inactiva' ? 'inactiva' : 'activa'}>{infoDetalle.est_motivo == 'inactiva' ? 'inactiva' : 'activa'}</option>
                                                    <option defaultValue={infoDetalle.est_motivo == 'activa' ? 'inactiva' : 'activa'}>{infoDetalle.est_motivo == 'activa' ? 'inactiva' : 'activa'}</option>

                                                </select>
                                            </div>
                                            <div className="col">
                                                <label>Motivo</label>
                                                <textarea className="form-control form-control-sm" id="motivo" placeholder="" name="motivo" defaultValue={infoDetalle.desc_motivo}></textarea>
                                            </div>
                                            <div className="col">
                                                <label>Porcentaje</label>
                                                <input type="text" className="form-control form-control-sm" id="porcentaje" placeholder="" name="porcentaje"  defaultValue={infoDetalle.id_contacto} />
                                            </div>
                                            <div className="col-sm-12 text-left">
                                                <button type="button" className="btn btn-primary btn-sm" data-bs-dismiss="modal" onClick={() => this.cambiarestado()}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Guardar Estado</button>
                                            </div>

                                        </div>

                                        <hr></hr>
                                        <div className="form-row">
                                            <div className="col">
                                                <label>Fecha limite </label>
                                                <input type="date" className="form-control form-control-sm" id="limite" placeholder="" name="limite" defaultValue={infoDetalle.vencimiento} />
                                            </div>
                                            <div className="col">
                                                <label>Activar Edicion</label>
                                                <select className="form-control form-control-sm" id="activar" >
                                                    <option defaultValue={infoDetalle.editar == '0' ? '0' : '1'}>{infoDetalle.editar == '0' ? 'No activar' : 'Activar Edicion'}</option>
                                                    <option defaultValue={infoDetalle.editar == '1' ? '0' : '1'}>{infoDetalle.editar == '1' ? 'No activar' : 'Activar Edicion'}</option>
                                                </select>
                                            </div>
                                            <br></br>
                                            <div className="col-sm-12 text-left">
                                                <label>Si deseas activar la edicion de la atencion selecciona "activar"</label>
                                                <br></br>
                                                <button type="button" className="btn btn-primary btn-sm" data-bs-dismiss="modal" onClick={() => this.activaredicion()}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Activar Edicion</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="modal-footer">

                                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><FontAwesomeIcon icon={faSignOutAlt} />&nbsp;&nbsp;Cerrar</button>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="modal" id="myModalEvolucion">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">


                            <div className="modal-header">
                                <h4 className="modal-title">Evolucion del paciente</h4>

                                <button type="button" className="close" data-bs-dismiss="modal">&times;</button>
                            </div>


                            <div className="modal-body">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-row">
                                            <div className="col">
                                                <label>Nombre del Paciente</label>
                                                <input type="text" className="form-control form-control-sm" id="pacienteevolucion" placeholder="" name="pacienteevolucion" value={infoPaciente.nombres + ' ' + infoPaciente.nombre2 + ' ' + infoPaciente.apellidos + ' ' + infoPaciente.apellido2} disabled />
                                            </div>

                                        </div>
                                        <div className="form-row">

                                            <div className="col">
                                                <label>Orden Interna</label>
                                                <input type="text" className="form-control form-control-sm" id="ordenevo" placeholder="" name="ordenevo" disabled value={infoDetalle.orden_servicio} />
                                                <input type="hidden" className="form-control form-control-sm" id="idevo" placeholder="" name="idevo" />
                                            </div>
                                            <div className="col">
                                                <label>Fecha de Registro </label>
                                                <input type="date" className="form-control form-control-sm" id="registroevo" placeholder="" name="registroevo" />
                                            </div>



                                        </div>
                                        <div className="form-row">

                                            <div className="col">
                                                <label>Evolucion</label>
                                                <textarea className="form-control form-control-sm" id="evo" placeholder="" name="evo" ></textarea>
                                            </div>
                                            <br></br>
                                            <div className="col-sm-12 text-left">
                                                <label>Puedes registrar la evolucion en la app movil</label>
                                                <br></br>
                                                <button type="button" className="btn btn-primary btn-sm" onClick={() => this.saveevolucion()}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Registrar Evolucion</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary btn-sm" onClick={() => this.imprimirevolucion()}><FontAwesomeIcon icon={faPrint} />&nbsp;&nbsp;Imprimir</button>
                                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><FontAwesomeIcon icon={faSignOutAlt} />&nbsp;&nbsp;Cerrar</button>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="modal" id="myModalRevisar">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">


                            <div className="modal-header">
                                <h4 className="modal-title">REVISAR ORDEN</h4>

                                <button type="button" className="close" data-bs-dismiss="modal">&times;</button>
                            </div>


                            <div className="modal-body">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-row">
                                            <div className="col">
                                                <label>Nombre del Paciente</label>
                                                <input type="text" className="form-control form-control-sm" id="pacienteevolucionREV" placeholder="" name="pacienteevolucionREV" value={infoPaciente.nombres + ' ' + infoPaciente.nombre2 + ' ' + infoPaciente.apellidos + ' ' + infoPaciente.apellido2} disabled />
                                            </div>

                                        </div>
                                        <div className="form-row">

                                            <div className="col">
                                                <label>Orden Interna</label>
                                                <input type="text" className="form-control form-control-sm" id="ordenrev" placeholder="" name="ordenrev" disabled value={infoDetalle.orden_servicio} />
                                                <input type="hidden" className="form-control form-control-sm" id="idevorev" placeholder="" name="idevorev" />
                                            </div>
                                            <div className="col">
                                                <label>Estado </label>
                                                <select className="form-control form-select" id="estadorev" name="estadorev" defaultValue={infoDetalle.Location} >
                                                    <option value="">Seleccione</option>
                                                    <option value="Revisado">Revisado</option>
                                                </select>
                                            </div>



                                        </div>
                                        <div className="form-row">

                                            <div className="col">
                                                <label>Observaciones</label>
                                                <textarea className="form-control form-control-sm" id="obsrev" placeholder="" name="obsrev" >{infoDetalle.RecurringRule}</textarea>
                                            </div>
                                            <br></br>
                                            <div className="col-sm-12 text-left">
                                                <label>Por favor seleccione el estado de revisado para terminar el proceso</label>
                                                <br></br>
                                                <button type="button" className="btn btn-primary btn-sm" onClick={() => this.Revisar()}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Revisar Orden</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="modal-footer">

                                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><FontAwesomeIcon icon={faSignOutAlt} />&nbsp;&nbsp;Cerrar</button>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="modal fade" id="usuarioReasignar" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="usuarioReasignarLabel" aria-hidden="true" data-backdrop="static">
                    <ListaUsuario agregarPaciente2={this.agregarPaciente2} />
                </div>
                <div className="modal" id="myModalReasignar">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">


                            <div className="modal-header">
                                <h4 className="modal-title">REASIGNAR ORDEN</h4>

                                <button type="button" className="close" data-bs-dismiss="modal">&times;</button>
                            </div>


                            <div className="modal-body">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-row">
                                            <div className="col">
                                                <label>Nombre del Paciente</label>
                                                <input type="text" className="form-control form-control-sm" id="pacienteevolucionREA" placeholder="" name="pacienteevolucionREA" value={infoPaciente.nombres + ' ' + infoPaciente.nombre2 + ' ' + infoPaciente.apellidos + ' ' + infoPaciente.apellido2} disabled />
                                            </div>

                                        </div>
                                        <div className="form-row">

                                            <div className="col">
                                                <label>Orden Interna</label>
                                                <input type="text" className="form-control form-control-sm" id="ordenrea" placeholder="" name="ordenre" disabled value={infoDetalle.orden_servicio} />
                                                <input type="hidden" className="form-control form-control-sm" id="idevorea" placeholder="" name="idevorea"  />
                                            </div>
                                            <div className="col">
                                                <label>Orden Externa </label>
                                                <input type="text" className="form-control form-control-sm" id="orden_ext" placeholder="" name="orden_ext" value={infoDetalle.orden_externa} />
                                            </div>
                                            <div className="col">
                                                <label>Atenciones Restante </label>
                                                <input type="text" className="form-control form-control-sm" id="restantes" placeholder="" name="restantes" value={Faltantes} />
                                            </div>
                                            <div className="col">
                                            <label>Asignado a</label>
                                            <div className="col-sm-12">
                                                <div className="input-group mb-3">
                                                    <input type="text" className="form-control form-control-sm" id="asignado_reasignar" placeholder="" disabled defaultValue={infoDetalle.user} />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-success btn-sm" type="button" data-bs-toggle='modal' data-bs-target='#usuarioReasignar'>Buscar</button>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                        </div>

                                        </div>
                                        <div className="form-row">

                                            <div className="col">
                                                <label>Fecha Final de la atencion</label>
                                                <input type="date" className="form-control form-control-sm" id="fechafinalrea" placeholder="" name="fechafinalrea" value={infoDetalle.EndTime}/>
                                            </div>
                                           
                                            <br></br>
                                            <div className="col-sm-12 text-left">
                                                <label>Por favor verifique los datos antes de guardar</label>
                                                <br></br>
                                                <button type="button" className="btn btn-primary btn-sm" onClick={() => this.Reasignar()}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Reasignar Orden</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="modal-footer">

                                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><FontAwesomeIcon icon={faSignOutAlt} />&nbsp;&nbsp;Cerrar</button>
                            </div>

                        </div>
                    </div>
                </div>
                
                <div className="modal" id="myModalReceta">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">


                            <div className="modal-header">
                                <h4 className="modal-title">Receta del paciente</h4>

                                <button type="button" className="close" data-bs-dismiss="modal">&times;</button>
                            </div>


                            <div className="modal-body">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-row">
                                            <div className="col">
                                                <label>Nombre del Paciente</label>
                                                <input type="text" className="form-control form-control-sm" id="pacienterec" placeholder="" name="pacienterec" value={infoPaciente.nombres + ' ' + infoPaciente.nombre2 + ' ' + infoPaciente.apellidos + ' ' + infoPaciente.apellido2} disabled />
                                            </div>

                                        </div>
                                        <div className="form-row">

                                            <div className="col">
                                                <label>Orden Interna</label>
                                                <input type="text" className="form-control form-control-sm" id="ordenrec" placeholder="" name="ordenrec" disabled value={infoDetalle.orden_servicio} />
                                                <input type="hidden" className="form-control form-control-sm" id="idrec" placeholder="" name="idrec" />
                                            </div>
                                            <div className="col">
                                                <label>Fecha de Registro </label>
                                                <input type="date" className="form-control form-control-sm" id="registrorec" placeholder="" name="registrorec" />
                                            </div>
                                            <div className="col">
                                                <label>Se requiere seguir con las atenciones?</label>
                                                <select className="form-control form-control-sm" id="seguirrec" name="seguirrec">
                                                    <option value="Si">Si</option>
                                                    <option value="No">No</option>
                                                </select>

                                            </div>



                                        </div>
                                        <div className="form-row">

                                            <div className="col">
                                                <label>Describa la receta</label>
                                                <textarea className="form-control form-control-sm" id="rec" placeholder="" name="rec" ></textarea>
                                            </div>
                                            <br></br>
                                            <div className="col-sm-12 text-left">
                                                <label>Puedes registrar la receta en la app movil</label>
                                                <br></br>
                                                <button type="button" className="btn btn-primary btn-sm" onClick={() => this.savereceta()}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Registrar Receta</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary btn-sm" onClick={() => this.imprimirreceta()}><FontAwesomeIcon icon={faPrint} />&nbsp;&nbsp;Imprimir</button>
                                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><FontAwesomeIcon icon={faSignOutAlt} />&nbsp;&nbsp;Cerrar</button>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="modal" id="myModalAna">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">


                            <div className="modal-header">
                                <h4 className="modal-title">ANAMNESIS del paciente</h4>

                                <button type="button" className="close" data-bs-dismiss="modal">&times;</button>
                            </div>


                            <div className="modal-body">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-row">
                                            <div className="col">
                                                <label>Nombre del Paciente</label>
                                                <input type="text" className="form-control form-control-sm" id="pacienterec" placeholder="" name="pacienterec" value={infoPaciente.nombres + ' ' + infoPaciente.nombre2 + ' ' + infoPaciente.apellidos + ' ' + infoPaciente.apellido2} disabled />
                                            </div>

                                        </div>
                                        <div className="form-row">

                                            <div className="col">
                                                <label>Orden Interna</label>
                                                <input type="text" className="form-control form-control-sm" id="ordenana" placeholder="" name="ordenana" disabled value={infoDetalle.orden_servicio} />

                                            </div>
                                            <div className="col">
                                                <label>Fecha de Registro </label>
                                                <input type="date" className="form-control form-control-sm" id="registroana" placeholder="" name="registroana" />
                                            </div>
                                            <div className="col">
                                                <label>Conciente y Orientado?</label>
                                                <select className="form-control form-control-sm" id="conciente" name="conciente">
                                                    <option value="No">No</option>
                                                    <option value="Si">Si</option>
                                                </select>

                                            </div>
                                            <div className="col">
                                                <label>Somnoliento?</label>
                                                <select className="form-control form-control-sm" id="somnoliento" name="somnoliento">
                                                    <option value="No">No</option>
                                                    <option value="Si">Si</option>

                                                </select>

                                            </div>



                                        </div>
                                        <div className="form-row">

                                            <div className="col">
                                                <label>FC:</label>
                                                <input type="text" className="form-control form-control-sm" id="fc" placeholder="" name="ordenana" />

                                            </div>
                                            <div className="col">
                                                <label>FR: </label>
                                                <input type="text" className="form-control form-control-sm" id="fr" placeholder="" name="registrorec" />
                                            </div>
                                            <div className="col">
                                                <label>TºAxilar: </label>
                                                <input type="text" className="form-control form-control-sm" id="ax" placeholder="" name="registrorec" />
                                            </div>
                                        </div>
                                        <div className="form-row">

                                            <div className="col">
                                                <label>TA:</label>
                                                <input type="text" className="form-control form-control-sm" id="ta" placeholder="" name="ordenana" />

                                            </div>
                                            <div className="col">
                                                <label>PULSO: </label>
                                                <input type="text" className="form-control form-control-sm" id="pulso" placeholder="" name="registrorec" />
                                            </div>
                                            <div className="col">
                                                <label>PESO: </label>
                                                <input type="text" className="form-control form-control-sm" id="peso" placeholder="" name="registrorec" />
                                            </div>
                                        </div>
                                        <div className="form-row">

                                            <div className="col-sm-12">
                                                <label>ANAMNESIS</label>
                                                <textarea className="form-control form-control-sm" id="ana" placeholder="" name="ana" ></textarea>
                                            </div>
                                            <div className="col-sm-12">
                                                <label>EXAMEN FÌSICO</label>
                                                <textarea className="form-control form-control-sm" id="examen" placeholder="" name="ana" ></textarea>
                                            </div>
                                            <div className="col-sm-12">
                                                <label>HALLAZGOS DIAGNOSTICO</label>
                                                <textarea className="form-control form-control-sm" id="hallazgo" placeholder="" name="ana" ></textarea>
                                            </div>
                                            <div className="col-sm-12">
                                                <label>PLAN DE MANEJO</label>
                                                <textarea className="form-control form-control-sm" id="plan" placeholder="" name="ana" ></textarea>
                                            </div>

                                            <br></br>
                                            <div className="col-sm-12 text-left">

                                                <br></br>
                                                <button type="button" className="btn btn-primary btn-sm" onClick={() => this.saveana()}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Guardar</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="modal-footer">

                                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><FontAwesomeIcon icon={faSignOutAlt} />&nbsp;&nbsp;Cerrar</button>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="modal" id="myModalIns">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">


                            <div className="modal-header">
                                <h4 className="modal-title">Registro de insumos</h4>

                                <button type="button" className="close" data-bs-dismiss="modal">&times;</button>
                            </div>


                            <div className="modal-body">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-row">
                                            <div className="col">
                                                <label>Nombre del Paciente</label>
                                                <input type="text" className="form-control form-control-sm" id="" placeholder="" name="pacienteins" value={infoPaciente.nombres + ' ' + infoPaciente.nombre2 + ' ' + infoPaciente.apellidos + ' ' + infoPaciente.apellido2} disabled />
                                            </div>

                                        </div>
                                        <div className="form-row">

                                            <div className="col">
                                                <label>Orden Interna</label>
                                                <input type="text" className="form-control form-control-sm" id="ordenins" placeholder="" name="ordenins" disabled value={infoDetalle.orden_servicio} />

                                            </div>
                                            <div className="col">
                                                <label>Archivo</label>
                                                <input type="text" className="form-control form-control-sm" id="archivoins" placeholder="" name="ordenins" disabled value={infoDetalle.archivo} />

                                            </div>
                                            <div className="col">
                                                <label>Factura No.</label>
                                                <input type="text" className="form-control form-control-sm" id="relacionado" placeholder="" name="relacionado" disabled value={infoDetalle.relacionado} />

                                            </div>
                                            <div className="col">
                                            </div>
                                            <div className="col">
                                                <label>Afecta Inventario?</label>
                                                <select className="form-control form-control-sm" id="inventario" name="inventario">
                                                    <option value="1">No</option>
                                                    <option value="0">Si</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-4">
                                                <label>Codigo</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" className="form-control form-control-sm" placeholder="Codigo" id="codigoins" />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-success btn-sm" type="button" data-bs-toggle='modal' data-bs-target='#buscarins'>Buscar</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <label>Stock Disponible </label>
                                                <input type="text" className="form-control form-control-sm" placeholder="" name="descripcion" id="stock" disabled />
                                            </div>
                                            <div className="col-4">
                                                <label>Stock Disponible </label>
                                                <input type="text" className="form-control form-control-sm" placeholder="" name="cantidad" id="cantidadins" />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary btn-sm" onClick={() => this.saveins()}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Guardar</button>
                                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><FontAwesomeIcon icon={faSignOutAlt} />&nbsp;&nbsp;Cerrar</button>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="modal" id="myModalGastarIns">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">


                            <div className="modal-header">
                                <h4 className="modal-title">Consumir insumos asignados</h4>

                                <button type="button" className="close" data-bs-dismiss="modal">&times;</button>
                            </div>


                            <div className="modal-body">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-row">
                                            <div className="col">
                                                <label>Nombre del Paciente</label>
                                                <input type="text" className="form-control form-control-sm" id="" placeholder="" name="pacienteins" value={infoPaciente.nombres + ' ' + infoPaciente.nombre2 + ' ' + infoPaciente.apellidos + ' ' + infoPaciente.apellido2} disabled />
                                            </div>

                                        </div>

                                        <div className="form-row">
                                        <div className="col-4">
                                                <label>Id Insumo </label>
                                                <input type="text" className="form-control form-control-sm" placeholder="" name="descripcion" id="c_idi" disabled />
                                            </div>
                                            <div className="col-4">
                                                <label>Cantidad Restante </label>
                                                <input type="text" className="form-control form-control-sm" placeholder="" name="descripcion" id="c_res" disabled />
                                            </div>
                                            <div className="col-4">
                                                <label>Cantidad a Consumir </label>
                                                <input type="text" className="form-control form-control-sm" placeholder="" name="cantidad" id="c_can" />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary btn-sm" onClick={() => this.ConsumirInsumos()}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Gastar Insumo</button>
                                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><FontAwesomeIcon icon={faSignOutAlt} />&nbsp;&nbsp;Cerrar</button>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="modal" id="myModalGastarMed">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">


                            <div className="modal-header">
                                <h4 className="modal-title">Consumir Medicamentos asignados</h4>

                                <button type="button" className="close" data-bs-dismiss="modal">&times;</button>
                            </div>


                            <div className="modal-body">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-row">
                                            <div className="col">
                                                <label>Nombre del Paciente</label>
                                                <input type="text" className="form-control form-control-sm" id="" placeholder="" name="pacienteins" value={infoPaciente.nombres + ' ' + infoPaciente.nombre2 + ' ' + infoPaciente.apellidos + ' ' + infoPaciente.apellido2} disabled />
                                            </div>

                                        </div>

                                        <div className="form-row">
                                        <div className="col-4">
                                                <label>Id medicamento </label>
                                                <input type="text" className="form-control form-control-sm" placeholder="" name="descripcion" id="c_idim" disabled />
                                            </div>
                                            <div className="col-4">
                                                <label>Cantidad Restante </label>
                                                <input type="text" className="form-control form-control-sm" placeholder="" name="descripcion" id="c_resm" disabled />
                                            </div>
                                            <div className="col-4">
                                                <label>Cantidad a Consumir </label>
                                                <input type="text" className="form-control form-control-sm" placeholder="" name="cantidad" id="c_canm" />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary btn-sm" onClick={() => this.ConsumirMed()}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Gastar Medicamento</button>
                                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><FontAwesomeIcon icon={faSignOutAlt} />&nbsp;&nbsp;Cerrar</button>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="modal" id="myModalMed">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">


                            <div className="modal-header">
                                <h4 className="modal-title">Registro de Medicamentos</h4>

                                <button type="button" className="close" data-bs-dismiss="modal">&times;</button>
                            </div>


                            <div className="modal-body">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-row">
                                            <div className="col">
                                                <label>Nombre del Paciente</label>
                                                <input type="text" className="form-control form-control-sm" id="pacientemed" placeholder="" name="pacientemed" value={infoPaciente.nombres + ' ' + infoPaciente.nombre2 + ' ' + infoPaciente.apellidos + ' ' + infoPaciente.apellido2} disabled />
                                            </div>

                                        </div>
                                        <div className="form-row">

                                            <div className="col">
                                                <label>Orden Interna</label>
                                                <input type="text" className="form-control form-control-sm" id="ordenmed" placeholder="" name="ordenmed" disabled value={infoDetalle.orden_servicio} />

                                            </div>
                                            <div className="col">
                                                <label>Archivo</label>
                                                <input type="text" className="form-control form-control-sm" id="archivomed" placeholder="" name="ordenins" disabled value={infoDetalle.archivo} />

                                            </div>
                                            <div className="col">
                                                <label>Factura No.</label>
                                                <input type="text" className="form-control form-control-sm" id="relacionadomed" placeholder="" name="relacionadomed" disabled value={infoDetalle.relacionado} />

                                            </div>
                                            <div className="col">
                                            </div>
                                            <div className="col">
                                                <label>Afecta Inventario?</label>
                                                <select className="form-control form-control-sm" id="inventariomed" name="inventariomed">
                                                    <option value="1">No</option>
                                                    <option value="0">Si</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-4">
                                                <label>Codigo</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" className="form-control form-control-sm" placeholder="Codigo" id="codigomed" />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-success btn-sm" type="button" data-bs-toggle='modal' data-bs-target='#buscarmed'>Buscar</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <label>Stock Disponible </label>
                                                <input type="text" className="form-control form-control-sm" placeholder="" name="descripcion" id="stockmed" disabled />
                                            </div>
                                            <div className="col-4">
                                                <label>Stock Disponible </label>
                                                <input type="text" className="form-control form-control-sm" placeholder="" name="cantidad" id="cantidadmed" />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary btn-sm" onClick={() => this.savemed()}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Guardar</button>
                                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><FontAwesomeIcon icon={faSignOutAlt} />&nbsp;&nbsp;Cerrar</button>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal" id="myModalGestionar">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">


                            <div className="modal-header">
                                <h4 className="modal-title">Gestionar Atencion</h4>

                                <button type="button" className="close" data-bs-dismiss="modal">&times;</button>
                            </div>

                            <form id="formulario_archivos">
                                <div className="modal-body">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="form-row">
                                                <div className="col">
                                                    <label>Nombre del Paciente</label>
                                                    <input type="text" className="form-control form-control-sm" id="pacienteges" placeholder="" name="pacienteges" value={infoPaciente.nombres + ' ' + infoPaciente.nombre2 + ' ' + infoPaciente.apellidos + ' ' + infoPaciente.apellido2} disabled />
                                                    <input type="text" className="form-control form-control-sm" id="Id" placeholder="" name="Id" />
                                                </div>

                                            </div>
                                            <div className="form-row">
                                                <div className="col">
                                                    <label>Archivo</label>
                                                    <input type="text" className="form-control form-control-sm" id="g_archivo" placeholder="" name="g_archivo" value={infoDetalle.archivo} disabled />
                                                </div>
                                                <div className="col">
                                                    <label>Orden Interna</label>
                                                    <input type="text" className="form-control form-control-sm" id="g_ordenint" placeholder="" name="g_ordenint" disabled value={infoDetalle.orden_servicio} />
                                                </div>
                                                <div className="col">
                                                    <label>Orden Externa</label>
                                                    <input type="text" className="form-control form-control-sm" placeholder="" name="g_ordenext" id="g_ordenext" defaultValue={infoDetalle.orden_externa} onChange={this.cambioValor} />
                                                </div> <br></br>

                                            </div>

                                            <div className="form-row">
                                                <div className="col">
                                                    <label>Fecha Registro</label>
                                                    <input type="date" className="form-control form-control-sm" id="fechages" placeholder="" name="fechages" />
                                                </div>
                                                <div className="col">
                                                    <label>Hora</label>
                                                    <input type="time" className="form-control form-control-sm" placeholder="" name="horages" id="horages" />
                                                </div>
                                                <div className="col">
                                                    <label>Pulso</label>
                                                    <input type="number" className="form-control form-control-sm" placeholder="" name="g_pulso" id="g_pulso" />
                                                </div>
                                                <div className="col">
                                                    <label>PA</label>
                                                    <input type="number" className="form-control form-control-sm" placeholder="" name="g_pa" id="g_pa" />
                                                </div>
                                                <div className="col">
                                                    <label>FR</label>
                                                    <input type="number" className="form-control form-control-sm" placeholder="" name="g_fr" id="g_fr" />
                                                </div>
                                            </div>

                                            <br></br>
                                            <div className="form-row">
                                                <div className="col">
                                                    <label>Valoracion: </label>
                                                    <textarea className="form-control form-control-sm" id="g_val" placeholder="" name="g_val" ></textarea>
                                                </div>
                                                <div className="col">
                                                    <label>Tratamiento: </label>
                                                    <textarea className="form-control form-control-sm" id="g_tra" placeholder="" name="g_tra" ></textarea>
                                                </div>
                                            </div>


                                            <div className="form-row">

                                                <div className="col">
                                                    <label>Prueba COVID</label>
                                                    <select className="form-control form-control-sm" id="g_prueba" name="g_prueba">
                                                        <option value="">Seleccione</option>
                                                        <option value="Si">Si</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                </div>
                                                <div className="col">
                                                    <label>Fecha Resultado:</label>
                                                    <input type="date" className="form-control form-control-sm" placeholder="" name="g_resu" id="g_resu" />
                                                </div>
                                                <div className="col">
                                                    <label>Resultado</label>
                                                    <select className="form-control form-control-sm" id="g_rescov" name="g_rescov">
                                                        <option value="">Sin resultado</option>
                                                        <option value="Positivo">Positivo</option>
                                                        <option value="Negativo">Negativo</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary btn-sm" data-bs-dismiss="modal" onClick={() => this.SaveAte()}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Guardar</button>
                                    <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><FontAwesomeIcon icon={faSignOutAlt} />&nbsp;&nbsp;Cerrar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>



                <div class="modal fade" id="buscarmed" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="buscarmedLabel" aria-hidden="true" data-backdrop="static">
                    <FormMedicamento agregarPaciente={this.agregarPaciente} />
                </div>
                <div class="modal fade" id="buscarins" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="buscarinsLabel" aria-hidden="true" data-backdrop="static">
                    <FormInsumo agregarPaciente={this.agregarPaciente} />
                </div>

                <div className="content">
                    <div className="container-fluid">
                        <br />
                        <div className="card">
                            <div className="card-header text-center">
                                <b>ORDEN INTERNA # {this.props.match.params.id}  </b>
                            </div>
                            <div className="card-body">
                            <Link
                              className=""
                              to={"/InfoPacientes/" + infoPaciente.id_paciente}
                            >
                            

                         
                                <h4 className="profile-username text-center">
                                
                                    {infoPaciente.nombres} {infoPaciente.nombre2} {infoPaciente.apellidos} {infoPaciente.apellido2}
                                    </h4>
                                    </Link>
                                <p className="text-muted text-center">{infoPaciente.documento}: {infoPaciente.numero_doc}</p>
                                <div className="card">
                                    <div className="card-body">
                                        <button className='btn btn-success btn-sm' id='' type='button' onClick={() => this.principal(infoDetalle.archivo)}>Archivo Principal.</button>&nbsp;
                                        <button className='btn btn-primary btn-sm' id='btnNuevoPacienteSub' type='button' data-bs-toggle='modal' data-bs-target='#myModal' onClick={() => (this.setState({ editar: 'No' }))}><FontAwesomeIcon icon={faNotesMedical} />&nbsp;&nbsp;Editar Atencion.</button> &nbsp;
                                        <button className='btn btn-primary btn-sm' id='' type='button' data-bs-toggle='modal' data-bs-target='#myModalEstado' onClick={() => this.EstadoAte()}>1.Estado Atencion.</button>&nbsp;
                                        <button className='btn btn-info btn-sm' id='' type='button' data-bs-toggle='modal' data-bs-target='#myModalMed' onClick={() => this.EstadoAte()}>2.Medicamentos.</button>&nbsp;
                                        <button className='btn btn-info btn-sm' id='' type='button' data-bs-toggle='modal' data-bs-target='#myModalIns' onClick={() => this.EstadoAte()}>3.Insumos.</button>&nbsp;

                                        <button className='btn btn-danger btn-sm' id='' type='button' data-bs-toggle='modal' data-bs-target='#myModalAna' onClick={() => this.veranamnesis()}>4.Anamnesis.</button>&nbsp;
                                        <button className='btn btn-danger btn-sm' id='' type='button' data-bs-toggle='modal' data-bs-target='#myModalEvolucion' onClick={() => this.verevolucion()}>5.Evolucion.</button>&nbsp;
                                        <button className='btn btn-danger btn-sm' id='' type='button' data-bs-toggle='modal' data-bs-target='#myModalReceta' onClick={() => this.verreceta()}>6.Receta.</button>&nbsp;
                                        <button className='btn btn-danger btn-sm' id='' type='button' data-bs-toggle='modal' data-bs-target='#myModalRevisar'>7.Revisar Orden.</button>&nbsp;
                                        <button className='btn btn-danger btn-sm' id='' type='button' data-bs-toggle='modal' data-bs-target='#myModalReasignar'>8.Reasignar Orden.</button>&nbsp;
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header text-center">
                                        <b>ATENCIONES</b>
                                    </div>


                                    <div className="card-body">
                                        <div style={{ float: "right" }} className="pb-3">
                                            <button className='btn btn-secondary btn-sm' id='' type='button' onClick={() => this.ImprimirAte()}><FontAwesomeIcon icon={faPrint} />&nbsp;&nbsp; Imprimir.</button>&nbsp;
                                            <button className='btn btn-secondary btn-sm' id='' type='button' onClick={() => this.ImprimirAteFirmado()}><FontAwesomeIcon icon={faPrint} />&nbsp;&nbsp; Imprimir con firma.</button>&nbsp;

                                        </div><br />
                                        <table className="table table-hover table-condensed table-sm">
                                            <thead className="thead-inverse">
                                                <tr>
                                                    <th className="align-middle">URGENTE</th>
                                                    <th className="align-middle">ATENCIÓN</th>
                                                    <th className="align-middle">ORDEN EXT.</th>
                                                    <th className="align-middle">VISITA #</th>
                                                    <th className="align-middle">CADA</th>
                                                    <th className="align-middle">FEC. VISITA</th>
                                                    <th className="align-middle">FEC. REGISTRO</th>
                                                    <th className="align-middle">PUNTAJE</th>
                                                    <th className="align-middle">ESTADO</th>
                                                    <th className="align-middle">ASIGNADO A</th>
                                                    <th className="align-middle">PORC. (%)</th>
                                                    <th className="align-middle">CANT.</th>
                                                    <th className="align-middle">P.R</th>
                                                    <th className="align-middle">Firmado</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {load}
                                                {
                                                    infoAtenciones.map((itemAtenciones) => (
                                                        <tr key={itemAtenciones.Id}>
                                                            <td>{itemAtenciones.urgente}</td>
                                                            <td className={itemAtenciones.estado == 'Completada' ? 'table-success' : 'table-danger'}>{itemAtenciones.Description} [<a href="#" data-bs-toggle='modal' data-bs-target='#myModalGestionar' onClick={() => this.veritem(itemAtenciones.Id)}>+</a>]</td>
                                                            <td>{itemAtenciones.orden_externa}</td>
                                                            <td>{itemAtenciones.cant_ins}</td>
                                                            <td>{itemAtenciones.cada} Días</td>
                                                            <td>{itemAtenciones.fecha_mod_ta}</td>
                                                            <td>{itemAtenciones.registro}</td>
                                                            <td>
                                                                {
                                                                    itemAtenciones.puntos === 1 ? (
                                                                        <img style={{ width: 16, height: 16 }} src={smileImage} alt="Feliz" />
                                                                    ) : (
                                                                        <img style={{ width: 16, height: 16 }} src={sadImage} alt="Triste" />
                                                                    )
                                                                }
                                                            </td>
                                                            <td>{itemAtenciones.estado}</td>
                                                            <td>{itemAtenciones.user}</td>
                                                            <td>{parseFloat(itemAtenciones.porcentaje).toFixed(2) + "%"}</td>
                                                            <td>{itemAtenciones.cant}</td>
                                                            <td>{itemAtenciones.prueba_realizada}</td>
                                                            <td className={itemAtenciones.quien != null ? 'table-success' : 'table-danger'}>{itemAtenciones.quien != null ? 'Firmado' : 'Pendiente'}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card">

                                    <div className="card-header text-center">
                                        <b>INSUMOS </b>

                                    </div>

                                    <div className="card-body">
                                        <div style={{ float: "right" }} className="pb-3">
                                            <button className='btn btn-secondary btn-sm' id='' type='button' onClick={() => this.ImprimirMI()}><FontAwesomeIcon icon={faPrint} />&nbsp;&nbsp; Imprimir.</button>&nbsp;
                                        </div><br />
                                        <table className="table table-hover table-condensed table-sm">
                                            <thead className="thead-inverse">
                                                <tr>
                                                    <th className="align-middle">ORDEN INT.</th>
                                                    <th className="align-middle">COD.</th>
                                                    <th className="align-middle">NOMBRE INSUMO</th>
                                                    <th className="align-middle">CANT.</th>
                                                    <th className="align-middle">CANT. USADAS</th>
                                                    <th className="align-middle">CANT. RESTANTE</th>
                                                    <th className="align-middle">RANGO FECHA</th>
                                                    <th className="align-middle">ASIGNADO A</th>
                                                    <th className="align-middle">-</th>
                                                    <th className="align-middle">-</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {load}
                                                {
                                                    infoInsumos.map((itemInsumos) => (
                                                        <tr key={itemInsumos.id_ia}>
                                                            <td>{itemInsumos.rel_atencion} {itemInsumos.id_ia}</td>
                                                            <td>{itemInsumos.cod_insumo}</td>
                                                            <td>{itemInsumos.nombre_insumo}</td>
                                                            <td>{itemInsumos.cantidad}</td>
                                                            <td>{itemInsumos.cant_usada}</td>
                                                            <td>{itemInsumos.cant_restante} <button data-bs-toggle='modal' data-bs-target='#myModalGastarIns' onClick={() => this.GastarInsumo(itemInsumos.id_ia,itemInsumos.cant_restante)}>[+]</button></td>
                                                            <td>{itemInsumos.minimo} - {itemInsumos.maximo}</td>
                                                            <td>{itemInsumos.asignado_a}</td>
                                                            <td><button className="btn btn-sm btn-warning" data-bs-toggle='modal' data-bs-target='#myModalIns' onClick={() => this.EditInsumo(itemInsumos.id_ia)}> <FontAwesomeIcon icon={faEdit} /> </button></td>
                                                            <td>{(itemInsumos.cant_restante == 0 ? '' : <button className="btn btn-sm btn-warning" onClick={() => this.DelInsumo(itemInsumos.id_ia)}> <FontAwesomeIcon icon={faTrash} />&nbsp;&nbsp; </button>)}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header text-center">
                                        <b>MEDICAMENTOS</b>
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-hover table-condensed table-sm">
                                            <thead className="thead-inverse">
                                                <tr>
                                                    <th className="align-middle">ORDEN INT.</th>
                                                    <th className="align-middle">COD.</th>
                                                    <th className="align-middle">NOMBRE INSUMO</th>
                                                    <th className="align-middle">CANT.</th>
                                                    <th className="align-middle">CANT. USADAS</th>
                                                    <th className="align-middle">CANT. RESTANTE</th>
                                                    <th className="align-middle">FECHA ASIG.</th>
                                                    <th className="align-middle">ASIGNADO A</th>
                                                    <th className="align-middle">-</th>
                                                    <th className="align-middle">-</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {load}
                                                {
                                                    infoMedicamentos.map((itemMedicamentos) => (
                                                        <tr key={itemMedicamentos.i}>
                                                            <td>{itemMedicamentos.rel_atencion} </td>
                                                            <td>{itemMedicamentos.codigo_int}</td>
                                                            <td>{itemMedicamentos.nombre_medicamento}</td>
                                                            <td>{itemMedicamentos.cantidad}</td>
                                                            <td>{itemMedicamentos.cantidad_usada}</td>
                                                            <td>{itemMedicamentos.cantidad_rest} <button data-bs-toggle='modal' data-bs-target='#myModalGastarMed' onClick={() => this.GastarMed(itemMedicamentos.i,itemMedicamentos.cantidad_rest)}>[+]</button></td>
                                                            <td>{itemMedicamentos.fecha_asig}</td>
                                                            <td>{itemMedicamentos.asignado_a}</td>
                                                            <td><button className="btn btn-sm btn-warning" data-bs-toggle='modal' data-bs-target='#myModalMed' onClick={() => this.EditMedicamento(itemMedicamentos.i)}> <FontAwesomeIcon icon={faEdit} /> </button></td>
                                                            <td>{(itemMedicamentos.cantidad_rest == 0 ? '' : <button className="btn btn-sm btn-danger" onClick={() => this.DelMedicamento(itemMedicamentos.i)}> <FontAwesomeIcon icon={faTrash} /> </button>)}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        );
    }
}

export default VerOrdenInterna;