import React from 'react';
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import Swal from 'sweetalert2';
import FormPaciente from "./Pacientes"
import findImage from "../../images/find.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons'

class Crear extends React.Component {
    constructor(props) {
        super(props);
        this.agregarPaciente = this.agregarPaciente.bind(this);
        this.state = {
            nombre: "",
            pac: "",
            errores: []
        }
    }
    resetForm() {
        document.getElementById('form').reset();
        document.getElementById('nombre').focus();
        //this.BuscarInsumo(1);
    }
    cambioValor = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState({ state, errores: [] })
    }
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    agregarPaciente(pac, nombre) {
        this.setState({ pac: pac, nombre: nombre });
    }
    enviarDatos = (e) => {
        e.preventDefault();
        const { nombre } = this.state;
        console.log(nombre);
        var errores = [];
        if (!nombre) { errores.push("error_nombre"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let form = document.getElementById('form');
        let formData = new FormData(form);
        fetch(Api + "Ordenes/AddArchivo", {
            method: "POST",
            body: formData
        })
            .then(respuesta => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                Swal.fire(
                    'Archivo!',
                    datosRespuesta.msj,
                    'success'
                )
                window.localStorage.setItem("orden", datosRespuesta.archivo.id);
                this.props.history.push("/HistorialClinico/" + datosRespuesta.archivo.id_paciente);
            })
            .catch(console.log)
    }
    render() {
        const { nombre, pac } = this.state;
        return (
            <div className="content-wrapper">
                <div class="modal fade" id="asignarPaciente" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="asignarPacienteLabel" aria-hidden="true">
                    <FormPaciente agregarPaciente={this.agregarPaciente} />
                </div>
                <div className="content">
                    <div className="container-fluid">
                        <br />
                        <div className="card">
                            <div className="card-header text-center">
                                <b>REGISTRO DE ORDENES</b>
                            </div>
                            <div className="card-body">
                                <form id='form'>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className='row g-3'>
                                                <div className="col-md-3">
                                                    <label htmlFor="nombre">Nombre</label>
                                                    <input type="hidden" id='pac' name="pac" />
                                                    <input className={((this.verificarError("error_nombre")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="nombre" id="nombre" value={nombre} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} readOnly />
                                                    <small id="helpId" className="invalid-feedback">Seleccione el Paciente</small>
                                                </div>
                                                <div className='col-md-1'>
                                                    <label htmlFor="" className="form-label">&nbsp;</label>
                                                    <img style={{ width: 40 }} className='form-control form-control-sm' src={findImage} alt="Asignado a" data-bs-toggle='modal' data-bs-target='#asignarPaciente'
                                                    />
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="estado">Estado</label>
                                                    <select name="estado"
                                                        id="estado"
                                                        className="form-select form-select-sm"
                                                        onChange={this.cambioValor} disabled>
                                                        <option value="A Iniciar" selected>Activo</option>
                                                    </select>
                                                    <small id="helpId" className="invalid-feedback">Seleccione el Estado</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="btn-group" role="group" aria-label="">
                                        <button type="submit" className="btn btn-primary btn-sm" onClick={this.enviarDatos}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Generar Archivo</button>&nbsp;&nbsp;
                                        <Link className="btn btn-danger btn-sm" to={"/MasterOrdenes"}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Crear;