import React from 'react';
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'

class Crear extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nombre: "",
            errores: []
        }
    }
    cambioValor = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState({ state, errores: [] })
    }
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    enviarDatos = (e) => {
        e.preventDefault();
        console.log("Formulario Enviado...");
        const { nombre } = this.state;
        console.log(nombre);
        var errores = [];
        if (!nombre) {
            errores.push("error_nombre");
        }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        var datosEnviar = { nombre: nombre }
        fetch(Api + "AddCargos", {
            method: "POST",
            body: JSON.stringify(datosEnviar)
        })
            .then(respuesta => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                Swal.fire(
                    'Cargos!',
                    datosRespuesta.msj,
                    'success'
                )
                this.props.history.push("/MasterCargos");
            })
            .catch(console.log)
    }
    render() {
        const { nombre } = this.state;
        return (
            <div className="content-wrapper">
                <div className="content">
                    <div className="container-fluid">
                        <br />
                        <div className="card">
                            <div className="card-header">
                                Registro de Cargos
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.enviarDatos}>
                                    <div className="form-group">
                                        <label htmlFor="nombre">Nombre</label>
                                        <input className={((this.verificarError("error_nombre")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="nombre" id="nombre" value={nombre} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                        <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                    </div>
                                    <br />
                                    <div className="btn-group" role="group" aria-label="">
                                        <button type="submit" className="btn btn-success btn-sm"><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Agregar</button>&nbsp;&nbsp;
                                        <Link className="btn btn-danger btn-sm" to={"/MasterCargos"}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Crear;