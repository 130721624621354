import React from "react";
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import axios from 'axios';
//import ItemInsumos from "./ItemInsumos";
import { currencyFormat } from '../../js/Functions';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleDoubleRight,
  faAngleRight,
  faPrint,
  faPlus,
  faFilter,
  faDownload,
  faEdit,
  faSave,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

class Listar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editar: "No",
      datosCargados: false,
      total : 0,
      empresas: [],
      detalle: [],
      pagina: [],
      tf:0,
      checkboxIds: [],
      facturas: [],
      detalles: [],
      medicamentos: [],
        insumos: [],
      date: new Date(),
    };
  }
  cargarEmpresas() {
    fetch(Api + "Empresas")
      .then((respuesta) => respuesta.json())
      .then((datosEmpresas) => {
        //console.log(datosEmpresas.data);
        this.setState({ empresas: datosEmpresas.data });
      })
      .catch(console.log);
  }
  
  componentDidMount() {
    this.cargarEmpresas();
    this.Buscar(1);
  }
  Buscar(page) {
    Swal.showLoading();
    let empresa = document.getElementById('empresax').value;
    let estado = document.getElementById('estado').value;
    let estado_ord = document.getElementById('estado_ord').value;
    let estado_orden = document.getElementById('estado_orden').value;
    let documento = document.getElementById('documento').value;
    let nombre = document.getElementById('nombre').value;
    let apellido = document.getElementById('apellido').value;
    let ano = document.getElementById('ano').value;
    let mes = document.getElementById('mesx').value;
    let regimen = document.getElementById('regimen').value;
    //var datos = {empresa:empresa,estado,estado_ord,estado_orden,documento,nombre,apellido,ano,mes,regimen}
    var datos = new FormData(document.getElementById("formulario"));
    fetch(Api + "alquiler/enproceso", {
      method: "POST",
      body: datos,
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
        this.setState({
          datosCargados: true,
          facturas: datosRespuesta.data,
          tf:datosRespuesta.cantidad,
        });
        Swal.close();
      })
      .catch(console.log);
  }
  Facturar() {
    
    let empresa = document.getElementById('empresax').value;
    if(empresa==''){
      alert("Debes de seleccionar la empresa");
      return false;
    }
    Swal.showLoading();
    //var datos = {empresa:empresa,estado,estado_ord,estado_orden,documento,nombre,apellido,ano,mes,regimen}
    const { checkboxIds } = this.state;
    var dato = {empresa:empresa, datos:checkboxIds}
    console.log(dato);
    //return false;
    axios.post(Api +'alquiler/facturartodo',dato)
    .then(response => {
      console.log(response.data);
      var m = response.data.factura;
         alert("Se ha generado la Factura No."+m);
         this.Buscar(1);
    })
    .catch(error => {
      console.log(error);
    });
  }
  handleCheckboxChange = (id) => {
    // Desestructura el estado actual
    const { checkboxIds } = this.state;

    // Verifica si el ID ya está en el array de checkboxIds
    if (checkboxIds.includes(id)) {
      // Si está presente, lo elimina (deselección)
      this.setState({
        checkboxIds: checkboxIds.filter((checkboxId) => checkboxId !== id),
      });
    } else {
      // Si no está presente, lo agrega (selección)
      this.setState({
        checkboxIds: [...checkboxIds, id],
      });
    }
  };
  handleSelectAll = () => {
    const {facturas} = this.state;
    // Obtiene todos los IDs de checkboxes de tu tabla (supongamos que están en la prop 'datos')
    const allCheckboxIds = facturas.map((item) => item.numero_orden_a);
    // Actualiza el estado con todos los IDs
    this.setState({
      checkboxIds: allCheckboxIds,
    });
  };
  render() {
    const {
      datosCargados,
      empresas,
      detalle,
      bodega,
      facturas,
      pagina,
      ultimo,
      actual,
      detalles,
      insumos,total,date,tf,checkboxIds 
    } = this.state;
    if (!datosCargados) {
      var load = "Cargando datos....";
    } else {
      var load = "";
    }
    return (
      <div className="content">
     
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
            <h4>Lista de Alquiler </h4>
           
            </div>
            <div className="card-body">
              
              <form id="formulario">
                <div className="row">
                  <div className="col-xl-2">
                  <label>Empresa</label>
                    <select
                      className="form-select form-select-sm"
                      type="text"
                      name="empresax"
                      id="empresax"
                    >
                      <option value="">Seleccione la Empresa</option>
                      {empresas.map((itemEmpresa) => (
                        <option
                          key={itemEmpresa.id_empresa}
                          value={itemEmpresa.rips}
                        >
                          {itemEmpresa.nombre_emp}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-xl-2">
                  <label>Documento Paciente</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      id="documento"
                      name="documento"
                      placeholder="Numero de Factura"
                    />
                  </div>
                  <div className="col-xl-2">
                  <label>Nombre Paciente</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      id="nombre"
                      name="nombre"
                      placeholder="Numero de Factura"
                    />
                  </div>
                  <div className="col-xl-2">
                  <label>Apellido Paciente</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      id="apellido"
                      name="apellido"
                      placeholder="Numero de Factura"
                    />
                  </div>
                  <div className="col-xl-2">
                  <label>Regimen</label>
                    <select
                      className="form-select form-select-sm"
                      type="text"
                      name="regimen"
                      id="regimen"
                    >
                      <option value="">Todos</option><option value="1">Contributivo</option><option value="2">Subsidiado</option><option value="3">Vinculado</option><option value="4">Particular</option><option value="5">Otro</option><option value="NO APLICA">NO APLICA</option><option value="7">Desplazado con afilacion al regimen Contributivo</option><option value="8">Desplazado con afilacion al regimen Subsidiado</option><option value="9">Desplazado no asegurado</option>
                
                    </select>
                  </div>
                  <div className="col-xl-2">
                  <label>Estado Factura</label>
                    <select
                      className="form-select form-select-sm"
                      type="text"
                      name="estado"
                      id="estado"
                    >
                      <option value="No Facturado">No Facturado</option><option value="Facturado">Facturado</option>
                
                    </select>
                  </div>
                  <div className="col-xl-2">
                  <label>Estado Alquiler</label>
                    <select
                      className="form-select form-select-sm"
                      type="text"
                      name="estado_ord"
                      id="estado_ord"
                    >
                      <option value="">En alquiler</option><option value="Retirado">Retirado</option>
                
                    </select>
                  </div>
                  <div className="col-xl-2">
                  <label>Estado orden</label>
                    <select
                      className="form-select form-select-sm"
                      type="text"
                      name="estado_orden"
                      id="estado_orden"
                    >
                      <option value="En proceso">En proceso</option><option value="Completada">Completada</option>
                
                    </select>
                  </div>
                  <div className="col-xl-2">
                    <label>Año</label>
                    <input
                      className="form-control form-control-sm"
                      type="number"
                      id="ano"
                      name="ano"
                      placeholder="Año" 
                      value={'2024'}
                    />
                  </div>
                  <div className="col-xl-2">
                    <label>Mes</label>
                    <select
                      className="form-select form-select-sm"
                      type="text"
                      name="mesx"
                      id="mesx"
                    >
                      <option value="">Todos</option><option value="01">Enero</option><option value="02">Febrero</option><option value="03">Marzo</option><option value="04">Abril</option><option value="05">Mayo</option><option value="06">Junio</option><option value="07">Julio</option><option value="08">Agosto</option><option value="09">Septiembre</option><option value="10">Octubre</option><option value="11">Noviembre</option><option value="12">Diciembre</option>
                    </select>
                  </div>
                  <div className="col-xl-2">
                    <label>Autorizacion</label>
                    <input
                      className="form-control form-control-sm"
                      type="number"
                      id="orden"
                      name="orden"
                     
                      
                    />
                  </div>

                  <div className="col-xl-2 pt-4">
                    <button
                      type="button"
                      id="bt"
                      name="bt"
                      className="btn btn-primary btn-sm"
                      onClick={() => this.Buscar(1)}
                    >
                      <FontAwesomeIcon icon={faFilter} />
                      &nbsp;&nbsp;Filtrar
                    </button>
                  </div>
                </div>
              </form>
              <br></br>
              <div className="row">
                <table className="table table-hover table-condensed table-sm">
                  <thead className="thead-inverse">
                    <tr>
                      <th>DOCUMENTO</th>
                      <th>PACIENTE</th>
                      <th>REGIMEN</th>
                      <th>ULT ORDEN</th>
                      <th>FECHA ALQUILER</th>
                      <th>MES</th>
                      <th>ESTADO</th>
                      <th>FACTURADO?</th>
                      <th>FACTURA</th>
                      <th><button onClick={this.handleSelectAll}>Seleccionar</button></th>
                    </tr>
                  </thead>
                  <tbody>
                    {load}
                    {facturas.map((itemMovimiento) => (
                      <tr key={itemMovimiento.id_equipo_a}>
                        <td>{itemMovimiento.numero_doc}</td>
                        <td>
                          
                            <Link
                              to={"InfoPacientes/" + itemMovimiento.id_paciente}
                            >
                              {itemMovimiento.nombres} {itemMovimiento.apellidos}
                            </Link>
                        
                        </td>
                        <td>{itemMovimiento.regimen}</td>
                        <td>{itemMovimiento.ult_orden}</td>
                        <td>{itemMovimiento.fecha_inicio} al {itemMovimiento.fecha_final}</td>
                        
                        <td>{itemMovimiento.mes}</td>
                        <td>{itemMovimiento.estado_orden}</td>
                        <td>{itemMovimiento.facturado}</td>
                        <td>{itemMovimiento.factura}</td>
                    
                        <td>
                        {itemMovimiento.factura=='nada'?
                          <input type="checkbox" checked={checkboxIds.includes(itemMovimiento.numero_orden_a)}
                    onChange={() => this.handleCheckboxChange(itemMovimiento.numero_orden_a)} />
                          :''}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div>
          <p>IDs seleccionados: {checkboxIds.join(', ')}</p>
        </div>
              </div>
           
            </div>
            <div className="card-footer text-muted">
             
              <b> | Total de Alquileres:</b> <span class="badge bg-warning">{tf}</span> <button
                      type="button"
                      id="bt"
                      name="bt"
                      className="btn btn-primary btn-sm"
                      onClick={() => this.Facturar()}
                    >Facturar</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Listar;
