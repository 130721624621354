import React from 'react';
import Api from "../../servicios/api";
import Swal from 'sweetalert2';

class Liquidacion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datosCargados: false,
            listado: [],
            valor_ate: 0,liq: 0,
            formData: {
                orden: '',
                precioAtencion: '',
                profesional: '',
                atencion: '',
                cantidadLiquidar: '',
                cantidadPendiente: 0,
                cantidadTotal: 0,
                totalPendientes: 0,
                valorUnidad: '',
                valorTotal: '',
                liquidacion: 'total', // Valor por defecto
                observacion: 'Ok',
                mesInicio: '',
                mesFin: '',
            },
        };
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [name]: value
            }
        }), () => {
            // Llamar a calcularValorTotal después de actualizar el estado
            this.calcularValorTotal();
        });
    };
   
    
    guardarLiquidacion = () => {
        const { formData } = this.state;
    
        fetch(Api + "liquidacion/guardar", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(formData)
        })
        .then(respuesta => respuesta.json())
        .then(datos => {
            // Cerrar el loading
            Swal.close();
    
            // Mostrar mensaje de éxito
            Swal.fire({
                title: '¡Éxito!',
                text: 'La liquidación ha sido guardada exitosamente.',
                icon: 'success'
            });
    
            // Manejar la respuesta del servidor
            console.log("Liquidación guardada:", datos);
    
            // Después de guardar, llamar a BuscarOrden para actualizar los datos
            this.BuscarOrden();
    
            // Llamar a la función actualizar pasada desde el componente principal
            if (this.props.actualizar) {
                this.props.actualizar();
            }
        })
        .catch(error => {
            // Cerrar el loading
            Swal.close();
    
            // Mostrar mensaje de error
            Swal.fire({
                title: 'Error',
                text: 'Hubo un problema al guardar la liquidación. Por favor, inténtalo de nuevo.',
                icon: 'error'
            });
    
            console.error("Error al guardar la liquidación:", error);
        });
    };
    calcularValorTotal = () => {
        const { cantidadLiquidar, valorUnidad,cantidadTotal,cantidadPendiente } = this.state.formData;
        const cantidad = parseFloat(cantidadLiquidar) || 0; // Asegurarse de que sea un número
        const valor = parseFloat(valorUnidad) || 0; // Asegurarse de que sea un número
        const pendiente = parseFloat(cantidadPendiente) || 0; // Asegurarse de que sea un número
        const total = parseFloat(cantidadTotal) || 0; // Asegurarse de que sea un número
        
        if(cantidad>total){
            alert("La cantidad supera a la total");
            this.setState(prevState => ({
                formData: {
                    ...prevState.formData,
                    cantidadLiquidar: total, // Mantener 2 decimales
                }
            }));
            return false;
        }
        const valorTotal = cantidad * valor;
        const pen = total - cantidad;
        if(pen!==0){
            this.setState(prevState => ({
                formData: {
                    ...prevState.formData,
                    liquidacion: 'parcial', // Mantener 2 decimales
                }
            }));
        }else{
            this.setState(prevState => ({
                formData: {
                    ...prevState.formData,
                    liquidacion: 'total', // Mantener 2 decimales
                }
            }));
        }
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                valorTotal: valorTotal.toFixed(2), // Mantener 2 decimales
                cantidadPendiente: pen,
            }
        }));
    };

    handleSubmit = (e) => {
        e.preventDefault();
        
        const { cantidadLiquidar, cantidadTotal, formData } = this.state;
        const { observacion } = formData; // Extraer la observación del formData
        const cantidadLiquidarNum = parseFloat(cantidadLiquidar) || 0;
        const cantidadTotalNum = parseFloat(cantidadTotal) || 0;
    
        // Validar que el campo de observación no esté vacío
        if (!observacion || observacion.trim() === '') {
            Swal.fire({
                title: 'Observación requerida',
                text: 'Por favor, ingresa una observación antes de continuar.',
                icon: 'warning',
                confirmButtonText: 'Ok'
            });
            return; // Detener el envío del formulario si la observación está vacía
        }
    
        // Validar que la cantidad a liquidar no supere la cantidad total
        if (cantidadLiquidarNum > cantidadTotalNum) {
            alert("La cantidad a liquidar supera la cantidad total.");
        } else {
            // Mostrar confirmación antes de proceder
            Swal.fire({
                title: '¿Estás seguro?',
                text: "Estás a punto de enviar la liquidación.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, enviar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    // Calcular la cantidad pendiente
                    const cantidadPendiente = cantidadTotalNum - cantidadLiquidarNum;
    
                    this.setState(prevState => ({
                        formData: {
                            ...prevState.formData,
                            cantidadPendiente: cantidadPendiente,
                        }
                    }), () => {
                        // Mostrar loading mientras se guarda la liquidación
                        Swal.fire({
                            title: 'Guardando...',
                            text: 'Por favor, espera mientras se guarda la liquidación.',
                            allowOutsideClick: false,
                            didOpen: () => {
                                Swal.showLoading();
                            }
                        });
    
                        // Enviar datos al servidor
                        this.guardarLiquidacion();
                    });
                }
            });
        }
    };

    componentDidMount() {
        this.BuscarOrden();
    }

    componentDidUpdate(prevProps) {
        // Verificar si IdOrden ha cambiado
        if (prevProps.IdOrden !== this.props.IdOrden) {
            this.BuscarOrden(); // Llamar a BuscarOrden si IdOrden ha cambiado
        }
    }

    BuscarOrden = () => {
        const { IdOrden } = this.props;
        fetch(Api + "liquidacion/ordenes", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ orden: IdOrden })
        })
            .then(respuesta => respuesta.json())
            .then(datosRespuesta => {
                
                this.setState({
                    datosCargados: true,
                    listado: datosRespuesta.ordenes,
                    valor_ate: datosRespuesta.precio.pagos,
                    liq: datosRespuesta.liquidacion?.id_liq || 0,
                    formData: {
                        orden: datosRespuesta.ordenes[0].orden_servicio,
                        precioAtencion: datosRespuesta.precio.precio,
                        profesional: datosRespuesta.ordenes[0].user,
                        atencion: datosRespuesta.ordenes[0].Description,
                        cantidadLiquidar: datosRespuesta.cantidad,
                        cantidadTotal: datosRespuesta.cantidad,
                        valorUnidad: datosRespuesta.precio.pagos,
                        valorTotal: datosRespuesta.ordenes[0].orden_servicio,
                        liquidacion: 'total',
                        observacion: 'Ok',
                        mesInicio: datosRespuesta.ordenes[0].StartTime,
                        mesFin: datosRespuesta.ordenes[0].EndTime,
                    }
                }, () => {
                    // Calcular valor total después de establecer el estado inicial
                    this.calcularValorTotal();
                });
            })
            .catch(console.log);
    };

    renderInputField = (label, name, type = "text", readOnly = false) => {
        const { formData } = this.state;
        return (
            <div className="mb-6 row">
                <label className="col-sm-5 col-form-label">{label}:</label>
                <div className="col-sm-7">
                    <input
                        type={type}
                        name={name}
                        value={formData[name]}
                        onChange={this.handleInputChange}
                        className="form-control form-sm"
                        readOnly={readOnly} // Hacer que el campo sea solo lectura
                    />
                </div>
            </div>
        );
    };

    render() {
        const { datosCargados, listado, formData, valor_ate,liq } = this.state;
        const loadMessage = datosCargados ? '' : 'Cargando datos....';

        return (
            <div className="modal-dialog modal-xl modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Liquidación de Atenciones</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="card">
                            <div className='card-body row'>
                                <div className="col-4">
                                    <form onSubmit={this.handleSubmit}>
                                        {this.renderInputField("Orden", "orden", "text", true)}
                                        {this.renderInputField("Precio Atención", "precioAtencion", "text", true)}
                                        {this.renderInputField("Profesional", "profesional", "text", true)}
                                        {this.renderInputField("Atención", "atencion", "text", true)}
                                        {this.renderInputField("Cantidad Total", "cantidadTotal", "text", true)}
                                        {this.renderInputField("Cantidad Pendiente", "cantidadPendiente", "text", true)}
                                        {this.renderInputField("Cantidad a Liquidar", "cantidadLiquidar")}

                                        {this.renderInputField("Valor Unidad", "valorUnidad")}
                                        {this.renderInputField("Valor Total", "valorTotal", "text", true)}

                                        <div className="mb-6 row">
                                            <label className="col-sm-5 col-form-label">Liquidación:</label>
                                            <div className="col-sm-7">
                                                <select
                                                    name="liquidacion"
                                                    value={formData.liquidacion}
                                                    onChange={this.handleInputChange}
                                                    className="form-select"
                                                >
                                                    <option value="total">Total</option>
                                                    <option value="parcial">Parcial</option>
                                                </select>
                                            </div>
                                        </div>

                                        {this.renderInputField("Observación", "observacion")}
                                        
                                        <div className="mb-6 row">
                                            <label className="col-sm-5 col-form-label">Mes:</label>
                                            <div className="col-sm-7 d-flex">
                                                <input
                                                    type="date"
                                                    name="mesInicio"
                                                    value={formData.mesInicio}
                                                    onChange={this.handleInputChange}
                                                    className="form-control"
                                                    readOnly // Hacer que el campo sea solo lectura
                                                />
                                                <span className="mx-2 align-self-center">al</span>
                                                <input
                                                    type="date"
                                                    name="mesFin"
                                                    value={formData.mesFin}
                                                    onChange={this.handleInputChange}
                                                    className="form-control"
                                                    readOnly // Hacer que el campo sea solo lectura
                                                />
                                            </div>
                                        </div>
                                        {liq==0?
                                        <button type="submit" className="btn btn-primary">Liquidar</button>
                                        :'Esta orden ya se encuentra liquidada No.'+liq}
                                    </form>
                                </div>

                                <div className="col-8">
                                    <table className="table table-hover table-condensed table-sm">
                                        <thead className="thead-inverse">
                                            <tr>
                                                <th>Atención</th>
                                                <th># Orden Ext.</th>
                                                <th>Realizado el Día</th>
                                                <th>Estado</th>
                                                <th>Valor</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loadMessage}
                                            {listado.map((item) => (
                                                <tr key={item.Id}>
                                                    <td>{item.Description}</td>
                                                    <td>{item.orden_externa}</td>
                                                    <td>{item.fecha_mod_ta}</td>
                                                    <td>{item.estado}</td>
                                                    <td>{valor_ate}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer"></div>
                </div>
            </div>
        );
    }
}

export default Liquidacion;
