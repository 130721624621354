import React from 'react';
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft, faAngleLeft, faAngleDoubleRight, faAngleRight, faFilter, faFileDownload } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

class Listar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datosCargados: false,
            load: '',
            ordenes: [],
            pagina: [],
        }
    }

    Buscar(page) {
        let bfecha = document.getElementById('bfecha').value;
        console.log("Formulario Enviado...");
        if (bfecha === '') {
            bfecha = 0;
        }
        this.setState({ load: 'Cargando Datos...' })
        fetch(Api + "Auditoria/IngresadosHoy/" + bfecha)
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                this.setState({ datosCargados: true, load: '', ordenes: datosRespuesta.datos, pagina: datosRespuesta.total, ultimo: datosRespuesta.last_page, actual: datosRespuesta.current_page });
            })
            .catch(console.log);
    }

    exportToExcel() {
        const { ordenes } = this.state;
        const worksheet = XLSX.utils.json_to_sheet(ordenes);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Ordenes");
        XLSX.writeFile(workbook, "Ordenes.xlsx");
    }

    render() {
        const { datosCargados, load, ordenes, pagina, ultimo, actual } = this.state;
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            &nbsp;
                        </div>
                        <div className="card-body">
                            <h4>Auditoría de Ordenes Internas Diarias</h4>
                            <div className="row">
                                <div className="col-xl-2">
                                    <input type="date" id="bfecha" name="bfecha" className="form-control form-control-sm" />
                                </div>
                                <div className="col-xl-1">
                                    <button id="bt" name="bt" className="btn btn-primary btn-sm" onClick={() => this.Buscar(1)}><FontAwesomeIcon icon={faFilter} />&nbsp;&nbsp;Filtrar</button>
                                </div>
                                <div className='col-xl-2'>
                                    {
                                        datosCargados ? (
                                            <button style={{ color: '#FFFFFF' }} id="btn_exportar" name="btn_exportar" onClick={() => this.exportToExcel()} className="btn btn-info btn-sm"><FontAwesomeIcon icon={faFileDownload} />&nbsp;&nbsp;Exportar</button>
                                        ) : null
                                    }
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <table className="table table-hover table-condensed table-sm">
                                    <thead className="thead-inverse">
                                        <tr>
                                            <th>NOMBRE PACIENTE</th>
                                            <th>ATENCIÓN</th>
                                            <th>PROFESIONAL</th>
                                            <th>ORDEN INTERNA</th>
                                            <th>CONTADOR</th>
                                            <th>FECHA INGRESO</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {load}
                                        {ordenes.map((itemOrdenes) => (
                                            <tr key={itemOrdenes.id_contacto}>
                                                <td>{itemOrdenes.Subject}</td>
                                                <td>{itemOrdenes.Description}</td>
                                                <td>{itemOrdenes.user}</td>
                                                <td>{itemOrdenes.Orden_servicio}</td>
                                                <td>{itemOrdenes.cant}</td>
                                                <td>{itemOrdenes.fecha_reg_ta}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Buscar(1)}><FontAwesomeIcon icon={faAngleDoubleLeft} /></a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Buscar(actual - 1)}><FontAwesomeIcon icon={faAngleLeft} /></a></li>
                                    {(() => {
                                        let p = [];
                                        p.push(<li className="page-item" key={actual}><a className="page-link" href="#" onClick={() => this.Buscar(actual)}>{actual}</a></li>);
                                        return p;
                                    })()}
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Buscar(actual + 1)}><FontAwesomeIcon icon={faAngleRight} /></a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.Buscar(ultimo)}><FontAwesomeIcon icon={faAngleDoubleRight} /></a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="card-footer text-muted">
                            <b>Pagina</b> <span class="badge bg-primary">{actual}</span> <b>de</b> <span class="badge bg-primary">{ultimo}</span> | <b>Registros</b> <span class="badge bg-success">{pagina}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Listar;
