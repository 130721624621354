import React from 'react';

class CrearAtencion extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {  }
    render() { 
        return (
            <div>
                
            </div>
        );
    }
}
 
export default CrearAtencion;