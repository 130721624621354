import React from "react";
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'

class Editar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datosCargados: false,
      cargo: [],
      errores: []
    };
  }
  cambioValor = (e) => {
    const state = this.state.cargo;
    state[e.target.name] = e.target.value;
    this.setState({ cargo: state });
  };
  verificarError(elemento) {
    return this.state.errores.indexOf(elemento) !== -1;
  }
  enviarDatos = (e) => {
    e.preventDefault();
    console.log("Formulario Enviado...");
    const nombre = document.getElementById('nombre').value;
    var errores = [];
    if (!nombre) { errores.push("error_nombre"); }
    this.setState({ errores: errores });
    if (errores.length > 0) {
      return false;
    }
    let form = document.getElementById('form');
    let formData = new FormData(form);
    console.log(formData);
    fetch(Api + "UpCargos", {
      method: "POST",
      body: formData
    })
      .then(respuesta => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta)
        Swal.fire(
          'Cargos!',
          datosRespuesta.msj,
          'success'
        )
        this.props.history.push("/MasterCargos");
      })
      .catch(console.log)
  }
  componentDidMount() {
    console.log(this.props.match.params.id);
    fetch(Api + "Cargos/" + this.props.match.params.id,
      {
        method: "GET",
        headers: new Headers({
          'Authorization': 'koala2021'
        }),
      })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
        this.setState({ datosCargados: true, cargo: datosRespuesta }); //
      })
      .catch(console.log);
  }
  render() {
    const { datosCargados, cargo } = this.state;
    if (!datosCargados) {
      var load = 'Cargando datos....';
    } else {
      var load = '';
    }
    return (
      <div>
        <div className="content-wrapper">
          <div className="content">
            <div className="container-fluid">
              <br />
              <div className="card">
                <div className="card-header">
                  {
                    load ? (
                      load
                    ) : (
                      "Editar Cargo"
                    )
                  }
                </div>
                <div className="card-body">
                  <form onSubmit={this.enviarDatos} id="form">
                    <div className="form-group">
                      <label htmlFor="id">Id :</label>
                      <input type="text" name="id" id="id" value={cargo.id_cargo} className="form-control form-control-sm" placeholder="" aria-describedby="helpId" onChange={this.cambioValor} readOnly />
                    </div>
                    <div className="form-group">
                      <label htmlFor="nombre">Nombre:</label>
                      <input type="text" name="nombre" id="nombre" defaultValue={cargo.nombre_cargo} className={((this.verificarError("error_nombre")) ? "is-invalid" : "") + " form-control form-control-sm"} onChange={this.cambioValor} />
                      <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                    </div>
                    <br />
                    <div className="btn-group" role="group" aria-label="">
                      <button type="submit" className="btn btn-primary btn-sm"><FontAwesomeIcon icon={faEdit} />&nbsp;&nbsp;Editar</button>&nbsp;&nbsp;
                      <Link className="btn btn-danger btn-sm" to={"/MasterCargos"}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Editar;
