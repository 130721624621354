import React from "react";
import Api from "../../servicios/api";
import { Link } from "react-router-dom";
import smileImage from "../../images/smile.png"
import sadImage from "../../images/sad.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNotesMedical,faSignOutAlt,faSave } from '@fortawesome/free-solid-svg-icons'
import findImage from "../../images/find.png"
import FormUsuario from "./UsuariosOrd"
import FormAtencion from "./Atenciones"
import './style.css';
import Swal from 'sweetalert2';
class VerArchivo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            load: '',
            datosCargados: false,
            infoPaciente: '',
            ordenmax:'',
            nombrePaciente: '',
            infoAtenciones: [],
            infoDetalle: [],
            infoInsumos: [],
            infoMedicamentos: []
           // nombre: '',archivo: '',ordenint: '',ordenext: '',cantidad: '',cantidadhide: '',fechaini: '',fechafin: '',cada: '',veces: '',asignado: '',codigo: '',descripcion: '',observacion: '',ingreso: '',urg: '',prueba: '',resu: '',rescov: ''
        };
    }
    SavePac(){
       
        var nombre = document.getElementById('paciente').value;
        var id_paciente = document.getElementById('id_paciente').value;
        var archivo = document.getElementById('archivo').value;
        var ordenint = document.getElementById('ordenint').value;
        var ordenext = document.getElementById('ordenext').value;
        var cantidad = document.getElementById('cantidad').value;
        var cantidadhide = document.getElementById('cantidadhide').value;
        var fechaini = document.getElementById('fechaini').value;
        var fechafin = document.getElementById('fechafin').value;
        var cada = document.getElementById('cada').value;
        var veces = document.getElementById('veces').value;
        var asignado = document.getElementById('asignado').value;
        var codigo = document.getElementById('codigo').value;
        var descripcion = document.getElementById('descripcion').value;
        var observacion = document.getElementById('observacion').value;
        var ingreso = document.getElementById('ingreso').value;
        var urg = document.getElementById('urg').value;
        var prueba = document.getElementById('prueba').value;
        var resu = document.getElementById('resu').value;
        var rescov = document.getElementById('rescov').value;
        var urg = document.getElementById('urg').value;
        document.getElementById('myModal').display = 'none';
        if(ordenext==''){
            alert('Debes de digitar la orden externa');
            return false;
        }
        if(cantidad==''){
            alert('Debes de digitar la cantidad');
            return false;
        }
        if(fechaini==''){
            alert('Debes de seleccionar la fecha inicial');
            return false;
        }
        if(fechaini==''){
            alert('Debes de seleccionar la fecha inicial');
            return false;
        }
        if(codigo==''){
            alert('Debes de seleccionar el codigo');
            return false;
        }
        if(asignado==''){
            alert('Debes de seleccionar al profesional');
            return false;
        }
        if(ingreso==''){
            alert('Debes de seleccionar la fecha ingreso');
            return false;
        }
       
        let datos = JSON.stringify({ nombre,archivo,ordenint,ordenext,cantidad,cantidadhide,fechaini,fechafin,cada,veces,asignado,codigo,descripcion,observacion,ingreso,urg,prueba,resu,rescov,urg,id_paciente });
       
        Swal.showLoading();
        fetch(Api + "Ordenes/NuevaAtencion", {
            method: "POST",
            body: datos
        })
            .then(respuesta => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                Swal.fire(
                    'Atencion!',
                    datosRespuesta.msj,
                    'success'
                )
                document.getElementById('myModal').display = 'none';
                this.cargar();
                this.setState({ datosCargados: true, load: '' });
            })
            .catch(console.log) 

    }
    EstadoAte(){
        console.log('estado');
    }
    componentDidMount() {
        this.cargar();
    }
    cargar(){
        //console.log(this.props.match.params.id);
        
        this.setState({ load: 'Cargando Datos...' })
        fetch(Api + "Ordenes/ListaOrdenes/" + this.props.match.params.id,
            {
                method: "GET",
                headers: new Headers({
                    'Authorization': 'koala2021'
                }),
            })
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                this.setState({
                    datosCargados: true,
                    load: '',
                    infoAtenciones: datosRespuesta.datos,
                    infoDetalle: datosRespuesta.datos[0],
                    infoPaciente: datosRespuesta.paciente,
                    nombrePaciente: datosRespuesta.paciente.nombres+ ' ' + datosRespuesta.paciente.apellidos,
                    infoInsumos: datosRespuesta.insumos,
                    infoMedicamentos: datosRespuesta.medicamentos
                    
                    
                });
                document.getElementById('ordenint').value = parseInt(datosRespuesta.maxorden)+1;
            })
            .catch(console.log);
    }
    cambioValor = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState({ state, errores: [] })
    }
    ordeninterna(oi){
        this.props.history.push("/VerOrdenInterna/"+oi);
    }
    render() {
        const { datosCargados, load, infoAtenciones, infoPaciente, infoInsumos, infoMedicamentos,infoDetalle,nombrePaciente } = this.state;
        return (
            <div className="content-wrapper">
                
                <div class="modal fade" id="atencionAsignado" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="atencionAsignadoLabel" aria-hidden="true"  data-backdrop="static">
                    <FormAtencion agregarAtencion={this.agregarAtencion} />
                </div>
                <div className="modal" id="myModal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">


                        <div className="modal-header">
                            <h4 className="modal-title">Agregar Atencion</h4>
                            
                            <button type="button" className="close" data-bs-dismiss="modal">&times;</button>
                        </div>

                        
                        <div className="modal-body">
                        <div className="card">
                                    <div className="card-body">
                                    <div className="form-row">
                                <div className="col">
                                    <label>Nombre del Paciente</label>
                                <input type="text" className="form-control form-control-sm" id="paciente" placeholder="" name="paciente" defaultValue={nombrePaciente} />
                                <input type="text" className="form-control form-control-sm" id="id_paciente" placeholder="" name="id_paciente" defaultValue={infoPaciente.id_paciente} />
                                </div>
                                
                            </div>
                            <div className="form-row">
                            <div className="col">
                                    <label>Archivo</label>
                                <input type="text" className="form-control form-control-sm" id="archivo" placeholder="" name="archivo" defaultValue={this.props.match.params.id} readonly />
                                </div>
                                <div className="col">
                                    <label>Orden Interna</label>
                                <input type="text" className="form-control form-control-sm" id="ordenint" placeholder="" name="ordenint"  />
                                </div>
                                <div className="col">
                                    <label>Orden Externa</label>
                                <input type="text" className="form-control form-control-sm" placeholder="" name="ordenext" id="ordenext"  onChange={this.cambioValor}/>
                                </div> <br></br>
                                <div className="col">
                                    <label>Cantidad</label>
                                    <input type="number" className="form-control form-control-sm" placeholder="" name="cantidad" id="cantidad" />
                                    <input type="hidden" className="form-control form-control-sm" placeholder="" name="cantidadhide" id="cantidadhide" />
                                </div>
                            </div>
                            
                            <div className="form-row">
                                <div className="col">
                                    <label>Fecha Inicial</label>
                                <input type="date" className="form-control form-control-sm" id="fechaini" placeholder="" name="fechaini" />
                                </div>
                                <div className="col">
                                    <label>Fecha Final</label>
                                <input type="date" className="form-control form-control-sm" placeholder="" name="fechafin" id="fechafin" />
                                </div>
                                <div className="col">
                                    <label>Atencion Cada</label>
                                <input type="number" className="form-control form-control-sm" placeholder="" name="cada" id="cada" defaultValue="1"/>
                                </div>
                                <div className="col">
                                    <label>En el dia</label>
                                <input type="number" className="form-control form-control-sm" placeholder="" name="veces" id="veces"  defaultValue="1"/>
                                </div>
                                
                            </div>
                            
                          <br></br>
                            <div className="form-group row">
                                <label for="inputPassword3" className="col-sm-2 col-form-label">Asignado a *</label>
                                <div className="col-sm-8">
                                <div className="input-group mb-3">
                                        <input type="text" className="form-control form-control-sm" id="asignado" placeholder="" disabled  />
                                        <div className="input-group-append">
                                            <button className="btn btn-success btn-sm" type="button" data-bs-toggle='modal' data-bs-target='#usuarioAsignado'>Buscar</button>
                                        </div>
                                        </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-4">
                                    <label>Codigo atencion</label>
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control form-control-sm" placeholder="Codigo" id="codigo"  />
                                        <div className="input-group-append">
                                            <button className="btn btn-success btn-sm" type="button"  data-bs-toggle='modal' data-bs-target='#atencionAsignado'>Buscar</button>
                                        </div>
                                        </div>
                                </div>
                                <div className="col-8">
                                    <label>Descripcion </label>
                                <input type="text" className="form-control form-control-sm" placeholder="" name="descripcion" id="descripcion"  />
                                </div> 
                                
                            </div>
                            <div className="form-row">
                                <div className="col">
                                    <label>Observaciones</label>
                                <input type="text" className="form-control form-control-sm" id="observacion" placeholder="" name="observacion"   />
                                </div>
                                
                                
                            </div>
                            <div className="form-row">
                            <div className="col">
                                    <label>Fecha de ingreso </label>
                                <input type="date" className="form-control form-control-sm" id="ingreso" placeholder="" name="ingreso"  />
                                </div>
                                <div className="col">
                                    <label>Urgente</label>
                                <select className="form-control form-control-sm" id="urg"  >
                                    <option defaultValue='No'>No</option>
                                    <option defaultValue='Si'>Si</option>
                                    </select>
                                </div>
                                <div className="col">
                                    <label>Prueba COVID</label>
                                    <select className="form-control form-control-sm" id="prueba"  >
                                    <option defaultValue='No'>No</option>
                                    <option defaultValue='Si'>Si</option>
                                    </select>
                                </div> 
                                <div className="col">
                                    <label>Fecha Resultado:</label>
                                    <input type="date" className="form-control form-control-sm" placeholder="" name="resu" id="resu" />
                                </div>
                                <div className="col">
                                    <label>Resultado</label>
                                    <select className="form-control form-control-sm" id="rescov" >
                                        <option value="">Sin resultado</option>
                                        <option value="Positivo">Positivo</option>
                                    <option value="Negativo">Negativo</option>
                                    </select>
                                </div> 
                            </div>
                            </div>
                            </div>
                        </div>

                    
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary btn-sm" onClick={() => this.SavePac()}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Guardar</button>
                            <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><FontAwesomeIcon icon={faSignOutAlt} />&nbsp;&nbsp;Cerrar</button>
                        </div>

                        </div>
                    </div>
                    </div>
                    <div class="modal fade" id="usuarioAsignado" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="usuarioAsignadoLabel" aria-hidden="true"  data-backdrop="static">
                    <FormUsuario agregarPaciente={this.agregarPaciente} />
                </div>
                
                <div className="content">
                    <div className="container-fluid">
                        <br />
                        <div className="card">
                            <div className="card-header text-center">
                                <b>ARCHIVO PRINCIPAL # {this.props.match.params.id}  </b>
                            </div>
                            <div className="card-body">
                                <h4 className="profile-username text-center">{infoPaciente.nombres} {infoPaciente.nombre2} {infoPaciente.apellidos} {infoPaciente.apellido2}</h4>
                                <p className="text-muted text-center">{infoPaciente.documento}: {infoPaciente.numero_doc}</p>
                                <div className="card">
                                    <div className="card-body">
                                        
                                        <button className='btn btn-primary btn-sm' id='btnNuevoPacienteSub' type='button' data-bs-toggle='modal' data-bs-target='#myModal' onClick={() => (this.setState({ editar: 'No' }))}><FontAwesomeIcon icon={faNotesMedical} />&nbsp;&nbsp;Nueva Atencion.</button> &nbsp; 
                                        
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header text-center">
                                        <b>ATENCIONES</b>
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-hover table-condensed table-sm">
                                            <thead className="thead-inverse">
                                                <tr>
                                                    <th className="align-middle">URGENTE</th>
                                                    <th className="align-middle">ATENCIÓN</th>
                                                    <th className="align-middle">ORDEN EXT.</th>
                                                    <th className="align-middle">VISITA #</th>
                                                    <th className="align-middle">CADA</th>
                                                   
                                                    <th className="align-middle">ESTADO</th>
                                                    <th className="align-middle">ASIGNADO A</th>
                                                    <th className="align-middle">PORC. (%)</th>
                                                    <th className="align-middle">CANT.</th>
                                                    <th className="align-middle">VER</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {load}  
                                                {
                                                    infoAtenciones.map((itemAtenciones) => (
                                                        <tr key={itemAtenciones.Id}>
                                                            <td>{itemAtenciones.urgente}</td>
                                                            <td><Link to="#" onClick={() => this.ordeninterna(itemAtenciones.orden_servicio)}>{itemAtenciones.Description}</Link></td>
                                                            <td>{itemAtenciones.orden_externa}</td>
                                                            <td>{itemAtenciones.cant_ins}</td>
                                                     
                                                            <td>
                                                            {itemAtenciones.cada}
                                                            </td>
                                                            <td>{itemAtenciones.aviso}</td>
                                                            <td>{itemAtenciones.user}</td>
                                                            <td>{parseFloat(itemAtenciones.porcentaje).toFixed(2) + "%"}</td>
                                                            <td>{itemAtenciones.cant}</td>
                                                            <td>{itemAtenciones.prueba_realizada}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header text-center">
                                        <b>INSUMOS</b>
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-hover table-condensed table-sm">
                                            <thead className="thead-inverse">
                                                <tr>
                                                    <th className="align-middle">ORDEN INT.</th>
                                                    <th className="align-middle">COD.</th>
                                                    <th className="align-middle">NOMBRE INSUMO</th>
                                                    <th className="align-middle">CANT.</th>
                                                    <th className="align-middle">CANT. USADAS</th>
                                                    <th className="align-middle">CANT. RESTANTE</th>
                                                    <th className="align-middle">RANGO FECHA</th>
                                                    <th className="align-middle">ASIGNADO A</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {load}
                                                {
                                                    infoInsumos.map((itemInsumos) => (
                                                        <tr key={itemInsumos.id_ia}>
                                                            <td>{itemInsumos.rel_atencion}</td>
                                                            <td>{itemInsumos.cod_insumo}</td>
                                                            <td>{itemInsumos.nombre_insumo}</td>
                                                            <td>{itemInsumos.cantidad}</td>
                                                            <td>{itemInsumos.cant_usada}</td>
                                                            <td>{itemInsumos.cant_restante}</td>
                                                            <td>{itemInsumos.minimo} - {itemInsumos.maximo}</td>
                                                            <td>{itemInsumos.asignado_a}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header text-center">
                                        <b>MEDICAMENTOS</b>
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-hover table-condensed table-sm">
                                            <thead className="thead-inverse">
                                                <tr>
                                                    <th className="align-middle">ORDEN INT.</th>
                                                    <th className="align-middle">COD.</th>
                                                    <th className="align-middle">NOMBRE INSUMO</th>
                                                    <th className="align-middle">CANT.</th>
                                                    <th className="align-middle">CANT. USADAS</th>
                                                    <th className="align-middle">CANT. RESTANTE</th>
                                                    <th className="align-middle">FECHA ASIG.</th>
                                                    <th className="align-middle">ASIGNADO A</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {load}
                                                {
                                                    infoMedicamentos.map((itemMedicamentos) => (
                                                        <tr key={itemMedicamentos.id}>
                                                            <td>{itemMedicamentos.rel_atencion}</td>
                                                            <td>{itemMedicamentos.codigo_int}</td>
                                                            <td>{itemMedicamentos.nombre_medicamento}</td>
                                                            <td>{itemMedicamentos.cantidad}</td>
                                                            <td>{itemMedicamentos.cantidad_usada}</td>
                                                            <td>{itemMedicamentos.cantidad_rest}</td>
                                                            <td>{itemMedicamentos.fecha_asig}</td>
                                                            <td>{itemMedicamentos.asignado_a}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                

            </div>
        );
    }
}

export default VerArchivo;