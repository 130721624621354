import React from "react";
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import Swal from 'sweetalert2';
import findImage from "../../images/find.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faUpload, faFileUpload, faUserPlus, faSignOutAlt, faSave, faViruses, faPrint } from '@fortawesome/free-solid-svg-icons'
import $ from "jquery";
class Editar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: '',
      datosCargados: false,
      paciente: this.props,
      departamentos: [],
      municipios: [],
      empresas: [],
      funcionarios: [],
      errores: []
    };
  }
  cambioValor = (e) => {
    //const state = this.state.empleado;
    //state[e.target.name] = e.target.value;
    //this.setState({ empleado: state });
  };
  verificarError(elemento) {
    return this.state.errores.indexOf(elemento) !== -1;
  }
  cargarEmpresas() {
    fetch(Api + "Empresas")
      .then(respuesta => respuesta.json())
      .then((datosEmpresas) => {
        this.setState({ empresas: datosEmpresas.data })
      })
      .catch(console.log);
  }
  Funcionarios() {
    fetch(Api + "SelectUsuarios")
      .then(respuesta => respuesta.json())
      .then((result) => {
        this.setState({ funcionarios: result })
      })
      .catch(console.log);
  }
  cargarDepartamentos() {
    fetch(Api + "Departamentos")
      .then(respuesta => respuesta.json())
      .then((datosDepartamentos) => {
        this.setState({ departamentos: datosDepartamentos })
      })
      .catch(console.log);
  }
  cargarMunicipios = (e) => {
    fetch(Api + "Municipios/" + e.target.value)
      .then(respuesta => respuesta.json())
      .then((datosMunicipios) => {
        this.setState({ municipios: datosMunicipios });
        this.municipio.focus();
      })
      .catch(console.log)
  }
  cargarMunicipiosPac() {
    fetch(Api + "Municipios/08")
      .then(respuesta => respuesta.json())
      .then((datosMunicipios) => {
        this.setState({ municipios: datosMunicipios });

      })
      .catch(console.log)
  }
  calcularEdad = (e) => {
    var hoy = new Date();
    var birthDate = new Date(e.target.value);
    var age_now = hoy.getFullYear() - birthDate.getFullYear();
    var month = hoy.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && hoy.getDate() < birthDate.getDate())) {
      age_now--;
    }
    this.setState({ edad: age_now });
    return age_now;
  }
  enviarDatos = (e) => {
    e.preventDefault();
    console.log("Formulario Enviado...");
    var errores = [];
    const alta = document.getElementById('alta').value;
    const documento = document.getElementById('documento').value;
    const numero_doc = document.getElementById('numero_doc').value;
    const sexo = document.getElementById('sexo').value;
    const nombres = document.getElementById('nombres').value;
    const nombre2 = document.getElementById('nombre2').value;
    const apellidos = document.getElementById('apellidos').value;
    const apellido2 = document.getElementById('apellido2').value;
    const fecha_nacimiento = document.getElementById('fecha_nacimiento').value;
    const edad = document.getElementById('edad').value;
    const civil = document.getElementById('civil').value;
    const ocupacion = document.getElementById('ocupacion').value;
    const id_empresa = document.getElementById('empresa').value;
    const estado = document.getElementById('estado').value;
    const regimen = document.getElementById('regimen').value;
    const tipo_s = document.getElementById('tipo_s').value;
    const departamento = document.getElementById('departamento').value;
    const municipio = document.getElementById('municipio').value;
    const zona = document.getElementById('zona').value;
    const direccion1 = document.getElementById('direccion1').value;
    const barrio = document.getElementById('barrio').value;
    const tel_1 = document.getElementById('telefono1').value;
    const celular = document.getElementById('celular').value;
    const email1 = document.getElementById('email1').value;
    const nombre_familiar = document.getElementById('nombre_familiar').value;
    const telefono_familiar = document.getElementById('telefono_familiar').value;
    const parentesco = document.getElementById('parentesco').value;
    const nombre_acudiente = document.getElementById('nombre_acudiente').value;
    const telefono_acudiente = document.getElementById('telefono_familiar').value;
    const parentesco2 = document.getElementById('parentesco2').value;
    const enfermedad = document.getElementById('enfermedad').value;
    const descripcion_enf = document.getElementById('descripcion_enf').value;
    const contrato = document.getElementById('contrato').value;
    if (!alta) { errores.push("error_alta"); errores.push("error_global"); }
    if (!documento) { errores.push("error_documento"); errores.push("error_global"); }
    if (!numero_doc) { errores.push("error_numero_doc"); errores.push("error_global"); }
    if (!sexo) { errores.push("error_sexo"); errores.push("error_global"); }
    if (!nombres) { errores.push("error_nombres"); errores.push("error_global"); }
    if (!nombre2) { errores.push("error_nombre2"); errores.push("error_global"); }
    if (!apellidos) { errores.push("error_apellidos"); errores.push("error_global"); }
    if (!apellido2) { errores.push("error_apellido2"); errores.push("error_global"); }
    if (!fecha_nacimiento) { errores.push("error_fecha_nacimiento"); errores.push("error_global"); }
    if (!edad) { errores.push("error_edad"); errores.push("error_global"); }
    if (!civil) { errores.push("error_civil"); errores.push("error_global"); }
    if (!ocupacion) { errores.push("error_ocupacion"); errores.push("error_global"); }
    if (!id_empresa) { errores.push("error_empresa"); errores.push("error_global"); }
    if (!estado) { errores.push("error_estado"); errores.push("error_global"); }
    if (!regimen) { errores.push("error_regimen"); errores.push("error_global"); }
    if (!tipo_s) { errores.push("error_tipo_s"); errores.push("error_global"); }
    if (!departamento) { errores.push("error_departamento"); errores.push("error_global"); }
    if (!municipio) { errores.push("error_municipio"); errores.push("error_global"); }
    if (!zona) { errores.push("error_zona"); errores.push("error_global"); }
    if (!direccion1) { errores.push("error_direccion1"); errores.push("error_global"); }
    if (!barrio) { errores.push("error_barrio"); errores.push("error_global"); }
    if (!tel_1) { errores.push("error_telefono1"); errores.push("error_global"); }
    if (!celular) { errores.push("error_celular"); errores.push("error_global"); }
    if (!email1) { errores.push("error_email1"); errores.push("error_global"); }
    if (!nombre_familiar) { errores.push("error_nombre_familiar"); errores.push("error_global"); }
    if (!telefono_familiar) { errores.push("error_telefono_familiar"); errores.push("error_global"); }
    if (!parentesco) { errores.push("error_parentesco"); errores.push("error_global"); }
    if (!nombre_acudiente) { errores.push("error_nombre_acudiente"); errores.push("error_global"); }
    if (!telefono_acudiente) { errores.push("error_telefono_acudiente"); errores.push("error_global"); }
    if (!parentesco2) { errores.push("error_parentesco2"); errores.push("error_global"); }
    if (!enfermedad) { errores.push("error_enfermedad"); errores.push("error_global"); }
    if (!descripcion_enf) { errores.push("error_descripcion_enf"); errores.push("error_global"); }
    if (!contrato) { errores.push("error_contrato"); errores.push("error_global"); }
    this.setState({ errores: errores });
    if (errores.length > 0) {
      return false;
    }
    let form = document.getElementById('form_paciente');
    let token = window.localStorage.getItem('k_token');
    let formData = new FormData(form);
    fetch(Api + "UpPacientes", {
      method: "POST",
      body: formData,
      headers: {
        'Authorization': token,
      },
    })
      .then(respuesta => respuesta.json())
      .then((datosRespuesta) => {
        Swal.fire(
          'Pacientes!',
          datosRespuesta.msj,
          datosRespuesta.icono,
        )
        this.props.func(1);
        this.limpiarformulario();
        document.getElementById('form').reset();
        window.$('#nuevoPacienteSub').modal('hide');
      })
      .catch(console.log)
  }
  enviarCondiciones = (e) => {
    e.preventDefault();
    console.log("Formulario Condiciones Enviado...");
    var errores = [];
    const numero_doc = document.getElementById('numero_doc').value;
    if (!numero_doc) { errores.push("error_condiciones"); }
    this.setState({ errores: errores });
    if (errores.length > 0) {
      return false;
    }
    document.getElementById('cedula_cond').value = numero_doc;
    let form = document.getElementById('form_condiciones');
    let formData = new FormData(form);
    console.log(form);
    fetch(Api + "Condiciones", {
      method: "POST",
      body: formData
    })
      .then(respuesta => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta)
        Swal.fire(
          'Condiciones!',
          datosRespuesta.msj,
          'success'
        )
      })
      .catch(console.log)
  }
  enviarEncuesta = (e) => {
    e.preventDefault();
    console.log("Formulario Encuesta Enviado...");
    var errores = [];
    const numero_doc = document.getElementById('numero_doc').value;
    if (!numero_doc) { errores.push("error_encuesta"); }
    this.setState({ errores: errores });
    if (errores.length > 0) {
      return false;
    }
    document.getElementById('cedula_enc').value = numero_doc;
    let form = document.getElementById('form_encuesta');
    let formData = new FormData(form);
    fetch(Api + "Encuestas", {
      method: "POST",
      body: formData
    })
      .then(respuesta => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta)
        Swal.fire(
          'Encuesta!',
          datosRespuesta.msj,
          'success'
        )
      })
      .catch(console.log)
  }
  ImprimirEncuesta = () =>{
    const idPaciente = window.localStorage.getItem('id_paciente');
  const url = `https://idb.softmediko.com/imprimir_historial.php?imprimir=${idPaciente}`;
  window.open(url, '_blank');
  }
  enviarAntecedentes = (e) => {
    e.preventDefault();
    console.log("Formulario antecedententes Enviado...");
   
    let form2 = document.getElementById('form_antecedente');
    let formData2 = new FormData(form2);
    fetch(Api + "Antecedentes", {
      method: "POST",
      body: formData2
    })
      .then(respuesta => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta)
        Swal.fire(
          'Antecedentes!',
          datosRespuesta.msj,
          'success'
        )
      })
      .catch(console.log)
  }
  limpiarformulario() {
    document.getElementById('fin').value = '';
    document.getElementById('fde').value = '';
    document.getElementById('uat').value = '';
    document.getElementById('ual').value = '';
    document.getElementById('alta').value = '';
    document.getElementById('documento').value = '';
    document.getElementById('numero_doc').value = '';
    document.getElementById('sexo').value = '';
    document.getElementById('nombres').value = '';
    document.getElementById('nombre2').value = '';
    document.getElementById('apellidos').value = '';
    document.getElementById('apellido2').value = '';
    document.getElementById('fecha_nacimiento').value = '';
    document.getElementById('civil').value = '';
    document.getElementById('ocupacion').value = '';
    document.getElementById('empresa').value = '';
    document.getElementById('estado').value = '';
    document.getElementById('regimen').value = '';
    document.getElementById('tipo_s').value = '';
    document.getElementById('departamento').value = '';
    document.getElementById('municipio').value = '';
    document.getElementById('zona').value = '';
    document.getElementById('direccion1').value = '';
    document.getElementById('barrio').value = '';
    document.getElementById('telefono1').value = '';
    document.getElementById('celular').value = '';
    document.getElementById('email1').value = '';
    document.getElementById('nombre_familiar').value = '';
    document.getElementById('telefono_familiar').value = '';
    document.getElementById('parentesco').value = '';
    document.getElementById('nombre_acudiente').value = '';
    document.getElementById('telefono_acudiente').value = '';
    document.getElementById('parentesco').value = '';
    document.getElementById('parentesco2').value = '';
    document.getElementById('enfermedad').value = '';
    document.getElementById('descripcion_enf').value = '';
    document.getElementById('enfermedad2').value = '';
    document.getElementById('descripcion_enf2').value = '';
    document.getElementById('deposito').value = '';
    document.getElementById('subcodigo').value = '';
    document.getElementById('prof_eps').value = '';
    document.getElementById('contrato').value = '';
    document.getElementById('covid').value = '';
    document.getElementById('observacion_covid').value = '';
    console.log('limpio?');
  }
  componentDidMount() {
    var today = new Date(), newDate = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
    this.setState({ date: newDate });
    this.cargarEmpresas();
    this.cargarDepartamentos();
    this.cargarMunicipiosPac();
    this.Funcionarios();
  }
  render() {
    const { datosCargados, paciente, departamentos, municipios, empresas, date, edad,funcionarios } = this.state;
    if (!datosCargados) {
      var load = 'Cargando datos....';
    } else {
      var load = '';
    }
    return (
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="nuevoPacienteSubLabel">Editar Pacientes Subsidiados</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <form onSubmit={this.enviarDatos} id="form_paciente">
            <div className="modal-body">
              <div className="card">
                <div style={{ backgroundImage: 'linear-gradient(#2FB3FB, #003153)', color: '#FFFFFF', borderTopLeftRadius: 5, borderTopRightRadius: 5 }} className="card-header">
                  <h5 style={{ fontSize: 18 }}>Formulario de Registro</h5>
                </div>
                <div className="card-body">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="datos_basicos-tab" data-bs-toggle="tab" data-bs-target="#datos_basicos" type="button" role="tab" aria-controls="datos_basicos" aria-selected="true">Datos Básicos</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="direcciones-tab" data-bs-toggle="tab" data-bs-target="#direcciones" type="button" role="tab" aria-controls="direcciones" aria-selected="false">Direcciones</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="familiares-tab" data-bs-toggle="tab" data-bs-target="#familiares" type="button" role="tab" aria-controls="familiares" aria-selected="false">Familiares</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="diagnosticos-tab" data-bs-toggle="tab" data-bs-target="#diagnosticos" type="button" role="tab" aria-controls="diagnosticos" aria-selected="false">Diagnosticos</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="condiciones-tab" data-bs-toggle="tab" data-bs-target="#condiciones" type="button" role="tab" aria-controls="condiciones" aria-selected="false">Condiciones de Vivienda</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="encuesta-tab" data-bs-toggle="tab" data-bs-target="#encuesta" type="button" role="tab" aria-controls="encuesta" aria-selected="false">Encuesta COVID-19</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="antecedente-tab" data-bs-toggle="tab" data-bs-target="#antecedente" type="button" role="tab" aria-controls="antecedente" aria-selected="false">Antecedentes</button>
                    </li>
                  </ul>

                  <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="datos_basicos" role="tabpanel" aria-labelledby="datos_basicos-tab">
                      <br />
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-xl-12">
                              <div style={{ marginTop: 0 }} className='row g-3'>
                                <div className="col-md-3">
                                  <label htmlFor="fin" className="form-label">Fecha Ingreso:</label>
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="fin"
                                    name="fin"
                                    value={paciente.fin}
                                    placeholder="Fecha Ingreso"
                                    title="FECHA INGRESO"
                                    readOnly={true}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="fde" className="form-label">Fecha Modificación:</label>
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="fde"
                                    name="fde"
                                    value={paciente.fde}
                                    placeholder="Fecha Modificación"
                                    title="FECHA MODIFICACIÓN"
                                    readOnly={true}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="uat" className="form-label">Última Atención:</label>
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="uat"
                                    name="uat"
                                    value={paciente.uat}
                                    placeholder="Última Atención"
                                    title="ÚLTIMA ATENCIÓN"
                                    readOnly={true}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="ual" className="form-label">Último Alquiler:</label>
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="ual"
                                    name="ual"
                                    value={paciente.ual}
                                    placeholder="Último Alquiler"
                                    title="ÚLTIMO ALQUILER"
                                    readOnly={true}
                                  />
                                </div>
                              </div>
                              <div style={{ marginTop: 0 }} className='row g-3'>
                                <hr style={{ marginBottom: 0 }} />
                              </div>
                              <div style={{ marginTop: 0 }} className="row g-3">
                                <div className="col-md-3">
                                  <label htmlFor="id_paciente">Id:</label>
                                  <input className="form-control form-control-sm" type="text" id="id_paciente" name="id_paciente" value={paciente.id_paciente} readOnly />
                                </div>
                              </div>
                              <div style={{ marginTop: 0 }} className="row g-3">
                                <div className="col-md-3">
                                  <label htmlFor="alta" className="form-label">Alta Temprana:</label>
                                  <select
                                    className={((this.verificarError("error_alta")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                    id="alta"
                                    name="alta"
                                    title="ALTA TEMPRANA"
                                    onChange={this.cambioValor}
                                  >
                                    <option value="">Seleccionar</option>
                                    <option value="Vinculado">Vinculado</option>
                                    <option value="No Vinculado">No Vinculado</option>
                                  </select>
                                </div>
                              </div>
                              <div style={{ marginTop: 0 }} className="row g-3">
                                <div className="col-md-3">
                                  <label htmlFor="documento" className="form-label">Tipo Documento:</label>
                                  <select
                                    className={((this.verificarError("error_documento")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                    id="documento"
                                    name="documento"
                                    title="TIPO DOCUMENTO"
                                    onChange={this.cambioValor}
                                  >
                                    <option value="">Seleccionar</option>
                                    <option value="CC">C.C. - Cédula Ciudadanía</option>
                                    <option value="TI">T.I. - Tarjeta Identidad</option>
                                    <option value="PA">P.A. - Pasaporte</option>
                                    <option value="CE">C.E. - Cédula Extranjería</option>
                                    <option value="RC">R.C. - Registro Civil</option>
                                    <option value="AS">A.S. - Adulto sin Identificación</option>
                                    <option value="MS">M.S. - Menor sin Identificación</option>
                                    <option value="SC">S.C. - Salvo Conducto</option>
                                  </select>
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="numero_doc" className="form-label">Identificación:</label>
                                  <input
                                    type="text"
                                    className={((this.verificarError("error_numero_doc")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                    id="numero_doc"
                                    name="numero_doc"
                                    value={paciente.numero_doc}
                                    placeholder="Identificación"
                                    title="IDENTIFICACIÓN"
                                    onChange={this.cambioValor}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="sexo" className="form-label">Sexo:</label>
                                  <select
                                    className={((this.verificarError("error_sexo")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                    id="sexo"
                                    name="sexo"
                                    title="SEXO"
                                    onChange={this.cambioValor}
                                  >
                                    <option value="">Seleccionar</option>
                                    <option value="M">Masculino</option>
                                    <option value="F">Femenino</option>
                                  </select>
                                </div>
                              </div>
                              <div style={{ marginTop: 0 }} className="row g-3">
                                <div className="col-md-3">
                                  <label htmlFor="nombres" className="form-label">Primer Nombre:</label>
                                  <input
                                    type="text"
                                    className={((this.verificarError("error_nombres")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                    id="nombres"
                                    name="nombres"
                                    value={paciente.nombres}
                                    placeholder="Primer Nombre"
                                    title="PRIMER NOMBRE"
                                    onChange={this.cambioValor}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="nombre2" className="form-label">Segundo Nombre:</label>
                                  <input
                                    type="text"
                                    className={((this.verificarError("error_nombre2")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                    id="nombre2"
                                    name="nombre2"
                                    value={paciente.nombre2}
                                    placeholder="Segundo Nombre"
                                    title="SEGUNDO NOMBRE"
                                    onChange={this.cambioValor}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="apellidos" className="form-label">Primer Apellido:</label>
                                  <input
                                    type="text"
                                    className={((this.verificarError("error_apellidos")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                    id="apellidos"
                                    name="apellidos"
                                    value={paciente.apellidos}
                                    placeholder="Primer Apellido"
                                    title="PRIMER APELLIDO"
                                    onChange={this.cambioValor}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="apellido2" className="form-label">Segundo Apellido:</label>
                                  <input
                                    type="text"
                                    className={((this.verificarError("error_apellido2")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                    id="apellido2"
                                    name="apellido2"
                                    value={paciente.apellido2}
                                    placeholder="Segundo Apellido"
                                    title="SEGUNDO APELLIDO"
                                    onChange={this.cambioValor}
                                  />
                                </div>
                              </div>
                              <div style={{ marginTop: 0 }} className='row g-3'>
                                <div className="col-md-3">
                                  <label htmlFor="fecha_nacimiento" className="form-label">Fecha Nacimiento:</label>
                                  <input
                                    type="date"
                                    className={((this.verificarError("error_fecha_nacimiento")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                    id="fecha_nacimiento"
                                    name="fecha_nacimiento"
                                    title="FECHA NACIMIENTO"
                                    value={paciente.fecha_nacimiento}
                                    onChange={e => {
                                      this.cambioValor(e);
                                      this.calcularEdad(e)
                                    }}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="edad" className="form-label">Edad:</label>
                                  <input
                                    type="text"
                                    className={((this.verificarError("error_edad")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                    id="edad"
                                    name="edad"
                                    value={paciente.edad}
                                    placeholder="Edad"
                                    title="EDAD"
                                    readOnly={true}
                                    onChange={this.cambioValor}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="civil" className="form-label">Estado Civil:</label>
                                  <select
                                    className={((this.verificarError("error_civil")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                    id="civil"
                                    name="civil"
                                    title="ESTADO CIVIL"
                                    onChange={this.cambioValor}
                                  >
                                    <option value="">Seleccionar</option>
                                    <option value="Soltero/a">Soltero/a</option>
                                    <option value="Casado/a">Casado/a</option>
                                    <option value="Divorciado/a">Divorciado/a</option>
                                    <option value="Viudo/a">Viudo/a</option>
                                    <option value="Separado/a">Separado/a</option>
                                    <option value="Union Libre">Unión Libre</option>
                                  </select>
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="ocupacion" className="form-label">Ocupación</label>
                                  <input
                                    type="text"
                                    className={((this.verificarError("error_ocupacion")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                    id="ocupacion"
                                    name="ocupacion"
                                    value={paciente.ocupacion}
                                    placeholder="Ocupación"
                                    title="OCUPACIÓN"
                                    onChange={this.cambioValor}
                                  />
                                </div>
                              </div>
                              <div style={{ marginTop: 0 }} className="row g-3">
                                <div className="col-md-3">
                                  <label htmlFor="empresa" className="form-label">Empresa:</label>
                                  <select
                                    className={((this.verificarError("error_empresa")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                    id="empresa"
                                    name="empresa"
                                    title="EMPRESA"
                                    onChange={this.cambioValor}
                                  >
                                    <option value="">Seleecionar</option>
                                    {
                                      empresas.map(itemEmpresa => (
                                        <option key={itemEmpresa.id_empresa} value={itemEmpresa.rips}>{itemEmpresa.nombre_emp}</option>
                                      ))
                                    }
                                  </select>
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="estado" className="form-label">Estado:</label>
                                  <select
                                    className={((this.verificarError("error_estado")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                    id="estado"
                                    name="estado"
                                    title="ESTADO"
                                    onChange={this.cambioValor}
                                  >
                                    <option value="">Seleccionar</option>
                                    <option value="Activo">Activo</option>
                                    <option value="No Activo">No Activo</option>
                                  </select>
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="regimen" className="form-label">Régimen:</label>
                                  <select
                                    className={((this.verificarError("error_regimen")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                    id="regimen"
                                    name="regimen"
                                    title="RÉGIMEN"
                                    onChange={this.cambioValor}
                                  >
                                    <option value="">Seleccionar</option>
                                    <option value="1">1. Contributivo</option>
                                    <option value="2">2. Subsidiado</option>
                                    <option value="3">3. Vinculado</option>
                                    <option value="4">4. Particular</option>
                                    <option value="5">5. Otro</option>
                                    <option value="7">7. Plan Complementario</option>
                                    <option value="8">8. Poliza</option>
                                    <option value="9">9. ARL</option>
                                    <option value="No Aplica">No Aplica</option>
                                  </select>
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="tipo_s" className="form-label">Tipo:</label>
                                  <select
                                    className={((this.verificarError("error_tipo_s")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                    id="tipo_s"
                                    name="tipo_s"
                                    title="TIPO"
                                    onChange={this.cambioValor}
                                  >
                                    <option value="">Seleccionar</option>
                                    <option value="Cotizante">Cotizante</option>
                                    <option value="Beneficiario">Beneficiario</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="direcciones" role="tabpanel" aria-labelledby="direcciones-tab">
                      <br />
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-xl-12">
                              <div style={{ marginTop: 0 }} className="row g-3">
                                <div className="col-md-3">
                                  <label htmlFor="departamento" className="form-label">Departamento:</label>
                                  <select
                                    className={((this.verificarError("error_departamento")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                    id="departamento"
                                    name="departamento"
                                    title="DEPARTAMENTO"
                                    onChange={e => {
                                      this.cambioValor(e);
                                      this.cargarMunicipios(e);
                                    }}
                                  >
                                    <option value="">Seleccionar</option>
                                    {
                                      departamentos.map(itemDepartamento => (
                                        <option key={itemDepartamento.cod_dep} value={itemDepartamento.cod_dep}>{itemDepartamento.nombre_dep}</option>
                                      ))
                                    }
                                  </select>
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="municipio" className="form-label">Municipio:</label>
                                  <select
                                    className={((this.verificarError("error_municipio")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                    id="municipio"
                                    name="municipio"
                                    title="MUNICIPIO"
                                    onChange={this.cambioValor}

                                    ref={(input) => { this.municipio = input }}
                                  >
                                    <option value="">Seleccionar</option>
                                    {
                                      municipios.map(itemMunicipio => (
                                        <option key={itemMunicipio.cod_mun} value={itemMunicipio.cod_mun}>{itemMunicipio.nombre_mun}</option>
                                      ))
                                    }
                                  </select>
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="zona" className="form-label">Zona:</label>
                                  <select
                                    className={((this.verificarError("error_zona")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                    id="zona"
                                    name="zona"
                                    title="ZONA"
                                    onChange={this.cambioValor}
                                  >
                                    <option value="">Seleccionar</option>
                                    <option value="U">Urbano</option>
                                    <option value="R">Rural</option>
                                  </select>
                                </div>
                              </div>
                              <div style={{ marginTop: 0 }} className='row g-3'>
                                <div className="col-md-6">
                                  <label htmlFor="direccion1" className="form-label">Dirección:</label>
                                  <input
                                    type="text"
                                    className={((this.verificarError("error_direccion1")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                    id="direccion1"
                                    name="direccion1"
                                    value={paciente.direccion1}
                                    placeholder="Dirección"
                                    title="DIRECCIÓN"
                                    onChange={this.cambioValor}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="barrio" className="form-label">Barrio:</label>
                                  <input
                                    type="text"
                                    className={((this.verificarError("error_barrio")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                    id="barrio"
                                    name="barrio"
                                    value={paciente.barrio}
                                    placeholder="Barrio"
                                    title="BARRIO"
                                    onChange={this.cambioValor}
                                  />
                                </div>
                              </div>
                              <div style={{ marginTop: 0 }} className="row g-3">
                                <div className="col-md-3">
                                  <label htmlFor="telefono1" className="form-label">Télefono:</label>
                                  <input
                                    type="text"
                                    className={((this.verificarError("error_telefono1")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                    id="telefono1"
                                    name="telefono1"
                                    value={paciente.telefono1}
                                    placeholder="Telefono"
                                    title="TELEFONO"
                                    onChange={this.cambioValor}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="celular" className="form-label">Celular:</label>
                                  <input
                                    type="text"
                                    className={((this.verificarError("error_celular")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                    id="celular"
                                    name="celular"
                                    value={paciente.celular}
                                    placeholder="Celular"
                                    title="CELULAR"
                                    onChange={this.cambioValor}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="email1" className="form-label">Email:</label>
                                  <input
                                    type="text"
                                    className={((this.verificarError("error_email1")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                    id="email1"
                                    name="email1"
                                    value={paciente.email1}
                                    placeholder="paciente@correo.com"
                                    title="EMAIL"
                                    onChange={this.cambioValor}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="familiares" role="tabpanel" aria-labelledby="familiares-tab">
                      <br />
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-xl-12">
                              <div style={{ marginTop: 0 }} className="row g-3">
                                <div className="col-md-6">
                                  <label htmlFor="nombre_familiar" className="form-label">Nombre Familiar:</label>
                                  <input
                                    type="text"
                                    className={((this.verificarError("error_nombre_familiar")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                    id="nombre_familiar"
                                    name="nombre_familiar"
                                    value={paciente.nombre_acudiente}
                                    placeholder="Nombre Familiar"
                                    title="NOMBRE FAMILIAR"
                                    onChange={this.cambioValor}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="telefono_familiar" className="form-label">Télefono Familiar:</label>
                                  <input
                                    type="text"
                                    className={((this.verificarError("error_telefono_familiar")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                    id="telefono_familiar"
                                    name="telefono_familiar"
                                    value={paciente.telefono_acudiente}
                                    placeholder="Telefono Familiar"
                                    title="TELEFONO FAMILIAR"
                                    onChange={this.cambioValor}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="parentesco" className="form-label">Parentesco:</label>
                                  <select
                                    className={((this.verificarError("error_parentesco")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                    id="parentesco"
                                    name="parentesco"
                                    title="PARENTESCO"
                                    onChange={this.cambioValor}
                                    value={paciente.parentesco}
                                  >
                                    <option value="">Seleccionar</option>
                                    <option value="Padres">Padres</option>
                                    <option value="Conyuge">Cónyuge</option>
                                    <option value="Hermano/a">Hermano/a</option>
                                    <option value="Hijo/a">Hijo/a</option>
                                    <option value="Primo/a">Primo/a</option>
                                    <option value="Particular">Particular</option>
                                    <option value="Cuñado/a">Cuñado/a</option>
                                    <option value="Abuelo/a">Abuelo/a</option>
                                    <option value="Vecino">Vecino</option>
                                    <option value="Tio/a">Tío/a</option>
                                    <option value="Suegro/a">Suegro/a</option>
                                    <option value="Sobrino/a">Sobrino/a</option>
                                    <option value="hijo(a)">hijo(a)</option>
                                  </select>
                                </div>
                              </div>
                              <div style={{ marginTop: 0 }} className="row g-3">
                                <div className="col-md-6">
                                  <label htmlFor="nombre_acudiente" className="form-label">Nombre Acudiente:</label>
                                  <input
                                    type="text"
                                    className={((this.verificarError("error_nombre_acudiente")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                    id="nombre_acudiente"
                                    name="nombre_acudiente"
                                    value={paciente.cedula_acudiente}
                                    placeholder="Nombre Acudiente"
                                    title="NOMBRE ACUDIENTE"
                                    onChange={this.cambioValor}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="telefono_acudiente" className="form-label">Télefono Acudiente:</label>
                                  <input
                                    type="text"
                                    className={((this.verificarError("error_telefono_acudiente")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                    id="telefono_acudiente"
                                    name="telefono_acudiente"
                                    value={paciente.dir_pariente}
                                    placeholder="Telefono Acudiente"
                                    title="TELEFONO ACUDIENTE"
                                    onChange={this.cambioValor}

                                  />
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="parentesco2" className="form-label">Parentesco:</label>
                                  <select
                                    className={((this.verificarError("error_parentesco2")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                    id="parentesco2"
                                    name="parentesco2"
                                    title="PARENTESCO"
                                    onChange={this.cambioValor}
                                    value={paciente.parentesco2}
                                  >
                                    <option value="">Seleccionar</option>
                                    <option value="Padres">Padres</option>
                                    <option value="Conyuge">Cónyuge</option>
                                    <option value="Hermano/a">Hermano/a</option>
                                    <option value="Hijo/a">Hijo/a</option>
                                    <option value="Primo/a">Primo/a</option>
                                    <option value="Particular">Particular</option>
                                    <option value="Cuñado/a">Cuñado/a</option>
                                    <option value="Abuelo/a">Abuelo/a</option>
                                    <option value="Vecino">Vecino</option>
                                    <option value="Tio/a">Tío/a</option>
                                    <option value="Suegro/a">Suegro/a</option>
                                    <option value="Sobrino/a">Sobrino/a</option>
                                    <option value="hijo(a)">hijo(a)</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="diagnosticos" role="tabpanel" aria-labelledby="diagnosticos-tab">
                      <br />
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-xl-12">
                              <div style={{ marginTop: 0 }} className="row g-3">
                                <div className="col-md-3">
                                  <label htmlFor="enfermedad" className="form-label">Código Enfemerdad 1:</label>
                                  <input
                                    type="text"
                                    className={((this.verificarError("error_enfermedad")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                    id="enfermedad"
                                    name="enfermedad"
                                    value={paciente.enfermedad}
                                    placeholder="Código Enfermedad 1"
                                    title="CÓDIGO ENFERMEDAD 1"
                                    onChange={this.cambioValor}
                                    readOnly
                                  />
                                </div>
                                <div className='col-md-1'>
                                  <label htmlFor="" className="form-label">&nbsp;</label>
                                  <img
                                    style={{ width: 40, cursor:'pointer' }}
                                    className='form-control form-control-sm'
                                    src={findImage}
                                    alt="Buscar Enfermedad 1"
                                    data-bs-toggle='modal' data-bs-target='#nuevaEnfermedad1'
                                  />
                                </div>
                                <div className="col-md-8">
                                  <label htmlFor="descripcion_enf" className="form-label">Descripción Enfermedad 1:</label>
                                  <input
                                    type="text"
                                    className={((this.verificarError("error_descripcion_enf")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                    id="descripcion_enf"
                                    name="descripcion_enf"
                                    value={paciente.descripcion_enf}
                                    placeholder="Descripción Enfermedad 1"
                                    title="DESCRIPCIÓN ENFERMEDAD 1"
                                    onChange={this.cambioValor}
                                    readOnly
                                  />
                                </div>
                              </div>
                              <div style={{ marginTop: 0 }} className="row g-3">
                                <div className="col-md-3">
                                  <label htmlFor="enfermedad2" className="form-label">Código Enfemerdad 2:</label>
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="enfermedad2"
                                    name="enfermedad2"
                                    value={paciente.enfermedad2}
                                    placeholder="Código Enfermedad 2"
                                    title="CÓDIGO ENFERMEDAD 2"
                                    onChange={this.cambioValor}
                                    readOnly
                                  />
                                </div>
                                <div className='col-md-1'>
                                  <label htmlFor="" className="form-label">&nbsp;</label>
                                  <img
                                    style={{ width: 40, cursor:'pointer' }}
                                    className='form-control form-control-sm'
                                    src={findImage}
                                    alt="Buscar Enfermedad 2"
                                    data-bs-toggle='modal' data-bs-target='#nuevaEnfermedad2'
                                  />
                                </div>
                                <div className="col-md-8">
                                  <label htmlFor="descripcion_enf2" className="form-label">Descripción Enfermedad 2:</label>
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="descripcion_enf2"
                                    name="descripcion_enf2"
                                    value={paciente.descripcion_enf2}
                                    placeholder="Descripción Enfermedad 2"
                                    title="DESCRIPCIÓN ENFERMEDAD 2"
                                    onChange={this.cambioValor}
                                    readOnly
                                  />
                                </div>
                              </div>
                              <div style={{ marginTop: 0 }} className="row g-3">
                                <div className="col-md-3">
                                  <label htmlFor="deposito" className="form-label">Depósito:</label>
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="deposito"
                                    name="deposito"
                                    value={paciente.deposito}
                                    placeholder="Depósito"
                                    title="DEPÓSITO"
                                    onChange={this.cambioValor}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="subcodigo" className="form-label">Sub-Código:</label>
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="subcodigo"
                                    name="subcodigo"
                                    value={paciente.subcodigo}
                                    placeholder="Sub-Código"
                                    title="SUB-CÓDIGO"
                                    onChange={this.cambioValor}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="prof_eps" className="form-label">Esp. Que Ordena:</label>
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="prof_eps"
                                    name="prof_eps"
                                    value={paciente.prof_eps}
                                    placeholder="Esp. Que Ordena"
                                    title="ESP. QUE ORDENA"
                                    onChange={this.cambioValor}
                                  />
                                </div>
                                <div className="col-md-3">
                                  <label htmlFor="contrato" className="form-label">Contrato:</label>
                                  <input
                                    type="text"
                                    className={((this.verificarError("error_contrato")) ? "is-invalid" : "") + " form-control form-control-sm"}
                                    id="contrato"
                                    name="contrato"
                                    value={paciente.contrato}
                                    placeholder="Contrato"
                                    title="CONTRATO"
                                    onChange={this.cambioValor}
                                  />
                                </div>
                              </div>
                              <div style={{ marginTop: 0 }} className="row g-3">
                                <div className="col-md-3">
                                  <label htmlFor="covid" className="form-label">Posible Covid-19:</label>
                                  <select
                                    className="form-select form-select-sm"
                                    id="covid"
                                    name="covid"
                                    title="POSIBLE COVID-19"
                                    onChange={this.cambioValor}
                                  >
                                    <option value="">Seleccionar</option>
                                    <option value="Si">Si</option>
                                    <option value="No">No</option>
                                  </select>
                                </div>
                                <div className="col-md-9">
                                  <label htmlFor="observacion_covid" className="form-label">Observaciones Covid-19:</label>
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="observacion_covid"
                                    name="observacion_covid"
                                    value={paciente.observacion_covid}
                                    placeholder="Observaciones Covid-19"
                                    title="OBSERVACIONES COVID-19"
                                    onChange={this.cambioValor}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="condiciones" role="tabpanel" aria-labelledby="condiciones-tab">
                      <form id='form_condiciones'>
                        <br />
                        <input type="hidden" id='cedula_cond' name="cedula_cond" />
                        
                        <table className="table table-condensed table-sm">
                          <thead className="thead-inverse">
                            <tr>
                              <th style={{ verticalAlign: "middle", width: '20%' }}>REQUISITOS</th>
                              <th style={{ verticalAlign: "middle", width: '15%' }}>CUMPLE / NO CUMPLE</th>
                              <th style={{ verticalAlign: "middle", width: '65%' }}>OBSERVACIONES</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>CONFORT</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="cond1"
                                  name="cond1"
                                  title="CONFORT"
                                >
                                  <option value="">Seleccionar</option>
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  id="obs1"
                                  name="obs1"
                                  placeholder="Observaciones Confort"
                                  title="OBSERVACIONES CONFORT"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>ENERGÍA ELÉCTRICA</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="cond2"
                                  name="cond2"
                                  title="ENERGÍA ELÉCTRICA"
                                >
                                  <option value="">Seleccionar</option>
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  id="obs2"
                                  name="obs2"
                                  placeholder="Observaciones Energía Eléctrica"
                                  title="OBSERVACIONES ENERGÍA ELÉCTRICA"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>LÍNEA TELEFÓNICA</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="cond3"
                                  name="cond3"
                                  title="LÍNEA TELEFÓNICA"
                                >
                                  <option value="">Seleccionar</option>
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  id="obs3"
                                  name="obs3"
                                  placeholder="Observaciones Línea Telefónica"
                                  title="OBSERVACIONES LÍNEA TELEFÓNICA"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>ACUEDUCTO</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="cond4"
                                  name="cond4"
                                  title="ACUEDUCTO"
                                >
                                  <option value="">Seleccionar</option>
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  id="obs4"
                                  name="obs4"
                                  placeholder="Observaciones Acueducto"
                                  title="OBSERVACIONES ACUEDUCTO"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>ALCANTARILLADO</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="cond5"
                                  name="cond5"
                                  title="ALCANTARILLADO"
                                >
                                  <option value="">Seleccionar</option>
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  id="obs5"
                                  name="obs5"
                                  placeholder="Observaciones Alcantarillado"
                                  title="OBSERVACIONES ALCANTARILLADO"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>NEVERA</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="cond6"
                                  name="cond6"
                                  title="NEVERA"
                                >
                                  <option value="">Seleccionar</option>
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  id="obs6"
                                  name="obs6"
                                  placeholder="Observaciones Nevera"
                                  title="OBSERVACIONES NEVERA"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>BAÑO</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="cond7"
                                  name="cond7"
                                  title="BAÑO"
                                >
                                  <option value="">Seleccionar</option>
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  id="obs7"
                                  name="obs7"
                                  placeholder="Observaciones Baño"
                                  title="OBSERVACIONES BAÑO"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>CUIDADOR</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="cond8"
                                  name="cond8"
                                  title="CUIDADOR"
                                >
                                  <option value="">Seleccionar</option>
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  id="obs8"
                                  name="obs8"
                                  placeholder="Observaciones Cuidador"
                                  title="OBSERVACIONES CUIDADOR"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>ACCESIBILIDAD</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="cond9"
                                  name="cond9"
                                  title="ACCESIBILIDAD"
                                >
                                  <option value="">Seleccionar</option>
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  id="obs9"
                                  name="obs9"
                                  placeholder="Observaciones Accesibilidad"
                                  title="OBSERVACIONES ACCESIBILIDAD"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>ALMACENAMIENTO DE RESIDUOS</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="cond10"
                                  name="cond10"
                                  title="ALMACENAMIENTO DE RESIDUOS"
                                >
                                  <option value="">Seleccionar</option>
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  id="obs10"
                                  name="obs10"
                                  placeholder="Observaciones Almacenamiento de Residuos"
                                  title="OBSERVACIONES ALMACENAMIENTO DE RESIDUOS"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>SEGURIDAD EN ENTORNO</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="cond11"
                                  name="cond11"
                                  title="SEGURIDAD EN ENTORNO"
                                >
                                  <option value="">Seleccionar</option>
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  id="obs11"
                                  name="obs11"
                                  placeholder="Observaciones Seguridad en Entorno"
                                  title="OBSERVACIONES SEGURIDAD EN ENTORNO"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>FUNCIONARIO QUE VERIFICA</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="usuario"
                                  name="usuario">
                                  <option value="">Seleccionar</option>
                                  {
                                      funcionarios.map(item => (
                                        <option key={item.id} value={item.usuario}>{item.nombre}</option>
                                      ))
                                    }
                                </select>
                              </td>
                              <td>
                                -
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <hr />
                        <div className='text-center'>
                          {((this.verificarError("error_condiciones")) ?
                            <span style={{ backgroundColor: '#F8D7DA', borderColor: '#F5C2C7', color: '#842029' }} class="badge bg-danger">Debe digitar el Campo 'Identificación'!</span> : "")}&nbsp;&nbsp;
                          <button type="submit" className="btn btn-primary btn-sm" id='btnGuardarCondiciones' onClick={this.enviarCondiciones}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Guardar</button>
                        </div>
                      </form>
                    </div>
                    <div className="tab-pane fade" id="encuesta" role="tabpanel" aria-labelledby="encuesta-tab">
                      <form id='form_encuesta'>
                        <br />
                        <input type="hidden" id='cedula_enc' name="cedula_enc" />
                        <input type="hidden" id='usuario' name="usuario" value={window.localStorage.getItem('k_usuario')} />
                        <table className="table table-condensed table-sm">
                          <thead className="thead-inverse">
                            <tr>
                              <th colSpan={8} style={{ verticalAlign: "middle", width: '100%', textTransform: 'uppercase' }}>¿Ha estado en alguno de éstos países en los últimos 14 días?</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>Dosis No. 1</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="enc1"
                                  name="enc1"
                                  title="CHINA"
                                >
                                  <option value="">Seleccionar</option>
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </td>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>Dosis No. 2</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="enc2"
                                  name="enc2"
                                  title="ITALIA"
                                >
                                  <option value="">Seleccionar</option>
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </td>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>Dosis No. 3</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="enc3"
                                  name="enc3"
                                  title="JAPÓN"
                                >
                                  <option value="">Seleccionar</option>
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </td>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>Dosis No. 4</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="enc4"
                                  name="enc4"
                                  title="IRÁN"
                                >
                                  <option value="">Seleccionar</option>
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </td>
                            </tr>
                            <tr>
                                                    <td style={{verticalAlign: "middle"}}>Nombre de la ultima Vacuna</td>
                                                    <td>
                                                        <input type="text" name="enc5" id="enc5" placeholder="enc5" defaultValue="" className="form-control form-control-sm"/>
                                                        <input type="hidden" name="enc6" placeholder="enc6" value="" />
                                                        <input type="hidden" name="enc7" placeholder="enc7" value="" />
                                                        <input type="hidden" name="enc8" placeholder="enc8" value="" />
                                                        <input type="hidden" name="enc9" placeholder="enc9" value="" />
                                                        <input type="hidden" name="enc10" placeholder="enc10" value="" />
                                                        <input type="hidden" name="enc11" placeholder="enc11" value="" />
                                                        <input type="hidden" name="enc12" placeholder="enc12" value="" />
                                                       
                                                        
                                                    </td>
                                                   
                                                </tr>
                          </tbody>
                        </table>
                        <table className="table table-condensed table-sm">
                          <thead className="thead-inverse">
                            <tr>
                              <th colSpan={8} style={{ verticalAlign: "middle", width: '100%', textTransform: 'uppercase' }}>¿Ha tenido usted alguno de estos síntomas en los últimos 14 días?</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>MALESTAR GENERAL</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="enc13"
                                  name="enc13"
                                  title="MALESTAR GENERAL"
                                >
                                  <option value="">Seleccionar</option>
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </td>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>DOLOR DE GARGANTA</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="enc14"
                                  name="enc14"
                                  title="DOLOR DE GARGANTA"
                                >
                                  <option value="">Seleccionar</option>
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </td>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>DIFICULTAD PARA RESPIRAR</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="enc15"
                                  name="enc15"
                                  title="DIFICULTAD PARA RESPIRAR"
                                >
                                  <option value="">Seleccionar</option>
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </td>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>FIEBRE</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="enc16"
                                  name="enc16"
                                  title="FIEBRE"
                                >
                                  <option value="">Seleccionar</option>
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>TOS</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="enc17"
                                  name="enc17"
                                  title="TOS"
                                >
                                  <option value="">Seleccionar</option>
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </td>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>MALESTAR ESTOMACAL</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="enc19"
                                  name="enc19"
                                  title="MALESTAR ESTOMACAL"
                                >
                                  <option value="">Seleccionar</option>
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </td>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>DIARREA</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="enc20"
                                  name="enc20"
                                  title="DIARREA"
                                >
                                  <option value="">Seleccionar</option>
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </td>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>FATIGA</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="enc21"
                                  name="enc21"
                                  title="FATIGA"
                                >
                                  <option value="">Seleccionar</option>
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>PERDIDA DE OLFATO</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="enc22"
                                  name="enc22"
                                  title="PERDIDA OLFATO"
                                >
                                  <option value="">Seleccionar</option>
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </td>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>PERDIDA DE GUSTO</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="enc23"
                                  name="enc23"
                                  title="PERDIDA DE GUSTO"
                                >
                                  <option value="">Seleccionar</option>
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                        <table className="table table-condensed table-sm">
                          <thead className="thead-inverse">
                            <tr>
                              <th colSpan={8} style={{ verticalAlign: "middle", width: '100%', textTransform: 'uppercase' }}> ¿En los últimos 14 días ha estado en contacto con personas que tuvieran alguno de los anteriores síntomas?</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>HA TENIDO CONTACTO</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="enc18"
                                  name="enc18"
                                  title="HA TENIDO CONTACTO"
                                >
                                  <option value="">Seleccionar</option>
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <hr />
                        <div className='text-center'>
                          {((this.verificarError("error_encuesta")) ?
                            <span style={{ backgroundColor: '#F8D7DA', borderColor: '#F5C2C7', color: '#842029' }} class="badge bg-danger">Debe digitar el Campo 'Identificación'!</span> : "")}&nbsp;&nbsp;
                          <button type="submit" className="btn btn-primary btn-sm" id='btnGuardarEncuesta' onClick={this.enviarEncuesta}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Guardar</button>
                        </div>
                      </form>
                    </div>
                    <div className="tab-pane fade" id="antecedente" role="tabpanel" aria-labelledby="antecedente-tab">
                      <form id='form_antecedente'>
                        <br />
                        <input type="hidden" id='id_paciente_ant' name="id_paciente_ant" value={window.localStorage.getItem('id_paciente')}/>
                        <input type="hidden" id='usuario_ant' name="usuario_ant" value={window.localStorage.getItem('k_usuario')} />
                        <table className="table table-condensed table-sm">
                          <thead className="thead-inverse">
                            <tr>
                              <th colSpan={8} style={{ verticalAlign: "middle", width: '100%', textTransform: 'uppercase' }}>HISTORIA FAMILIAR</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>Cancer:</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="cancer"
                                  name="cancer"
                                >
                                  <option value="No">No</option>
                                  <option value="Si">Si</option>
                                  
                                </select>
                              </td>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>Ataques De Corazon:</td>
                              <td>
                              <select
                                  className="form-select form-select-sm"
                                  id="ataques"
                                  name="ataques"
                                >
                                  <option value="No">No</option>
                                  <option value="Si">Si</option>
                                  
                                </select>
                              </td>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>Emfermedades Renales:</td>
                              <td>
                              <select
                                  className="form-select form-select-sm"
                                  id="renales"
                                  name="renales"
                                >
                                  <option value="No">No</option>
                                  <option value="Si">Si</option>
                                  
                                </select>
                              </td>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>Diabetes:</td>
                              <td>
                              <select
                                  className="form-select form-select-sm"
                                  id="diabetes"
                                  name="diabetes"
                                >
                                  <option value="No">No</option>
                                  <option value="Si">Si</option>
                                  
                                </select>
                              </td>
                            </tr>
                            <tr>
                            <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>Hipertension:</td>
                              <td>
                              <select
                                  className="form-select form-select-sm"
                                  id="hipertension"
                                  name="hipertension"
                                >
                                  <option value="No">No</option>
                                  <option value="Si">Si</option>
                                  
                                </select>
                              </td>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>Tuberculosis:</td>
                              <td>
                              <select
                                  className="form-select form-select-sm"
                                  id="tuberculosis"
                                  name="tuberculosis"
                                >
                                  <option value="No">No</option>
                                  <option value="Si">Si</option>
                                  
                                </select>
                              </td>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>Otras:</td>
                              <td>
                              <select
                                  className="form-select form-select-sm"
                                  id="otras"
                                  name="otras"
                                >
                                  <option value="No">No</option>
                                  <option value="Si">Si</option>
                                  
                                </select>
                              </td>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>Especifique::</td>
                              <td>
                              <input type="text" name="especifique" id="especifique" placeholder="Especifique" defaultValue="" className="form-control form-control-sm"/>
                              </td>        
                                                </tr>
                          </tbody>
                        </table>
                        <table className="table table-condensed table-sm">
                          <thead className="thead-inverse">
                            <tr>
                              <th colSpan={8} style={{ verticalAlign: "middle", width: '100%', textTransform: 'uppercase' }}>ANTECEDENTES PERSONALES</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>Alcohol:</td>
                              <td>
                              <select
                                  className="form-select form-select-sm"
                                  id="alcohol"
                                  name="alcohol"
                                >
                                  <option value="No">No</option>
                                  <option value="Si">Si</option>
                                  
                                </select>
                              </td>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>Tabaco:</td>
                              <td>
                              <select
                                  className="form-select form-select-sm"
                                  id="tabaco"
                                  name="tabaco"
                                >
                                  <option value="No">No</option>
                                  <option value="Si">Si</option>
                                  
                                </select>
                              </td>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>Drogas:</td>
                              <td>
                              <select
                                  className="form-select form-select-sm"
                                  id="drogas"
                                  name="drogas"
                                >
                                  <option value="No">No</option>
                                  <option value="Si">Si</option>
                                  
                                </select>
                              </td>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>	
                              Cancer:</td>
                              <td>
                              <select
                                  className="form-select form-select-sm"
                                  id="cancer_2"
                                  name="cancer_2"
                                >
                                  <option value="No">No</option>
                                  <option value="Si">Si</option>
                                  
                                </select>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>Diabetes:</td>
                              <td>
                              <select
                                  className="form-select form-select-sm"
                                  id="diabetes_2"
                                  name="diabetes_2"
                                >
                                  <option value="No">No</option>
                                  <option value="Si">Si</option>
                                  
                                </select>
                              </td>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>Ataques De Corazòn:L</td>
                              <td>
                              <select
                                  className="form-select form-select-sm"
                                  id="ataques_2"
                                  name="ataques_2"
                                >
                                  <option value="No">No</option>
                                  <option value="Si">Si</option>
                                  
                                </select>
                              </td>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>Hipertensiòn:</td>
                              <td>
                              <select
                                  className="form-select form-select-sm"
                                  id="hipertension_2"
                                  name="hipertension_2"
                                >
                                  <option value="No">No</option>
                                  <option value="Si">Si</option>
                                  
                                </select>
                              </td>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>Emfermedades Renales:</td>
                              <td>
                              <select
                                  className="form-select form-select-sm"
                                  id="renales_2"
                                  name="renales_2"
                                >
                                  <option value="No">No</option>
                                  <option value="Si">Si</option>
                                  
                                </select>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>Tuberculosis:</td>
                              <td>
                              <select
                                  className="form-select form-select-sm"
                                  id="tuberculosis_2"
                                  name="tuberculosis_2"
                                >
                                  <option value="No">No</option>
                                  <option value="Si">Si</option>
                                  
                                </select>
                              </td>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>Otras</td>
                              <td>
                              <select
                                  className="form-select form-select-sm"
                                  id="otras_2"
                                  name="otras_2"
                                >
                                  <option value="No">No</option>
                                  <option value="Si">Si</option>
                                  
                                </select>
                              </td>
                              <td>Especifique</td>
                              <td><input type="text" name="especifique_2" id="especifique_2" placeholder="Especifique" defaultValue="" className="form-control form-control-sm"/></td>
                              <td></td>
                              <td></td>
                            </tr>
                            
                            <tr>
                              <td colSpan={2} style={{ verticalAlign: "middle", fontWeight: "bold" }}>Medicamentos</td>
                             
                              <td colSpan={6}><textarea type="text" name="medicamentos" id="medicamentos" placeholder=" Enumere la dòsis o el nùmero de pìldoras por dìa" defaultValue="" className="form-control form-control-sm"></textarea></td>
                          
                            </tr>
                            <tr>
                              <td colSpan={2} style={{ verticalAlign: "middle", fontWeight: "bold" }}>Alergias</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="alergias"
                                  name="alergias"
                                  title="alergias"
                                >
                                  <option value="No">No</option>
                                  <option value="Si">Si</option>
                                </select>
                              </td>
                              <td><input type="text" name="alergias_1" id="alergias_1" placeholder="Especifique" defaultValue="" className="form-control form-control-sm"/></td>
                              <td><input type="text" name="alergias_2" id="alergias_2" placeholder="Especifique" defaultValue="" className="form-control form-control-sm"/></td>
                              <td><input type="text" name="alergias_3" id="alergias_3" placeholder="Especifique" defaultValue="" className="form-control form-control-sm"/></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              
                            </tr>
                          </tbody>
                        </table>
                        <table className="table table-condensed table-sm">
                          <thead className="thead-inverse">
                            <tr>
                              <th colSpan={8} style={{ verticalAlign: "middle", width: '100%', textTransform: 'uppercase' }}> EXAMENES COMPLEMENTARIOS</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>Laboratorios</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="laboratorio"
                                  name="laboratorio"
                                  title="laboratorio"
                                >
                                  <option value="No">No</option>
                                  <option value="Si">Si</option>
                                </select>
                              </td>
                              <td><input type="text" name="laboratorio_1" id="laboratorio_1" placeholder="Especifique" defaultValue="" className="form-control form-control-sm"/></td>
                              <td><input type="text" name="laboratorio_2" id="laboratorio_2" placeholder="Especifique" defaultValue="" className="form-control form-control-sm"/></td>
                              <td><input type="text" name="laboratorio_3" id="laboratorio_3" placeholder="Especifique" defaultValue="" className="form-control form-control-sm"/></td>
                            </tr>
                            <tr>
                              <td style={{ verticalAlign: "middle", fontWeight: "bold" }}>Otros Estudios</td>
                              <td>
                                <select
                                  className="form-select form-select-sm"
                                  id="estudios"
                                  name="estudios"
                                  title="estudios"
                                >
                                  <option value="No">No</option>
                                  <option value="Si">Si</option>
                                </select>
                              </td>
                              <td><input type="text" name="estudios_1" id="estudios_1" placeholder="Especifique" defaultValue="" className="form-control form-control-sm"/></td>
                              <td><input type="text" name="estudios_2" id="estudios_2" placeholder="Especifique" defaultValue="" className="form-control form-control-sm"/></td>
                              <td><input type="text" name="estudios_3" id="estudios_3" placeholder="Especifique" defaultValue="" className="form-control form-control-sm"/></td>
                            </tr>
                          </tbody>
                        </table>
                        <hr />
                        <div className='text-center'>
                          {((this.verificarError("error_encuesta")) ?
                            <span style={{ backgroundColor: '#F8D7DA', borderColor: '#F5C2C7', color: '#842029' }} class="badge bg-danger">Debe digitar el Campo 'Identificación'!</span> : "")}&nbsp;&nbsp;
                          <button type="submit" className="btn btn-primary btn-sm" id='btnGuardarEncuesta' onClick={this.enviarAntecedentes}><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Guardar</button>
                         
                        </div>
                      </form>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="modal-footer">
              {((this.verificarError("error_global")) ?
                <span style={{ backgroundColor: '#F8D7DA', borderColor: '#F5C2C7', color: '#842029' }} class="badge bg-danger">Faltan campos por Digitar!</span> : "")}
              <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><FontAwesomeIcon icon={faSignOutAlt} />&nbsp;&nbsp;Cerrar</button>
              <button type="submit" className="btn btn-primary btn-sm" id='btnGuardarNuevoPacienteSub' ><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Guardar</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}


export default Editar;
