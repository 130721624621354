import React from "react";
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft, faAngleDoubleRight, faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2';

class Listar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datosCargados: false,
      productosAlquiler: [],
      pagina: [],
    };
  }
  borrarRegistros = (id) => {
    console.log(id);
    Swal.fire({
      title: 'Estas seguro de eliminar el registro?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Borrar',
      denyButtonText: `No borrar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        fetch(Api + "DelAlquiler/" + id)
          .then((respuesta) => respuesta.json())
          .then((datosRespuesta) => {
            console.log(datosRespuesta);
            Swal.fire('Borrado!', datosRespuesta.msj, 'success')
            this.Buscar(1);
          })
          .catch(console.log);
      } else if (result.isDenied) {
        Swal.fire('Cancelado', '', 'info')
      }
    })
  };
  componentDidMount() {
    this.Buscar(1);
  }
  Buscar(page) {
    let btipo = document.getElementById('buscar').value;
    let bname = document.getElementById('bnombre').value;
    console.log("Formulario Enviado...");
    if (btipo == '') {
      btipo = 0;
    }
    if (bname == '') {
      bname = 0;
    }
    fetch(Api + "SearchAlquiler/" + btipo + "/" + bname + "?page=" + page)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
        this.setState({ datosCargados: true, productosAlquiler: datosRespuesta.data, pagina: datosRespuesta.total, ultimo: datosRespuesta.last_page, actual: datosRespuesta.current_page });
      })
      .catch(console.log);
  }
  render() {
    const { datosCargados, productosAlquiler, pagina, ultimo, actual } = this.state;
    if (!datosCargados) {
      var load = 'Cargando datos....';
    } else {
      var load = '';
    }
    return (
      <div className="content">
        <div className="container-fluid">
          <br />
          <div className="card">
            <div className="card-header">
              <Link className="btn btn-success btn-sm" to={"/AddAlquiler"}>
                Agregar Producto a Alquilar
              </Link>
            </div>
            <div className="card-body">
              <h4>Lista de Alquiler</h4>
              <div className="row">
                <div className="col-xl-3">
                  <input type="text" id="buscar" name="buscar" placeholder="Código del Producto" className="form-control form-control-sm" />
                </div>
                <div className="col-xl-3">
                  <input type="text" id="bnombre" name="bnombre" placeholder="Nombre del Producto" className="form-control form-control-sm" />
                </div>
                <div className="col-xl-3">
                  <input type="button" id="bt" name="bt" className="btn btn-primary btn-sm" value="Filtrar" onClick={() => this.Buscar(1)} />
                </div>
              </div>
              <br></br>
              <div className="row">
                <table className="table table-hover table-condensed table-sm">
                  <thead className="thead-inverse">
                    <tr>
                      <th>CÓDIGO</th>
                      <th>NOMBRE DEL PRODUCTO</th>
                      <th>TIPO</th>
                      <th>PRECIO</th>
                      <th>ACCIONES</th>
                    </tr>
                  </thead>
                  <tbody>
                    {load}
                    {productosAlquiler.map((itemProductoAlq) => (
                      <tr key={itemProductoAlq.id}>
                        <td>{itemProductoAlq.codigo}</td>
                        <td>{itemProductoAlq.nombre}</td>
                        <td>{itemProductoAlq.tipo}</td>
                        <td>{itemProductoAlq.precio}</td>
                        <td>
                          <div className="btn-group" role="group" aria-label="">
                            <Link
                              className="btn btn-primary btn-sm"
                              to={"/UpAlquiler/" + itemProductoAlq.id}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </Link>&nbsp;&nbsp;
                            <button
                              type="button"
                              className="btn btn-danger btn-sm"
                              onClick={() =>
                                this.borrarRegistros(itemProductoAlq.id)
                              }
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li class="page-item"><a className="page-link" href="#" onClick={() => this.Buscar(1)}><FontAwesomeIcon icon={faAngleDoubleLeft} /></a></li>
                  {(() => {
                    let p = [];
                    if (ultimo >= 20) {
                      var ult = 20;
                    } else {
                      ult = ultimo;
                    }
                    for (let i = 1; i <= ult; i++) {
                      p.push(<li class="page-item" key={i}><a className="page-link" href="#" onClick={() => this.Buscar(i)}>{i}</a></li>);
                    }
                    return p;
                  })()}
                  <li class="page-item"><a className="page-link" href="#" onClick={() => this.Buscar(ultimo)}><FontAwesomeIcon icon={faAngleDoubleRight} /></a></li>
                </ul>
                Pagina {actual} de {ultimo} Registros {pagina}
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Listar;