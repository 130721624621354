import React from 'react';
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import Swal from 'sweetalert2';

class Crear extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nombre: "",
            archivo:null,
            errores: []
        }
    }
    cambioValor = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState({ state, errores: [] })
        //this.setState({ archivo:e.target.files[0]  })
        //console.log(e.target.files[0])
    }
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    enviarDatos = (e) => {
        e.preventDefault();
        console.log("Formulario Enviado...");
        const { nombre, archivo } = this.state;
        console.log(nombre);
        
        let form = document.getElementById('form');
         let formData = new FormData(form);
         fetch(Api + "AddArchivos", {
            method: "POST",
            body: formData
          })
            .then(respuesta => respuesta.json())
            .then((datosRespuesta) => {
              console.log(datosRespuesta)
              Swal.fire(
                'Cargos!',
                datosRespuesta.msj,
                'success'
              )
              this.props.history.push("/MasterDocumentos");
            })
            .catch(console.log)
        // formData.append('image', this.archivo, this.state.archivo.name);
        //var datosEnviar = { nombre: nombre, archivo:archivo }
        console.log(formData);
    }
    render() {
        const { nombre, archivo } = this.state;
        return (
            <div className="content-wrapper">
                <div className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header">
                                Registro de Cargos
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.enviarDatos}  id="form">
                                    <div className="form-group">
                                        <label htmlFor="nombre">Nombre</label>
                                        <input className={((this.verificarError("error_nombre")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="nombre" id="nombre" value={nombre} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                        <small id="helpId" className="invalid-feedback">Digita el Nombre</small>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="nombre">Archivo</label>
                                        <input className={((this.verificarError("error_archivo")) ? "is-invalid" : "") + " form-control form-control-sm"} type="file" name="archivo" id="archivo"  placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                        <small id="helpId" className="invalid-feedback">Seleciona el archivo</small>
                                    </div>
                                    <br />
                                    <div className="btn-group" role="group" aria-label="">
                                        <button type="submit" className="btn btn-success btn-sm">Agregar</button>&nbsp;&nbsp;
                                        <Link className="btn btn-danger btn-sm" to={"/MasterDocumentos"}>Cancelar</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Crear;