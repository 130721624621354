import React from "react";
import { Link } from "react-router-dom";
import Api from "../../servicios/api";
import Swal from 'sweetalert2';
import findImage from "../../images/find.png"
import FormularioEnf1 from "./Pacientes"
import FormUsuario from "./Usuarios"

class Editar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datosCargados: false,
      nombre: "",
      tipo: "",
      empleado: [],
      errores: []
    };
  }
  cambioValor = (e) => {
    const state = this.state.empleado;
    state[e.target.name] = e.target.value;
    this.setState({ empleado: state });
  };
  verificarError(elemento) {
    return this.state.errores.indexOf(elemento) !== -1;
  }
  enviarDatos = (e) => {
    e.preventDefault();
    console.log("Formulario Enviado...");
    // const {nombre_atencion, tipo,id_atencion,codigo_atencion,cuenta} = this.state.empleado;

    let form = document.getElementById('form');
    let formData = new FormData(form);
    console.log(formData);
    //var datosEnviar = {nombre:nombre_atencion, tipo:tipo,id:id_atencion,cuenta:cuenta,codigo:codigo_atencion}


    fetch(Api + "incidencias/up/"+this.props.match.params.id, {
      method: "POST",
      body: formData
    })
      .then(respuesta => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta)
        Swal.fire(
          'Soportes!',
          datosRespuesta.Msj,
          'success'
        )
        this.props.history.push("/MasterSoporte");
      })
      .catch(console.log)
  }
  componentDidMount() {

    console.log(this.props.match.params.id);
    fetch(Api + "incidencias/" + this.props.match.params.id,
      {
        method: "GET",
        headers: new Headers({
          'Authorization': 'koala2021'
        }),
      })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
        this.setState({ datosCargados: true, empleado: datosRespuesta }); //

      })
      .catch(console.log);
  }
  render() {
    const { datosCargados, empleado, nn } = this.state;
    if (!datosCargados) {
      var load = 'Cargando datos....';
    } else {
      var load = '';
    }
    return (
      <div>
<div class="modal fade" id="nuevaEnfermedad1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevaEnfermedad1Label" aria-hidden="true">
          <FormularioEnf1 />
        </div>
        <div class="modal fade" id="buscarUsuario" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevaEnfermedad1Label" aria-hidden="true">
          <FormUsuario />
        </div>
        <div className="content-wrapper">
          <div className="content">
            <div className="container-fluid">
              <div className="card">
                <div className="card-header">Editar Soportes {load} de {empleado.nombres} {empleado.apellidos}</div>
                <div className="card-body">
                  <form onSubmit={this.enviarDatos} id="form">

                    <div className="row">
                      <div className="col-sm-8">

                        <div className="form-group">
                          <label htmlFor="asunto">Asunto:</label>
                          <input
                            type="text"
                            name="asunto_inc"
                            id="asunto"
                            defaultValue={empleado.asunto_inc}
                            className="form-control form-control-sm"
                            onChange={this.cambioValor}
                            required
                          />

                        </div>
                        <div className="form-group">
                          <label htmlFor="descripcion">Descripcion:</label>
                          <textarea
                            rows="4"
                            name="descripcion_inc"
                            id="descripcion"
                            defaultValue={empleado.descripcion_inc}
                            className={((this.verificarError("error_nombre")) ? "is-invalid" : "") + " form-control form-control-sm"}
                            onChange={this.cambioValor}
                          />

                        </div>
                        <div className="form-group">
                          <label htmlFor="tipo">Solucion:</label>

                          <textarea
                            rows="4"
                            name="registro_inc"
                            id="solucion"
                            className={((this.verificarError("error_tipo")) ? "is-invalid" : "") + " form-control form-control-sm"}
                            onChange={this.cambioValor}
                            defaultValue={empleado.registro_inc}
                          />
                          <small id="" className="text-muted">
                            Digite Concentración
                          </small>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor="id">Fecha registro :</label>
                              <input
                                type="text"
                                name="fecha"
                                id="fecha"
                                value={empleado.fecha_registro_inc}
                                className="form-control form-control-sm"
                                placeholder=""
                                aria-describedby="helpId"
                                onChange={this.cambioValor}
                                readOnly
                              />

                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor="id">fecha solución :</label>
                              <input
                                type="text"
                                name="fecha_mod_reg"
                                id="fecha_mod"
                                value={empleado.fecha_mod_reg}
                                className="form-control form-control-sm"
                                placeholder=""
                                aria-describedby="helpId"
                                onChange={this.cambioValor}
                                readOnly
                              />
                           
                            </div>
                          </div>
                        </div>


                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="id">Radicado :</label>
                          <input
                            type="text"
                            name="id_incidencia"
                            id="id_incidencia"
                            value={empleado.id_incidencia}
                            className="form-control form-control-sm"
                            placeholder=""
                            aria-describedby="helpId"
                            onChange={this.cambioValor}
                            readOnly
                          />

                        </div>
                        <div className="form-group">
                          <label htmlFor="estado">Estado:</label>
                          
                          <input
                            type="text"
                            name="estado_inc"
                            id="estado"
                            defaultValue={empleado.estado_inc}
                            className={((this.verificarError("error_forma")) ? "is-invalid" : "") + " form-control form-control-sm"}
                            onChange={this.cambioValor}
                            required
                          />
                          
                        </div>
                        <div className="form-group">
                          <label htmlFor="forma">Paciente:
                          
                                                                        </label>

                          <input
                            type="hidden"
                            name="id_paciente"
                            id="idp"
                            defaultValue={empleado.id_paciente}
                            className={((this.verificarError("error_forma")) ? "is-invalid" : "") + " form-control form-control-sm"}
                            onChange={this.cambioValor}
                          />
                          <div className="row">
                            <div className="col-sm-5">
                            <input
                            type="text"
                            name="nom"
                            id="nom"
                            defaultValue={empleado.nombres}
                            className={((this.verificarError("error_forma")) ? "is-invalid" : "") + " form-control form-control-sm"}
                            onChange={this.cambioValor}
                          />
                            </div>
                            <div className="col-sm-5">
                            <input
                            type="text"
                            name="ape"
                            id="ape"
                            defaultValue={empleado.apellidos}
                            className={((this.verificarError("error_forma")) ? "is-invalid" : "") + " form-control form-control-sm"}
                            onChange={this.cambioValor}
                          />
                            </div>
                            <div className="col-sm-2">
                            <img
                                                                            style={{ width: 40 }}
                                                                            className='form-control form-control-sm end'
                                                                            src={findImage}
                                                                            alt="Buscar Paciente"
                                                                            data-bs-toggle='modal' data-bs-target='#nuevaEnfermedad1'
                                                                        />
                            </div>

                          </div>
                          
                          
                        </div>
                        <div className="form-group">
                          <label htmlFor="cuenta">Asignado:
                          
                                                                         </label>
                                                                         <div className="row">
                                                                           <div className="col-sm-10">
                                                                           <input
                            type="text"
                            name="asignado_inc"
                            id="asignado"
                            defaultValue={empleado.asignado_inc}
                            onChange={this.cambioValor}
                            className="form-control form-control-sm"
                          />
                                                                           </div>
                                                                           <div className="col-sm-2">
                                                                           <img
                          style={{ width: 40 }}
                          className='form-control form-control-sm end'
                          src={findImage}
                          alt="Buscar Usuario"
                          data-bs-toggle='modal' data-bs-target='#buscarUsuario'
                      />
                                                                           </div>

                                                                         </div>
                          

                        </div>
                        <div className="form-group">
                          <label htmlFor="categoria">Categoria:</label>
                          <input
                            type="text"
                            name="categoria_inc"
                            id="categoria"
                            defaultValue={empleado.categoria_inc}
                            onChange={this.cambioValor}
                            className="form-control form-control-sm"
                          />

                        </div>
                        <div className="form-group">
                          <label htmlFor="tipo">Tipo:</label>
                          <input
                            type="text"
                            name="tipo_inc"
                            id="tipo"
                            defaultValue={empleado.tipo_inc}
                            onChange={this.cambioValor}
                            className="form-control form-control-sm"
                          />

                        </div>
                      </div>

                    </div>



                    <br />
                    <div className="btn-group" role="group" aria-label="">
                      <button type="submit" className="btn btn-warning btn-sm">
                        Editar
                      </button>&nbsp;&nbsp;
                      <Link className="btn btn-danger btn-sm" to={"/MasterSoporte"}>
                        Cancelar
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}


export default Editar;
